import React, {useReducer, useEffect, useState} from 'react';
import {
    Link,
    useNavigate
} from 'react-router-dom';
import './Navigation.css';
import Logo1  from '../../assets/images/brain-trainer-logo.svg';
import user_icon  from '../../assets/images/user-icon.png';
import {MenuContext} from '../sideMenu/SideMenuContext';
import { useSelector, useDispatch } from 'react-redux';
//import {userService} from '../../services/user.services';
//import { useDispatch } from 'react-redux';
import { getEntities } from '../../api/restUtilities';
import { BaseURL } from '../../constants/URLs';
import axios from 'axios';
//import Moment from 'moment';


//import React, {useEffect, useState} from 'react';
//import styles from './drop-down.module.style.css';
//import useMeasure from 'react-use-measure';
//import dropdownButton from '../../assets/images/ui-images/Drop down button-01.svg'

const DropdownBox = (props) => {
      
    const [showUserMenu, setShowUserMenu] = useState(false);
    const [options, setOptions] = useState([])//props.options && [...props.options]);
    const [defaultOption, setDefaultOptions] = useState(props.defaultValue);



    const toggleUserMenu = () => setShowUserMenu(!showUserMenu);

    useEffect(()=>{

        if(props.options)
            setOptions([...props.options])
    },[props.options])

    useEffect(()=>{
        setDefaultOptions(props.defaultValue)
    },[props.defaultValue])
    //const [ref2, bounds] = useMeasure();

    const ref = React.createRef();
//
    function hideMenu(e){
        if(showUserMenu)
        {
            let rectBound = ref.current ? ref.current.getBoundingClientRect() : null;

            if(rectBound)
            {
                var rectTarget = e.target.getBoundingClientRect();
                const {x, y, width, height} = rectBound;//rectTarget;
                const mouseY = e.clientY;
                const mouseX = e.clientX;
                console.log("Size", rectBound)
                console.log("Pos", {yr: e.clientY, xr: e.clientX, yb: ref.current, ty:rectTarget})

                //if(mouseY > y && mouseY < (y + height) && mouseX > x && mouseX < (x + width))
                if(mouseY < y || mouseY > (y + height) || mouseX < x || mouseX > (x + width))
                    setShowUserMenu(false);
            }
        }
        
        
    }//, [showUserMenu])

    const handleKeyDown = (evt) => {
        evt = evt || window.event;
        var isEscape = false;
        if ("key" in evt) {
            isEscape = (evt.key === "Escape" || evt.key === "Esc");
        } else {
            isEscape = (evt.keyCode === 27);
        }
        if (isEscape) {
            setShowUserMenu(false);
        }
    }
    
    useEffect(()=>{

        document.body.addEventListener("click", hideMenu );
        document.body.addEventListener("keydown", handleKeyDown )

        return () => {
            document.body.removeEventListener("click", hideMenu);
            document.body.removeEventListener("keydown", handleKeyDown )
        };

    },[showUserMenu])

    const setSelectedItem = (option) =>
    {
        props.onSelectedHandler && props.onSelectedHandler(option);

        setDefaultOptions(option)

        setShowUserMenu(false)
    }

    return (
        <div style={{position:'relative', /* minWidth:100, */ /* width:'100%', */ margin:2, display:'flex', flexDirection:'column', border:'0px solid #ce730f', justifyContent:'center', borderRadius:20, height:40, /* alignItems:'center', */ background:`none`, }}>
                    
            <div style={{display:'flex'}} onClick={(e)=>{e.stopPropagation(); toggleUserMenu()}}>
               {/*  <div style={{flexGrow:1, fontSize:16, fontFamily:'chewy', background:'none', padding:'10px 20px'}}>{defaultOption}</div><div style={{width:30, cursor:'pointer', height:30, margin:5, background:'black'}} onClick={(e)=>{e.stopPropagation(); toggleUserMenu()}}></div> */}
               {props.buttonElement}
            </div>
            <div style={{position:'relative'}}>
                {/*  <div style={{}}></div> */}
                <div ref={ref} className={'user-info-menu'} 
                    style={{
                        position:'absolute',
                        top:10,
                        right:0,
                        //background:'#eeffff',
                        borderRadius:10,
                        boxShadow:'2px 2px 5px rgba(120,120,120,0.4)',
                        zIndex:1,
                        minWidth:258,
                        maxHeight:400,
                        minHeight:100,
                        overflowY:'auto',
                        width:'100%',
                        border:'1px solid rgb(91 91 91 / 23%)',
                        display:`${showUserMenu? 'block': 'none'}`
                    }}>
                       {props.children}
                </div>
            </div>
            
        </div>
    );
}

//export default DropdownInput;

const SideMenuButton= (props) =>{
    return (
        <div  onClick={()=>props.onClick()} className={`side-menu-button${props.isActive ? ' side-menu-button-active':''}`}>
            <div className={`side-menu-button-bar ${props.isActive ? 'side-menu-button-bar-1-active':'side-menu-button-bar-1'}`}></div>
            <div className={`side-menu-button-bar ${props.isActive ? 'side-menu-button-bar-2-active':'side-menu-button-bar-2'}`}></div>
            <div className={`side-menu-button-bar ${props.isActive ? 'side-menu-button-bar-3-active':'side-menu-button-bar-3'}`}></div>
        </div>
    )
}

const NavigationBar = (props) => {

    let history = useNavigate();

    const user = useSelector(state => state.user);
    const notificationsCount = useSelector(state => state.unreadNotificationsCount);
    
    const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(notificationsCount || 0)
    
    
    const dispatch = useDispatch();

    const [showUserMenu, setShowUserMenu] = useState(false)

    const toggleUserMenu = () => setShowUserMenu(!showUserMenu)

    const ref = React.createRef();

    function handleNavigation(toLink) {
        history(toLink);
    }

    const logoutUser = (e) => {
        //signOutUser(dispatch);
        //userService.logout();
        dispatch({type:"LOGOUT_USER"})
        e.preventDefault(); //userService.logout()
    }

    useEffect(()=>{

        const hideMenu =() => {
            setShowUserMenu(false);
        }

        document.body.addEventListener("click", hideMenu )

        return () => {
            document.body.removeEventListener("click", hideMenu);
        };

    },[])

    useEffect(()=>{

        setInterval(()=> getUnreadNotificationsCount({url:BaseURL + 'api/v1/Notifications/GetUserUnreadNotificationsCount', successCallback:(d)=>console.log("Not", d)}), 120000);

    },[])

    const getUnreadNotificationsCount = ({url, successCallback, failedCallback}) => {
        axios.get(url).then((r)=>
        {   
            successCallback && successCallback(r.data)

            setUnreadNotificationsCount(r.data?.unreadNotifications);
            dispatch({type:"SET_UNREAD_NOTIFICATIONS_COUNT", data:r.data?.unreadNotifications || 0})
        }).catch((e)=>{
            console.log(e)
            failedCallback && failedCallback()
        })
    }

    return (
        <MenuContext.Consumer>
            {({isOpen, setIsOpen}) => (<div className={"navigation-bar"}>
            <div>
                {props.showSideMenuButton && <SideMenuButton onClick={()=>setIsOpen(true)}  isActive={isOpen} />}
                
                <Link to="/" style={{height:'98%', textDecoration:'none'}} className="app-logo" >
                    
                    <img src={Logo1} style={{height:36,fontFamily:'Dancing Script'}} alt="logo" />
                    {/*<img src={LogoText}  className="app-logo" alt="logo" />*/}
                    <div style={{display:'flex', flexDirection:'column'}}>
                    <div className={"app-title"} style={{fontWeight:'normal'}}>Oda<span style={{opacity:1.0, color:"#f7941e"}}>Now</span></div>
                    <div className="user-info-email" style={{fontSize:10, textAlign:'center'}}>{"v1.3"} <span style={{fontStyle:'italic', fontWeight:'normal', color:'orange'}}>Beta</span></div>
           
                    </div>
                    
                </Link>
                 </div>
            <div style={{padding:10}}>
            
{/* 
            <div style={{position:'relative'}}>
                <div className="user-info-avartar" style={{ overflow:'hidden'}}>
                    <img width={24} src={user_icon} />   

                </div>
                
            </div>
            <div className="user-info-wrapper">
                    <div className="user-info-name">{`${user && user.fullName ? `Hi, ${user.fullName}` : "Guest"}`}</div>
                    <div className="user-info-email" style={{fontSize:12}}><span style={{fontStyle:'italic', fontWeight:'normal', color:'green'}}>{`@${user.accountDomainName}`}</span></div>
                    
                    
            </div> */}
            <div>
                <span className='notifications-count' style={{background:notificationsCount > 0 ?'red':'#e7e7e7'}}>{notificationsCount}</span>
            </div>

            <DropdownBox buttonElement={<> <div style={{/* boxShadow:'2px 2px 3px rgba(150, 150, 150, 0.5)' */ overflow:'hidden'}}>
                    {/* <img width={24} src={user_icon} />   */} 
                    <div className="user-info-avartar">
                    {user.fullName?.charAt(0)}                    
                </div>
                </div><div className="user-info-wrapper">
                    <div className="user-info-name" style={{fontSize:11, maxWidth:'100%', overflow:'hidden', textOverflow:'ellipsis'}}>{`${user && user.fullName ? `Hi, ${user.fullName}` : "Guest"}`}</div>
                    <div className="user-info-email" style={{fontSize:11}}><span style={{fontStyle:'italic', fontWeight:'normal', color:'green'}}>{`@${user?.accountDomainName}`}</span></div>
                    
                    
            </div></>}>
            {user ? <>
            <div style={{position:'relative', display:'flex', flexDirection:'column', alignItems:'center'}}>
                <div onClick={(e)=>{e.stopPropagation(); toggleUserMenu()}} className="user-info-avartar">
                    {user.fullName?.charAt(0)}                    
                </div>
                <div className="user-info-email" style={{fontSize:12}}><span style={{fontStyle:'italic', fontWeight:'normal', color:'green'}}>{`@${user.accountDomainName}`}</span></div>
                
                {/* <div className={'user-info-menu'} style={{display:`${showUserMenu? 'block': 'none'}`}}>
                        <ul style={{padding:0}}>                           
                            <li><div className='.app-button-primary' href={null} onClick={logoutUser}>Sign Out</div></li>
                        </ul>
                </div> */}
            {/* </div>
            <div className="user-info-wrapper"> */}
                    <div className="user-info-name">{`${user.fullName}`}</div>

                    <div className="user-info-email" style={{fontSize:12}}>{user.email}</div>
                    <div className='app-button-primary' style={{margin:10}} onClick={logoutUser}>Sign Out</div>
                    
            </div>
           
            </>:
            <>
                <button className={'navigation-bar-button-1'} onClick={()=>handleNavigation('/login')}>Login</button>
                <button className={'navigation-bar-button-2'} onClick={()=>handleNavigation('/signup')}>Sign Up</button>
            </>}
            </DropdownBox>
           
            
          {/*   {user ? <>
            <div style={{position:'relative'}}>
                <div onClick={(e)=>{e.stopPropagation(); toggleUserMenu()}} className="user-info-avartar">
                    {user.firstName?.charAt(0)}                    
                </div>
                <div className={'user-info-menu'} style={{display:`${showUserMenu? 'block': 'none'}`}}>
                        <ul style={{padding:0}}>                           
                            <li><a href={null} onClick={logoutUser}>Sign Out</a></li>
                        </ul>
                </div>
            </div>
            <div className="user-info-wrapper">
                    <div className="user-info-name">{`${user.firstName} ${user.lastName}`}</div>

                    <div className="user-info-email" style={{fontSize:12}}>{user.email}</div>
                    
            </div>
           
            </>:
            <>
                <button className={'navigation-bar-button-1'} onClick={()=>handleNavigation('/login')}>Login</button>
                <button className={'navigation-bar-button-2'} onClick={()=>handleNavigation('/signup')}>Sign Up</button>
            </>} */}


            </div>
            </div>)} 
        </MenuContext.Consumer>
    );
}

export default NavigationBar;