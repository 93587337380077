import React, { memo, useEffect } from "react";
import increaseIcon from './images/increase-icon.svg';
import decreaseIcon from './images/decrease-icon.svg';
import { useState } from "react";
import './NumberField.css'

export const NumberField = memo(({name, value, caption, config, onChange, minValue, maxValue}) => {

    const [data, setData] = useState(value ? parseInt(value) : 0);

    useEffect(()=>{
        onChange && onChange({name: name, value:data})
    },[data])

    useEffect(()=>{
        console.log("Number",value)
        //setValue(data)
    },[value])

    useEffect(()=>{
        console.log("Number",value)
        setData(value)
    },[])

    const onChangeHandler = (e) => {
        const {name, value : v} = e.target;
        console.log("vvv",v)
        //setValue(parseInt(v))
    }

    return <div className="number-field-wrapper">
        
        
        <button className="number-field-button"  style={{opacity:minValue === undefined || minValue === null ? 1.0 : data<=minValue ? 0.2 : 1.0}}  onClick={()=>setData(v => minValue === undefined || minValue === null ? parseFloat(v) - 1 :  v > minValue ? parseFloat(v) - 1 : v)}>
            <img src={decreaseIcon} width={12}/>
        </button>
        <input style={{textAlign:'center'}} onChange={onChangeHandler} name={name} className={'number-field-input'} value={data} type="text"></input>
        <button className="number-field-button" style={{opacity:maxValue === undefined || maxValue === null ? 1.0 : data>=maxValue ? 0.2 : 1.0}}  onClick={()=>setData(v => maxValue === undefined || maxValue === null ? parseFloat(v) + 1 :  v < maxValue ? parseFloat(v) + 1 : v)}>
            <img src={increaseIcon} width={12}/>
        </button>
       
        </div>
},(p,c)=> p.value !== c.value)




export default NumberField