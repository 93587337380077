import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import OrderItemsListView from './OrderItemsListView';
import { BaseURL } from '../../constants/URLs';
import { getEntities } from '../../api/restUtilities';
import { useState } from 'react';
import ToggleSelect from '../ToggleSelect/ToggleSelect';
import PopUp from '../PopUp/PopUp';
import SearchListView from '../SearchListView/SearchListView';
import { v4 as uuidv4 } from 'uuid';

import defaultIcon from '../../assets/images/default-inventory-icon.svg';
import TabbedView from '../TabbedView/TabbedView';
import Html, { Document, Page } from 'react-pdf-html';
//import ReactDOMServer from 'react-dom/server';

const InvoiceSettings = ({ invoiceData, handleSettingsChange }) => {

    const [selectedThemeColors, setSelectedThemeColors] = useState([])
    const [selectedLayout, setSelectedLayout] = useState([])
    const [selectedDueDate, setSelectedDueDate] = useState(invoiceData?.dueDate)
    const [selectedInvoiceReminderDuration, setSelectedInvoiceReminderDuration] = useState(invoiceData?.invoiceReminderDuration)

    useEffect(() => {
        console.log("SS", { ss: invoiceData })
        setSelectedDueDate(invoiceData?.dueDate);
        setSelectedInvoiceReminderDuration(invoiceData?.invoiceReminderDuration)
    }, [invoiceData?.dueDate, invoiceData?.invoiceReminderDuration])
    const themeColors = [
        {
            color1: '#de6700',
            color2: '#f6e5d6',
            color3: 'blue',
            color4: 'blue',
        },
        {
            color1: 'blue',
            color2: '#d6ebff',
            color3: 'grey',
            color4: 'blue',
        },
        {
            color1: '#ac01e5',
            color2: '#e1d0ff',
            color3: 'grey',
            color4: 'purple',
        },
        {
            color1: 'black',
            color2: '#efefef',
            color3: 'grey',
            color4: 'purple',
        },
        {
            color1: 'red',
            color2: 'yellow',
            color3: 'grey',
            color4: 'purple',
        }
    ]

    const getDateInputValue = dateValue => {
        var date = new Date(dateValue);

        return date.getFullYear() + "-" + ("00" + (date.getMonth() + 1)).slice(-2) + "-" +
            ("00" + date.getDate()).slice(-2);
        /*  +
        ("00" + date.getHours()).slice(-2) + ":" +
        ("00" + date.getMinutes()).slice(-2) + ":" +
        ("00" + date.getSeconds()).slice(-2); */
    }

    const layouts = [
        {
            title: 'Layout 1',
            name: 'BASIC_LAYOUT_1'
        },
        {
            title: 'Layout 2',
            name: 'BASIC_LAYOUT_2'
        }
    ]
    return (
        <div style={{ padding: 20, }}>
            <div>
                <div style={{fontSize:14, fontWeight:'bold',padding:'10px 0px', borderBottom:'1px solid grey'}}>Invoice Options</div>
                <div>
                    <div className='input-field'>
                        <label className='form-label'>Due Date</label>
                        <div style={{ display: 'inline-flex', flexWrap: 'wrap' }}>
                            <input className='form-text-input' valueAsDate value={getDateInputValue(selectedDueDate)}
                                onChange={(e) => handleSettingsChange("dueDate", new Date(e.target.value))} type="date" />
                        </div>
                    </div>
                    <div className='input-field'>
                        <label className='form-label'>Is Shipping Included</label>
                        <div style={{ display: 'inline-flex', flexWrap: 'wrap' }}>
                            <input className='form-text-input' checked={invoiceData?.isShippingCostIncluded}
                                onChange={(e) => handleSettingsChange("IsShippingCostIncluded", e.target.checked)} type="checkbox" />
                        </div>
                    </div>
                    <div className='input-field'>
                        <label className='form-label'>Footer Text</label>
                        <div style={{ display: 'inline-flex', flexWrap: 'wrap' }}>
                            <input className='form-text-input' valueAsDate value={invoiceData?.footerText}
                                onChange={(e) => handleSettingsChange("footerText", e.target.value)} />
                        </div>
                    </div>



                    <div className='input-field'>
                        <label className='form-label'>Send Reminders every:</label>
                        <div style={{ display: 'inline-flex', alignItems:'center' }}>
                            <input className='form-text-input' value={selectedInvoiceReminderDuration}
                                onChange={(e) => handleSettingsChange("invoiceReminderDuration", parseInt(e.target.value))} type="number" /> <span style={{padding:5}}>Hrs</span>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                
                <div style={{fontSize:14, fontWeight:'bold',padding:'10px 0px', borderBottom:'1px solid grey'}}>Theme</div>
                
                {invoiceData?.theme && <><label className='form-label'>Selected them colors</label>
                    <div style={{ display: 'inline-flex', flexWrap: 'wrap' }}>
                        <div style={{ display: 'flex', margin: 10, alignItems:'center', flexDirection: 'column' }}>
                            <div style={{
                                //width: 80, height: 80, 
                                //borderRadius:'50%',
                                overflow:'hidden',
                                border: '0px solid red', margin: 5, display: 'inline-flex', flexWrap: 'wrap', cursor: 'pointer', background: 'none'
                            }}>
                                <div style={{ width: 40, height: 40, margin:10, borderRadius:'50%', background: invoiceData?.theme.color1 }}></div>
                                <div style={{ width: 40, height: 40, margin:10, borderRadius:'50%', background: invoiceData?.theme.color2 }}></div>
                                {/* <div style={{ width: 40, height: 40, background: invoiceData?.theme.color3 }}></div>
                                <div style={{ width: 40, height: 40, background: invoiceData?.theme.color4 }}></div> */}
                            </div>
                            <span>Theme</span>
                        </div>
                    </div></>}
                <div>
                    <div className='input-field'>
                        <label className='form-label'>Select theme colors</label>
                        <div style={{ display: 'inline-flex', flexWrap: 'wrap' }}>
                            {themeColors.map((c, index) => <div
                             onClick={() => handleSettingsChange("theme", invoiceData?.theme ? { ...invoiceData?.theme, ...c } : { ...c })}
                                   
                            style={{ display: 'flex', margin: 10, alignItems:'center', flexDirection: 'column' }}>
                                <div
                                    style={{
                                        width: 'auto', 
                                        height: 50,
                                        //borderRadius:'50%',
                                        //overflow:'hidden',
                                        border: '0px solid red', margin: 5, display: 'inline-flex', flexWrap: 'wrap', cursor: 'pointer', background: 'none'
                                    }}>
                                    <div style={{ width: 25, margin:10, borderRadius:'50%', height: 25, background: c.color1 }}></div>
                                    <div style={{ width: 25, margin:10, borderRadius:'50%', height: 25, background: c.color2 }}></div>
                                    {/* <div style={{ width: 25, height: 25, background: c.color3 }}></div>
                                    <div style={{ width: 25, height: 25, background: c.color4 }}></div> */}
                                </div>
                                <span>Theme {index + 1}</span>
                            </div>)}
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div style={{fontSize:14, fontWeight:'bold',padding:'10px 0px', borderBottom:'1px solid grey'}}>Layout</div>
                <div>
                    <div className='input-field'>
                        <label className='form-label'>Select a layout</label>
                        <div style={{ display: 'inline-flex', flexWrap: 'wrap' }}>
                            {layouts.map((l, ind) => <div onClick={() => handleSettingsChange("theme", invoiceData?.theme ? { ...invoiceData?.theme, layout: l.name } : { layout: l.name })} style={{
                                width: 100, height: 150,
                                display:'flex',
                                justifyContent:'center',
                                alignItems:'center',
                                flexDirection:'column',
                                border: invoiceData?.theme?.layout === l.name ? '1px dotted red' : '', borderRadius: 2, boxShadow: '2px 2px 5px -2px rgba(10,10,10,0.6)', margin: 5, background: '#ffedcc'
                            }}>
                                <span style={{fontSize:50, color:invoiceData?.theme?.layout === l.name ? '#ff9900': '#999999'}}>{ind + 1}</span>
                                {l.title}</div>)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export const InvoiceEditor = ({ orderId, orderData, stageInfo, stageCallbackHandler }) => {

    //const items = useSelector(state => state.orderItems.filter(o => o.orderId == orderId));

    const [invoiceItems, setInvoiceItems] = useState(orderData?.invoice && orderData.invoice.invoiceItems ? [...orderData.invoice.invoiceItems] : [])
    const [customer] = useState(orderData?.customerInfo ? { ...orderData?.customerInfo } : {})
    const [order] = useState(orderData ? { ...orderData } : {})
    const [invoiceData, setInvoiceData] = useState({})
    const user = useSelector(state => state.user);


    const accountConfiguration = useSelector(state => state.accountConfiguration);

    console.log("INVOICE ORDER ITEMS", invoiceItems)

    const dispatch = useDispatch();

    var date = new Date(Date.now() + (3600 * 1000 * 73));
    var dateStr =
        ("00" + (date.getMonth() + 1)).slice(-2) + "/" +
        ("00" + date.getDate()).slice(-2) + "/" +
        date.getFullYear() + " " +
        ("00" + date.getHours()).slice(-2) + ":" +
        ("00" + date.getMinutes()).slice(-2) + ":" +
        ("00" + date.getSeconds()).slice(-2);

    console.log(dateStr);

    useEffect(() => {
        setInvoiceData({
            ...orderData?.invoice,
            "invoiceNumber": orderData.orderNumber,
            "title": orderData.orderNumber,
            "description": "string",
            "payTo": {
                bankName: orderData.invoice?.payTo?.bankName || accountConfiguration?.bankName,
                bankAccountName: orderData.invoice?.payTo?.bankAccountName || accountConfiguration?.bankAccountName,
                bankAccountNumber: orderData.invoice?.payTo?.bankAccountNumber || accountConfiguration?.bankAccountNumber,
                currency: orderData.invoice?.payTo?.currency || 'NGN'
            },

            "billTo": orderData && orderData.customerInfo ? { ...orderData.customerInfo } : null,
            "logoData": accountConfiguration?.businessLogo,
            //"merchantSignature": "",
            //"customerSignature": "",
            "layout": "1",
            "isShippingCostIncluded": orderData?.invoice?.isShippingCostIncluded || false,
            "theme": orderData?.invoice?.theme || {
                "layout": 'BASIC_LAYOUT_1',
                "color1": 'orange',
                "color2": 'lightOrange',
                "color3": 'black',
                "color4": 'yellow',
            },
            "invoiceReminderDuration": orderData?.invoice?.invoiceReminderDuration || 24,
            "dueDate": orderData?.invoice?.dueDate || date.toString(),//(Date.now() + (3600*1000*73)).toString(),
            invoiceItems: invoiceItems ? [...invoiceItems] : []
        })
    }, [])


    console.log("Date Time", dateStr)//.toLocaleDateString("en-US"))



    useEffect(() => {
        let invoiceDataXXX = {
            "invoiceNumber": orderData.orderNumber,
            "title": orderData.orderNumber,
            "description": "string",
            "payTo": {
                bankName: accountConfiguration?.bankName,
                bankAccountName: accountConfiguration?.bankAccountName,
                bankAccountNumber: accountConfiguration?.bankAccountNumber,
                currency: 'NGN'
            },

            "billTo": orderData && orderData.customerInfo ? { ...orderData.customerInfo } : null,
            "logoData": accountConfiguration?.businessLogo,
            //"merchantSignature": "",
            //"customerSignature": "",
            "layout": "1",
            "theme": {
                "layout": 'BASIC_LAYOUT_1',
                "color1": 'orange',
                "color2": 'lightOrange',
                "color3": 'black',
            },
            "dueDate": Date.now() + (3600 * 1000 * 73),
            invoiceItems: invoiceItems ? [...invoiceItems] : []
        }

        stageCallbackHandler && stageCallbackHandler("updateInvoiceItems", { ...invoiceData, invoiceItems: invoiceItems ? [...invoiceItems] : [] })
    }, [invoiceItems])

    const handeInvoiceDataChange = (fieldName, fieldData) => {

        console.log({ fieldName, fieldData })

        //handeInvoiceDataChange
        stageCallbackHandler && stageCallbackHandler("updateInvoiceField", { fieldName, fieldData })
        setInvoiceData(i => ({ ...i, [fieldName]: fieldData }))
        //stageCallbackHandler
    }

    const handleItemChange = (itms) => {
        //stageCallbackHandler("updateItems", [...itms])
        //setOrderItems([...itms]);

        //console.log("invoice data", itms)
        //itms.map(itm => dispatch({type:'SET_ORDER_ITEM', data:itm}))

        setInvoiceItems(itms)

        /*  let invoiceData = {
             "invoiceNumber": orderData.orderNumber,
             "title": "string",
             "description": "string",
             "payTo": {
                 bankName: accountConfiguration?.bankName,
                 bankAccountName: accountConfiguration?.bankAccountName,                
                 bankAccountNumber: accountConfiguration?.bankAccountNumber
             },
             "billTo": orderData && orderData.customerInfo ? {...orderData.customerInfo} : null,
             "logoData": accountConfiguration?.businessLogo,
             "merchantSignature":"",
             "customerSignature":"",
             "layout": "1",
             invoiceItems:[...itms]
         } */

        stageCallbackHandler && stageCallbackHandler("updateInvoiceItems", { ...invoiceData, invoiceItems: [...itms] })
    }

    const handeFieldChange = (invoiceFields) => {
        stageCallbackHandler && stageCallbackHandler("updateInvoiceFields", invoiceFields)
        //setInvoiceData(i => ({...i, ...invoiceFields}))
    }



    useEffect(() => {
        console.log("stage info", stageInfo)
        getEntities({
            url: `${BaseURL}api/v1/ordercatalogue/getorderstage?id=${stageInfo?.orderStageId}&catalogueId=${orderData?.catalogueId}`,
            successCallback: (d) => {
                console.log("stage data", { d, orderData })
            }
        });
    }, [stageInfo, orderData])

    const [invoiceZoomScale, setInvoiceZoomScale] = useState(1.0);
    const [isEditModeOn, setIsEditModeOn] = useState(false);

    const currentUser = useSelector(state => state.user);
    //const inventory = useSelector(state => state.inventory) 
    const [showAddItemPopup, setShowAddItemPopup] = useState(false)
    const showItemPopup = () => {
        setSelectedItems([])
        setShowAddItemPopup(true)
    }
    const addItem = () => {
        //setItems(i => [...i, ...selectedItems]);
        setInvoiceItems(i => [...i, ...selectedItems])
        setSelectedItems([])
    }

    const [selectedItems, setSelectedItems] = useState([])

    const handleItemSelectionChange = (item) => {
        setSelectedItems(s => s.some(si => si.inventoryItemId === item.id) ? [...s.filter(si => si.inventoryItemId != item.id)] : [...s, { ...item, id: uuidv4(), inventoryItemId: item.id, name: item.name, unitCost: item.sellingPrice, quantity: 0, availableQuantity: item.quantity }])
    }
    const renderComponent = ({ data }) => {

        return (<div className='item-card' style={{ margin: 0, height: 120, boxShadow: '2px 2px 11px -2px rgb(20 20 20 / 20%)', border: selectedItems.some(s => s.inventoryItemId === data?.id) ? '2px solid orange' : 0, marginBottom: 10, alignItems: 'center', width: '100%' }}>
            <div style={{ padding: 15, display: 'flex', width: '100%', justifyContent: 'left', alignItems: 'center', fontSize: 20 }}>

                <button className='app-button-secondary-small' onClick={e => handleItemSelectionChange(data)} type="checkbox" >Select</button>
                {/*  <div style={{background:'aqua', padding:15, width:20, height:20, fontSize:13, margin:10}} className="user-info-avartar">
               {data.firstName?.toUpperCase()?.charAt(0)}                    
           </div> */}
                {/*  <img  src={defaultIcon} style={{height:50, background:'none', borderRadius:5,   margin:5, marginRight:20}} alt="logo" />  */}
                <div style={{ padding: 15, display: 'flex', width: '100%', justifyContent: 'left', alignItems: 'center', fontSize: 20 }}>

                    <img src={defaultIcon} style={{ height: 20, background: 'none', borderRadius: 5, margin: 5, marginRight: 10 }} alt="logo" />
                    <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>

                        <div style={{ display: 'flex' }}>{data.title}</div>
                        <div style={{ display: 'flex', color: '#888888', paddingTop: 5, fontSize: 16 }}>{`${data.availableQuantity} Available`}</div>
                        <div style={{ display: 'flex', color: 'green', paddingTop: 5, fontSize: 16 }}>{/* `\u20A6` */}{`NGN ${data.unitPrice} `}</div>


                    </div>

                </div>

            </div>
        </div>)
    }

    /*   const html = `<html>
      <body>
        <style>
          .my-heading4 {
            background: darkgreen;
            color: white;
          }
          pre {
            background-color: #eee;
            padding: 10px;
          }
        </style>
        <h1>Heading 1</h1>
        <h2 style="background-color: pink">Heading 2</h2>
        <h3>Heading 3</h3>
        <h4 class="my-heading4">Heading 4</h4>
        <p>
          Paragraph with <strong>bold</strong>, <i>italic</i>, <u>underline</u>,
          <s>strikethrough</s>,
          <strong><u><s><i>and all of the above</i></s></u></strong>
        </p>
        <p>
          Paragraph with image <img src="${defaultIcon}" /> and
          <a href="http://google.com">link</a>
        </p>
        <hr />
        <ul>
          <li>Unordered item</li>
          <li>Unordered item</li>
        </ul>
        <ol>
          <li>Ordered item</li>
          <li>Ordered item</li>
        </ol>
        <br /><br /><br /><br /><br />
        Text outside of any tags
        <table>
          <thead>
            <tr>
              <th>Column 1</th>
              <th>Column 2</th>
              <th>Column 3</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Foo</td>
              <td>Bar</td>
              <td>Foobar</td>
            </tr>
            <tr>
              <td colspan="2">Foo</td>
              <td>Bar</td>
            </tr>
            <tr>
              <td>Some longer thing</td>
              <td>Even more content than before!</td>
              <td>Even more content than before!</td>
            </tr>
          </tbody>
        </table>
        <div style="width: 200px; height: 200px; background: pink"></div>
        <pre>
    function myCode() {
      const foo = 'bar';
    }
    </pre>
      </body>
    </html>
    `; */

    const invoiceWrapperRef = useRef();
    const invoiceRef = useRef();

    useEffect(() => {
        if (invoiceWrapperRef.current && invoiceWrapperRef.current) {
            var outerDiv = invoiceWrapperRef.current;// document.getElementById('img-wrapper');
            var imgEle = invoiceRef.current;// document.getElementById(id);
            var scale = "scale(" + invoiceZoomScale + ");"
            var origin = "top";
            var translateX = '';
            if (outerDiv.clientWidth !== outerDiv.scrollWidth) {
                origin = "top left";
                translateX = ' translateX(' + (-imgEle.offsetLeft) + 'px) ';
            }

            var style = "-ms-transform:" + translateX + scale + "-webkit-transform:" + translateX + scale + "transform:" + translateX + scale + "transform-origin:" + origin + ";";
            imgEle.setAttribute("style", style);

            outerDiv.scrollTop = outerDiv.scrollHeight / 2 - outerDiv.clientHeight / 2;
            outerDiv.scrollLeft = outerDiv.scrollWidth / 2 - outerDiv.clientWidth / 2;
        }


    }, [invoiceZoomScale])

    return (<>
        {/* <div style={{ padding: 10, }}>Invoice </div> */}

        <TabbedView style={{ height: '100%', width: "100%", justifyContent: 'center' }} tabbedViewCaptions={["Invoice", "Settings"]}>


<div style={{width:'100%'}}>
        <div style={{ position:'fixed', height:45,  zIndex:10, top:10,}}>
        <div style={{display:'flex', flexDirection:'column', /* position:'fixed',  */zIndex:10, top:10,}}>
                <button className='invoice-view-menu-button' title='Download' ><img width={20} src={'images/download-icon.svg'}/></button>
                <button className='invoice-view-menu-button' title='Send' ><img width={20} src={'images/send-icon.svg'}/></button>
                <button className='invoice-view-menu-button' title='Zoom in'  onClick={()=>setInvoiceZoomScale(z => z < 3.5 ? z  + 0.2 : z)}><img width={20} src={'images/zoom-in-icon.svg'}/></button>
                <button className='invoice-view-menu-button' title='Zoom out'  onClick={()=>setInvoiceZoomScale(z => z >0.5 ? z -0.2 : z)}><img width={20} src={'images/zoom-out-icon.svg'}/></button>
                <button style={{background: isEditModeOn ? '#8ae68a' : '#CCCCCC'}} className='invoice-view-menu-button' title='Edit'  onClick={()=>setIsEditModeOn(s => !s)}><img width={20} src={'images/edit-icon.svg'}/></button>
               
        
            </div>
            {/* <div style={{display:'flex'}}>
                <div style={{display:'flex', fontSize:12, alignItems:'center'}}>
                    <span style={{color: isEditModeOn ? 'black' : '#CCCCCC'}}>Edit Mode</span>
            
                    <ToggleSelect onChange={(s)=>setIsEditModeOn(s)} isTrue={isEditModeOn}></ToggleSelect>
                    <span  style={{color: isEditModeOn ? '#CCCCCC' : 'black'}}>Preview Mode</span>
                </div>
        
            </div> */}
            
        </div>
        <div ref={invoiceWrapperRef} style={{ overflowX: 'auto', width: '100%', position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>



                <div ref={invoiceRef} className='invoice-wrapper' style={{ display: 'flex', width: 600, transformOrigin: 'center top', transform: `scale(${invoiceZoomScale})`, background: 'white', margin: 10, fontSize: 12, padding: 0, borderRadius: 0, boxShadow: 'rgb(30 30 30 / 82%) 2px 2px 20px -3px', flexDirection: 'column' }}>
                    <div style={{ minHeight: 60, padding: '10px 20px', width: '100%', display: 'flex', justifyContent: 'space-between', background: '#FFFFFF' }}>
                        <div style={{
                            margin: 5,
                            borderRadius: 10,
                            width: 80,
                            height: 80,
                            display: 'flex',
                            alignItems: 'center',
                            background: 'none'
                        }}>
                            {accountConfiguration?.businessLogo && <img style={{ width: '100%' }} src={accountConfiguration?.businessLogo} />}
                        </div>
                        <div style={{
                            margin: 5,
                            borderRadius: 3,
                            minWidth: 50,
                            flexGrow: 1,
                            minHeight: 50,
                            //background:'white',
                            display: 'flex',
                            flexDirection: 'column',
                        }}>


                            <span>{user?.name}</span>
                            <span>{user?.address}</span>
                            <span>{user?.phoneNumber}</span>
                            <span>{user?.email}</span>
                        </div>
                        <div style={{
                            margin: 5,
                            borderRadius: 3,
                            minWidth: 50,
                            flexGrow: 1,
                            fontSize: 28,
                            minHeight: 50,
                            //background:'white',
                            color: invoiceData?.theme?.color1 || '#de6700',
                            display: 'flex',
                            alignItems: 'flex-end',
                            flexDirection: 'column',
                        }}>
                            INVOICE
                            <span style={{ fontSize: 11 }}>{`#${orderData.orderNumber}`}</span>
                        </div>
                    </div>
                    <div style={{
                        minHeight: 80, padding: '10px 20px', width: '100%',
                        display: 'flex', justifyContent: 'space-between', background: invoiceData?.theme?.color2 || '#f6e5d6'
                    }}>
                        <div style={{
                            margin: 5,
                            borderRadius: 3,
                            minWidth: 50,
                            flexGrow: 1,
                            minHeight: 50,
                            //background:'white',
                            display: 'flex',
                            flexDirection: 'column',
                        }}>
                            <div style={{ paddingBottom: 5, fontWeight: 'bold' }}>Bill To:</div>
                            <span>{order?.customerInfo?.firstName}</span>
                            <span>{order?.customerInfo?.email}</span>
                            <span>{order?.customerInfo?.address}</span>
                            <span>{order?.customerInfo?.phoneNumber}</span>
                        </div>
                        <div style={{
                            margin: 5,
                            borderRadius: 3,
                            minWidth: 50,
                            flexGrow: 1,
                            minHeight: 50,
                            //background:'white',
                            display: 'flex',
                            flexDirection: 'column',
                        }}>
                            <div style={{ paddingBottom: 5, fontWeight: 'bold' }}>Payment To:</div>

                            <span>{invoiceData.payTo?.bankName}</span>
                            <span>{invoiceData.payTo?.bankAccountName}</span>
                            <span>{invoiceData.payTo?.accountName}</span>
                            <span>{invoiceData.payTo?.bankAccountNumber}</span>
                        </div>
                    </div>
                    <div style={{ padding: 0 }}>
                        <OrderItemsListView items={invoiceItems} theme={invoiceData?.theme} showItemPopup={showItemPopup} 
                        accountSignature={accountConfiguration?.accountSignature}
                        customerSignature={invoiceData?.customerSignature}
                        invoiceData={invoiceData}
                        tax={invoiceData?.vat}
                        discount={invoiceData?.discount} 
                        shipping={invoiceData?.shipping}
                        onItemChange={handleItemChange} handeFieldChange={handeFieldChange} isEditable={isEditModeOn} />
                        <div style={{ padding: 10, color: 'darkgrey', fontSize: 11 }}>{invoiceData.footerText}</div>
                    </div>



                </div>
            </div>
</div>
            
            <div style={{ width: '100%', maxWidth:700 }}>
                <InvoiceSettings invoiceData={invoiceData} handleSettingsChange={handeInvoiceDataChange} />
                <div>
                    {/*  <Document>
                    <Page> *
                    <Html>{html}</Html>
                    </Page>
                </Document> */}
                </div>
            </div>
        </TabbedView>
        {<PopUp styles={{ borderRadius: 9 }} isEmbedded={false} open={showAddItemPopup} >
            <div style={{
                width: '100%', height: '100%', display: 'flex', flexDirection: 'column',
                padding: 0,
                borderRadius: 10,
                overflow: 'hidden',
                boxShadow: '2px 2px 5px rgba(10,10,10,0.7)', minHeight: 50, background: 'white'
            }}>
                <div style={{ padding: 15, display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'normal', fontSize: 16 }}>
                    {/*  <img  src={defaultIcon} style={{height:50, background:'#ecf5ef', borderRadius:5,   margin:2, marginRight:20}} alt="logo" />  */}
                    <div style={{ display: 'flex', color: '#444444', padding: 20, flexDirection: 'column' }}>
                        {/** An async call should be made to fetch inventory */}

                        {/*  <div style={{display:'flex'}}><input></input><button>Search</button></div>
            
            {inventory.map(r => <div style={{display:'flex'}}>
                <input  onChange={e => handleItemSelectionChange(r)} type="checkbox" />
                <div style={{display:'flex', flexDirection:'column'}}>
                <span>{r.name}</span>
                <span>{r.quantity}</span>
                </div>
            </div>)} */}

                        <SearchListView renderComponent={renderComponent} dataUrl={BaseURL + `api/v1/Inventory/GetInventories?accountId=${currentUser?.accountId}`} />

                    </div>

                </div>


                <div style={{
                    width: '100%',
                    padding: 10,

                    background: '#EEEEEE', display: 'flex', justifyContent: 'space-between'
                }}>
                    <button className='app-button-primary' onClick={() => { addItem(); setShowAddItemPopup(false) }} >Add</button>
                    <button className='app-button-primary' onClick={() => setShowAddItemPopup(false)} >Close</button>
                </div>
            </div>
        </PopUp>}
    </>)
}
