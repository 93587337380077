import React, {useEffect, useState} from 'react';
import {
    Link,
    useNavigate
} from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';
import { useSpring, animated as a, config } from 'react-spring';
import LayoutWrapper from '../layout/LayoutWrapper';
import ViewWrapper from './ViewWrapper';
//import { deleteAsset } from '../redux/actions/assetActions';
import Prompt from '../components/Prompt/Prompt';
import searchIcon from '../assets/images/search.svg'
import PopUp from '../components/PopUp/PopUp';
import backgroundImage from '../assets/images/background_01.svg';
import {v4 as uuidv4} from 'uuid';
import axios from 'axios';
import { BaseURL,PIN_RESET_URL } from '../constants/URLs';

import { getEntities, postEntities, putEntities, deleteEntities } from '../api/restUtilities';


const SubAccountsView = (props)  => {
    
    const dispatch = useDispatch(); 

    let history = useNavigate();

    //const user = useSelector(state => state.user);

    const dummyCustomerData = 
        {
            "email": "user@example.com",
            "password": "string",
            "confirmPassword": "string",
            "firstName": "string",
            "lastName": "string",
            "isRootUser": true,
            "accountDomainName": "string"
        }
    
    
    const [openPromptDialog, setOpenPromptDialog] = useState(false);
    const [promptOkAction, setPromptOkAction] = useState(null);
    const [searchString, setSearchString] = useState("");
    const [showCreateSubAccountPopup, setShowCreateSubAccountPopup] = useState(false);
    
    const [selectedGame, setSelectedGame] = useState(null);
    const [users, setUsers] = useState(null);
    
    const [isEditing, setIsEditing] = useState(false);
    const [user, setUser] = useState({...dummyCustomerData})

    const currentUser  = JSON.parse(localStorage.getItem('currentUser'));

    useEffect(()=>{

        getEntities({url:BaseURL + 'api/v1/user/getusers?accountId=' + currentUser?.accountId, successCallback: setUsers});
      
    },[])

    
    const filterList = (searchStr, data, fieldName) => {

        console.log("data", data)
        if(data && fieldName)
        {
            return data.filter( d => d[`${fieldName}`]?.toLowerCase().match(searchStr.toLowerCase()))
        }
        else
        {
           return []; 
        }
    }

   
    const createSubAccount = () => {
        let newCustomerId = uuidv4();
        
        postEntities(
            {
                url:BaseURL + 'api/v1/user/registersubaccount?accountId=' + currentUser?.accountId, 
                data:{...user, id:newCustomerId},
                successCallback: ()=>{
                setShowCreateSubAccountPopup(false);
                getEntities({
                    url:BaseURL + 'api/v1/user/getusers?accountId=' + currentUser?.accountId, 
                    successCallback:setUsers});
                    setUser({...dummyCustomerData})
                },
                successNotification:{title:"Success", message:'Sub-account created'},
                errorNotification:{title:"Failed", message:'Sub-account creation failed'},
            }        
        )
    }

    const updateCustomer = () => {

        putEntities(
            {
                url:BaseURL + 'api/v1/user/update', 
                data:{...user},
                successCallback: ()=>{
                    setShowCreateSubAccountPopup(false);
                    getEntities({
                        url:BaseURL + 'api/v1/user/getusers?accountId=' + currentUser?.accountId, 
                        successCallback:setUsers});
                    setUser({...dummyCustomerData})
                },
                successNotification:{title:"Success", message:'Sub-account updated'},
                errorNotification:{title:"Failed", message:'Sub-account update failed'},
            }        
        )
    }

    const resetMemberPIN = (selectedUser) => {
        

        postEntities(
            {
                url: PIN_RESET_URL + '?accountId=' + currentUser?.accountId, 
                data:{...selectedUser},
                successCallback: ()=>{
                //setShowCreateSubAccountPopup(false);
                /*getEntities({
                    url:BaseURL + 'api/v1/user/getusers?accountId=' + currentUser?.accountId, 
                    successCallback:setUsers});
                    setUser({...dummyCustomerData})*/
                },
                successNotification:{title:"Success", message:'Sub-account PIN updated'},
                errorNotification:{title:"Failed", message:'Sub-account PIN update failed'},
            }        
        )
    }

    const deleteCustomer = (id) => {        

        deleteEntities(
            {
                url:BaseURL + 'api/v1/user/remove?id='+id, 
                data:{...user},
                successCallback: ()=>{
                    setShowCreateSubAccountPopup(false);
                    getEntities({
                        url:BaseURL + 'api/v1/user/getusers?accountId=' + currentUser?.accountId, 
                        successCallback:setUsers});                    
                },
                successNotification:{title:"Success", message:'Sub-account deleted'},
                errorNotification:{title:"Failed", message:'Sub-account delete failed'},
            }        
        )

    }

    const deleteModel = (id) =>
    {
        setOpenPromptDialog(true);
        setPromptOkAction({message: "Do you want to delete the Customer?",OkAction:()=>{deleteCustomer(id);setOpenPromptDialog(false)}});
    }

    const showEditingModal = (c) => {
        setUser(c);
        setShowCreateSubAccountPopup(true);
        setIsEditing(true);
    }

 
   

    return(<><ViewWrapper title={'Team'}>
   
            <Prompt promptOptions={{...promptOkAction}} OkLabel={"Yes"}  isOpen={openPromptDialog} modalClosed={()=> setOpenPromptDialog(false)}/>
                 <div style={{margin:5, position:'relative'}}>
                    <div style={{display:'flex', position:'sticky', flexDirection:'row', width:'100%', margin:'0px', alignItems:'center', justifyContent:'flex-start'}}>
                   
                    <buttom value={searchString} onClick={() => setShowCreateSubAccountPopup(true)} className={'app-button-primary'}>
                        + New Member
                    </buttom>
                    </div>      
                </div>

                <div style={{margin:5, position:'relative'}}>
                    <div style={{display:'flex', position:'sticky', flexDirection:'row', width:'100%', margin:'10px 0px', alignItems:'center', justifyContent:'center'}}>
                    {searchString && <div style={{color:'#ab571e', marginRight:10}}  onClick={e=>setSearchString("")} >Clear</div>}    
                    <input className='search-input-wide'  value={searchString} onChange={e=>setSearchString(e.target.value)} placeholder="Search" style={{backgroundImage:`url('${searchIcon}')`, backgroundRepeat:'no-repeat', backgroundPositionX:'98%',  backgroundPositionY:'50%',}}/>
                    
                    </div>      
                </div>

                <div style={{margin:5}}>
                <Prompt promptOptions={{...promptOkAction}} OkLabel={"Yes"}  isOpen={openPromptDialog} modalClosed={()=> setOpenPromptDialog(false)}/>
                
                {filterList(searchString, users, "email").length > 0 ? 
                <div style={{ width:'100%',/* display: 'inline-grid',
                flexDirection: 'column', columnGap:10, rowGap:10, gridTemplateColumns:'repeat(auto-fill, minmax(260px, 1fr))', */ minHeight:'calc(100vh-50px)'}}>
              
                {filterList(searchString, users, "email").map((g,i) => 
                <div className='item-card' style={{margin:0, height:120, 
                boxShadow:'2px 2px 4px -2px rgb(20 20 20 / 20%)',  
                marginBottom:10, alignItems:'center', width:'100%'}}>
                     <div style={{padding:15, display:'flex', width:'100%', justifyContent:'left', alignItems:'center', fontSize:20}}>
                        <div style={{background:'aqua', padding:15, width:50, height:50, margin:20}} className="user-info-avartar">
                            {g.firstName?.toUpperCase()?.charAt(0)}                    
                        </div>
                        {/*  <img  src={defaultIcon} style={{height:50, background:'none', borderRadius:5,   margin:5, marginRight:20}} alt="logo" />  */}
                        <div style={{display:'flex', flexGrow:1, flexDirection:'column'}}>

                        <div style={{display:'flex'}}>{`${g.firstName} ${g.lastName}`}</div>
                        <div style={{display:'flex', color:'#888888', fontSize:14}}>{g.email}</div>
                        <div style={{display:'flex', color:'#888888', fontSize:14}}>{g.phoneNumber}</div>
                        <div style={{display:'flex', color:'#888888', fontSize:14}}>{g.address}</div>
                        <div style={{display:'flex', justifyContent:'flex-end'}}>
                            <button onClick={()=>showEditingModal(g)} className='app-button-secondary-small'>Edit</button>
                            <button onClick={()=>deleteModel(g.userId)} className='app-button-secondary-small'>Remove</button>
                        </div>
                    
                    </div>
                   
                    </div>
                </div>
                )}</div>
                :
                <div style={{width:'100%', height:100, fontSize:20, color:'#CECECE', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    No Record found  
                </div>}
                
                
                <div style={{height:80}}></div>
                
                </div>
    </ViewWrapper>
    {<PopUp open={showCreateSubAccountPopup} >
    <div style={{width:'100%', height:'100%', display:'flex', flexDirection:'column',
        padding:0,
        borderRadius:10, 
        overflow:'hidden',
        boxShadow:'2px 2px 5px rgba(10,10,10,0.7)', minHeight:40, background:'white'}}>
            <div style={{padding:15, display:'flex', justifyContent:'left', alignItems:'center', fontSize:20}}>
           {/*  <img  src={defaultIcon} style={{height:50, background:'#ecf5ef', borderRadius:5,   margin:2, marginRight:20}} alt="logo" /> 
            */} <div style={{display:'flex', flexDirection:'column'}}>

                <div style={{display:'flex'}}>{isEditing ? "Edit Member" :'New Member'}</div>
               
            </div>
            
            </div>
            <div className='popup-view-container' style={{padding:15, display:'flex', flexDirection:'column', justifyContent:'left', alignItems:'center', fontSize:20}}>
            <div className='input-field' style={{width:'100%'}}>
                <label className='form-label'>First Name</label>
                <input value={user.firstName} onChange={e => setUser(sot => sot ? {...sot, firstName:e.target.value} : null)} className='form-text-input'/>
            </div> 
            <div className='input-field' style={{width:'100%'}}>
                <label className='form-label'>Last Name</label>
                <input value={user.lastName} onChange={e => setUser(sot => sot ? {...sot, lastName:e.target.value} : null)} className='form-text-input'/>
            </div> 
            <div className='input-field' style={{width:'100%'}}>
                <label className='form-label'>Email</label>
                <input value={user.email} onChange={e => setUser(sot => sot ? {...sot, email:e.target.value} : null)} className='form-text-input'/>
            </div>    
            <div className='input-field' style={{width:'100%'}}>
                <label className='form-label'>Phone Number</label>
                <input value={user.phoneNumber} onChange={e => setUser(sot => sot ? {...sot, phoneNumber:e.target.value} : null)} className='form-text-input'/>
            </div>    
            {isEditing ?
            
            <div className='input-field' style={{width:'100%'}}>
                <label className='form-label'>PIN</label>
                <button onClick={()=>resetMemberPIN(user)} className='app-button-secondary-small'>Reset PIN</button>
            </div>  
            
            :<>
            <div className='input-field' style={{width:'100%'}}>
                <label className='form-label'>Default PIN</label>
                <input value={user.password} onChange={e => setUser(sot => sot ? {...sot, password:e.target.value} : null)} className='form-text-input'/>
            </div>   
            <div className='input-field' style={{width:'100%'}}>
                <label className='form-label'>Default PIN-Retype</label>
                <input value={user.confirmPassword} onChange={e => setUser(sot => sot ? {...sot, confirmPassword:e.target.value} : null)} className='form-text-input'/>
            </div> 
            </>  }         
            </div>          
                
            <div style={{width:'100%', 
                padding:10,                
                background:'#EEEEEE', display:'flex', justifyContent:'space-between'}}>
                <button className='app-button-primary' onClick={() => isEditing? updateCustomer() : createSubAccount()} >{isEditing ? "Update" : "Create"}</button>
                <button className='app-button-primary' onClick={() => {setIsEditing(false);setShowCreateSubAccountPopup(false)}}>Cancel</button>
            </div>            
        </div>
        </PopUp>}
    </>)

}   

export default LayoutWrapper(SubAccountsView, {showNavigationBar:true, showSideMenu:true,backgroundImage});