import React, {useEffect, useState} from 'react';
import {
    Link,
    useNavigate,
    useParams
} from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';
import Prompt from '../../../components/Prompt/Prompt';
import Moment from 'moment';
import {v4 as uuidv4} from 'uuid';

import { BaseURL } from '../../../constants/URLs';
import { getEntities, postEntities, putEntities, deleteEntities } from '../../../api/restUtilities';
import { useRef } from 'react';
import user_icon  from '../../../assets/images/user-icon.png';


const CustomerNavigationView = (props)  => {

    const {accountDomainName} = useParams()
    
    const orderCatalogueTemplates = useSelector(state => state.orderCatalogueTemplates)

    const dispatch = useDispatch(); 

    let history = useNavigate();

    const customerAccount = useSelector(state => state.customerAccount);   
    const sessionAccountInfo = useSelector(state => state.accountConfiguration); 
    const [accountInfo, setAccountInfo] = useState(null)
   
    useEffect(()=>{

        if(sessionAccountInfo == null || sessionAccountInfo.name != accountDomainName)
        {
            console.log("acc config loaded", {sessionAccountInfo, accountDomainName})
            getEntities({
                url:BaseURL + `api/v1/AccountConfiguration/GetAccountInfo?accountDomainName=${accountDomainName}`, 
                successCallback:setAccountInfo});
        }
        else
        {
            setAccountInfo({...sessionAccountInfo})
        }
    },[])

    useEffect(()=>{

        console.log({customerAccount, accountDomainName})

        if(customerAccount != null && customerAccount.accountDomainName != accountDomainName) 
        {
            logoutCustomer();
        }
        
    },[customerAccount])


    const logoutCustomer = () => {
        localStorage.removeItem('customerAccount');
        dispatch({type:"LOGOUT_CUSTOMER", data:null});
    }

     
    return(<>
      
                <div style={{padding:15, width:'100%', position:'relative'}}>
                    <div style={{display:'flex', fontSize:20, position:'sticky', flexDirection:'row', width:'100%', margin:'0px', alignItems:'center', justifyContent:'space-between', color:'grey'}}>
                   <img height={80} src={accountInfo?.businessLogo} />

                   <div style={{display:'flex', flexDirection:'column'}}>
                   {/* {`@${accountDomainName}`}
                   <span>{customerAccount? customerAccount.firstName : 'Guest'}</span> */}

                   <div style={{padding:10, display:'flex'}}>
            

            <div style={{position:'relative'}}>
                <div className="user-info-avartar" style={{/* boxShadow:'2px 2px 3px rgba(150, 150, 150, 0.5)' */ overflow:'hidden'}}>
                    <img width={24} src={user_icon} />   

                </div>
                
            </div>
            <div className="user-info-wrapper">
                    <div className="user-info-name">{`${customerAccount && customerAccount.email ? `Hi, ${customerAccount.email}` : "Guest"}`}</div>
                    <div className="user-info-email" style={{fontSize:12}}><span style={{fontStyle:'italic', fontSize:14, fontWeight:'normal', color:'green'}}>{`@${accountDomainName}`}</span></div>
                    
                    
            </div>
            {customerAccount ? 
            <button onClick={()=>logoutCustomer()} className='app-button-primary'>Logout</button>
            :
            
            <Link className='app-button-primary' to={`/${accountDomainName}/customer-login`}>Login</Link>}
            </div>
                    
                    </div>    
           
            </div>
                     
                </div>

    </>)

}   

export default CustomerNavigationView