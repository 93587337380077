import React from 'react';
import './dataWidget.css';

const DataWidget = (props) => 
{

    return (
        <div className='data-widget' style={{background:props.background || '#f5f5f5'}}> 
            <div className='data-widget-caption'>{props.caption}</div>
            <div className='data-widget-data'>{props.data || 0}</div>
            <div className='data-widget-description'>{props.description}</div>
            <div className='data-widget-label'>{props.label}</div>
        </div>
    )
}

export default DataWidget;