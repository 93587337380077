import React, { useState } from 'react';
import './SideMenu.css';
import {
    Link,
    useLocation,
    useParams
} from 'react-router-dom';

import {MenuContext} from './SideMenuContext';
import { useEffect } from 'react';
/* import { useSelector } from 'react-redux';
import {signOutUser} from '../../services/user.services';
import { useDispatch } from 'react-redux' */

import notificationIcon from '../../assets/images/icons/notification-icon.svg';
import teamIcon from '../../assets/images/icons/team-icon.svg';
import inventoryIcon from '../../assets/images/icons/inventory-icon.svg';
import customerIcon from '../../assets/images/icons/customer-icon.svg';
import orderIcon from '../../assets/images/icons/order-icon.svg';
import orderCatalogueIcon from '../../assets/images/icons/order-catalogue-icon.svg';
import statisticsIcon from '../../assets/images/icons/statistics-icon.svg';
import helpIcon from '../../assets/images/icons/help-icon.svg';
import settingsIcon from '../../assets/images/icons/settings-icon.svg';
import { useSelector } from 'react-redux';

const mainMenuItemsDef = [
    {
        icon:statisticsIcon,
        menuLink:'/dashboard',
        name:"Dashboard",
        key:"user-statistics",
        isCollapsed:true
    },
    {
        icon:orderIcon,
        menuLink:'/orders',
        name:"Orders",
        key:"orders",
        isCollapsed:false
    },
    {
        icon:orderCatalogueIcon,
        menuLink:'/order-catalogues',
        name:"Catalogues",
        key:"order-catalogues",
        isCollapsed:true
    },     
    
   
    {
        icon:teamIcon,
        menuLink:'/team',
        name:"Team",
        key:"team",
        isCollapsed:true
    },
    {
        icon:customerIcon,
        menuLink:'/customers',
        name:"Customers",
        key:"customers",
        isCollapsed:true
    },
    {
        icon:inventoryIcon,
        menuLink:'/inventory',
        name:"Inventory",
        key:"inventory",
        isCollapsed:true
    },
    {
        icon: notificationIcon,
        menuLink:'/notifications',
        name:"Notifications",
        key:"notifications",
        isCollapsed:true
    },
    {
        icon:settingsIcon,
        menuLink:'/settings',
        name:"Account Settings",
        key:"settings",
        isCollapsed:true
    },
    {
        icon:helpIcon,
        menuLink:'/help',
        name:"Help",
        key:"help",
        isCollapsed:true
    },
   /*  {
        menuLink:'https://forms.gle/rDM5ZfnR3k5U9rJo8',
        name:"Feedback",
        key:"feedback",
        isCollapsed:true,
        isExternal:true
    }, */
    
]

const subMenuItems = [
    /* {
        menuLink:'/track-order',
        name:"Track Order",
        key:"track-order",
        parentKey:'orders',
        isCollapsed:true
    },
    {
        menuLink:'/invoices',
        name:"Invoices",
        key:"invoices",
        parentKey:'orders',
        isCollapsed:true
    },
    {
        menuLink:'/receipts',
        name:"Receipts",
        key:"receipts",
        parentKey:'orders',
        isCollapsed:true
    } */
];


const SideMenu = (props) =>
{
    //const user = useSelector(state => state.user);
    //const dispatch = useDispatch();
    const location = useLocation();

    const [mainMenuItems, setMainMenuItems] = useState(props.menuList ? [...props.menuList]:[...mainMenuItemsDef])

    const [menuSearchString, setMenuSearchString] = useState("")

    const {accountDomainName} = useParams()

    const customerAccount = useSelector(state => state.customerAccount);   
    //const sessionAccountInfo = useSelector(state => state.accountConfiguration); 
    
    const filterMenuItems = (menuItems) => {
        if(menuSearchString && menuSearchString != "")
        {
            let filteredMenuItems = menuItems.filter( mi => {
                if(mi.name.toLowerCase().match(menuSearchString.toLowerCase()))
                {
                    return true;
                }
                else
                {
                    // Search the submenus
                    let foundSubMenuItems = subMenuItems.filter(sm => sm.parentKey === mi.key && sm.menuCaption.toLowerCase().match(menuSearchString.toLowerCase()))

                    if(foundSubMenuItems && foundSubMenuItems.length>0)
                    {
                        return true;
                    }
                    else
                    {
                        return false;
                    }
                }
            })

            return filteredMenuItems.map(fm => {return {...fm, isCollapsed:false}});
        }
        else
        {
            return menuItems
        }
    }
    
    const collapseMenuItem = (key) =>{
        let selectedMenuItem = mainMenuItems.find(mi => mi.key === key);

        if(selectedMenuItem)
        {
            selectedMenuItem.isCollapsed = !selectedMenuItem.isCollapsed;

            setMainMenuItems([...mainMenuItems.map(
                (m, i) => m.key === selectedMenuItem.key ? selectedMenuItem
                                        : m)])
        }
    }

    const isCurrentLink = (menuItem) => {
        // Search the submenus
        let foundSubMenuItems = subMenuItems.filter(sm => sm.menuLink === location.pathname && menuItem.key === sm.parentKey)

        if(foundSubMenuItems && foundSubMenuItems.length>0)
        {
            return true
        }
        else
        {
            return false;
        }
    }

    useEffect(()=>{
        if(props.isCustomerView)
        {
            setMainMenuItems(mis => mis.map(mm => ({...mm, menuLink:`/${accountDomainName}${mm.menuLink}`})).filter(m =>customerAccount? m : !m.isAuthorised) )
        }
    },[customerAccount])

    return (
        <MenuContext.Consumer>
        {({isOpen, setIsOpen}) => (
        <>
        <div  onClick={()=> setIsOpen(false)}  className={`side-menu-backdrop ${isOpen?'side-menu-backdrop-show':'side-menu-backdrop-hide'}`}></div>
       
        
        <div style={{overflowY:'auto',/*  background:'linear-gradient(135deg, rgb(150, 251, 196) 0%, rgb(249, 245, 134) 100%)', background:'rgba(20,20,20,0.5)', background:'white', *//* boxShadow:'inset 20px 2px 0px #a7faba' */}} className={`side-menu-container ${isOpen?'side-menu-open':'side-menu-close'}`}>

        <div style={{margin:'0px', display:'flex', flexDirection:'column', width:'90%', justifyContent:'left'}}>
        {props.showMenuSearchBar && <div style={{display:'flex', flexDirection:'column', padding:8, paddingBottom:2, margin:'0px 0px', alignItems:'center', justifyContent:'center'}}>
            <input placeholder="Search" value={menuSearchString} onChange={(e)=>setMenuSearchString(e.target.value)} style={{boxSizing:'border-box', 
            //boxShadow:'0px 0px 2px rgba(10,10,10,0.2)', 
            background:"#eeeeee", padding:'2px 10px', height:30,
            border:0,
             //border:'1px solid #CCCCCC', 
             width:'100%', borderRadius:5}}/>
        </div>}
        {
            filterMenuItems(mainMenuItems).map(ss => <div key={ss.key} style={{marginTop:3}}>
                {ss.menuLink ? 
                ss.isExternal ?  
                <a href={ss.menuLink} className={`side-menu-category-label ${ss.menuLink === location.pathname || isCurrentLink(ss)? 'side-menu-category-label-active' : 'side-menu-category-label-inactive'}`}><span className={`side-menu-category-label`}>{ss.name}</span></a>
                
                :
                <div style={{display:'flex'}}><img src={ss.icon} style={{marginRight:5}} width={26}/><Link to={ss.menuLink} className={`side-menu-category-label ${ss.menuLink === location.pathname || isCurrentLink(ss)? 'side-menu-category-label-active' : 'side-menu-category-label-inactive'}`}><span className={`side-menu-category-label`}>{ss.name}</span></Link></div> :
                <div className={'side-menu-category-label-inactive side-menu-category-label'}>{ss.name}</div>}
                <ul style={{marginLeft:20, borderLeft:'1px dashed black'}} className={'side-menu-links-container'}>
                {!ss.isCollapsed && subMenuItems.filter(s => s.parentKey === ss.key).map(sm => <li key={sm.menuLink} className={'side-menu-links-item'}><div>
                
                    <Link className={'side-menu-container-link'} style={{fontWeight:`${sm.menuLink === location.pathname ? 'bold':'normal' }`}} to={sm.menuLink}>{sm.name}</Link>
                    </div></li>)}
                </ul>
                </div>)
        }
        {/*user? <div style={{cursor:'pointer'}} onClick={(e)=>{e.preventDefault(); signOutUser(dispatch)}} className={'side-menu-category-label-inactive side-menu-category-label'}>Logout</div>:<Link className={'side-menu-category-label'} to={'/login'}>Login</Link>*/}
        </div>
        </div></>)}
        </MenuContext.Consumer>)
}

export default SideMenu;