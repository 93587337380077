import React from "react";
import {
  Route,
  Navigate,
} from "react-router-dom";
import { useSelector } from 'react-redux'

// Redirects the user to the login page if the
// currentUser object saved in localStorage is 
// null.

const PrivateRoute = ({ children, ...rest }) => {

    const user = useSelector(state => state.user);

    return( <>{user && (user.email || user.token)? 
        children
        : 
        <Navigate
            to={"/merchant-login"}
        />}
        </>)
}

export default PrivateRoute;
