import React, {useEffect, useState} from 'react';
import {
    Link,
    useNavigate
} from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';
import { useSpring, animated as a, config } from 'react-spring';
import LayoutWrapper from '../layout/LayoutWrapper';
import ViewWrapper from './ViewWrapper';
//import { deleteAsset } from '../redux/actions/assetActions';
import Prompt from '../components/Prompt/Prompt';
import searchIcon from '../assets/images/search.svg';
import defaultIcon  from '../assets/images/default-game-icon.svg';
import PopUp from '../components/PopUp/PopUp';
import backgroundImage from '../assets/images/background_01.svg';
import {v4 as uuidv4} from 'uuid';
import axios from 'axios';
import Moment from 'moment';
import { BaseURL } from '../constants/URLs';

import { getEntities, postEntities, putEntities, deleteEntities } from '../api/restUtilities';
import { useRef } from 'react';
import PaginationView from '../components/PaginationView/PaginationView';
import QRCode from 'react-qr-code';
import SearchListView from '../components/SearchListView/SearchListView';
import './Order.css'
import TabbedView from '../components/TabbedView/TabbedView';
import ToggleSelect from '../components/ToggleSelect/ToggleSelect';

const AllOrdersView = (props)  => {
    
    
    const history = useNavigate();

    const dummyOrderData ={
        "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        "catalogueId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        "accountId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        "createdBy": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        "lastModifiedBy": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        "orderNumber": "string",
        "title": "string",
        "description": "string",
        "invoice": null,
        "customerInfo": null,
        "currentStage": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        "creationDate": "2023-01-06T03:23:58.783Z",
        "lastModificationDate": "2023-01-06T03:23:58.783Z"
      }
    
    const [openPromptDialog, setOpenPromptDialog] = useState(false);
    const [promptOkAction, setPromptOkAction] = useState(null);
    const [searchString, setSearchString] = useState("");
    //const [showCreateNewOrderPopup, setShowCreateNewOrderPopup] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(null)
    const [showSelectCustomerPopup, setShowSelectCustomerPopup] = useState(false)
    const [showCreateNewOrderPopup, setShowCreateNewOrderPopup] = useState(false);   
    const [selectedOrderCatalogue, setSelectedOrderCatalogue] = useState(null);
    const [newOrder, setNewOrder] = useState({...dummyOrderData})
    //const [orderList, setOrderList] = useState([...orders])
   
    const [orders, setOrders] = useState([]);    
    const [orderCatalogues, setOrderCatalogues] = useState([])
    
    const [currentPageIndex, setCurrentPageIndex] = useState(0) 
    const [totalRecords, setTotalRecords] = useState(0) 
    const [totalPageCount, setTotalPageCount] = useState(0) 
    const [isDataLoaded, setIsDataLoaded] = useState(false)
    const [isTableView, setIsTableView] = useState(false);

    const maxPerPage = 15

    const refreshData = (newPageIndex) =>
    {
        getEntities({url:BaseURL + `api/v1/order/getorders?maxPerPage=${maxPerPage}&pageNumber=${newPageIndex != undefined ? newPageIndex :currentPageIndex}&searchString=${searchString}`, successCallback: setOrderRecordsData});
      
        getEntities({
            url:BaseURL + 'api/v1/ordercatalogue/getordercatalogues', 
            successCallback:setOrderCatalogues});
    }

    /*useEffect(()=>{


       
    },[currentPageIndex])*/

    useEffect(()=>{

        clearTimeout(delayTimer?.current);
        delayTimer.current = setTimeout(function() {
            //getEntities({url:BaseURL + `api/v1/order/getcustomers?maxPerPage=${maxPerPage}&pageNumber=${currentPageIndex}&searchString=${searchString}`, successCallback: setOrderRecordsData});
            refreshData()
            setIsDataLoaded(true)
        }, 1000);
        
    },[searchString])

    const delayTimer = useRef();
 

    const setOrderRecordsData = (data) => {
        const {listData, pageNumber,totalRecords,totalPageCount} = data;

        //console.log(data)
        setCurrentPageIndex(pageNumber);
        setTotalRecords(totalRecords);
        setTotalPageCount(totalPageCount);
        setOrders(listData);
    }
   
    /* const createOrder = () => {
        let newCustomerId = uuidv4();
      
        postEntities(
            {
                url:BaseURL + 'api/v1/order/save', 
                data:{...newOrder, id:newCustomerId},
                successCallback: ()=>{
                    setShowCreateNewOrderPopup(false);
                    //getEntities({url:BaseURL + `api/v1/order/getcustomers?maxPerPage=${maxPerPage}&pageNumber=${currentPageIndex}&searchString=${searchString}`, successCallback: setOrderRecordsData});
                    refreshData();
                    setNewOrder({...dummyOrderData})
                },
                successNotification:{title:"Success", message:'Customer created'},
                errorNotification:{title:"Failed", message:'Customer creation failed'},
            }        
        )
       
    }
 */
   /*  const updateCustomer = () => {
      
        putEntities(
            {
                url:BaseURL + 'api/v1/order/update', 
                data:{...order},
                successCallback: ()=>{
                    setShowCreateNewOrderPopup(false);
                    //getEntities({url:BaseURL + `api/v1/order/getcustomers?maxPerPage=${maxPerPage}&pageNumber=${currentPageIndex}&searchString=${searchString}`, successCallback: setOrderRecordsData});
                    refreshData();
                    setOrder({...dummyCustomerData})
                },
                successNotification:{title:"Success", message:'Customer updated'},
                errorNotification:{title:"Failed", message:'Customer update failed'},
            }        
        )
    } */

    const formatDate = (lastPlayed) => {
       
        if(lastPlayed && lastPlayed !== -Infinity)
        {
            console.log("lastPlayed",lastPlayed);
            return Moment(new Date(lastPlayed)).startOf('second').fromNow()
        }
        return "";
        
    }

    const deleteOrder = (id) => {
       let order = orders.find(o => o.id === id)

       if(order)
        putEntities(
            {
                url:BaseURL + 'api/v1/order/CancelOrder?id='+id, 
                data:{...order},
                successCallback: ()=>{
                    setShowCreateNewOrderPopup(false);
                    //getEntities({url:BaseURL + `api/v1/order/getcustomers?maxPerPage=${maxPerPage}&pageNumber=${currentPageIndex}&searchString=${searchString}`, successCallback: setOrderRecordsData});
                    refreshData();
                    //setNewOrder({...dummyOrderData})
                },
                successNotification:{title:"Success", message:'Order cancelled'},
                errorNotification:{title:"Failed", message:'Order cancellation failed'},
            }        
        )

    }

    const deleteModel = (id) =>
    {
        let order = orders.find(o => o.id === id)
        setOpenPromptDialog(true);
        setPromptOkAction({message: `Do you want to cancel the order, #${order.orderNumber} ?`,OkAction:()=>{deleteOrder(id);setOpenPromptDialog(false)}});
    }

    

    const createNewOrder = () => {
        let newOrderId = uuidv4();

        let templateData = selectedOrderCatalogue ? {...selectedOrderCatalogue} : {}

        // :TODO
        //Check the customer entry
        //dispatch({type:'CREATE_ORDER', data: {...newOrder, catalogue:{...templateData}, id:newOrderId}});

        //history(`/edit-order-catalogue/${newOrderCatalogueId}`);

        postEntities(
            {
                url:BaseURL + 'api/v1/order/save', 
                data:{...newOrder, CatalogueId:templateData.id, catalogue:{...templateData}, customerInfo:{...selectedCustomer}, id:newOrderId},
                successCallback: (d)=>{
                    console.log("new order", d)
                //setShowCreateNewOrderPopup(false);
                setNewOrder({...d})
                /* getEntities({
                    url:BaseURL + 'api/v1/ordercatalogue/getordercatalogues', 
                    successCallback:setOrders}); */
                    refreshData();
                    //setNewOrderCatalogue({...dummyOrderCataloge})
                    //history(`/edit-order-catalogue/${d.id}`)
                    history(`/order/${d.id}`)
                },
                successNotification:{title:"Success", message:'Order created'},
                errorNotification:{title:"Failed", message:'Order creation failed'},
            }        
        )

        //history(`/edit-order/${newOrderId}`)
    }

    const toggleSelectedCatalogue = (id) => {
        if(selectedOrderCatalogue && selectedOrderCatalogue.id === id)
        {
            setSelectedOrderCatalogue(null);
        }
        else
        {
            setSelectedOrderCatalogue(orderCatalogues.find(ot => ot.id === id));
        }
    }

    useEffect(()=>{

        if(orderCatalogues && orderCatalogues.length > 0)
        setSelectedOrderCatalogue({...orderCatalogues[0]})
    },[orderCatalogues])

   
    /* useEffect(()=>{
        setOrderList([...orders])
    },[orders]) */

    useEffect(()=>{
        getEntities({
            url:BaseURL + 'api/v1/ordercatalogue/getordercatalogues', 
            successCallback:setOrderCatalogues});
    },[])
   
    const handleItemSelectionChange = () => 
    {
        
        //console.log(selectedCustomer)
        /* onFieldChangeHandler({name:'customerName', value:selectedCustomer.name})
        onFieldChangeHandler({name:'customerEmail', value:selectedCustomer.email})
        onFieldChangeHandler({name:'customerPhoneNumber', value:selectedCustomer.phoneNumber}) */
    }

    const renderComponent = ({data}) => {
       
        return (<div className='item-card' style={{margin:0, height:120, boxShadow:'2px 2px 11px -2px rgb(20 20 20 / 20%)', border:selectedCustomer?.id === data?.id ? '2px solid orange' :0,  marginBottom:10, alignItems:'center', width:'100%'}}>
        <div style={{padding:15, display:'flex', width:'100%', justifyContent:'left', alignItems:'center', fontSize:20}}>
           
            <button  className='app-button-secondary-small' onClick={e => setSelectedCustomer(data)} type="checkbox" >Select</button>
           <div style={{background:'aqua', padding:15, width:20, height:20, fontSize:13, margin:10}} className="user-info-avartar">
               {data.firstName?.toUpperCase()?.charAt(0)}                    
           </div>
           {/*  <img  src={defaultIcon} style={{height:50, background:'none', borderRadius:5,   margin:5, marginRight:20}} alt="logo" />  */}
           <div style={{display:'flex', flexGrow:1, flexDirection:'column'}}>

           <div style={{display:'flex'}}>{`${data.firstName} ${data.lastName}`}</div>
           <div style={{display:'flex', color:'#888888', fontSize:14}}>{data.email}</div>
           <div style={{display:'flex', color:'#888888', fontSize:14}}>{data.phoneNumber}</div>
           <div style={{display:'flex', color:'#888888', fontSize:14}}>{data.address}</div>          
       
       </div>
      
       </div>
   </div>)
      }

    return(<><ViewWrapper title={'Orders'}>
   
            <Prompt promptOptions={{...promptOkAction}} OkLabel={"Yes"}  isOpen={openPromptDialog} modalClosed={()=> setOpenPromptDialog(false)}/>
                 <div style={{margin:5, position:'relative'}}>
                    <div style={{display:'flex', position:'sticky', flexDirection:'row', width:'100%', margin:'0px', alignItems:'center', justifyContent:'space-between'}}>
                   
                    <buttom value={searchString} onClick={() => setShowCreateNewOrderPopup(true)} className={'app-button-primary'}>
                        + New Order
                    </buttom>
                    <button className={'app-button-primary'}>Filter</button>
                    </div>      
                </div>

                <div style={{margin:5, position:'relative'}}>
                    <div style={{display:'flex', position:'sticky', flexDirection:'row', width:'100%', margin:'10px 0px', alignItems:'center', justifyContent:'center'}}>
                    {searchString && <div style={{color:'#ab571e', marginRight:10}}  onClick={e=>setSearchString("")} >Clear</div>}    
                    <input className='search-input-wide'  value={searchString} onChange={e=>setSearchString(e.target.value)} placeholder="Search" style={{backgroundImage:`url('${searchIcon}')`, backgroundRepeat:'no-repeat', backgroundPositionX:'98%',  backgroundPositionY:'50%',}}/>
                    
                    </div>      
                </div>

                <div style={{margin:5, display:'flex', justifyContent:'space-between', position:'relative'}}>
                    <span style={{color:'#888888', fontSize:11}}>{`Total Records: ${totalRecords}`}</span>
                    <div style={{display:'flex', fontSize:12, alignItems:'center'}}>
                        <span style={{color: isTableView ? 'black' : '#CCCCCC'}}>Table</span>
                
                        <ToggleSelect onChange={(s)=>setIsTableView(s)} isTrue={isTableView}></ToggleSelect>
                        <span  style={{color: isTableView ? '#CCCCCC' : 'black'}}>Grid</span>
                    
                    </div>
                <PaginationView numberOfPages={totalPageCount} partLength={3} currentPageIndex={currentPageIndex+1} onClick={(i)=>{setCurrentPageIndex(i-1);refreshData(i-1);}}/>  
                </div>

                <div style={{margin:5}}>
                <Prompt promptOptions={{...promptOkAction}} OkLabel={"Yes"}  isOpen={openPromptDialog} modalClosed={()=> setOpenPromptDialog(false)}/>
                
                {orders.length > 0 ? 
                
                isTableView ? 
                    
                <div style={{overflow:'auto'}}>
                    <table cellSpacing={0} className='order-list-table'>
                        <thead>
                            <tr>
                                <td>SN</td>
                                <td></td>
                                <td>Order #</td>
                                <td>Total Amount</td>
                                <td>Customer Name</td>
                                <td>Customer Email</td>
                                <td>Customer Phone</td>
                                <td>Workflow</td>
                                <td>Status</td>
                                <td>Created</td>
                                <td>Last Modified</td>
                               
                                
                            </tr>
                        </thead>
                        <tbody>
                        {orders && orders.map((g,i) => <tr  onClick={()=>history(`/order/${g.id}`)}>
                                <td>{currentPageIndex * maxPerPage + i +1} </td>
                                <td>
                                <div style={{background:'lightgrey', padding:1, width:40, height:40, margin:5}} >
                                    <QRCode
                                    size={256}
                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                    value={g.orderNumber}
                                    viewBox={`0 0 256 256`}
                                    />   
                                </div>
                                </td>
                                <td>{g.orderNumber}</td>
                                <td>{g.totalAmount}</td>
                                <td>{`${g.customerInfo?.firstName} ${g.customerInfo?.lastName}`}</td>
                                <td>{g.customerInfo?.email}</td>
                                <td>{g.customerInfo?.phoneNumber}</td>
                                <td>{g.catalogueTitle}</td>
                                <td>{g.status}</td>
                                <td>{Moment(g.creationDate).format("dddd, MMMM Do YYYY, h:mm:ss a")}</td>
                                <td>{Moment(g.lastModificationDate).format("dddd, MMMM Do YYYY, h:mm:ss a")}</td>
                                
                            </tr>)}
                        </tbody>
                    </table>
                </div>
                    
                    :<div style={{ width:'100%',display: 'inline-grid',
                flexDirection: 'column', columnGap:10, rowGap:10, /* gridTemplateColumns:'repeat(auto-fill, minmax(260px, 1fr))', */ minHeight:'calc(100vh-50px)'}}>
              
                {orders && orders.map((g,i) => 
                <div className='item-card' style={{margin:0, boxShadow:'2px 2px 7px 0px rgb(20 20 20 / 30%)',background:'white', /* height:120,  boxShadow:'2px 2px 11px -2px rgb(20 20 20 / 20%)',*/  marginTop:10, marginBottom:5, alignItems:'center', width:'100%'}}>
                     <div style={{padding:15, display:'flex', width:'100%', justifyContent:'left', alignItems:'flex-start', fontSize:16}}>
                        <div style={{background:'none', display:'inline-flex', flexDirection:'column'}} >
                        <div onClick={()=>history(`/order/${g.id}`)}  style={{background:'aqua', padding:1, width:60, height:60, margin:5}} >
                            <QRCode
                            size={256}
                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                            value={g.orderNumber}
                            viewBox={`0 0 256 256`}
                            />     
                        </div>  
                        <div onClick={()=>history(`/order/${g.id}`)}  style={{display:'flex', fontSize:12, cursor:'pointer', padding:5, fontWeight:'bold'}}>{`#${g.orderNumber}`}</div>
                           
                        </div>
                        {/*  <img  src={defaultIcon} style={{height:50, background:'none', borderRadius:5,   margin:5, marginRight:20}} alt="logo" />  */}
                       
                        <div style={{display:'flex', flexGrow:1, flexDirection:'column'}}>

                            
                        <div style={{display:'flex', flexDirection:'row'}}>
                            <div style={{display:'flex', flexGrow:1, flexDirection:'column'}}>

                               {/*  <div style={{display:'flex', color:'#888888', fontSize:14}}>{g.title}</div>
                                <div style={{display:'flex', color:'#888888', fontSize:14}}>{g.description}</div> */}
                              
                                <div style={{fontSize:12, color:'green', padding:0, fontWeight:'bold'}}>{g.invoice  && `NGN ${g.invoice.invoiceItems.reduce((p,c)=> p + (c.quantity*c.unitPrice), 0).toLocaleString('en-US')}`}</div>
                                <div style={{display:'flex', color:'#888888', fontSize:11}}>{g.customerInfo?.firstName}</div>
                                <div style={{display:'flex', color:'#888888', fontSize:10}}>{g.customerInfo?.email}</div>
                            </div>

                            <div style={{display:'flex', flexGrow:1, flexDirection:'column'}}>

                               
                                <div style={{display:'flex', justifyContent:'end'}}>
                                   
                                    {g.status && <span className='catalogue-tag' style={{background:g.status === 'InProgress' ? '#e29008' : g.status === 'Completed' ? 'green' : '#cecece'}}>{g.status || 'Unknown'}</span>}
                                </div>
                                <div style={{display:'flex',justifyContent:'end', color:'#888888', fontSize:10}}><span style={{color:'#CCCCCC'}}>Stage:</span>{`: ${g.currentStageTitle || 'Unknown'}`}</div>
                                
                                <div style={{display:'flex', justifyContent:'end'}}>
                                    <div style={{height:6, width:100, position:'relative', border:'1px solid #d6d5d5', margin:4, borderRadius:5, backgroundColor:'#ffffff'}}>
                                        <div style={{height:4,width:`${g.stageProgress *100}%`, borderRadius:5, backgroundColor: g.stageProgress == 1.0 ? 'green' : '#ff9900'}}></div>
                                    </div>
                                </div>
                                <div style={{display:'flex', color:'#787878', fontSize:10, justifyContent:'end'}}>
                                     {`${g.stageData?.reduce((p,c) => c.attachments?.length + p, 0)} Attachment(s)`}
                                </div>

                            </div>
                        </div>
                            
                            <div>
                            {g.catalogueTitle && <span className='catalogue-tag'>{g.catalogueTitle || ''}</span>}
                            </div>
                            <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                            <div style={{display:'flex', color:'#888888', fontSize:11}}>{`Last Updated: ${formatDate(g.lastModificationDate)}`}</div>
                                <button style={{visibility:g.status === 'Completed' ? 'hidden' : 'visible'}} onClick={()=>deleteModel(g.id)} className='app-button-secondary-small'>Cancel</button>
                            </div>
                        
                        </div>
                   
                    </div>
                </div>
                )}</div>
                :
                <div style={{width:'100%', height:100, fontSize:20, color:'#CECECE', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    {isDataLoaded ? 'No Record found' : 'Loading...'}  
                </div>}
                
                
                <div style={{height:80}}></div>
                
                </div>
    </ViewWrapper>
    {<PopUp open={showCreateNewOrderPopup} >
    <div style={{width:'100%', height:'100%', display:'flex', flexDirection:'column',
        padding:0,
        borderRadius:10, 
        overflow:'hidden',
        boxShadow:'2px 2px 5px rgba(10,10,10,0.7)', minHeight:40, background:'white'}}>
            <div style={{padding:15, display:'flex', justifyContent:'left', alignItems:'center', fontSize:20}}>
           {/*  <img  src={defaultIcon} style={{height:50, background:'#ecf5ef', borderRadius:5,   margin:2, marginRight:20}} alt="logo" /> 
            */} <div style={{display:'flex', flexDirection:'column'}}>

                <div style={{display:'flex'}}>{'New Order'}</div>
                <div style={{display:'flex'}}>
                    <span style={{color:'#888888', fontWeight:'normal', fontFamily:'Righteous',padding:'3px 0px',  fontSize:12}}>
                    {`Selected Workflow: ${selectedOrderCatalogue?.title || "None"}`}           
                    </span>
            
                </div>
            </div>
            
            </div>
            
            <TabbedView tabbedViewCaptions={["Customer Details", "Order Workflow"]} style={{width:'100%'}}>
                <div style={{padding:15, display:'flex', flexDirection:'column', width:'100%', justifyContent:'left', alignItems:'center', fontSize:20}}>
            <div style={{width:'100%', padding:10}}>
                <button className='app-button-primary'  onClick={()=>setShowSelectCustomerPopup(true)} >Select existing Customer</button>
            </div>
            <div className='input-field' style={{width:'100%'}}>
                <label className='form-label'>Customer Name</label>
                <input value={selectedCustomer?.firstName} onChange={e => setSelectedCustomer(sot => sot ? {...sot, firstName:e.target.value} : null)} className='form-text-input'/>
            </div> 
            <div className='input-field' style={{width:'100%'}}>
                <label className='form-label'>Customer Name</label>
                <input value={selectedCustomer?.lastName} onChange={e => setSelectedCustomer(sot => sot ? {...sot, lastName:e.target.value} : null)} className='form-text-input'/>
            </div> 
            <div className='input-field' style={{width:'100%'}}>
                <label className='form-label'>Customer Email *</label>
                <input value={selectedCustomer?.email} onChange={e => setSelectedCustomer(sot => sot ? {...sot, email:e.target.value} : null)} className='form-text-input'/>
            </div>  
            <div className='input-field' style={{width:'100%'}}>
                <label className='form-label'>Customer Phone *</label>
                <input value={selectedCustomer?.phoneNumber} onChange={e => setSelectedCustomer(sot => sot ? {...sot, phoneNumber:e.target.value} : null)} className='form-text-input'/>
            </div>          
            </div>
            <div style={{flexGrow:1, fontWeight:'normal', color:'#555555', maxHeight:'40vh', background:'#f0fdfa', boxShadow:'inset 0px 2px 5px', padding:20, textAlign:'left', overflowY:'auto'}}>
           
                {orderCatalogues?.map( oct => <div onClick={()=>toggleSelectedCatalogue(oct.id)} style={{ minHeight:100, margin:0, marginBottom:10, height:'auto', background:'white', justifyContent:'flex-start', alignItems:'center', width:'100%', backgroundColor:selectedOrderCatalogue && selectedOrderCatalogue.id === oct.id ?  '#f3ebe5' : 'white', border:selectedOrderCatalogue && selectedOrderCatalogue.id === oct.id ? '2px solid orange' : 0}}  className={'item-card'}>
                    
                    <img  src={defaultIcon} style={{height:80, padding:10, background:'none', borderRadius:5,   margin:2, }} alt="logo" />
                    <div style={{padding:10, display:'flex', height:'100', justifyContent:'flex-start', flexDirection:'column'}}>
                    <span>{oct.title}</span>
                    <span style={{marginTop:10, fontSize:12, color:'grey'}}>{oct.description}</span>
                    </div>
                    </div>)}
            </div>
                
            </TabbedView>
            
            <div style={{width:'100%', 
                padding:10,                
                background:'#EEEEEE', display:'flex', justifyContent:'space-between'}}>
                <button className='app-button-primary' onClick={() => createNewOrder()} >Create</button>
                <button className='app-button-primary' onClick={() => setShowCreateNewOrderPopup(false)}>Cancel</button>
            </div>            
        </div>
        </PopUp>}

        {<PopUp styles={{borderRadius:9}} isEmbedded={true} open={showSelectCustomerPopup} >
        <div style={{width:'100%', height:'100%', 
        display:'flex', flexDirection:'column',
        padding:0,
        borderRadius:10, 
        overflow:'hidden',
        boxShadow:'2px 2px 5px rgba(10,10,10,0.7)', minHeight:50, background:'white'}}>
           {/*  <div style={{padding:0, display:'flex', justifyContent:'center', alignItems:'center', fontWeight:'normal', fontSize:16}}>
            */}     <div className='popup-title'>Select Customer</div>
           {/*  <img  src={defaultIcon} style={{height:50, background:'#ecf5ef', borderRadius:5,   margin:2, marginRight:20}} alt="logo" />  */}
            {/* <div  style={{display:'flex', color:'#444444', padding:20, width:'100%', flexDirection:'column'}}>
            */} {/** An async call should be made to fetch inventory */}
            {/* 
                <div style={{display:'flex'}}><input></input><button>Search</button></div>
            
            {customers.map(r => <div style={{display:'flex', border:selectedCustomer?.id === r.id ? '1px solid orange' :0}}>
                <button  onClick={e => setSelectedCustomer(r)} type="checkbox" >Select</button>
                <div style={{display:'flex', flexDirection:'column'}}>
                <span>{r.name}</span>
                <span>{r.email}</span>
                <span>{r.phoneNumber}</span>
                </div>
            </div>)} */}

            <SearchListView style={{padding:10}} renderComponent={renderComponent} dataUrl={BaseURL + `api/v1/customer/getcustomers`}/>
            
            {/* </div> */}
            
            {/* </div> */}
           
                 
            <div style={{width:'100%', 
        padding:10,
         
        background:'#EEEEEE', display:'flex', justifyContent:'space-between'}}>
                <button className='app-button-primary' onClick={()=>{handleItemSelectionChange();setShowSelectCustomerPopup(false)}} >Add</button>
                <button className='app-button-primary' onClick={()=>setShowSelectCustomerPopup(false)} >Close</button>
            </div>            
        </div>
    </PopUp>}
   {/*  {<PopUp open={showCreateNewOrderPopup} >
    <div style={{width:'100%', height:'100%', display:'flex', flexDirection:'column',
        padding:0,
        borderRadius:10, 
        overflow:'hidden',
        boxShadow:'2px 2px 5px rgba(10,10,10,0.7)', minHeight:40, background:'white'}}>
            <div style={{padding:15, display:'flex', justifyContent:'left', alignItems:'center', fontSize:20}}>
            <div style={{display:'flex', flexDirection:'column'}}>

                <div style={{display:'flex'}}>{isEditing ? "Edit Customer" :'New Customer'}</div>
               
            </div>
            
            </div>
            <div style={{padding:15, display:'flex', flexDirection:'column', justifyContent:'left', alignItems:'center', fontSize:20}}>
            <div className='input-field' style={{width:'100%'}}>
                <label className='form-label'>First Name</label>
                <input value={order.firstName} onChange={e => setOrder(sot => sot ? {...sot, firstName:e.target.value} : null)} className='form-text-input'/>
            </div> 
            <div className='input-field' style={{width:'100%'}}>
                <label className='form-label'>Last Name</label>
                <input value={order.lastName} onChange={e => setOrder(sot => sot ? {...sot, lastName:e.target.value} : null)} className='form-text-input'/>
            </div> 
            <div className='input-field' style={{width:'100%'}}>
                <label className='form-label'>Email</label>
                <input value={order.email} onChange={e => setOrder(sot => sot ? {...sot, email:e.target.value} : null)} className='form-text-input'/>
            </div>    
            <div className='input-field' style={{width:'100%'}}>
                <label className='form-label'>Phone Number</label>
                <input value={order.phoneNumber} onChange={e => setOrder(sot => sot ? {...sot, phoneNumber:e.target.value} : null)} className='form-text-input'/>
            </div>    
            <div className='input-field' style={{width:'100%'}}>
                <label className='form-label'>Address</label>
                <input value={order.address} onChange={e => setOrder(sot => sot ? {...sot, address:e.target.value} : null)} className='form-text-input'/>
            </div>            
            </div>          
                
            <div style={{width:'100%', 
                padding:10,                
                background:'#EEEEEE', display:'flex', justifyContent:'space-between'}}>
                <button className='app-button-primary' onClick={() => isEditing? updateCustomer() : createCustomer()} >{isEditing ? "Update" : "Create"}</button>
                <button className='app-button-primary' onClick={() => {setIsEditing(false);setShowCreateNewOrderPopup(false)}}>Cancel</button>
            </div>            
        </div>
        </PopUp>} */}
    </>)

}   

export default LayoutWrapper(AllOrdersView, {showNavigationBar:true, showSideMenu:true,backgroundImage});