import PropTypes, { string } from 'prop-types';
import { useEffect, useState } from 'react';
import './paginationView.css'
 

const PaginationView = ({currentPageIndex, numberOfPages,onClick}) =>
{

  const [firstPartPages, setFirstPartPages] = useState([])
  const [midPartPages, setMidPartPages] = useState([])
  const [lastPartPages, setLastPartPages] = useState([])
 

  useEffect(()=>{

    setFirstPartPages(generateFirstPartPageNumbers(currentPageIndex,numberOfPages,3));
    setMidPartPages(generateMidPartPageNumbers(currentPageIndex,numberOfPages,3));
    setLastPartPages(generateLastPartPageNumbers(currentPageIndex,numberOfPages,3)); 

  },[currentPageIndex, numberOfPages])

 

  const generatePageNumbers = (pageNumbersCount) =>
  {

    let pageNumbersArray = []; 

    for(let i = 1; i <= pageNumbersCount; i++)
    {
      pageNumbersArray.push(i)
    }

    return pageNumbersArray;
  }

 

  const generateFirstPartPageNumbers = (currentIndex, pageNumbersCount, partLength) =>
  {
    let pageNumbersArray = [];   

    if(currentIndex - partLength > 0)
    {

      for(let i = 1; i <=  partLength; i++)
      {
        if(i <= currentIndex- partLength)
        {
          pageNumbersArray.push(i)
        }
      }
    }
 
    return pageNumbersArray;
  }

 

  const generateMidPartPageNumbers = (currentIndex, pageNumbersCount, partLength) =>
  {
    let pageNumbersArray = [];   

    for(let i = 1; i <= pageNumbersCount; i++)
    {
      if(i> (currentIndex - partLength) && i < (currentIndex + partLength))
      {
        pageNumbersArray.push(i)
      }
    }

    return pageNumbersArray;
  }
 
  const generateLastPartPageNumbers = (currentIndex, pageNumbersCount, partLength) =>
  {
    let pageNumbersArray = [];  
 
    for(let i = pageNumbersCount - partLength; i <=pageNumbersCount; i++)
    {
      if(i >= currentIndex + partLength)
      {
        pageNumbersArray.push(i)
      }
    }

    return pageNumbersArray;
  }

 

  const gotToNext = () => {

    let i = currentPageIndex + 1 <= numberOfPages ? currentPageIndex +1 : 1
    onClick && onClick(i)

  }

 

  const gotToPrevious = () => {
    let i = currentPageIndex - 1 >= 1 ? currentPageIndex - 1 : numberOfPages
    onClick && onClick(i)

  }


  return(
    <div style={{display:'flex', fontSize:13}}>
    {numberOfPages > 1 && <><div onClick={()=>gotToPrevious()}>Previous</div>
        <div>
        {firstPartPages.map(p => <span onClick={()=>onClick(p)}  className={ p === currentPageIndex ? 'current-nav-page-button' : 'nav-page-button'}>{p}</span>)}
        </div>
        <div>{Math.min.apply(Math,midPartPages) > 1 && Math.min.apply(Math,midPartPages) > Math.max.apply(Math,firstPartPages) + 1? '...': null }</div>
        <div>
        {midPartPages.map(p => <span onClick={()=>onClick(p)} className={ p === currentPageIndex ? 'current-nav-page-button' : 'nav-page-button'}>{p}</span>)}
        </div>
        <div>{Math.max.apply(Math,midPartPages) < numberOfPages && Math.min.apply(Math,lastPartPages) > Math.max.apply(Math,midPartPages) + 1? '...': null }</div>
        <div>
        {lastPartPages.map(p => <span onClick={()=>onClick(p)}  className={ p === currentPageIndex ? 'current-nav-page-button' : 'nav-page-button'}>{p}</span>)}
        </div>
    <div onClick={()=>gotToNext()}>Next</div></> }
    </div>

  )

 

}

PaginationView.propTypes ={

  currentPageIndex : PropTypes.number.isRequired

}

export default PaginationView;
 