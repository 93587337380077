import React, { useEffect, useState } from 'react';
import {useNavigate} from 'react-router-dom';
import Logo1  from '../assets/images/brain-trainer-logo-large.svg';
import backgroundImage from '../assets/images/background_01.svg';
import backgroundImage02 from '../assets/images/background_02.svg';
//import DropIns from '../games/components/DropIns/DropIns';
import {animated, config, useSpring} from 'react-spring';
import { useSelector, useDispatch} from 'react-redux';
import introImage from '../assets/images/intro-image.JPG'
import { AUTHENTICATE_SUBACCOUNT_USER_URL, BaseURL } from '../constants/URLs';

import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import NotificationManager from '../notification/NotificationManager';
import { getEntities } from '../api/restUtilities';
import PINEntryField from '../components/PINEntryField/PINEntryField';

const SubAccountLoginView = (props) => {

    const navigate = useNavigate();

    const dispatch = useDispatch();  
    
    const user = useSelector( state => state.user);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loginCredentials, setLoginCredentials] = useState(
        {
            "username": "",
            "password": "",
            "accountDomainName": ""
        }      
    );

    useEffect(()=>{
       /*  if(user)
        {
            console.log("calling get entities", BaseURL + 'api/v1/accountconfiguration/getconfiguration?Id=' + user?.accountId)
            getEntities({url:BaseURL + 'api/v1/accountconfiguration/getconfiguration?Id=' + user?.accountId, successCallback: (d)=>{
                dispatch({type:"SET_ACCOUNT_CONFIGURATION", data:{...d}});
                console.log("SET_ACCOUNT_CONFIGURATION",d)
                localStorage.setItem('accountConfiguration', JSON.stringify(d));
              }});
        } */
    }, [user])
    const updateAccountConfig = (u) => {

       
    }
    const loginUser = () => {
        //console.log(loginCredentials)
        //navigate('/order-catalogues')

        //let companyDomain = companyidentifier != ""? "/"+companyidentifier:""
                        
        const requestOptions = {
            url: AUTHENTICATE_SUBACCOUNT_USER_URL +'?accountDomainName='+loginCredentials.accountDomainName,
            method: 'post',
            headers: { 'Content-Type': 'application/json', 'access-control-allow-origin':'*' },
            data: {...loginCredentials}
        };
    
        setIsSubmitting(true);
        trackPromise(axios({...requestOptions})
            .then((response) => {
                console.log(response)
               
                NotificationManager.success({
                    title:'Login Success',
                    'message':'You have been logged in.'
                })

                //setTimeout(function(){ props.history.push("/#login") }, 1500);
                return response.data;
            }).then(user => {
              // store user details and jwt token in local storage to keep user logged in between page refreshes
              localStorage.setItem('currentUser', JSON.stringify(user));
              dispatch({type:"LOGIN_USER", data:{...user}});

              updateAccountConfig(user);
              /*currentUserSubject.next(user);                  
            return user;*/
            navigate('/orders')
            //console.log("user",user)
           }).catch((e)=>
            {
                setIsSubmitting(false);                
                console.error("Error",e)                
                NotificationManager.error({
                    title:'Login Failed',
                    message:"Invalid Entry"//e.response?.data
                })
            })
        );
    }

    const handleNewUserChange = (e)  => 
    {
        const {name, value} = e.target;

        setLoginCredentials(nu => ({...nu, [name]:value}));
    }


    const [{x, opacity}, api] = useSpring(()=>({from:{x:-60, opacity:0}, config:config.wobbly}))
    
    useEffect(()=>
    {
        api.start(()=>({to:{x:0, opacity:1}}))

        if(user != null)
        {
            navigate('/order');
        }

    },[])

    return(
        <div style={{display:'flex',
         justifyContent:'space-around',
         padding:20,
         textAlign:'center',
         alignItems:'center',
         position:'absolute', 
         flexDirection:'column',
         background:'linear-gradient(135deg, rgb(150, 251, 196) 0%, rgb(249, 245, 134) 100%)',
         //background:`url('${backgroundImage}')`,
         //backgroundImage:'url(images/background-photo-01.jpg)',
         background:`white`,
         backgroundPosition:'center',
         backdropFilter:'greyscale(80%)',
         backgroundRepeat:'no-repeat',
         backgroundSize:'cover',
         height:'100vh', width:'100vw'}}>
             
                          
            <div style={{display:'flex',}}>
                <div style={{position:'relative', width:50, height:50}}>                
                    <animated.img width={50} style={{position:'absolute',left:x, opacity}} src={Logo1}/>                
                </div>            
                <div className={"app-title"} style={{fontWeight:'normal'}}>Oda<span style={{opacity:1.0, color:"#f7941e"}}>Now</span></div>
            </div>  
            <div style={{display:'flex', maxWidth:400, width:'100%', justifyContent:'space-around'}}>
                <span onClick={()=>navigate('/merchant-login')}  style={{fontSize:12, padding:'10px 25px', borderRadius:5, background:'#eeeeee', color:'#777777' }}>Merchant Login</span>
                <span style={{fontSize:12, padding:'10px 25px', borderRadius:5,background:'#fff4e3', color:'#ff9900' }}>Sub-Account Login</span>
            
            </div>
            
            <div className='account-prompt-view' >
                <div style={{alignItems:'flex-start'}} className='input-field'>
                    <label className='form-label-2'>Account Domain Name</label>
                    <input name='accountDomainName' onChange={handleNewUserChange} value={loginCredentials.accountDomainName} style={{width:'100%'}} className='form-text-input'/>
                </div>
                <div style={{alignItems:'flex-start'}} className='input-field'>
                    <label className='form-label-2'>Email/Username</label>
                    <input name='username' onChange={handleNewUserChange} value={loginCredentials.username} style={{width:'100%'}} className='form-text-input'/>
                </div>
                <div style={{alignItems:'flex-start'}} className='input-field'>
                    <label className='form-label-2'>PIN</label>
                    <PINEntryField  onChange={(d)=>setLoginCredentials(nu => ({...nu, password:d}))} value={loginCredentials.password}/>
                    {/* <input name='password' type='password' onChange={handleNewUserChange} value={loginCredentials.password} style={{width:'100%'}} className='form-text-input'/> */}
                </div>
                <div style={{width:'95%'}}>
                <button style={{width:'100%', marginTop:10, padding:'15px 25px'}}  onClick={()=>loginUser()} className='app-button-primary' >Login</button>
                </div>
            </div>
            <div style={{color:'#0f5a4d', fontSize:28, maxWidth:250}}  >
                {/* <div>Welcome</div>
                <div>to</div>
                <div className={"app-title"} style={{fontFamily:'Arial', fontWeight:'bold', color:'#56BE8F', 
                display:'flex', whiteSpace:'pre-wrap', maxWidth:340, fontSize:46,
                background:'linear-gradient(45deg, #56be8f, #fdc90d)', backgroundClip:'text', WebkitBackgroundClip:'text', WebkitTextFillColor:'transparent'}}>
                   <DropIns>{'Brain'}</DropIns><span>&nbsp;</span><DropIns>{'Trainer'}</DropIns> 
                </div> */}
            </div>
           
            <div style={{width:'100%'}}>
                <div style={{display:'flex', justifyContent:'space-between'}}>
                    <button onClick={()=>navigate('/')} className='app-button-primary'>Back</button>
                    
                
                </div>
                <div style={{color:'#0f5a4d',bottom:-15, position:'absolute', left:0, bottom:0, fontSize:10, width:'100%', background:'none', color:'white'}} >
                    <p>
                    OdaNow 2023
                    </p>
                </div>
            </div>
           
        {/* <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</div>
         */}</div>
        )
}

export default SubAccountLoginView;//LayoutWrapper(SplashScreen, {showNavigationBar:false, showSideMenu:false});