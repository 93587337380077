import React, {memo, useCallback, useEffect, useState, useRef} from 'react';
import {
    Link,
    useNavigate
} from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';
import { useSpring, animated as a, config } from 'react-spring';
import LayoutWrapper from '../layout/LayoutWrapper';
import ViewWrapper from './ViewWrapper';
//import { deleteAsset } from '../redux/actions/assetActions';
import Prompt from '../components/Prompt/Prompt';
import searchIcon from '../assets/images/search.svg'
import PopUp from '../components/PopUp/PopUp';
import backgroundImage from '../assets/images/background_01.svg';
import {v4 as uuidv4} from 'uuid';
import axios from 'axios';
import { BaseURL } from '../constants/URLs';
import NotificationManager from '../notification/NotificationManager';
import defaultIcon  from '../assets/images/default-inventory-icon.svg';
import { getEntities, postEntities, putEntities, deleteEntities } from '../api/restUtilities';

import PaginationView from '../components/PaginationView/PaginationView';
import { CheckBox } from '../components/Form/FormControls';
import SearchListView from '../components/SearchListView/SearchListView';
import FileUploader from '../components/FileUploader/FileUploader';
import ClipLoader from "react-spinners/ClipLoader";

import {Buffer} from 'buffer';
import NumberField from '../components/NumberField/NumberField';

const ImageBox = memo((props) =>
{


    const [isLoaded, setIsLoaded] = useState(false)
    const imageRef = useRef()
    useEffect(()=>{
       
        //var img = new Image();
        if(imageRef?.current && props.src)
        {
           
            imageRef.current.onLoad = function() {
                //alert('image loaded');
                setIsLoaded(true);
            }
            imageRef.current.src = props.src;
            if (imageRef.current?.complete || imageRef.current?.naturalWidth > 0) imageRef.current?.onLoad();
        }

        console.log("IMGBOX", {a:imageRef?.current,s:imageRef.current?.complete, b:props.src})
       

    },[props.src, imageRef])

    return (
         <div style={{aspectRatio:1, background:'white', position:'relative',...props.style}}> <img onClick={props.onClick} ref={imageRef} src={props.src} style={{ width:'100%', margin:0, visibility: isLoaded ? 'visible' : 'hidden',...props.style}}/>  
         {!isLoaded && <div style={{...props.style, aspectRatio:1, background:'none', justifyContent:'center', alignItems:'center', flexDirection:'column', position:'absolute', top:0, left:0, display: isLoaded ? 'none' : 'flex' }}>
            <ClipLoader                
         //style={{display:'block', margin:'0 auto', borderColor:'red'}}  

         size={20}                
         //size={"150px"} this also works                
         color={"#FF9900"} 
        loading={true}                
         /> <span style={{padding:10, fontSize:10}}>loading...</span></div> }</div>
    )

},(p,c) => p.src === c.src)

const InventoryView = (props)  => {
    
    const dummyInventoryData ={
        "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        "accountId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        "createdBy": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        "lastModifiedBy": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        "title": "",
        "description": "",
        "unit": "",
        "unitPrice": 0,
        "availableQuantity": 0,
        "creationDate": "2022-12-26T20:52:50.409Z",
        "lastModificationDate": "2022-12-26T20:52:50.409Z"
      }
    
    const [openPromptDialog, setOpenPromptDialog] = useState(false);
    const [promptOkAction, setPromptOkAction] = useState(null);
    const [searchString, setSearchString] = useState("");
    const [showCreateNewOrderPopup, setShowCreateNewOrderPopup] = useState(false);
    const [showAddCataloguesPopup, setShowAddCataloguesPopup] = useState(false);
    const [selectedOrderCatalogues, setSelectedOrderCatalogues] = useState([]);
    const [showSetImagesPopup, setShowSetImagesPopup] = useState(false)
    
    
   
    const [inventories, setInventories] = useState(null);    
    const [isEditing, setIsEditing] = useState(false);
    const [inventory, setInventory] = useState({...dummyInventoryData})
    const [images, setImages] = useState({});
    const [showImagePopUp, setShowImagePopUp] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageZoomScale, setImageZoomScale] = useState(1.0)

    const [currentPageIndex, setCurrentPageIndex] = useState(0) 
    const [totalRecords, setTotalRecords] = useState(0) 
    const [totalPageCount, setTotalPageCount] = useState(0)

    const currentUser  = useSelector(state => state.user);// JSON.parse(localStorage.getItem('currentUser'));

    const maxPerPage = 3

    useEffect(()=>{
       
             
        getEntities({url:BaseURL + `api/v1/inventory/getinventories?accountId=${currentUser?.accountId}&maxPerPage=${maxPerPage}&pageNumber=${currentPageIndex}&searchString=${searchString}`, successCallback: setInventoryRecordsData});
      
      
    },[currentPageIndex])

    useEffect(()=>{

        clearTimeout(delayTimer?.current);
        delayTimer.current = setTimeout(function() {
            getEntities({url:BaseURL + `api/v1/inventory/getinventories?accountId=${currentUser?.accountId}&maxPerPage=${maxPerPage}&pageNumber=${currentPageIndex}&searchString=${searchString}`, successCallback: setInventoryRecordsData});
      
        }, 1000);
        
    },[searchString])

    const delayTimer = useRef();
   
    const setInventoryRecordsData = (data) => {
        const {listData, pageNumber,totalRecords,totalPageCount} = data;
        setCurrentPageIndex(pageNumber);
        setTotalRecords(totalRecords);
        setTotalPageCount(totalPageCount);
        setInventories(listData);
    }
   
    useEffect(()=>{
        console.log(inventory)
    }, [inventory])
    const createInventory = () => {
        let newId = uuidv4();

        postEntities(
            {
                url:BaseURL + 'api/v1/inventory/save', 
                data:{...inventory, id:newId},
                successCallback: ()=>{
                setShowCreateNewOrderPopup(false);
                getEntities({url:BaseURL + `api/v1/inventory/getinventories?accountId=${currentUser?.accountId}&maxPerPage=${maxPerPage}&pageNumber=${currentPageIndex}&searchString=${searchString}`, successCallback: setInventoryRecordsData});
      
                setInventory({})
                },
                successNotification:{title:"Success", message:'Inventory created'},
                errorNotification:{title:"Failed", message:'Inventory creation failed'},
            }        
        )

       
    }

    const updateInventory = () => {
      console.log(inventory)
        putEntities(
            {
                url:BaseURL + 'api/v1/inventory/update', 
                data:{...inventory},
                successCallback: ()=>{
                setShowCreateNewOrderPopup(false);
                getEntities({url:BaseURL + `api/v1/inventory/getinventories?accountId=${currentUser?.accountId}&maxPerPage=${maxPerPage}&pageNumber=${currentPageIndex}&searchString=${searchString}`, successCallback: setInventoryRecordsData});
      
                setInventory({})
                },
                successNotification:{title:"Success", message:'Inventory updated'},
                errorNotification:{title:"Failed", message:'Inventory update failed'},
            }  
        )    

        
    }

    const deleteCustomer = (id) => {
        let newCustomerId = uuidv4();

       

        deleteEntities(
            {
                url:BaseURL + 'api/v1/inventory/remove?id='+id, 
                data:{...inventory},
                successCallback: ()=>{
                setShowCreateNewOrderPopup(false);
                getEntities({url:BaseURL + `api/v1/inventory/getinventories?accountId=${currentUser?.accountId}&maxPerPage=${maxPerPage}&pageNumber=${currentPageIndex}&searchString=${searchString}`, successCallback: setInventoryRecordsData});
      
                setInventory({})
                },
                successNotification:{title:"Success", message:'Inventory deleted'},
                errorNotification:{title:"Failed", message:'Inventory delete failed'},
            }  
        )    
    }

    const deleteModel = (id) =>
    {
        setOpenPromptDialog(true);
        setPromptOkAction({message: "Do you want to delete the inventory?",OkAction:()=>{deleteCustomer(id);setOpenPromptDialog(false)}});
    }

    const showEditingModal = (c) => {
        setInventory(c);
        setShowCreateNewOrderPopup(true);
        setIsEditing(true);
    }

    const showImagesSettingModal = (c) => {
        setInventory(c);
        setShowSetImagesPopup(true);
        setIsEditing(true);
    }

    const addItem = () => {
        setInventory(i => ({...i, catalogues:[...selectedOrderCatalogues.map(so => ({...so, catalogueName:so.title, catalogueId:so.id}))]}))
    }

    const toggleSelectedCatalogue = (item) => {
        if(selectedOrderCatalogues && selectedOrderCatalogues.some(s => s.id == item.id))
        {
            setSelectedOrderCatalogues(s => [...s.filter(si => si.id != item.id)])
        }
        else
        {
            setSelectedOrderCatalogues(s => [...s, {...item}])
        }
    }

    const renderComponent = ({data:oct}) => {
        return (<div onClick={()=>toggleSelectedCatalogue(oct)} style={{ minHeight:100, margin:0, marginBottom:10, height:'auto', background:'white', justifyContent:'flex-start', alignItems:'center', width:'100%', backgroundColor:selectedOrderCatalogues && selectedOrderCatalogues.some(s => s.id == oct.id) ?  '#f3ebe5' : 'white', border:selectedOrderCatalogues && selectedOrderCatalogues.some(s => s.id == oct.id) ? '2px solid orange' : 0}}  className={'item-card'}>
                    
                    <img  src={defaultIcon} style={{height:80, padding:10, background:'none', borderRadius:5,   margin:2, }} alt="logo" />
                    <div style={{padding:10, display:'flex', height:'100', justifyContent:'flex-start', flexDirection:'column'}}>
                    <span>{oct.title}</span>
                    <span style={{marginTop:10, fontSize:12, color:'grey'}}>{oct.description}</span>
                    </div>
                    </div>)
    }

    const showImage = (key) => {
        setSelectedImage(images[key]);
        setShowImagePopUp(true)
    }
 
   
    useEffect(()=>{
        console.log("Image loading 1", inventory)
        inventory?.images?.map(a =>a.fileClass == 'IMAGE' && getImage(BaseURL + `api/v1/Inventory/GetFileByKey?key=${a.link}`, a.fileType));
    
    }, [inventory])

    useEffect(()=>{
        console.log("Image loading 2", inventories)
        inventories?.map( i => i?.images?.map(a =>a.fileClass == 'IMAGE' && getImage(BaseURL + `api/v1/Inventory/GetFileByKey?key=${a.link}`, a.fileType)));
    
    }, [inventories])

    const getImage = useCallback((key, type) => {
       
        var stringifiedData = '';
        if(images[key] === undefined)
        {
            //responseType:"arraybuffer"}
            setImages( i => ({...i, [key]:null}))
            axios.get(key, {responseType:"arraybuffer"}).then(d => {
                let bdata = Buffer.from(d.data, "binary").toString('base64')//Buffer.from(d.data,'base64');
                //let bdata = btoa(data)
                console.log(bdata);
                 stringifiedData = `data:${type};charset=utf-8;base64, ${bdata}`
                setImages( i => ({...i, [key]:stringifiedData}))
            })
        }
        else
        {
            stringifiedData = images[key]
        }
       
        //let d = Buffer.from(r.data).toString('base64');
        //console.log(d)
        //setImages( i = ({...i, [key]:d}))
        return stringifiedData;//`data:image/png;base64, ${d}`;
    },[inventories])

    /* const showImage = (key) => {
        setSelectedImage(images[key]);
        setShowImagePopUp(true)
    } */
    useEffect(()=>{
       // console.log("blogImages",images)
    },[images])

    const handleUploadComplete = (d) => {
        setInventory( i => ({...i, images : [...i.images, d]}))
    }

    const deleteFile = (key,inventoryItemId) => {
        axios.delete(BaseURL + `api/v1/Inventory/DeleteInventoryImage?key=${key}&inventoryItemId=${inventoryItemId}`)
        .then(r => console.log("del", r))

    }

    const setDefaultImage = (key,inventoryItemId) => {
        axios.delete(BaseURL + `api/v1/Inventory/SetInventoryDefaultImage?key=${key}&inventoryItemId=${inventoryItemId}`)
        .then(r => console.log("setDefaultImage", r))

    }
    
    //useEffect(()=>{
    //    stageInfo?.attachments?.map(a =>a.fileClass == 'IMAGE' && getImage(BaseURL + `api/v1/Order/GetFileByKey?key=${a.link}`, a.fileType));
    //},[stageInfo])

    //

    return(<><ViewWrapper title={'Inventory'}>
   
            <Prompt promptOptions={{...promptOkAction}} OkLabel={"Yes"}  isOpen={openPromptDialog} modalClosed={()=> setOpenPromptDialog(false)}/>
                 <div style={{margin:5, position:'relative'}}>
                    <div style={{display:'flex', position:'sticky', flexDirection:'row', width:'100%', margin:'0px', alignItems:'center', justifyContent:'flex-start'}}>
                   
                    <button value={searchString} onClick={() =>{setInventory({...dummyInventoryData}); setShowCreateNewOrderPopup(true)}} className={'app-button-primary'}>
                        + New Inventory
                    </button>
                    </div>      
                </div>

                <div style={{margin:5, position:'relative'}}>
                    <div style={{display:'flex', position:'sticky', flexDirection:'row', width:'100%', margin:'10px 0px', alignItems:'center', justifyContent:'center'}}>
                    {searchString && <div style={{color:'#ab571e', marginRight:10}}  onClick={e=>setSearchString("")} >Clear</div>}    
                    <input className='search-input-wide'  value={searchString} onChange={e=>setSearchString(e.target.value)} placeholder="Search" style={{backgroundImage:`url('${searchIcon}')`, backgroundRepeat:'no-repeat', backgroundPositionX:'98%',  backgroundPositionY:'50%',}}/>
                    
                    </div>      
                </div>

                <div style={{margin:5, display:'flex', justifyContent:'space-between', position:'relative'}}>
                    <span style={{color:'#888888', fontSize:11}}>{`Total Records: ${totalRecords}`}</span>
                <PaginationView numberOfPages={totalPageCount} partLength={3} currentPageIndex={currentPageIndex+1} onClick={(i)=>setCurrentPageIndex(i-1)}/>  
                </div>

                <div style={{margin:5}}>
                <Prompt promptOptions={{...promptOkAction}} OkLabel={"Yes"}  isOpen={openPromptDialog} modalClosed={()=> setOpenPromptDialog(false)}/>
                
                {inventories && inventories.length > 0 ? 
                <div style={{ width:'100%',/* display: 'inline-grid',
                flexDirection: 'column', columnGap:10, rowGap:10, gridTemplateColumns:'repeat(auto-fill, minmax(260px, 1fr))', */ minHeight:'calc(100vh-50px)'}}>
              
                {inventories.map((g,i) => 
               <div className='item-card' style={{margin:0, height:'auto', minHeight:120, boxShadow:'2px 2px 11px -2px rgb(20 20 20 / 20%)',  marginBottom:10, alignItems:'center', width:'100%'}}>
               <div style={{display:'flex', width:'100%', justifyContent:'left', flexDirection:'column', alignItems:'center', }}>
                   <div style={{padding:5, display:'flex', width:'100%', justifyContent:'left', alignItems:'flex-start', fontSize:20}}>
                       {/* <div style={{background:'#ffe8be', padding:15, width:50, fontSize:16, height:50, margin:15}} className="user-info-avartar">
                       {i+1}                 
                       </div> */}
                       <div style={{fontSize:11, position:'relative', color:'grey', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                       {/* <ImageBox  src={g.defaultImageFile ? getImage(BaseURL + `api/v1/Inventory/GetFileByKey?key=${g.defaultImageFile.link}`, g.defaultImageFile.fileType): defaultIcon} style={{width:120, background:g.type === 'Service'? '#d6f7ef' :'white', borderRadius:5, padding:5,  margin:5, marginRight:10}} alt={g.title} />  */}
                       <ImageBox  src={g.defaultImageFile ? images[BaseURL + `api/v1/Inventory/GetFileByKey?key=${g.defaultImageFile.link}`]: defaultIcon} style={{width:120, background:g.type === 'Service'? '#d6f7ef' :'white', borderRadius:5, padding:5,  margin:5, marginRight:10}} alt={g.title} /> 
                       {g.images?.length > 1 && <span style={{position:'absolute', background:'black', color:'white', fontSize:10, padding:'2px 10px',borderRadius:15, right:10, top:10}}>+{g.images?.length -1}</span>}
                        {g.type}
                       </div>
                       {/*  <img  src={defaultIcon} style={{height:50, background:'none', borderRadius:5,   margin:5, marginRight:20}} alt="logo" />  */}
                       <div style={{display:'flex', flexGrow:1, flexDirection:'column'}}>

                           <div style={{display:'flex'}}>{g.title}</div>
                           <div style={{display:'flex', color:'#939090', fontSize:11}}>{g.description}</div>
                           <div style={{display:'flex', color:'#888888', paddingTop:5, fontSize:16}}>{`${g.availableQuantity} Available`}</div>
                           <div style={{display:'flex', color:'green', paddingTop:5, fontSize:16}}>{/* `\u20A6` */}{`NGN ${g.unitPrice.toLocaleString('en-US')} `}</div>
                           
                       
                       </div>
                  
                   </div>
                   <div style={{display:'flex', width:'100%', justifyContent:'flex-end'}}>
                           <button className='app-button-secondary-small' onClick={()=>showImagesSettingModal(g)}>Set Image</button>
                           <button className='app-button-secondary-small' onClick={()=>showEditingModal(g)}>Edit</button>
                           <button className='app-button-secondary-small' onClick={()=>deleteModel(g.id)}>Remove</button>
                   </div>

               </div>
           </div>
                )}</div>
                :
                <div style={{width:'100%', height:100, fontSize:20, color:'#CECECE', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    No Record found  
                </div>}
                
                
                <div style={{height:80}}></div>
                
                </div>
    </ViewWrapper>
    {<PopUp open={showCreateNewOrderPopup} >
    <div style={{width:'100%', height:'100%', display:'flex', flexDirection:'column',
        padding:0,
        borderRadius:10, 
        overflow:'hidden',
        boxShadow:'2px 2px 5px rgba(10,10,10,0.7)', minHeight:40, background:'white'}}>
            <div style={{padding:15, display:'flex', justifyContent:'left', alignItems:'center', fontSize:20}}>
           {/*  <img  src={defaultIcon} style={{height:50, background:'#ecf5ef', borderRadius:5,   margin:2, marginRight:20}} alt="logo" /> 
            */} <div style={{display:'flex', flexDirection:'column'}}>

                <div style={{display:'flex'}}>{isEditing ? "Edit Inventory Item" :'New Inventory Item'}</div>
               
            </div>
            
            </div>
            <div className='popup-view-container' style={{padding:15, display:'flex', flexDirection:'column', justifyContent:'left', alignItems:'center', fontSize:20}}>
            <div className='input-field' style={{width:'100%'}}>
                <label className='form-label'>Title</label>
                <input value={inventory.title} onChange={e => setInventory(sot => sot ? {...sot, title:e.target.value} : null)} className='form-text-input'/>
            </div> 
            <div className='input-field' style={{width:'100%'}}>
                <label className='form-label'>Description</label>
                <input value={inventory.description} onChange={e => setInventory(sot => sot ? {...sot, description:e.target.value} : null)} className='form-text-input'/>
            </div> 
            <div className='input-field' style={{width:'100%'}}>
                <label className='form-label'>Unit</label>
                <input value={inventory.unit} onChange={e => setInventory(sot => sot ? {...sot, unit:e.target.value} : null)} className='form-text-input'/>
            </div>    
            <div className='input-field' style={{width:'100%'}}>
                <label className='form-label'>Unit Price</label>
                <input value={inventory.unitPrice} onChange={e => setInventory(sot => sot ? {...sot, unitPrice:parseFloat(e.target.value)} : null)} className='form-text-input'/>
            </div>    
            <div className='input-field' style={{width:'100%'}}>
                <label className='form-label'>Available Quantity</label>
                <NumberField name={"availableQuantity"} onChange={e => setInventory(sot => sot ? {...sot, availableQuantity: parseFloat(e.value)} : null)}  key={inventory.availableQuantity}  value={inventory.availableQuantity}  />
               
            </div>   
            <div className='input-field' style={{width:'100%'}}>
                <label className='form-label'>Item Type</label>
                <select value={inventory.type} onChange={e => setInventory(sot => sot ? {...sot, type: e.target.value} : null)} className='form-text-input'>
                    <option value='Product'>Product</option>
                    <option value='Service'>Service</option>
                </select>
            </div>   
            <div className='input-field' style={{width:'100%'}}>
                <div style={{display:'flex', alignItems:'center'}}>
                
                <input type={'checkbox'} checked={inventory.isMappedToCatalogues} onChange={e => setInventory(sot => sot ? {...sot, isMappedToCatalogues: e.target.checked} : null)}  />
                <label className='form-label'>Is mapped only to catalogues</label>
                </div>
                
                {inventory.isMappedToCatalogues && 
                <div>
                    <div> {inventory.catalogues?.length}
                    {inventory.catalogues && inventory.catalogues.map(invCat => <div key={invCat.catalogueId}> {invCat.catalogueName} </div>)}
                    </div>
                    <button onClick={()=>setShowAddCataloguesPopup(true)}>Add Catalogue</button>
                </div>
                }
            </div>          
            </div>          
                
            <div style={{width:'100%', 
                padding:10,                
                background:'#EEEEEE', display:'flex', justifyContent:'space-between'}}>
                <button className='app-button-primary' onClick={() => isEditing? updateInventory() : createInventory()} >{isEditing ? "Update" : "Create"}</button>
                <button className='app-button-primary' onClick={() => {setIsEditing(false);setShowCreateNewOrderPopup(false)}}>Cancel</button>
            </div>            
        </div>

        </PopUp>}

        {<PopUp open={showSetImagesPopup} >
    <div style={{width:'100%', height:'100%', display:'flex', flexDirection:'column',
        padding:0,
        borderRadius:10, 
        overflow:'hidden',
        boxShadow:'2px 2px 5px rgba(10,10,10,0.7)', minHeight:40, background:'white'}}>
            <div style={{padding:15, display:'flex', justifyContent:'left', alignItems:'center', fontSize:20}}>
           {/*  <img  src={defaultIcon} style={{height:50, background:'#ecf5ef', borderRadius:5,   margin:2, marginRight:20}} alt="logo" /> 
            */} <div style={{display:'flex', flexDirection:'column'}}>

                <div style={{display:'flex'}}>{'Set Images:' + inventory?.title}</div>
               
            </div>
            
            </div>
            <div style={{padding:15, display:'flex', flexDirection:'column', justifyContent:'left', alignItems:'center', fontSize:20}}>
            {/* <div className='input-field' style={{width:'100%'}}>
                <label className='form-label'>{inventory.title}</label>
                <input value={inventory.title} onChange={e => setInventory(sot => sot ? {...sot, title:e.target.value} : null)} className='form-text-input'/>
            </div>  */}

            <FileUploader onFileUpload={handleUploadComplete} url={BaseURL + `api/v1/Inventory/UploadInventoryImage?inventoryItemId=${inventory?.id}`}/>
            
            <div style={{display:'flex', flexDirection:'row', overflow:'auto', maxWidth:'100%'}}>
            {inventory?.images?.map(a => <div onClick={()=>showImage(BaseURL + `api/v1/Inventory/GetFileByKey?key=${a.link}`)} style={{border: '1px solid #DEDEDE',height:205, width:250, backgroundImage:`url('${images[BaseURL + `api/v1/Inventory/GetFileByKey?key=${a.link}`]}')`, backgroundRepeat:'no-repeat', backgroundPosition:'center center', backgroundSize:'contain',  position:'relative', margin:5, borderRadius:10, boxShadow:'1px 1px 3px -3px'}}>
                <div style={{width:200, height:200}}></div>
           {/*  {a.fileClass == "IMAGE" && <ImageBox onClick={()=>showImage(BaseURL + `api/v1/Inventory/GetFileByKey?key=${a.link}`)} src={images[BaseURL + `api/v1/Inventory/GetFileByKey?key=${a.link}`]} style={{width:150}} />}
            */} <div  style={{position:'absolute', display:'flex', width:'100%', padding:5, justifyContent:'space-between', bottom:0}}>
                <span className='app-button-03-small' onClick={()=> deleteFile(a.link, inventory?.id)} style={{fontSize:10, bottom:0}}>Remove</span>
                <span className='app-button-03-small' onClick={()=> setDefaultImage(a.link, inventory?.id)} style={{fontSize:10, bottom:0}}>Set as default</span>
            </div>
            </div>)} 
            </div>
             

                  

            </div>
            <div style={{width:'100%', 
                padding:10,                
                background:'#EEEEEE', display:'flex', justifyContent:'flex-end'}}>
               
                <button className='app-button-primary' onClick={() => {setShowSetImagesPopup(false)}}>Close</button>
            </div>            
        
        </div>

        </PopUp>}

        <PopUp styles={{borderRadius:9, height:'100vh', width:'100vw'}} fillWidth={true} isEmbedded={true} open={showImagePopUp} >
            <div style={{width:'100%', background:'black'}}>
                <button className='app-button-03-small' onClick={()=>setShowImagePopUp(false)}>Close</button>
                <button className='app-button-03-small' onClick={()=>setImageZoomScale(z => z < 3.5 ? z  + 0.2 : z)}>Zoom in</button>
                <button className='app-button-03-small' onClick={()=>setImageZoomScale(z => z >0.5 ? z -0.2 : z)}>Zoom out</button>
               
            </div>
            <div style={{maxWidth:'100%', maxHeight:'80vh', /* padding: 10, */ overflow:'auto'}}>
                <ImageBox style={{maxWidth:'100%', width:'auto', aspectRatio:'none', borderRadius:0, transformOrigin:'left top', display:'flex', justifyContent:'center', transform:`scale(${imageZoomScale})`}} src={selectedImage}/>
                
            </div>
                
            </PopUp>  

        {<PopUp styles={{borderRadius:9}} isEmbedded={true} open={showAddCataloguesPopup} >
        <div style={{width:'100%', height:'100%', display:'flex', flexDirection:'column',
        padding:0,
        borderRadius:10, 
        overflow:'hidden',
        boxShadow:'2px 2px 5px rgba(10,10,10,0.7)', minHeight:50, background:'white'}}>
            <div style={{padding:15, display:'flex', justifyContent:'center', alignItems:'center', fontWeight:'normal', fontSize:16}}>
            <div style={{display:'flex', color:'#444444', padding:20, flexDirection:'column'}}>
           

            <SearchListView renderComponent={renderComponent} dataUrl={BaseURL + `api/v1/OrderCatalogue/GetPublicOrderCatalogues?accountDomainName=${currentUser?.accountDomainName}`}/>
            
            </div>
            
            </div>
           
                 
            <div style={{width:'100%', 
        padding:10,
         
        background:'#EEEEEE', display:'flex', justifyContent:'space-between'}}>
                <button className='app-button-primary' onClick={()=>{addItem();setShowAddCataloguesPopup(false)}} >Add</button>
                <button className='app-button-primary' onClick={()=>setShowAddCataloguesPopup(false)} >Close</button>
            </div>            
        </div>
    </PopUp>}
    </>)

}   

export default LayoutWrapper(InventoryView, {showNavigationBar:true, showSideMenu:true,backgroundImage});