import React, { useEffect, useRef, useState } from 'react';

const PINEntryField = (props) => {


  const [fieldLength, setFieldLength] = useState(6 || props.fieldLength)
  const [fieldRefs, setFieldRefs] = useState({})
  const [value, setValue] = useState({})
 

  const generateArray = (length) =>
  {
     let result = []; 

     for(let i = 0; i < length; i++)
     {
        result.push(i)
     }

     return result;
  }

 

  const handleFieldChange = (e) => {
    const {value, name} = e.target; 

    if(fieldRefs[name])
    {

      //console.log("pin field", fieldRefs[name])

      fieldRefs[name].current.value = value?.charAt(0)

      setValue(fr => ({...fr, [name]:value?.charAt(0)}))

      if(value != "")
      {
        fieldRefs[parseInt(name)+1]?.current?.focus();
      }     

    }
  }

 

  const handleKeyDown = (e) => {

    e.preventDefault();
    const {value, name} = e.target; 

    //const key = e.key; // const {key} = event; ES6+
    var key = e.keyCode || e.charCode;
    //if (key === "Backspace") {
    if( key == 8 || key == 46 ){
      console.log("del bkspace",parseInt(name)-1)
      fieldRefs[name].current.value= "";
      setValue(fr => ({...fr, [name]:''}))
      fieldRefs[parseInt(name)-1]?.current?.focus();
    }
    else if(fieldRefs[name])
    {
      console.log("pin field", {f:fieldRefs[name], value, k:e.key })

      fieldRefs[name].current.value = e?.key?.charAt(0)
      setValue(fr => ({...fr, [name]:e?.key?.charAt(0)})) 

      if(e?.key != "")
      {
        fieldRefs[parseInt(name)+1]?.current?.focus();
      }

     /*  console.log("pin field", {f:fieldRefs[name], value, k:e.key })

       fieldRefs[name].current.value = value?.charAt(0)

      setValue(fr => ({...fr, [name]:value?.charAt(0)}))
 

      if(value != "")
      {

        fieldRefs[parseInt(name)+1]?.current?.focus();

      }  */
     
    }

  }

 

  useEffect(()=>{

    generateArray(fieldLength).map(a => setFieldRefs(fr => ({...fr, [a]:React.createRef()})))

    generateArray(fieldLength).map(a => setValue(fr => ({...fr, [a]:props.value?.charAt(parseInt(a))})))

  },[fieldLength])

 

  useEffect(()=>{

    console.log(Object.values(value).join(''))
    props.onChange && props.onChange(Object.values(value).join(''))
  },[value])

 

  return(

    <div style={{display:'flex', width:'100%', justifyContent:'space-between'}}>

      {generateArray(fieldLength).map(a => <input key={a} maxLength={1}

      style={{width:40, color:'black', textAlign:'center', fontSize:16, fontWeight:'bold', background:'#d9d9d9', padding:8, margin:4, borderRadius:3, border:'1px solid rgba(10,10,10,0.2)'}}

       onKeyDown={handleKeyDown} ref={fieldRefs[a]} onChange={handleFieldChange} name={a} />)}

    </div>

  )

 

 

}

export default PINEntryField