import React, {memo, useEffect, useState, useRef} from 'react';



import ClipLoader from "react-spinners/ClipLoader";

import {Buffer} from 'buffer';

const ImageBox = memo((props) =>
{


    const [isLoaded, setIsLoaded] = useState(false)
    const imageRef = useRef()
    useEffect(()=>{
       
        //var img = new Image();
        if(imageRef?.current && props.src)
        {
           
            imageRef.current.onLoad = function() {
                //alert('image loaded');
                setIsLoaded(true);
            }
            imageRef.current.src = props.src;
            if (imageRef.current?.complete || imageRef.current?.naturalWidth > 0) imageRef.current?.onLoad();
        }

        console.log("IMGBOX", {a:imageRef?.current,s:imageRef.current?.complete, b:props.src})
       

    },[props.src, imageRef])

    return (
         <div style={{...props.style,aspectRatio:1, background: props.background || 'white', position:'relative'}}> <img onClick={props.onClick} ref={imageRef} src={props.src} style={{...props.style, width:'100%', margin:0, visibility: isLoaded ? 'visible' : 'hidden'}}/>  
         {!isLoaded && <div style={{...props.style, aspectRatio:1, background:'none', justifyContent:'center', alignItems:'center', flexDirection:'column', position:'absolute', top:0, left:0, display: isLoaded ? 'none' : 'flex' }}>
            <ClipLoader                
         //style={{display:'block', margin:'0 auto', borderColor:'red'}}  

         size={20}                
         //size={"150px"} this also works                
         color={"#FF9900"} 
        loading={true}                
         /> <span style={{padding:10, fontSize:10}}>loading...</span></div> }</div>
    )

},(p,c) => p.src === c.src)

export default ImageBox;