import React, {useEffect, useState} from 'react';


const CatalogueStageFormDesigner = (props) => {

    return(
        <div style={{padding:25, display:'flex', alignItems:'center', flexDirection:'column', justifyContent:'center', minWidth:320, width:'100%'}}>
            <div className='input-field'>
                <label className='form-label'>URL</label>
                <input className='form-text-input'/>
            </div>
            <div className='input-field'>
                <label className='form-label'>Order Catalogue ID</label>
                <input className='form-text-input'/>
            </div>
            <div className='input-field'>
                <label className='form-label'>Theme Colors</label>
                <div style={{display:'inline-flex', flexWrap:'wrap'}}>
                    {[1,2,3,4,5,6,7].map( c => <div style={{width:50,height:50, 
                        border:'4px solid red', margin:5, borderRadius:'50%', background:'blue'}}></div>)}
                </div>
            </div>            
        </div>
    )
}


export default CatalogueStageFormDesigner;