import React, { useEffect, useState } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import Logo1  from '../../assets/images/brain-trainer-logo-large.svg';
import backgroundImage from '../../assets/images/background_01.svg';
//import DropIns from '../games/components/DropIns/DropIns';
import {animated, config, useSpring} from 'react-spring';
import { useSelector, useDispatch} from 'react-redux';
import { BaseURL, REGISTER_CUSTOMER_URL } from '../../constants/URLs';

import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import NotificationManager from '../../notification/NotificationManager';
//import CustomerViewLayoutWrapper from './CustomerViewLayoutWrapper';
import CustomerViewLayoutWrapper from './layout/CustomerViewLayoutWrapper';
import { getEntities } from '../../api/restUtilities';

const CustomerRegistrationPage = (props) => {

    const navigate = useNavigate();

    const dispatch = useDispatch(); 
    
    const {accountDomainName} = useParams()
    
    const customerAccount = useSelector( state => state.customerAccount);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loginCredentials, setLoginCredentials] = useState(
        {
            "userName": "",
            "password": "",
            "passwordConfirmation": "",
            "accountDomainName": accountDomainName
        }      
    );

    const sessionAccountInfo = useSelector(state => state.accountConfiguration); 
    const [accountInfo, setAccountInfo] = useState(null)
   
    useEffect(()=>{

        if(sessionAccountInfo == null || sessionAccountInfo.name != accountDomainName)
        {
            console.log("acc config loaded", {sessionAccountInfo, accountDomainName})
            getEntities({
                url:BaseURL + `api/v1/AccountConfiguration/GetAccountInfo?accountDomainName=${accountDomainName}`, 
                successCallback:setAccountInfo});
        }
        else
        {
            setAccountInfo({...sessionAccountInfo})
        }
    },[])

    const loginUser = () => {
        console.log(loginCredentials)
        //navigate('/order-catalogues')

        //let companyDomain = companyidentifier != ""? "/"+companyidentifier:""
                        
        const requestOptions = {
            url: REGISTER_CUSTOMER_URL,// +'?accountDomainName='+loginCredentials.accountDomainName,
            method: 'post',
            headers: { 'Content-Type': 'application/json', 'access-control-allow-origin':'*' },
            data: {...loginCredentials}
        };
    
        setIsSubmitting(true);
        trackPromise(axios({...requestOptions})
            .then((response) => {
                console.log(response)
               
                NotificationManager.success({
                    title:'Login Success',
                    'message':'You have been logged in.'
                })

                //setTimeout(function(){ props.history.push("/#login") }, 1500);
                return response.data;
            }).then(user => {
              // store user details and jwt token in local storage to keep user logged in between page refreshes
              localStorage.setItem('customerAccount', JSON.stringify(user));
              dispatch({type:"LOGIN_CUSTOMER", data:{...user}});
              /*currentUserSubject.next(user);                  
            return user;*/
            navigate(`/${accountDomainName}/home`)
            console.log("user",user)
           }).catch((e)=>
            {
                setIsSubmitting(false);                
                console.error("Error",e)                
                NotificationManager.error({
                    title:'Login Failed',
                    message:"Invalid Entry"//e.response?.data
                })
            })
        );
    }

    const handleNewUserChange = (e)  => 
    {
        const {name, value} = e.target;

        setLoginCredentials(nu => ({...nu, [name]:value}));
    }


    const [{x, opacity}, api] = useSpring(()=>({from:{x:-60, opacity:0}, config:config.wobbly}))
    
    useEffect(()=>
    {
        api.start(()=>({to:{x:0, opacity:1}}))
    },[])

    return(
        <div style={{display:'flex',
         justifyContent:'space-around',
         padding:30,
         textAlign:'center',
         alignItems:'center',
         //position:'absolute', 
         flexDirection:'column',
         //background:'linear-gradient(135deg, rgb(150, 251, 196) 0%, rgb(249, 245, 134) 100%)',
         //background:`url('${backgroundImage}')`,
         //background:`white`,
         backdropFilter:'greyscale(80%)',
         backgroundRepeat:'no-repeat',
         backgroundSize:'cover',
         //height:'100vh', 
         flexGrow:1,
         width:'100%'}}>
             
                          
             <div style={{position:'relative', width:50, height:50}}>
                 <animated.img style={{height:36,fontFamily:'Dancing Script',position:'absolute',left:x, opacity}} alt="logo"  src={accountInfo?.businessLogo} />
                
            </div> 
            <span style={{fontSize:16, color:'#998988'}}>Customer Registration</span>
            <div  className='account-prompt-view' style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', width:'80%'}}>
                <div style={{width:'100%',display:'flex', flexDirection:'column', alignItems:'center', maxHeight:'50vh', overflowY:'auto'}}>
                <div style={{alignItems:'flex-start'}} className='input-field'>
                    <label className='form-label'>Account Domain Name</label>
                    <input name='accountDomainName' disabled={true} value={accountDomainName} style={{width:'100%'}} className='form-text-input'/>
                </div>
                <div style={{alignItems:'flex-start'}} className='input-field'>
                    <label className='form-label'>First Name</label>
                    <input name='firstName' onChange={handleNewUserChange} value={loginCredentials.firstName} style={{width:'100%'}} className='form-text-input'/>
                </div>
                <div style={{alignItems:'flex-start'}} className='input-field'>
                    <label className='form-label'>Last Name</label>
                    <input name='lastName' onChange={handleNewUserChange} value={loginCredentials.lastName} style={{width:'100%'}} className='form-text-input'/>
                </div>
                <div style={{alignItems:'flex-start'}} className='input-field'>
                    <label className='form-label'>Email/Username</label>
                    <input autoComplete='false' name='userName' onChange={handleNewUserChange} value={loginCredentials.username} style={{width:'100%'}} className='form-text-input'/>
                </div>
                <div style={{alignItems:'flex-start'}} className='input-field'>
                    <label className='form-label'>Password</label>
                    <input name='password' type='password' onChange={handleNewUserChange} value={loginCredentials.password} style={{width:'100%'}} className='form-text-input'/>
                </div>
                <div style={{alignItems:'flex-start'}} className='input-field'>
                    <label className='form-label'>Password Confirmation</label>
                    <input name='passwordConfirmation' type='password' onChange={handleNewUserChange} value={loginCredentials.passwordConfirmation} style={{width:'100%'}} className='form-text-input'/>
                </div>
                </div>
                <div style={{display:'flex', justifyContent:'space-between', width:'95%'}}>
                    {/* <button onClick={()=>loginUser()} className='app-button-primary' >Login</button> */}
                    <button style={{width:'100%', padding:'15px 25px'}} onClick={()=>loginUser()} className='app-button-primary-01' >Register</button>
                </div>
                <div style={{display:'flex', flexDirection:'row', marginTop:20, justifyContent:'space-between', alignItems:'flex-start'}} className='input-field'>                    
                    <span style={{fontSize:11, color:'#464646', textDecoration:'none', padding:'10px 0px'}}>Already have an account?</span>
                    <button onClick={()=>navigate(`/${accountDomainName}/customer-login`)} className='app-button-primary-01'>Login</button>
                </div>
            </div>
            <div style={{color:'#0f5a4d', fontSize:28, maxWidth:250}}  >
                {/* <div>Welcome</div>
                <div>to</div>
                <div className={"app-title"} style={{fontFamily:'Arial', fontWeight:'bold', color:'#56BE8F', 
                display:'flex', whiteSpace:'pre-wrap', maxWidth:340, fontSize:46,
                background:'linear-gradient(45deg, #56be8f, #fdc90d)', backgroundClip:'text', WebkitBackgroundClip:'text', WebkitTextFillColor:'transparent'}}>
                   <DropIns>{'Brain'}</DropIns><span>&nbsp;</span><DropIns>{'Trainer'}</DropIns> 
                </div> */}
            </div>
           
            
           
        {/* <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</div>
         */}</div>
        )
}

//export default CustomerLoginPage;//LayoutWrapper(SplashScreen, {showNavigationBar:false, showSideMenu:false});
export default CustomerViewLayoutWrapper(CustomerRegistrationPage, {showNavigationBar:false,removePadding:false, showSideMenu:false});