import React from 'react';
//import NavigationBar from './navigation/NavigationBar';
//import './LayoutWrapper.css'
import NavigationBar from '../../../layout/navigation/NavigationBar';
import {SideMenuContext} from '../../../layout/sideMenu/SideMenuContext';
import NotificationContainer from '../../../notification/NotificationContainer';
import SideMenu from '../../../layout/sideMenu/SideMenu';
// Navigation Layout is a HOC for pages
// that will require navigation views to be present.

import notificationIcon from '../../../assets/images/icons/notification-icon.svg';
import teamIcon from '../../../assets/images/icons/team-icon.svg';
import inventoryIcon from '../../../assets/images/icons/inventory-icon.svg';
import customerIcon from '../../../assets/images/icons/customer-icon.svg';
import orderIcon from '../../../assets/images/icons/order-icon.svg';
import orderCatalogueIcon from '../../../assets/images/icons/order-catalogue-icon.svg';
import statisticsIcon from '../../../assets/images/icons/statistics-icon.svg';
import helpIcon from '../../../assets/images/icons/help-icon.svg';
import settingsIcon from '../../../assets/images/icons/settings-icon.svg';
import CustomerViewNavigationBar from './navigation/CustomerViewNavigationBar';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const mainMenuItemsDef = [
   /*  {
        icon:orderIcon,
        menuLink:'/orders',
        name:"Create Order",
        key:"orders",
        isCollapsed:false
    }, */
   /*  {
        icon:statisticsIcon,
        menuLink:'/dashboard',
        name:"Track Order",
        key:"user-statistics",
        isCollapsed:true
    }, */
    
    {
        icon:orderCatalogueIcon,
        menuLink:'/order-history',
        name:"Order History",
        key:"order-catalogues",
        isCollapsed:true,
        isAuthorised:true,
    },    
    {
        icon:inventoryIcon,
        menuLink:'/inventory',
        name:"Inventory",
        key:"inventory",
        isCollapsed:true
    },
    {
        icon:settingsIcon,
        menuLink:'/about',
        name:"About",
        key:"about",
        isAuthorised:false,
        isCollapsed:true
    },    
    {
        icon:settingsIcon,
        menuLink:'/settings',
        name:"Profile Settings",
        key:"settings",
        isAuthorised:true,
        isCollapsed:true
    }
    
]
const CustomerViewLayoutWrapper = (PageComponent, options={}) => (props) => {

    //const {accountDomainName} = useParams()

    //const customerAccount = useSelector(state => state.customerAccount);   
    //const sessionAccountInfo = useSelector(state => state.accountConfiguration); 
        
    return (<SideMenuContext>
            {/*<NotificationsWrapper>*/}
            <div style={{minHeight:'100vh'}}>
            {options.showNavigationBar && <CustomerViewNavigationBar showSideMenuButton={options.showSideMenu}/>}
             {/* Notification Container */}
             <NotificationContainer />

            <div background={options.backgroundImage} className={`centered-view ${options.showSideMenu? 'offset-centered-view':''}`} style={{
                backgroundColor:options.backgroundColor,
                //backgroundImage:`url(${options.backgroundImage})`,
                backgroundRepeat:'no-repeat',
                backgroundSize:'cover',
                flexDirection:'column',
                paddingTop:options.removePadding ? 0 : null,
                alignItems:`${options.alignCenter? 'center':'flex-start'}`,
                justifyContent:`${options.alignCenter? 'center': 'start'}`
            }}>              
                
                
                {/*Page Component*/}
                <PageComponent {...props}/>
               
                {/*Side Menu*/}
                {options.showSideMenu === true && <SideMenu isCustomerView={true} menuList={mainMenuItemsDef}/>}
                {/**.map(mm => ({...mm, menuLink:`/${accountDomainName}${mm.menuLink}`})) */}
            </div>
            </div>
            {/*</NotificationsWrapper>*/}
        </SideMenuContext>)
}

export default CustomerViewLayoutWrapper;


