import React, {useEffect, useState} from 'react';
import {
    Link,
    useNavigate
} from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';
import GameHeader from '../../components/GameHeader/GameHeader';
import {
    useParams,
    
} from 'react-router-dom';
import Moment from 'moment';
import {v4 as uuidv4} from 'uuid';

//import ToggleSelect from '../components/ToggleSelect/ToggleSelect';
//import //useAnalyticsEventTrack from '../games/hooks/analyticsTracker';
import { InvoiceEditor } from '../../components/Invoice/InvoiceEditor';
import { PaymentView } from '../../components/Payment/PaymentView';
import StageActionView from '../../components/StageAction/StageActionView';
import { moveByIndexField } from '../../utilities/arrayUtilities';
import { NavigationCounter } from '../../components/ViewNavigation/NavigationCounter';

import OrderForm from '../../components/Form/OrderForm';

import { BaseURL } from '../../constants/URLs';

import { getEntities, postEntities, putEntities, deleteEntities } from '../../api/restUtilities';
import CustomerViewLayoutWrapper from './layout/CustomerViewLayoutWrapper';
//import CustomerViewLayoutWrapper from './CustomerViewLayoutWrapper';







const OrderStageSelector = (props) => {
    let currentIndex = props.currentIndex;

    if(props.orderStages== null || props.orderStages.length === 0)
    {
        return <div>Empty Catalogue</div>
    }
    
    let currentStage = props.orderStages[currentIndex];

    

    //TODO: Fetch the order at this point

    if(currentStage == null) return <div>Invalid Catalogue</div>
    console.log(currentStage)
    console.log("cataloguee", props.orderData)
    switch(currentStage.type)
    {
        case "OrderPlacement" : return  <OrderForm orderData={props.orderData} orderDataSet={props.orderDataSet}  stageInfo={currentStage} orderId={props.orderData.id} isOrderPlacementForm={true} items={currentStage.items} stageCallbackHandler={props.stageCallbackHandler} formFields={currentStage.formFields}/>
        case "Invoice" : return  <InvoiceEditor  orderData={props.orderData}  stageInfo={currentStage} orderId={props.orderData.id} stageCallbackHandler={props.stageCallbackHandler} items={currentStage.items}/>
        case "Payment" : return  <PaymentView orderId={props.orderData.id}  stageInfo={currentStage}  stageCallbackHandler={props.stageCallbackHandler} formFields={currentStage.formFields}/>
        case "Confirmation" : return  <StageActionView orderId={props.orderData.id} stageCallbackHandler={props.stageCallbackHandler} formFields={currentStage.formFields}/>
        case "Action" : return  <StageActionView stageCallbackHandler={props.stageCallbackHandler} formFields={currentStage.formFields}/>
        case "Feedback" : return  <OrderForm stageCallbackHandler={props.stageCallbackHandler} formFields={currentStage.formFields}/>
        default : return <div>{currentStage.description}</div>
    }
}

const CreateOrderView = (props)  => {
  

    const {orderId} = useParams();
    
    const {accountDomainName} = useParams()

    const dispatch = useDispatch();    

    const orderCatalogues = useSelector(state => state.orderCatalogues);

    const savedOrder = useSelector(state => state.orders.find(o => o.id === orderId));

    

    //const currentOrderCatalogue = orderCatalogues.find(g => g.id === orderCatalogueId);

    console.log("currentOrderCatalogue",orderId)

    const gamePlayData = useSelector(state => state.gamePlayData);

    const dummyStage = {  
        id:uuidv4(),      
        position:2,
        title: "New Stage",
        stageDuration: 6,
        description: "This stage hanldes Customer inquiries and can be entered by the Customer or the Merchant.",
        type: "Inquiry", 
        formFields:[],
        reviewers:[]
    }

    /*const gaEventTracker = useAnalyticsEventTrack(orderCatalogueId)
    useEffect(()=>{
        gaEventTracker("Launched Catalogue@", orderCatalogueId)
    })*/

    const dummyCatalogue = 
        {
            title:'New Catalogue',
            description: 'This is a long text description',
            lastEdited: "2022-08-30T19:52:06.056Z"
        }

    
    useEffect(()=>{

        console.log("order id", orderId)
        getEntities({
            url:BaseURL + 'api/v1/order/getCustomerOrder?id=' + orderId, 
            successCallback:(d)=>{
                setOrder(d);
                
                setOrderStages(d.stageData)

                
                setCurrentNavigationIndex(d.currentStageIndex)
   

                setNavigationSteps([...d.stageData?.map(s => ({caption:s.title}))])
            }});
    },[])

    
  
    

    const [currentNavigationIndex, setCurrentNavigationIndex] = useState(0);
    const [showStageEditor, setShowStageEditor] = useState(false);
    const [showStageDeletePrompt, setShowStageDeletePrompt] = useState(false);
    const [isEditingStage, setIsEditingStage] = useState(true);
    const [order, setOrder] = useState(savedOrder ? {...savedOrder} : {...dummyCatalogue})
    const [orderInvoice, setOrderInvoice] = useState(null)
    const [orderDataSet, setOrderDataSet] = useState([])
    const [invoiceData, setInvoiceData] = useState({})
    const [orderCatalogue, setOrderCatalogue] = useState(savedOrder?.catalogue ? {...savedOrder?.catalogue} : {...dummyCatalogue})
    const [orderStages, setOrderStages] = useState(savedOrder?.catalogue.stages ? [...savedOrder?.catalogue.stages] : []
       )
    const [selectedStage, setSelectedStage] = useState({
        title: "Inquiry",
        description: "This stage hanldes Customer inquiries and can be entered by the Customer or the Merchant.",
        type: "Inquiry", // Inquiry type will have compulsory fields
        formFields: [ // Custom/optional fields
            {
                caption:'Phone Number',
                type:'text',
                id:'12233-112233'
            },
            {
                caption:'Phone Number',
                type:'dropdown',
                id:'12233-112233',

            },
            {
                caption:'Quantity',
                type:'number',
                id:'12233-112233',
                minValue:0,
                maxValue:20
            },

        ],
        acknowledgement: {

        },
        reviewers:[
            {
                name:"Karl",
                email:"karl@yahoo.com"
            },
            {
                name:"Chinenye",
                email:"Chinenye@yahoo.com"
            },
            {
                name:"Sarah",
                email:"sarah@yahoo.com"
            }
        ]
    })
    const [navigationSteps, setNavigationSteps] = useState(//[
        //{caption:"Details"}, {caption:"Stages"}, {caption:"Form Details"}
        order?.catalogue ? [...order?.catalogue.stages?.map(s => ({caption:s.title}))] : []
    //]
    )

    const sessionAccountInfo = useSelector(state => state.accountConfiguration); 
    const [accountInfo, setAccountInfo] = useState(null)
   
    useEffect(()=>{

        if(sessionAccountInfo == null || sessionAccountInfo.name != accountDomainName)
        {
            console.log("acc config loaded", {sessionAccountInfo, accountDomainName})
            getEntities({
                url:BaseURL + `api/v1/AccountConfiguration/GetAccountInfo?accountDomainName=${accountDomainName}`, 
                successCallback:setAccountInfo});
        }
        else
        {
            setAccountInfo({...sessionAccountInfo})
        }
    },[])

    /*useEffect(()=>{

        if(orderStages && orderStages.length > 0)
        {
            let currentStage = orderStages[currentNavigationIndex];
            console.log("Curr", {currentStage,orderStages, currentNavigationIndex})
            getEntities({
                url:BaseURL + 'api/v1/order/GetOrderStageData?orderId=' + orderId + '&stageId=' + currentStage?.orderStageId, 
                successCallback:(od)=>{
                    //setOrder(d);

                    setOrderDataSet(od.dataSet.data)
                    
                    console.log("CURR 2",{od, currentStage})
                }});
        }
    },[orderStages])*/
    const getLastEdtedDuration = (lastPlayed) => {
        
        
        if(lastPlayed && lastPlayed !== -Infinity)
        {
            
            return Moment(new Date(lastPlayed)).startOf('second').fromNow()
        }
        return "";//new Date(Math.max(...gamePlayData.filter(g => g.gameCode === code).map(gp => new Date(gp.startTime))));
        
    }

    const stageCallbackHandler = (action, data) => {

        console.log("Key entered", {action,data})
        switch(action)
        {
            case "openStageEditor" : openStageEditor(data) 
                break;
            case "moveStage" : moveStage(data)
                break;
            case "showDeletePrompt" : showDeletePrompt(data)
                break;
            case "addStage" : addStage(data)
                break;
            case "setSelectedStageField" : setSelectedStageField(data) 
                break;
            case "updateStageReviewers" : updateStageReviewers(data)
                break;
            case "updateForm" : updateForm(data)
                break;
            case "updateFormData" : updateFormData(data)
                break;
            case "updateInvoiceItems" : updateInvoiceItems(data)
                break;
            //case "updatefieldDetails" : updateFormData(data)//updatefieldDetails(data)
            //    break;
            case "updateInvoiceFields" : updateInvoiceFields(data)
                break;
            case "updateItems" : updateItems(data)
                break;
            case "updateOrderInfo" : updateOrderInfo(data)
                break;

                
        }
    }
    
    const updateInvoiceFields = (invoiceInfo) => {
        console.log("we have invoice data", invoiceInfo)
        setInvoiceData(i => ({...i,...invoiceInfo}))
    }

    const updateInvoiceItems = (invoice) => {
        console.log("we have invoice data", invoice)
        setInvoiceData(invoice)
    }

    const updateItems = (items) => {
                
        let currentStage = orderStages[currentNavigationIndex];
        setOrderStages( os => os.map(o =>
            o.id === currentStage.id ? {...o, items:[...items]} : o));

    }

    const updateFormData = (formData) => {
                
        let currentStage = orderStages[currentNavigationIndex];
        //dispatch('UPDATE_ORDER', 
        //setOrder(o => ({...o, orderData:{...formData, stageId:currentStage.id}}))
        //setOrderStages( os => os.map(o =>
        //    o.id === currentStage.id ? {...o, formData:[...formData]} : o));

        setOrderDataSet([...formData])
            
    }

    useEffect(()=>console.log(orderDataSet),[orderDataSet])

    const updateForm = (updatedFormFields) => { 
        console.log(updatedFormFields)     
        setSelectedStage(s => ({...s,formFields:updatedFormFields}))
    }

    const updateStageReviewers = (updatedReviewers) => {
        console.log(updatedReviewers)
        setSelectedStage(s => ({...s,reviewers:updatedReviewers}))
    }

    const setSelectedStageField = ({field, value}) => {
        setSelectedStage(s => ({...s,[field]:value}))
    }

    const addStage = (position) => {
        setSelectedStage({
            ...dummyStage,
            position
        });
        setShowStageEditor(true);
        setIsEditingStage(false);
    }

    const addNewStage = () => {
        setOrderStages( os => [...os, {...selectedStage}]);
        setShowStageEditor(false);
        setIsEditingStage(true);
    }

    const updateStage = () => {
        setOrderStages( os => os.map(o =>
            o.id === selectedStage.id ? {...selectedStage} : o));
          
        setShowStageEditor(false);
    }

    const showDeletePrompt = (stage) => {
        setSelectedStage(stage);
        setShowStageDeletePrompt(true);

    }

    const deleteSelectedStage = () => {
        setOrderStages(os => os.filter(o => o.id !== selectedStage?.id))
        setShowStageDeletePrompt(false);
    }

    const moveStage = ({newPosition, stage}) => {
        console.log({newPosition, stage})
        setOrderStages(od => moveByIndexField(stage.position, newPosition, od, "position"))
    }

    const saveOrder = () => {
        //dispatch({type:"UPDATE_ORDER", data:{...order,  stages:[...orderStages]}});   

        
    
    let currentStage = orderStages[currentNavigationIndex];

        console.log("Saving order", {
            ...order,
            dataSet: {
                data:[...orderDataSet]
            },
            orderStageId: order.id,
            invoice: {...invoiceData}
        } )

        putEntities({url:BaseURL + 'api/v1/Order/UpdateStageAsCustomer',
        data: {
            ...order,
            orderStageData: {
                dataSet : {data:[...orderDataSet]}
            },
            orderId : order.id,
            orderStageId: currentStage.orderStageId,
            invoice: {...invoiceData}
        },
        successCallback: (d)=>{
            //setShowCreateCustomerPopup(false);
            //getEntities({url:BaseURL + `api/v1/customer/getcustomers?maxPerPage=${maxPerPage}&pageNumber=${currentPageIndex}&searchString=${searchString}`, successCallback: setCustomerRecordsData});
            //refreshData();
            //setCustomer({...dummyCustomerData})
            
        },
        successNotification:{title:"Success", message:'Order saved'},
        errorNotification:{title:"Failed", message:'Failed to save Order'},})
    }

    /* const progresOrder = () => {
          
    let currentStage = orderStages[currentNavigationIndex];

        console.log("Saving order", {
            ...order,
            dataSet: {
                data:[...orderDataSet]
            },
            orderStageId: order.id,
            invoice: {...invoiceData}
        } )

        putEntities({url:BaseURL + 'api/v1/Order/progressOrder',
        data: {
            ...order,
            orderStageData: {
                dataSet : {data:[...orderDataSet]}
            },
            orderId : order.id,
            orderStageId: currentStage.orderStageId,
            invoice: {...invoiceData}
        },
        successCallback: (d)=>{
            //setShowCreateCustomerPopup(false);
            //getEntities({url:BaseURL + `api/v1/customer/getcustomers?maxPerPage=${maxPerPage}&pageNumber=${currentPageIndex}&searchString=${searchString}`, successCallback: setCustomerRecordsData});
            //refreshData();
            //setCustomer({...dummyCustomerData})
            setOrder(d.order);
            //setOrderDataSet
            setCurrentNavigationIndex(d.order.currentStageIndex)
        },
        successNotification:{title:"Success", message:'Order saved'},
        errorNotification:{title:"Failed", message:'Failed to save Order'},})
    } */

    const updateOrderInfo = ({field, value}) => {
        dispatch({type:"UPDATE_ORDER_FIELD", data:{id:order.id,  field, value}});   
    }
    

    //console.log(moveByIndexField(2,4,[{name:'A',position:1},{name:'B',position:2},{name:'C',position:3},{name:'D',position:4}],'position'))

    const openStageEditor = (stage) => {       
        setSelectedStage(stage);
        setShowStageEditor(true);
    }

    const goToNextNavigationStep = () => {

        setCurrentNavigationIndex ( c => {
            if(c < navigationSteps.length -1)
            {
                return c + 1;
            }

            return c;
        })
       
    }

    const goToPreviousNavigationStep = () => {

        setCurrentNavigationIndex ( c => {
            if(c > 0)
            {
                return c - 1;
            }

            return c;
        })
       
    }

    

    return( <>
    <GameHeader logo={accountInfo?.businessLogo} titleSubText={`Manage Order : ${order?.catalogueTitle}`} moduleActions={[
        {
            actionCaption: "Save",
            actionFunc: ()=>saveOrder()
        }
    ]} activityTitle={`#${order?.orderNumber} : ${order?.customerInfo?.email}`}/>
   
    <div style={{
        position:'relative',
        height:'calc(100vh - 50px)',
        width:'100%'
    }}>
        
        
        <div style={{
            //backgroundColor:'green',
            //background: 'linear-gradient(0deg, rgba(9,73,121,1) 19%, rgba(0,145,200,1) 47%, rgba(52,154,230,1) 69%, rgba(0,212,255,1) 100%)',
            //background: 'linear-gradient(45deg, rgb(9, 73, 121) 19%, rgb(0, 145, 200) 47%, rgb(63 230 52) 69%, rgb(0, 212, 255) 100%)',
            
            width:'100%',
            height:'100%',
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            position:'relative',
            alignItems:'center'
        }}>
             <div style={{width:'100%', textAlign:'right', padding:5, fontSize:12, color:'grey'}}>
                {`Last updated: ${getLastEdtedDuration(order?.lastModificationDate)}`}
            </div>
            <NavigationCounter onClick={(c)=>setCurrentNavigationIndex(c)} currentNavigationStep={currentNavigationIndex} navigationSteps={navigationSteps}/>
                   
            <div style={{backgroundColor:'none', height:'95%', margin:'10px', display:'flex',
            flexDirection:'column',
            overflow:'auto',
            borderRadius:10,
            //background:'linear-gradient(0deg, rgb(255 133 95 / 15%) 0%, rgb(253 253 253) 100%)',
            //justifyContent:'center',
            position:'relative',
            minWidth:300,

            //maxWidth:700,
            alignItems:'center', 
            //border:'1px solid #e9e9e9',
            width:'95%', 
            //boxShadow:'rgb(157 154 154 / 60%) 2px 3px 7px',
            }}>

                   {/* <GameSelector gameCode={gameCode}/> */}
                   <OrderStageSelector orderStages={orderStages} orderDataSet={orderDataSet} orderData={order} orderCatalogue={orderCatalogue} stageCallbackHandler={stageCallbackHandler} currentIndex={currentNavigationIndex}/>
            </div>

            <div style={{display:'flex', width:'100%', borderTop:'1px solid grey', padding:5, justifyContent:'space-between'}}>
                <button onClick={goToPreviousNavigationStep} style={{visibility: currentNavigationIndex > 0 ? 'visible' : 'hidden'}} className='app-button-primary'>Back</button>
              {/*   {currentNavigationIndex < navigationSteps.length - 1 ? <button onClick={progresOrder} className='app-button-primary'>Progress Order</button>
                : <button onClick={progresOrder} className='app-button-primary'>Close Order</button>} */}

            </div>

        </div>
       
    </div>
   
    </>)

}   



export default CustomerViewLayoutWrapper(CreateOrderView, {showNavigationBar:false,removePadding:false, showSideMenu:false});