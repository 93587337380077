import React, {useEffect, useState} from 'react';
import {
    Link,
    useNavigate
} from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';
import { useSpring, animated as a, config } from 'react-spring';
import LayoutWrapper from '../layout/LayoutWrapper';
import ViewWrapper from './ViewWrapper';
//import { deleteAsset } from '../redux/actions/assetActions';
import Prompt from '../components/Prompt/Prompt';
import searchIcon from '../assets/images/search.svg'
import PopUp from '../components/PopUp/PopUp';
import backgroundImage from '../assets/images/background_01.svg';
import {v4 as uuidv4} from 'uuid';
import axios from 'axios';
import { BaseURL } from '../constants/URLs';

import { getEntities, postEntities, putEntities, deleteEntities } from '../api/restUtilities';
import { useRef } from 'react';
import PaginationView from '../components/PaginationView/PaginationView';

const CustomersView = (props)  => {
    
    const dummyCustomerData ={ 
        "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        "accountId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        "createdBy": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        "lastModifiedBy": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        "email": "",
        "firstName": "",
        "lastName": "",
        "address": "",
        "state": "",
        "country": "",
        "phoneNumber": "",
        "creationDate": "2022-12-26T15:16:40.814Z",
        "lastModificationDate": "2022-12-26T15:16:40.814Z"
    }
    
    const [openPromptDialog, setOpenPromptDialog] = useState(false);
    const [promptOkAction, setPromptOkAction] = useState(null);
    const [searchString, setSearchString] = useState("");
    const [showCreateNewOrderPopup, setShowCreateCustomerPopup] = useState(false);   
   
    const [customers, setCustomers] = useState([]);    
    const [isEditing, setIsEditing] = useState(false);
    const [customer, setCustomer] = useState({...dummyCustomerData})
    
    const [currentPageIndex, setCurrentPageIndex] = useState(0) 
    const [totalRecords, setTotalRecords] = useState(0) 
    const [totalPageCount, setTotalPageCount] = useState(0) 

    const maxPerPage = 3

    const refreshData = (newPageIndex) =>
    {
        getEntities({url:BaseURL + `api/v1/customer/getcustomers?maxPerPage=${maxPerPage}&pageNumber=${newPageIndex != undefined ? newPageIndex :currentPageIndex}&searchString=${searchString}`, successCallback: setCustomerRecordsData});
      
    }

    /*useEffect(()=>{


       
    },[currentPageIndex])*/

    useEffect(()=>{

        clearTimeout(delayTimer?.current);
        delayTimer.current = setTimeout(function() {
            //getEntities({url:BaseURL + `api/v1/customer/getcustomers?maxPerPage=${maxPerPage}&pageNumber=${currentPageIndex}&searchString=${searchString}`, successCallback: setCustomerRecordsData});
            refreshData()
        }, 1000);
        
    },[searchString])

    const delayTimer = useRef();
 

    const setCustomerRecordsData = (data) => {
        const {listData, pageNumber,totalRecords,totalPageCount} = data;
        setCurrentPageIndex(pageNumber);
        setTotalRecords(totalRecords);
        setTotalPageCount(totalPageCount);
        setCustomers(listData);
    }
   
    const createCustomer = () => {
        let newCustomerId = uuidv4();
      
        postEntities(
            {
                url:BaseURL + 'api/v1/customer/save', 
                data:{...customer, id:newCustomerId},
                successCallback: ()=>{
                    setShowCreateCustomerPopup(false);
                    //getEntities({url:BaseURL + `api/v1/customer/getcustomers?maxPerPage=${maxPerPage}&pageNumber=${currentPageIndex}&searchString=${searchString}`, successCallback: setCustomerRecordsData});
                    refreshData();
                    setCustomer({...dummyCustomerData})
                },
                successNotification:{title:"Success", message:'Customer created'},
                errorNotification:{title:"Failed", message:'Customer creation failed'},
            }        
        )
       
    }

    const updateCustomer = () => {
      
        putEntities(
            {
                url:BaseURL + 'api/v1/customer/update', 
                data:{...customer},
                successCallback: ()=>{
                    setShowCreateCustomerPopup(false);
                    //getEntities({url:BaseURL + `api/v1/customer/getcustomers?maxPerPage=${maxPerPage}&pageNumber=${currentPageIndex}&searchString=${searchString}`, successCallback: setCustomerRecordsData});
                    refreshData();
                    setCustomer({...dummyCustomerData})
                },
                successNotification:{title:"Success", message:'Customer updated'},
                errorNotification:{title:"Failed", message:'Customer update failed'},
            }        
        )
    }

    const deleteCustomer = (id) => {
       
        deleteEntities(
            {
                url:BaseURL + 'api/v1/customer/remove?id='+id, 
                data:{...customer},
                successCallback: ()=>{
                    setShowCreateCustomerPopup(false);
                    //getEntities({url:BaseURL + `api/v1/customer/getcustomers?maxPerPage=${maxPerPage}&pageNumber=${currentPageIndex}&searchString=${searchString}`, successCallback: setCustomerRecordsData});
                    refreshData();
                    setCustomer({...dummyCustomerData})
                },
                successNotification:{title:"Success", message:'Customer deleted'},
                errorNotification:{title:"Failed", message:'Customer delete failed'},
            }        
        )

    }

    const deleteModel = (id) =>
    {
        setOpenPromptDialog(true);
        setPromptOkAction({message: "Do you want to delete the Customer?",OkAction:()=>{deleteCustomer(id);setOpenPromptDialog(false)}});
    }

    const showEditingModal = (c) => {
        setCustomer(c);
        setShowCreateCustomerPopup(true);
        setIsEditing(true);
    }

 
   

    return(<><ViewWrapper title={'Customers'}>
   
            <Prompt promptOptions={{...promptOkAction}} OkLabel={"Yes"}  isOpen={openPromptDialog} modalClosed={()=> setOpenPromptDialog(false)}/>
                 <div style={{margin:5, position:'relative'}}>
                    <div style={{display:'flex', position:'sticky', flexDirection:'row', width:'100%', margin:'0px', alignItems:'center', justifyContent:'flex-start'}}>
                   
                    <buttom value={searchString} onClick={() => setShowCreateCustomerPopup(true)} className={'app-button-primary'}>
                        + New Customer
                    </buttom>
                    </div>      
                </div>

                <div style={{margin:5, position:'relative'}}>
                    <div style={{display:'flex', position:'sticky', flexDirection:'row', width:'100%', margin:'10px 0px', alignItems:'center', justifyContent:'center'}}>
                    {searchString && <div style={{color:'#ab571e', marginRight:10}}  onClick={e=>setSearchString("")} >Clear</div>}    
                    <input className='search-input-wide'  value={searchString} onChange={e=>setSearchString(e.target.value)} placeholder="Search" style={{backgroundImage:`url('${searchIcon}')`, backgroundRepeat:'no-repeat', backgroundPositionX:'98%',  backgroundPositionY:'50%',}}/>
                    
                    </div>      
                </div>

                <div style={{margin:5, display:'flex', justifyContent:'space-between', position:'relative'}}>
                    <span style={{color:'#888888', fontSize:11}}>{`Total Records: ${totalRecords}`}</span>
                <PaginationView numberOfPages={totalPageCount} partLength={3} currentPageIndex={currentPageIndex+1} onClick={(i)=>{setCurrentPageIndex(i-1);refreshData(i-1);}}/>  
                </div>

                <div style={{margin:5}}>
                <Prompt promptOptions={{...promptOkAction}} OkLabel={"Yes"}  isOpen={openPromptDialog} modalClosed={()=> setOpenPromptDialog(false)}/>
                
                {customers.length > 0 ? 
                <div style={{ width:'100%',/* display: 'inline-grid',
                flexDirection: 'column', columnGap:10, rowGap:10, gridTemplateColumns:'repeat(auto-fill, minmax(260px, 1fr))', */ minHeight:'calc(100vh-50px)'}}>
              
                {customers && customers.map((g,i) => 
                <div className='item-card' style={{margin:0, height:'auto', boxShadow:'2px 2px 5px -2px rgb(20 20 20 / 20%)',  marginBottom:10, alignItems:'center', width:'100%'}}>
                     <div style={{padding:10, display:'flex', width:'100%', justifyContent:'left', alignItems:'center', fontSize:20}}>
                        <div style={{background:'aqua', padding:15, width:50, height:50, margin:20}} className="user-info-avartar">
                            {g.firstName?.toUpperCase()?.charAt(0)}                    
                        </div>
                        {/*  <img  src={defaultIcon} style={{height:50, background:'none', borderRadius:5,   margin:5, marginRight:20}} alt="logo" />  */}
                        <div style={{display:'flex', flexGrow:1, flexDirection:'column'}}>

                        <div style={{display:'flex'}}>{`${g.firstName} ${g.lastName}`}</div>
                        <div style={{display:'flex', color:'#888888', fontSize:12}}>{g.email}</div>
                        <div style={{display:'flex', color:'#888888', fontSize:12}}>{g.phoneNumber}</div>
                        <div style={{display:'flex', color:'#888888', fontSize:12}}>{g.address}</div>
                        <div style={{display:'flex', justifyContent:'flex-end'}}>
                            <button onClick={()=>showEditingModal(g)} className='app-button-secondary-small'>Edit</button>
                            <button onClick={()=>deleteModel(g.id)} className='app-button-secondary-small'>Remove</button>
                        </div>
                    
                    </div>
                   
                    </div>
                </div>
                )}</div>
                :
                <div style={{width:'100%', height:100, fontSize:20, color:'#CECECE', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    No Record found  
                </div>}
                
                
                <div style={{height:80}}></div>
                
                </div>
    </ViewWrapper>
    {<PopUp open={showCreateNewOrderPopup} >
    <div style={{width:'100%', height:'100%', display:'flex', flexDirection:'column',
        padding:0,
        borderRadius:10, 
        overflow:'hidden',
        boxShadow:'2px 2px 5px rgba(10,10,10,0.7)', minHeight:40, background:'white'}}>
            <div style={{padding:15, display:'flex', justifyContent:'left', alignItems:'center', fontSize:20}}>
           {/*  <img  src={defaultIcon} style={{height:50, background:'#ecf5ef', borderRadius:5,   margin:2, marginRight:20}} alt="logo" /> 
            */} <div style={{display:'flex', flexDirection:'column'}}>

                <div style={{display:'flex'}}>{isEditing ? "Edit Customer" :'New Customer'}</div>
               
            </div>
            
            </div>
            <div className='popup-view-container' style={{padding:15, display:'flex', flexDirection:'column', justifyContent:'left', alignItems:'center', fontSize:20}}>
            <div className='input-field' style={{width:'100%'}}>
                <label className='form-label'>First Name</label>
                <input value={customer.firstName} onChange={e => setCustomer(sot => sot ? {...sot, firstName:e.target.value} : null)} className='form-text-input'/>
            </div> 
            <div className='input-field' style={{width:'100%'}}>
                <label className='form-label'>Last Name</label>
                <input value={customer.lastName} onChange={e => setCustomer(sot => sot ? {...sot, lastName:e.target.value} : null)} className='form-text-input'/>
            </div> 
            <div className='input-field' style={{width:'100%'}}>
                <label className='form-label'>Email</label>
                <input value={customer.email} onChange={e => setCustomer(sot => sot ? {...sot, email:e.target.value} : null)} className='form-text-input'/>
            </div>    
            <div className='input-field' style={{width:'100%'}}>
                <label className='form-label'>Phone Number</label>
                <input value={customer.phoneNumber} onChange={e => setCustomer(sot => sot ? {...sot, phoneNumber:e.target.value} : null)} className='form-text-input'/>
            </div>    
            <div className='input-field' style={{width:'100%'}}>
                <label className='form-label'>Address</label>
                <input value={customer.address} onChange={e => setCustomer(sot => sot ? {...sot, address:e.target.value} : null)} className='form-text-input'/>
            </div>            
            </div>          
                
            <div style={{width:'100%', 
                padding:10,                
                background:'#EEEEEE', display:'flex', justifyContent:'space-between'}}>
                <button className='app-button-primary' onClick={() => isEditing? updateCustomer() : createCustomer()} >{isEditing ? "Update" : "Create"}</button>
                <button className='app-button-primary' onClick={() => {setIsEditing(false);setShowCreateCustomerPopup(false)}}>Cancel</button>
            </div>            
        </div>
        </PopUp>}
    </>)

}   

export default LayoutWrapper(CustomersView, {showNavigationBar:true, showSideMenu:true,backgroundImage});