

import { useEffect, useState } from 'react';
import axios from 'axios';
 
import searchIcon from '../../assets/images/search.svg'
import PaginationView from '../PaginationView/PaginationView';

const SearchListView = (props) =>
{

  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  //from props: const [filterFields, setSearchFilterFields] = useState([])
  const [searchString, setSearchString] = useState("") 
  const [currentPageIndex, setCurrentPageIndex] = useState(0) 
  const [totalRecords, setTotalRecords] = useState(0) 
  const [totalPageCount, setTotalPageCount] = useState(0) 

  const maxPerPage = 3

  useEffect(()=>{

    if(props.dataUrl)
    {
      setIsLoading(true);
      axios.get(`${props.dataUrl}${props.dataUrl.indexOf("?") > 0 ?'&' : '?'}maxPerPage=${maxPerPage}&pageNumber=${currentPageIndex}&searchString=${searchString}`).then(r=> {

        const {listData, pageNumber,totalRecords,totalPageCount} = r.data;
        //setCurrentPageIndex(pageNumber);
        setTotalRecords(totalRecords);
        setTotalPageCount(totalPageCount);
        //setCustomers(listData);

        setIsLoading(false);
        console.log({r, listData})
        setData(listData)
      
      }
        ).catch((e)=>{
          setIsLoading(false);
        })

    }
 
  },[props.dataUrl, currentPageIndex, searchString])

 /*  useEffect(()=>{


    getEntities({url:BaseURL + `api/v1/customer/getcustomers?maxPerPage=${maxPerPage}&pageNumber=${currentPageIndex}&searchString=${searchString}`, successCallback: setCustomerRecordsData});
  
},[currentPageIndex])

useEffect(()=>{

    clearTimeout(delayTimer?.current);
    delayTimer.current = setTimeout(function() {
        getEntities({url:BaseURL + `api/v1/customer/getcustomers?maxPerPage=${maxPerPage}&pageNumber=${currentPageIndex}&searchString=${searchString}`, successCallback: setCustomerRecordsData});
  
    }, 1000);
    
},[searchString]) */

  return <div>

    {/** pagination, if data.length > 0*/}
    {/* <div>
      <input value={searchString} onChange={(e)=>setSearchString(e.target.value)}></input>
      {searchString && <button clear onClick={()=>setSearchString('')}>Clear</button>}
    </div> */}

    <div style={{margin:5, paddingLeft:props.style?.padding, paddingRight:props.style?.padding, position:'relative'}}>
        <div style={{display:'flex', position:'sticky', flexDirection:'row', width:'100%', margin:'10px 0px', alignItems:'center', justifyContent:'center'}}>
        {searchString && <div style={{color:'#ab571e', marginRight:10}}  onClick={e=>setSearchString("")} >Clear</div>}    
        <input className='search-input-wide'  value={searchString} onChange={e=>setSearchString(e.target.value)} placeholder="Search" style={{backgroundImage:`url('${searchIcon}')`, backgroundRepeat:'no-repeat', backgroundPositionX:'98%',  backgroundPositionY:'50%',}}/>
        
        </div>      
    </div>
    <div style={{margin:0, padding: 10, display:'flex', justifyContent:'space-around', position:'relative'}}>
    <PaginationView numberOfPages={totalPageCount} partLength={3} currentPageIndex={currentPageIndex+1} onClick={(i)=>setCurrentPageIndex(i-1)}/>  
  
    </div>
    <div style={{margin:5, padding:5, minHeight:'40vh', maxHeight:'50vh', overflowY:'auto'}}>
    {isLoading ? <div>Loading...</div> :  (
    data? data.map(d => props.renderComponent && props.renderComponent({data:d})) : <div>Empty</div>
  ) }
    </div>
    


  </div>

}

 
export default SearchListView;