import React, {useEffect, useState} from 'react';
import {
    Link,
    useNavigate
} from 'react-router-dom';





const StageCardInfoField = (props) => {

    return (
        <div style={{display:'flex', paddingBottom:3, gridColumn:props.gridColumn, flexDirection:'column', fontSize:11}}>
            <label className='form-label'>{props.label}</label>
            <span style={{color:'grey', fontSize:12}}>{props.children}</span>
        </div>
    )

}

export default StageCardInfoField;