let user = JSON.parse(localStorage.getItem('currentUser'));
let accountConfiguration = JSON.parse(localStorage.getItem('accountConfiguration'));
let customerAccount = JSON.parse(localStorage.getItem('customerAccount'));
let gamePlayData = JSON.parse(localStorage.getItem('gamePlayData'));
let trainingData = JSON.parse(localStorage.getItem('trainingData'));
let gamePlayOptions = JSON.parse(localStorage.getItem('gamePlayOptions'));
/* 
let customerAccount = {
    "id": "87142c97-f32d-45b8-9967-af9300ce7216",
    "accountId": "7a4249e6-c8ab-46e0-a5a7-af780099b2ce",
    "firstName": "Adam",
    "lastName": "Smith",
    "phoneNumber": "+08022222222",
    "userName": "adam@gmail.com",
    "email": "adam@gmail.com",
    "passwordHash": "$MYHASH$V1$10000$nEoheCn4Y9cmduFy97PLhd5PUenU3OZWOOEuUnaXjXiezWc2",
    "creationDate": "2023-01-23T12:31:38.691Z",
    "lastModificationDate": "2023-01-23T12:31:38.691Z"
} */

/*
linear-gradient(135deg, rgb(255 153 0) 0%, rgb(255 249 88) 100%)

linear-gradient(135deg, rgb(251 150 150) 0%, rgb(162 134 249) 100%)

linear-gradient(135deg, rgb(10 151 251) 0%, rgb(134 249 222) 100%)

linear-gradient(135deg, rgb(150 251 153) 0%, rgb(134 164 249) 100%)

linear-gradient(135deg, rgb(255 104 104) 0%, rgb(255 248 56) 100%)

linear-gradient(135deg, rgb(145 255 99) 0%, rgb(249, 245, 134) 100%)

linear-gradient(135deg, rgb(75 65 239) 0%, rgb(134 249 238) 100%)
*/
const cognitiveCategories = [
    {
        code:'memory',
        name:'Memory',
        color:'linear-gradient(135deg, rgb(249 216 205) 0%, rgb(230, 238, 255) 100%)',
        description:''
    },
    {
        code:'speed',
        name:'Speed',
        color:'linear-gradient(135deg, rgb(221 205 249) 0%, rgb(230 238 255) 100%)',
        description:''
    },
    {
        code:'reasoning',
        name:'Reasoning',
        color:'linear-gradient(135deg, rgb(221 205 249) 0%, rgb(230 238 255) 100%)',
        description:''
    },
    {
        code:'attention',
        name:'Attention',
        color:'linear-gradient(135deg, rgb(221 205 249) 0%, rgb(230 238 255) 100%)',
        description:''
    }
]
const games = [
    {
        code:'recall-the-digits',
        name:'IG Order',
        color:'linear-gradient(135deg, rgb(249 216 205) 0%, rgb(230, 238, 255) 100%)',
        description:''
    },
    {
        code:'spot-the-circles',
        name:'Inquiry',
        color:'linear-gradient(135deg, rgb(221 205 249) 0%, rgb(230 238 255) 100%)',
        description:''
    },
    /*  {
        code:'tic-tac-toe',
        name:'Tic-Tac-Toe!',
        color:'linear-gradient(135deg, rgb(150 251 153) 0%, rgb(134 164 249) 100%)',
        description:''
    }, */
    {
        code:'matrix-pattern',
        name:'Consultancy Request',
        color:'linear-gradient(135deg, rgb(255 227 227) 0%, rgb(224 241 235) 100%)',
        description:''
    },
    
    {
        code:'matching-matrix',
        name:'Tailor Request',
        color:'linear-gradient(135deg, rgb(199 243 200) 0%, rgb(233 239 255) 100%)',
        description:''
    },
    {
        code:'arithmetic-speed',
        name:'Document Review',
        color:'linear-gradient(135deg, rgb(211 230 255) 0%, rgb(238 240 245) 100%)',
        description:''
    },
    /* {
        code:'find-the-characters',
        name:'Find the Characters!',
        color:'linear-gradient(135deg, rgb(150 251 153) 0%, rgb(134 164 249) 100%)',
        description:''
    }, */
    {
        code:'fraction-comparison',
        name:'Application Tracking',
        color:'linear-gradient(135deg, rgb(243 251 150) 0%, rgb(235 223 247) 100%)',
        description:''
    },
    {
        code:'word-joggler',
        name:'Transcript Request',
        color:'linear-gradient(135deg, rgb(198 227 249) 0%, rgb(239 216 216) 100%)',
        description:''
    },
    {
        code:'word-joggler-2',
        name:'Machine Parts Sourcing',
        color:'linear-gradient(135deg, rgb(150 251 153) 0%, rgb(134 164 249) 100%)',
        description:''
    },
    /* {
        code:'shape-process',
        name:'Process Building!',
        color:'linear-gradient(135deg, rgb(150 251 153) 0%, rgb(134 164 249) 100%)',
        description:''
    }*/
] 


const gameInfo = [
    {
        code:"recall-the-digits",
        title:"What is an Order Catalogue",
        description:"This game tests and trains your ability to quickly memorise visual information. Random characters (letters and numbers) are initially presented to you for 3 seconds and then quickly flipped, showing you a text box to enter the same characters in 10 seconds. Each correct entry amounts to 1 point.",
        howToPlay:["Take note of the initial set of characters.","After the board flips, enter the characters in the text field."],
        benefits:["Test your ability to retain information.", "Trains your short term memory."]
    },
    {
        code:"spot-the-circles",
        title:"Creating an Order",
        description:"This game tests and trains your ability to quickly memorise visual information. Circles of different sizes are initially presented to you for 3 seconds and then quickly flipped, showing you a mix of other circles. You are required to spot the initial set of circles in 10 seconds. Each correct pick amounts to 1 point.",
        howToPlay:["Take note of the initial set of circles and their positions.","Click or tap on the same circles after the board flips to score points."],
        benefits:["Trains your visual and spatial memory."]
    },
    {
        code:"matrix-pattern",
        title:"Managing Sub-Accounts",
        description:"This game tests and trains your ability to quickly memorise visual information. The Stars or Circles in a grid are initially presented to you for 3 seconds and then quickly flipped, showing you a matrix of squares. You are required to spot the initial set of circles or stars presented to you in 10 seconds by tapping on the square shapes covering it. Each correct pick amounts to 1 point.",
        howToPlay:["Take note of the initial set of circles or stars and their positions","Click or tap on the squares covering the circles or shapes after the board flips."],
        benefits:["Trains your visual and spatial memory."]
    },
    {
        code:"matching-matrix",
        title:"Canceling an Order",
        description:"This is game measures and trains your ability to transform visual information. The matrix shown on the left with a particular pattern, is tranformed to get the matrix on the right. You have 10 seconds to identify the transformation (rotation to the right, rotation to the left, flipping along the x-axis, flipping along the y-axis, shifting the shape up or shifting the shape to the right) applied to the matrix on the left to get the matrix on the right. Each correct pick amounts to 1 point.",
        howToPlay:["Compare the left and right matrices.","Click or tap on the right transform in the options below the matrices."],
        benefits:["Test your visual abilities to manipulate/transform shapes."]
    },
    {
        code:"arithmetic-speed",
        title:"Managing Inventory",
        description:"This game measures and trains your math skills; your ability to quickly perform daily computations if you are someone who has to play with numbers. Quickly figure the answer to the arithmetic and type it in using the keyboard presented to you.",
        howToPlay:["Compute the arithmetic and enter the answer in the box to the right."],
        benefits:["Improves your math skills; accuracy and speed."]
    },    
    {
        code:"fraction-comparison",
        title:"Customer Records",
        description:"This game measures and trains your math skills; your ability to quickly compute and compare fractions. Quickly compare the fraction on the left to the one on the right of the question mark, then select an option indicating if the left fraction is less than, greater than or equal to the one on the right.",
        howToPlay:["Compute the arithmetic and enter the answer in the box to the right."],
        benefits:["Improves your accuracy and speed of your computation of fractions."]
    },        
    {
        code:"word-joggler",
        title:"Viewing Statistics",
        description:"This game tests and trains your vocabulary. The meaning of the word is displayed. You are to fill in the missing letters from the options below.",
        howToPlay:["Drag the missing letters from the list below into the empty boxes. You can click on the delete button below the character to remove it and return to the list below or drag."],
        benefits:["Improves your vocabulary."]
    },
    {
        code:"word-joggler-2",
        title:"Enabling Accounts",
        description:"This game tests and trains your vocabulary. The meaning of the word is displayed. You are to fill in the missing letters from the options below.",
        howToPlay:["Drag the missing letters from the list below into the empty boxes. You can click on the delete button below the character to remove it and return to the list below or drag."],
        benefits:["Improves your vocabulary."]
    }
    
]

/* const gamePlayOptions = [
    {
        gameCode:'recall-the-digits',
        isAudioOn:false,
        difficulty: 1
    }
] */

// Should only be set when users confirm their email for this domain
const reviewers = [
    {
        id:'111-111-222',
        name:"Karl Nwala",
        email:"karlnwala@yahoo.com",
        isEmailConfirmed:true
    },
    {
        id:'111-111-333',
        name:"Chinenye Okoli",
        email:"chinenyeokoli@yahoo.com",
        isEmailConfirmed:true
    },
    {
        id:'111-111-111',
        name:"Ijeoma Nwala",
        email:"ijeomanwala@yahoo.com",
        isEmailConfirmed:true
    }
]

const dummyStages =  [
    {
        id:'12',
        position:1,
        title: "Inquiry",
        stageDuration: 8,
        description: "This stage hanldes Customer inquiries and can be entered by the Customer or the Merchant.",
        type: "Inquiry",
        formFields: [ // Custom/optional fields
            {
                caption:'Customer Name',
                type:'short-text',
                position:1,
                id:'12233-112233'
            },
            {
                caption:'Customer Email',
                type:'short-text',
                position:1,
                id:'12233-112233'
            },
            {
                caption:'Customer Phone Number',
                type:'dropdown',
                position:2,
                id:'12233-11q2233',

            },
            {
                caption:'Quantity',
                type:'number',
                position:3,
                id:'12233-11b2233',
                minValue:0,
                maxValue:20
            },

        ],
        reviewers:[
            {
                name:"Karl",
                email:"karl@yahoo.com"
            }
        ]
    },
    {
        id:'172',
        position:2,
        title: "Invoice",
        stageDuration: 18,
        description: "This stage hanldes Customer inquiries and can be entered by the Customer or the Merchant.",
        type: "Invoice", 
        formFields:[],
        items:[
            {
                item:"1 kg Strawberries",
                quantity:20,
                unitCost:3000,
            },
            {
                item:"0.75 kg Strawberries",
                quantity:20,
                unitCost:2500,
            }
        ],
        reviewers:[               
            {
                name:"Chinenye",
                email:"Chinenye@yahoo.com"
            },
            {
                name:"Sarah",
                email:"sarah@yahoo.com"
            }
        ]
    },
    {
        id:'152',
        position:3,
        title: "Payment",
        stageDuration: 24,
        description: "This stage hanldes Customer inquiries and can be entered by the Customer or the Merchant.",
        type: "Payment", 
        formFields:[],
        payment:{
            totalAmount:50000,
            paymentDueDate:'',
            paymentStatus:'Pending',
            proofOfPayment:'none'
        },
        reviewers:[              
            {
                name:"Sarah",
                email:"sarah@yahoo.com"
            }
        ]
    }
    ,
    {
        id:'112',
        position:4,
        title: "Fulfilment",
        stageDuration: 48,
        description: "This stage hanldes Customer inquiries and can be entered by the Customer or the Merchant.",
        type: "Action", 
        reviewers:[              
            {
                name:"Sarah",
                email:"sarah@yahoo.com"
            }
        ]
    },
    {
        id:'132',
        position:5,
        title: "Feedback",
        stageDuration: 24,
        description: "This stage hanldes Customer inquiries and can be entered by the Customer or the Merchant.",
        type: "Feedback", 
        formFields: [ // Custom/optional fields
            {
                caption:'Customer Name',
                type:'short-text',
                position:1,
                id:'12233-112233'
            },
            {
                caption:'Customer Email',
                type:'short-text',
                position:1,
                id:'12233-112233'
            },
            {
                caption:'Recommendation',
                type:'dropdown',
                position:2,
                id:'12233-11q2233',

            },
            {
                caption:'Rate your experience',
                type:'number',
                position:3,
                id:'12233-11b2233',
                minValue:0,
                maxValue:20
            },

        ],
        reviewers:[              
            {
                name:"Sarah",
                email:"sarah@yahoo.com"
            }
        ]
    }
]
const orderCatalogues = [
   
    {
        id:'1224-2233-4424',
        title:'CV Reviews',
        description: 'This is a long text description',
        lastEdited: "2022-08-30T19:52:06.056Z",
        stages:[...dummyStages]
    },
    {
        id:'2222-2233-4424',
        title:'Kora Online Orders',
        description: 'This is a long text description',
        lastEdited: "2022-09-30T19:52:06.056Z",
        stages:[...dummyStages]
    },
    {
        id:'3322-2233-4424',
        title:'Event Consultancy',
        description: 'This is a long text description',
        lastEdited: "2022-04-30T19:52:06.056Z",
        stages:[...dummyStages]
    },

]

const orderCatalogueTemplates = [
    {
        id:'122-2233-4424',
        title:'IG Request',
        description: 'This is a long text description',
        lastEdited: "2022-08-30T19:52:06.056Z"
    },
    {
        id:'111-2233-4424',
        title:'Document Review/Processing',
        description: 'This is a long text description',
        lastEdited: "2022-08-30T19:52:06.056Z"
    },
    {
        id:'122-5555-4424',
        title:'Online Orders',
        description: 'This is a long text description',
        lastEdited: "2022-08-30T19:52:06.056Z"
    },
    {
        id:'122-2233-7878',
        title:'Consultancy Request',
        description: 'This is a long text description',
        lastEdited: "2022-08-30T19:52:06.056Z"
    },
    {
        id:'1234-44433-4424',
        title:'Spare Parts',
        description: 'This is a long text description',
        lastEdited: "2022-08-30T19:52:06.056Z"
    }

]

const orders = [
    {
        title:'New order from Kunle',
        orderNumber:'001',
        tags:'001,Karl,',
        description:'This is an order for fresh strawberries.',
        createdBy:{
            name:'Karl',
            email:'karlnwala@yahoo.com'
        },
        onBehalfOf:null,
        created: "2022-08-30T19:52:06.056Z",
        workflow:{

        },
        currentStage: 1,
        status:'in-progress',
    }
]

const customers = [
    {
        id:'12211-121212-212',
        email:'mark@yahoo.com',
        name:'Mark Spein',
        phoneNumber:'+234803333333',
        address:'Abuja'
    },
    {
        id:'1bb11-121212-212',
        email:'Linda@yahoo.com',
        name:'Linda Park',
        phoneNumber:'+234806633333',
        address:'UK'
    },
    {
        id:'12211-sss-212',
        email:'Sandy@yahoo.com',
        name:'Sandy Lu',
        phoneNumber:'+234805333333',
        address:'Enugu'
    },
    {
        id:'12211-1aaa12-212',
        email:'mark@yahoo.com',
        name:'Mark Spein',
        phoneNumber:'+234803333333',
        address:'Abuja'
    },
    {
        id:'1eeeff1-121212-212',
        email:'Linda@yahoo.com',
        name:'Linda Park',
        phoneNumber:'+234806633333',
        address:'UK'
    },
    {
        id:'cecece1-121212-212',
        email:'Sandy@yahoo.com',
        name:'Sandy Lu',
        phoneNumber:'+234805333333',
        address:'Enugu'
    }

]

const subAccounts = [
    {
        email:'Sheyi@yahoo.com',
        name:'Sheyi Oluwaniyi',
        phoneNumber:'+234803333333',
        address:'Abuja'
    },
    {
        email:'Ada@yahoo.com',
        name:'Ada Okeke',
        phoneNumber:'+234806633333',
        address:'UK'
    },
    {
        email:'Ahmed@yahoo.com',
        name:'Ahmed Suraj',
        phoneNumber:'+234805333333',
        address:'Enugu'
    }
]

const inventory = [
    {
        name:"1kg of Fresh Manoges",
        sellingPrice:2500,
        quantity:20,
        image:null
    },
    {
        name:"1kg of Fresh Strawberries",
        sellingPrice:2500,
        quantity:20,
        image:null
    },
    {
        name:"0.7kg of Fresh Manoges",
        sellingPrice:2500,
        quantity:20,
        image:null
    },
    {
        name:"0.5kg of Fresh Manoges",
        sellingPrice:2500,
        quantity:20,
        image:null
    },
    {
        name:"0.5kg of Fresh Passion fruit",
        sellingPrice:2500,
        quantity:20,
        image:null
    },
    {
        name:"0.5kg of Fresh Strawberries",
        sellingPrice:2500,
        quantity:20,
        image:null
    }
]

const notifications = [
    {
        title:"You have a new Payment!",        
        description: 'This is a long text description',
        dateTime: "2022-08-30T19:52:06.056Z"
    },
    {
        title:"You have a new Payment!",        
        description: 'This is a long text description',
        dateTime: "2022-09-30T19:52:06.056Z"
    },
    {
        title:"You have a new Order!",        
        description: 'This is a long text description',
        dateTime: "2022-10-04T19:52:06.056Z"
    },
    {
        title:"You have a pending Payment Confirmation!",        
        description: 'This is a long text description',
        dateTime: "2022-08-30T19:52:06.056Z"
    },
    {
        title:"You have an overdue Delivery!",        
        description: 'This is a long text description',
        dateTime: "2022-08-30T19:52:06.056Z"
    },
    {
        title:"You have a new Payment!",        
        description: 'This is a long text description',
        dateTime: "2022-08-30T19:52:06.056Z"
    }
]

const initState = {
    user:user ? {...user, loggedIn: false, loggingIn:false}:null,
    rooms:[],
    accountConfiguration:accountConfiguration ? {...accountConfiguration} : accountConfiguration,
    customers:[...customers],
    subAccounts:[...subAccounts],
    notifications:[...notifications],
    inventory:[...inventory],
    reviewers:[...reviewers],
    orderCatalogues:[...orderCatalogues],
    orderCatalogueTemplates:[...orderCatalogueTemplates],
    orders:[...orders],
    games:[...games],
    gameInfo:[...gameInfo],
    gamePlayData: gamePlayData ? [...gamePlayData] : [],
    gamePlayOptions: gamePlayOptions ? [...gamePlayOptions]:[],
    trainingData: trainingData ? [...trainingData] : [],//? trainingData
    cognitiveCategories: [...cognitiveCategories],
    assets:[],
    users:[],
    orderItems:[],
    invoiceStyles:[],
    receiptStyles:[],
    stageForms:[],
    stageFormFields:[],
    stageFormData:[],
    orderInfo:[],
    unreadNotificationsCount:0,
    customerAccount: customerAccount ? {...customerAccount} : null
}

const rootReducer = (state = initState, action) => {
    console.log("reducer", action)
    //console.log("isFound", state.stageFormData.some(oi => oi.id == action.data.id) )
    switch (action.type) {
        /* User Reducer Operations */
        case "SET_UNREAD_NOTIFICATIONS_COUNT":
            return {
                ...state,
                unreadNotificationsCount:action.data || 0    
            }; 
        case "LOGIN_USER":
            return {
                ...state,
                user:{
                    loggedIn: true,
                    ...action.data
                }
            };
        case "LOGIN_CUSTOMER":
            return {
                ...state,
                customerAccount:{
                    loggedIn: true,
                    ...action.data
                }
            };    
        case "LOGOUT_CUSTOMER":
            localStorage.setItem('customerAccount',null);            
            return {
                ...state,
                customerAccount:null
            };    
        /* case "SAVE_GAME_PROGRESS":
            return {
                ...state,
                gameData:[...gameData, action.data]
            }; */

        // Order Catalogues
        case "CREATE_NEW_CATALOGUE":
            return {
                ...state,
                orderCatalogues:[...state.orderCatalogues, {...action.data}]    
            }; 
        case "UPDATE_CATALOGUE":
            return {
                ...state,
                orderCatalogues: state.orderCatalogues.map(
                    (m, i) => m.id === action.data.id ? action.data
                                            : m
                )                  
            }; 
            
        case "CREATE_ORDER":
            return {
                ...state,
                orders:[...state.orders, {...action.data}]    
            }; 
        case "UPDATE_ORDER":
            return {
                ...state,
                orders: state.orders.map(
                    (m, i) => m.id === action.data.id ? action.data
                                            : m
                )                  
            }; 
        case "UPDATE_ORDER_FIELD":
            return {
                ...state,
                orders: state.orders.map(
                    (m, i) => m.id === action.data.id ? {...m, [action.data.field]:action.data.value}
                                            : m
                )                  
            }; 
        case "CREATE_ORDER_ITEM":
            return {
                ...state,
                orderItems:[...state.orderItems, {...action.data}]    
            }; 
        case "UPDATE_ORDER_ITEM":
            return {
                ...state,
                orderItems: state.orderItems.map(
                    (m, i) => m.id === action.data.id ? action.data
                                            : m
                )                  
            }; 
        case "SET_ORDER_ITEM":
            return {
                ...state,
                orderItems: state.orderItems.some(oi => oi.id ===action.data.id) ?  state.orderItems.map(
                    (m, i) => m.id === action.data.id ? action.data
                                            : m 
                ) : [...state.orderItems, {...action.data}] 
            }; 

        case "SET_INVOICE_STYLE":
            return {
                ...state,
                invoiceStyles:[...state.invoiceStyles, {...action.data}]    
            }; 
        case "UPDATE_INVOICE_STYLE":
            return {
                ...state,
                invoiceStyles: state.invoiceStyles.map(
                    (m, i) => m.id === action.data.id ? action.data
                                            : m
                )                  
            };
        case "SET_RECEIPT_STYLE":
            return {
                ...state,
                receiptStyles:[...state.receiptStyles, {...action.data}]    
            }; 
        case "UPDATE_RECEIPT_STYLE":
            return {
                ...state,
                receiptStyles: state.receiptStyles.map(
                    (m, i) => m.id === action.data.id ? action.data
                                            : m
                )                  
            }; 
        case "SET_STAGE_FORM":
            return {
                ...state,
                stageForms:[...state.stageForms, {...action.data}]    
            }; 
        case "UPDATE_STAGE_FORM":
            return {
                ...state,
                stageForms: state.stageForms.map(
                    (m, i) => m.id === action.data.id ? action.data
                                            : m
                )                  
            }; 
        case "SET_STAGE_FORM_FIELDS":
            return {
                ...state,
                stageFormFields:[...state.stageFormFields, {...action.data}]    
            }; 
        case "UPDATE_STAGE_FORM_FIELDS":
            return {
                ...state,
                stageFormFields: state.stageFormFields.map(
                    (m, i) => m.id === action.data.id ? action.data
                                            : m
                )                  
            }; 
      /*   case "SET_STAGE_FORM_DATA":
            return {
                ...state,
                stageFormData:[...state.stageFormData, {...action.data}]    
            };  */
        case "UPDATE_STAGE_FORM_DATA":
            return {
                ...state,
                stageFormData: state.stageFormData.map(
                    (m, i) => m.id === action.data.id ? action.data
                                            : m
                )                  
            }; 
        case "SET_STAGE_FORM_DATA":
            
            return {
                ...state,
                stageFormData: state.stageFormData.some(oi => oi.id == action.data.id) ?  state.stageFormData.map(
                    (m, i) => m.id === action.data.id ? action.data
                                            : m 
                ) : [...state.stageFormData, {...action.data}] 
            }; 
        case "SET_ORDER_INFO":
            return {
                ...state,
                stageFormData:[...state.stageFormData, {...action.data}]    
            }; 
        case "SET_ACCOUNT_CONFIGURATION":
            return {
                ...state,
                accountConfiguration:{...state.accountConfiguration,...action.data}   
            }; 
        case "UPDATE_ORDER_INFO":
            return {
                ...state,
                orderInfo: state.orderInfo.map(
                    (m, i) => m.id === action.data.id ? action.data
                                            : m
                )                  
            }; 
        
        
            /*orderItems:[],
            invoiceStyle:[],
            receiptStyle:[],
            stageForms:[],
            stageFormFields:[],
            stageFormData:[],
            orderInfo:[]*/
        
        case "UPDATE_GAME_SCORE":
            if(action.data.points > 0)
            {
                let updatedGamePlayData = state.gamePlayData.find(g => g.sessionId === action.data.sessionId) ?  state.gamePlayData.map(
                    (m, i) => m.sessionId === action.data.sessionId ? { ...m, points: action.data.points}
                                            : m
                ) : [...state.gamePlayData, {...action.data, startTime:action.data.sessionTime}]

                localStorage.setItem('gamePlayData',JSON.stringify(updatedGamePlayData));

                return {
                    ...state,
                    gamePlayData:[...updatedGamePlayData]    
                };    
            }
            return {
                ...state,
            };    
        
        case "UPDATE_GAME_PLAY_OPTIONS":
            if(action?.data)
            {
                let updatedGamePlayOptions =  state.gamePlayOptions.find(g => g.gameCode === action?.data?.gameCode) ? state.gamePlayOptions.map(
                    (m, i) => m.gameCode === action.data.gameCode ? { ...m, ...action.data}
                                            : m)
                                            : [...state.gamePlayOptions, {...action.data}]
                

                console.log("play options", [...updatedGamePlayOptions])
                localStorage.setItem('gamePlayOptions',JSON.stringify(updatedGamePlayOptions));

                return {
                    ...state,
                    gamePlayOptions:[...updatedGamePlayOptions]    
                };    
            }
            return {
                ...state,
            };    

        case "CLEAR_GAME_SCORES":           
    
                localStorage.setItem('gamePlayData',JSON.stringify([]));
                return {
                    ...state,
                    gamePlayData:[]    
                };    
        case "LOGOUT_USER":
            localStorage.setItem('currentUser',null);
            return {
                ...state,
                user:null
            };  
        
        case 'SAVE_TRAINING_DATA':
           
            if(action.data)
            {
               // if(state.trainingData)
                {
                    let updatedTrainingData = state.trainingData?.find(g => g.sessionId === action.data.sessionId) ?  state.trainingData?.map(
                        (m, i) => m.sessionId === action.data.sessionId ? { ...m, ...action.data}
                                                : m
                    ) : [...state.trainingData, {...action.data, startTime:action.data.sessionTime}]

                    if(updatedTrainingData)
                    {
                        localStorage.setItem('trainingData',JSON.stringify(updatedTrainingData));
                    }

                    return {
                        ...state,
                        trainingData:[...updatedTrainingData]    
                    };   
                }
                /*else
                 {
                    let updatedTrainingData =  [{...action.data, startTime:action.data.sessionTime}]

                    if(updatedTrainingData)
                    {
                        localStorage.setItem('trainingData',JSON.stringify(updatedTrainingData));
                    }

                    return {
                        ...state,
                        gamePlayData:[...updatedTrainingData]    
                    };   
                } */
                

                 
            }
            return {
                ...state,
            };    
       
         /* Consignment Reducer Operations */
         case 'CREATE_ROOM':
            return {
                ...state,
                rooms:[...state.rooms, action.data]
            }
        case 'UPDATE_ROOM':
            {
                return{
                    ...state,                    
                    rooms: state.rooms.map(
                        (m, i) => m.id === action.data.id ? action.data
                                                : m
                    )                
                }
            }
        case 'FETCH_ROOMS':
            {
                return{
                    ...state,                    
                    rooms: [...action.data]
                }
            }
        case 'DELETE_ROOM':
            {                         
                return{
                    ...state,
                    rooms:[...state.rooms.filter(m => {return m.id !== action.data})]
                }
            }
      
        /* Users Reducer Operations */
        case 'CREATE_USER':
            return {
                ...state,
                users:[...state.users, action.data]
            }
        case 'UPDATE_USER':
            {
                return{
                    ...state,                    
                    users: state.users.map(
                        (m, i) => m.id === action.data.id ? action.data
                                                : m
                    )                
                }
            }
        case 'UPDATE_USER_INFO':
            {
                localStorage.setItem('user',JSON.stringify({...state.user, ...action.data}));

                return{
                    ...state,
                    user:{...state.user, ...action.data}
                }

                
            }
      
        default:
            return state;
    }
};

export default rootReducer;