import React, { useEffect } from 'react';
import {useNavigate} from 'react-router-dom';
import Logo1  from '../assets/images/brain-trainer-logo-large.svg';
import backgroundImage from '../assets/images/background_01.svg';
import backgroundImage02 from '../assets/images/background_02.svg';
//import DropIns from '../games/components/DropIns/DropIns';
import {animated, config, useSpring} from 'react-spring';
import { useSelector, useDispatch} from 'react-redux';
import introImage from '../assets/images/intro-image.JPG'

const SplashScreen = (props) => {

    const navigate = useNavigate();

    const user = useSelector( state => state.user);

    console.log(user)

    const [{x, opacity}, api] = useSpring(()=>({from:{x:-60, opacity:0}, config:config.wobbly}))

    const navigateToNext = () => {

        if(user && user.name && user.gender)
            navigate('order-catalogues')
        else
            navigate('setup')
    }

    useEffect(()=>
    {
        api.start(()=>({to:{x:0, opacity:1}}))
    },[])

    return(
        <div style={{display:'flex',
         justifyContent:'space-around',
         padding:20,
         textAlign:'center',
         alignItems:'center',
         position:'absolute', 
         flexDirection:'column',
         //background:'linear-gradient(135deg, rgb(150, 251, 196) 0%, rgb(249, 245, 134) 100%)',
         //background:`url('${backgroundImage}')`,
         //backgroundImage:'url(images/background-photo-01.jpg)',
         background:`white`,
         backgroundPosition:'center',
         backdropFilter:'greyscale(80%)',
         backgroundRepeat:'no-repeat',
         backgroundSize:'cover',
         height:'100vh', width:'100vw'}}>
             
                          
             <div style={{display:'flex',}}>
                <div style={{position:'relative', width:50, height:50}}>                
                    <animated.img width={50} style={{position:'absolute',left:x, opacity}} src={Logo1}/>                
                </div>            
                <div className={"app-title"} style={{fontWeight:'normal'}}>Oda<span style={{opacity:1.0, color:"#f7941e"}}>Now</span></div>
            </div>  
            <span style={{fontSize:16, padding:'10px 25px', background:'black', color:'white'}}>Login</span>
            <div style={{display:'flex', justifyContent:'center', width:'100%'}}>
                <animated.div onClick={()=>navigate('merchant-login')} className={'item-card'} style={{boxShadow:'none',  color:'#ff9900', cursor:'pointer',  background:'rgb(255, 244, 227)',  position:'relative', top:x, 
                opacity, margin:10, marginLeft:5, display:'flex', justifyContent:'center', alignItems:'center' }} >              
                    Merchant 
                    <div style={{fontSize:10, background:'#222222', borderRadius:10, color:'white', padding:'5px', width:'80%', position:'absolute', bottom:10}}>For primary accounts</div>
                </animated.div> 
                <animated.div onClick={()=>navigate('sub-account-login')} className={'item-card'} style={{boxShadow:'none',  color:'#ff9900',cursor:'pointer', background:'rgb(255, 244, 227)',  top:x, 
                opacity, margin:10,  display:'flex', justifyContent:'center', alignItems:'center'}} >              
                    Sub-Account 
                    <div style={{fontSize:10, background:'#222222', borderRadius:10, color:'white', padding:'5px', width:'80%', position:'absolute', bottom:10}}>For team members</div>
                </animated.div> 
            </div>
            <div style={{color:'#0f5a4d', fontSize:28, maxWidth:250}}  >
                {/* <div>Welcome</div>
                <div>to</div>
                <div className={"app-title"} style={{fontFamily:'Arial', fontWeight:'bold', color:'#56BE8F', 
                display:'flex', whiteSpace:'pre-wrap', maxWidth:340, fontSize:46,
                background:'linear-gradient(45deg, #56be8f, #fdc90d)', backgroundClip:'text', WebkitBackgroundClip:'text', WebkitTextFillColor:'transparent'}}>
                   <DropIns>{'Brain'}</DropIns><span>&nbsp;</span><DropIns>{'Trainer'}</DropIns> 
                </div> */}
            </div>
           
            <div>
               {/*  <button onClick={()=>navigateToNext()} className='app-button-primary'>START</button> */}
                <div style={{color:'#0f5a4d',bottom:-15, position:'absolute', left:0, bottom:0, fontSize:10, width:'100%', background:'none', color:'white'}} >
                    <p>
                    OdaNow 2023
                    </p>
                </div>
            </div>
           
        {/* <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</div>
         */}</div>
        )
}

export default SplashScreen;//LayoutWrapper(SplashScreen, {showNavigationBar:false, showSideMenu:false});