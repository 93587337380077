
import axios from 'axios';
import NotificationManager from '../notification/NotificationManager';
import { trackPromise } from 'react-promise-tracker';

export const getEntities = ({url, successCallback, failedCallback}) => 
{    
    trackPromise(axios.get(url).then((r)=>
    {   
        successCallback && successCallback(r.data)
    }).catch((e)=>{
        console.log(e)
        failedCallback && failedCallback()
    }))    
}

export const postEntities = ({url,data, successCallback, failedCallback,successNotification, errorNotification}) => 
{
    
    trackPromise(axios.post(url,data).then((r)=>
    {      
        successNotification && NotificationManager.success({
            ...successNotification
        })

        successCallback && successCallback(r.data)
    }).catch((e)=>{
       
        errorNotification && NotificationManager.error({
            ...errorNotification
        })

        failedCallback && failedCallback();
    }))

    return data;
}

export const putEntities = ({url,data, successCallback, failedCallback,successNotification, errorNotification}) => 
{
    
    trackPromise(axios.put(url,data).then((r)=>
    {     
        successNotification && NotificationManager.success({
            ...successNotification
        })

        successCallback && successCallback(r.data)
    }).catch((e)=>{
        
        errorNotification && NotificationManager.error({
            ...errorNotification
        })

        failedCallback && failedCallback();
    }))

    return data;
}

export const deleteEntities = ({url,data, successCallback, failedCallback,successNotification, errorNotification}) => 
{
    
    trackPromise(axios.delete(url,data).then((r)=>
    {
        successNotification && NotificationManager.success({
            title:successNotification.title,
            message:successNotification.message
        })

        successCallback && successCallback()
    }).catch((e)=>{

        errorNotification && NotificationManager.error({
            ...errorNotification
        })

        failedCallback && failedCallback();
    }))

    return data;
}