import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch} from 'react-redux';
//import OrderItemsListView from '../Invoice/OrderItemsListView';
import PopUp from '../PopUp/PopUp';
import SearchListView from '../SearchListView/SearchListView';
import ToggleSelect from '../ToggleSelect/ToggleSelect';
import { RenderControl, ShortText } from './FormControls';
import { BaseURL } from '../../constants/URLs';
import OrderItemsListView from '../Invoice/OrderItemsListView';
import { getEntities } from '../../api/restUtilities';



export const FeedbackForm = ({formFields, stageInfo, orderData, orderDataSet, formData, orderId, isOrderPlacementForm, items, stageCallbackHandler}) => {

    const defaultOrderFields = isOrderPlacementForm ? [
        {
            name:'customerName',
            type:'phone-number',
            caption:"Customer Name",
            id:"12233-112233-aaaa",
            isDefaultOrderField:true,
            position: 1,
            type: "short-text",
        },
        {
            name:'customerEmail',
            type:'phone-number',
            caption:"Customer Email",
            id:"12233-112233-bbbb",
            isDefaultOrderField:true,
            position: 1,
            type: "short-text",
        },
        {
            name:'customerPhoneNumber',
            type:'phone-number',
            caption:"Customer Phone-number",
            id:"12233-112233-cccc",
            isDefaultOrderField:true,
            position: 1,
            type: "short-text",
        },
        {
            name:'customerAddress',
            type:'phone-number',
            caption:"Customer Address",
            isDefaultOrderField:true,
            id:"12233-112233-dddd",
            position: 1,
            type: "short-text",
        }
    ] : [];


    const dispatch = useDispatch();

    //const orderItems = useSelector(state => state.orderItems.filter(o => o.orderId ==orderId));
    //const stageFormData = useSelector(state => state.stageFormData.filter(o => o.orderId ==orderId));
    const customers = useSelector(state => state.customers)

    

    const [showAddFieldPrompt, setShowAddFieldPrompt] = useState(false);
    const [isPlacingOrder, setIsPlacingOrder] = useState(true)
    const [selectedCustomer, setSelectedCustomer] = useState(null)
    const [showSelectCustomerPopup, setShowSelectCustomerPopup] = useState(false)
    const [fieldDetails, setFieldDetails] = useState(formFields ? [...defaultOrderFields,...formFields] : [...defaultOrderFields]);
    const [stageFormData, setStageFormData] = useState(orderDataSet && Array.isArray(orderDataSet) ? [...orderDataSet] : []);
    const [orderItems, setOrderItems] = useState(orderData?.invoice && Array.isArray(orderData?.invoice?.invoiceItems)? [...orderData.invoice.invoiceItems] : []);

    
    useEffect(()=>{
        // Get form data

        console.log("The entered data", stageFormData)
        stageCallbackHandler("updateFormData", stageFormData)
    },[stageFormData])

    useEffect(()=>{
        console.log("stage info", stageInfo)
        getEntities({
            url:`${BaseURL}api/v1/ordercatalogue/getorderstage?id=${stageInfo?.orderStageId}&catalogueId=${orderData?.catalogueId}`, 
            successCallback:(d)=>{
                console.log("stage data", d);
                setFieldDetails(d.dataForm.items)
            
            }
            });

            getEntities({
                url:BaseURL + 'api/v1/order/GetOrderStageData?orderId=' + orderData.id + '&stageId=' + stageInfo?.orderStageId, 
                successCallback:(od)=>{
                    //setOrder(d);

                    setStageFormData(od.dataSet.data)
                    
                    console.log("CURR 2",{od})
                }});
    },[stageInfo,orderData])

    //const [orderData, setOrderData] = useState(formData)

    useEffect(()=>{
        console.log("setting formfields",formFields);
        setFieldDetails(fd => [...fd.map(f => stageFormData.some(s => s.id === f.id) ? ({...stageFormData.find(s => s.id === f.id)}) : ({...f}))])
    },[])

    
    const onFieldChangeHandler = ({name, value}) => {

      
        /*setFieldDetails(fd => fd.some(m => m.formItemName === name) ? [...fd.map(
            (m, i) => m.formItemName === name? {...m, value, formItemName:name, orderId}
                                    : m)], [...fd, {}]);*/
        
        // get form schema
        let fieldDetail = fieldDetails.find(f => f.name === name)
        console.log("ENTERED", {name, value, fieldDetail})
        if(fieldDetail)
        setStageFormData(fd => fd.some(m => m.formItemName === name) ? [...fd.map(
            (m, i) => m.formItemName === name? {...m, value, formItemName:name, orderId}
                                    : m)]: [...fd, {...fieldDetail, formItemType:fieldDetail.itemType, formItemName:name, value}])
 
                                    //setFieldDetails()  
                                    
     }

     const [customer] = useState(orderData?.customerInfo ? {...orderData?.customerInfo} : {})
    const [order] = useState(orderData? {...orderData} : {})
    //const [customer, setInvoiceItems] = useState(orderData?.invoice ? [])


    const user = useSelector(state => state.user);

    const accountConfiguration = useSelector(state => state.accountConfiguration);

    const handleItemChange = (itms) => {
        //stageCallbackHandler("updateItems", [...itms])
        //setOrderItems([...itms]);
        //itms.map(itm => dispatch({type:'SET_ORDER_ITEM', data:{...itm,orderId}}));

        setOrderItems(itms);

        //console.log("invoice items", itms)
        /* <span>{order?.customerInfo?.firstName}</span>
                    <span>{order?.customerInfo?.email}</span>
                    <span>{order?.customerInfo?.address}</span>
                    <span>{order?.customerInfo?.phoneNumber}</span>
                    */
        let invoiceData = {
            "invoiceNumber": orderData.orderNumber,
            "title": "string",
            "description": "string",
            "payTo": "",
            "billTo": orderData && orderData.customerInfo ? {...orderData.customerInfo} : null,
            "logoData": accountConfiguration?.businessLogo,
            "layout": "1",
            invoiceItems:[...itms]
        }

        stageCallbackHandler && stageCallbackHandler("updateInvoiceItems",invoiceData)// [...itms])
       
    }

    useEffect(()=>{     
        
        console.log("field details changed",fieldDetails)
        //stageCallbackHandler("updatefieldDetails", [...fieldDetails])
        fieldDetails.map(itm => {
            
            //dispatch({type:'SET_STAGE_FORM_DATA', data:itm})

            //if(itm.isDefaultOrderField)
            //{
             //   stageCallbackHandler("updateOrderInfo", {field:[itm.name], value:itm.value})
            //}
            
        })

        stageCallbackHandler && stageCallbackHandler("updatefieldDetails", fieldDetails)
   

    },[fieldDetails])

    /*const getData=(fieldDataInfo, fieldInfo)=>
    {
        console.log("Field data",{fieldDataInfo, fieldInfo})

        let data = fieldDataInfo.find(fd => fd.name === fieldInfo.name)

        return data ? data.value : '';
    }*/

    const handleItemSelectionChange = () => 
    {
        //console.log(selectedCustomer)
        onFieldChangeHandler({name:'customerName', value:selectedCustomer.name})
        onFieldChangeHandler({name:'customerEmail', value:selectedCustomer.email})
        onFieldChangeHandler({name:'customerPhoneNumber', value:selectedCustomer.phoneNumber})
    }

    const renderComponent = ({data}) => {
       
        return (<div className='item-card' style={{margin:0, height:120, boxShadow:'2px 2px 11px -2px rgb(20 20 20 / 20%)', border:selectedCustomer?.id === data?.id ? '2px solid orange' :0,  marginBottom:10, alignItems:'center', width:'100%'}}>
        <div style={{padding:15, display:'flex', width:'100%', justifyContent:'left', alignItems:'center', fontSize:20}}>
           
            <button  className='app-button-secondary-small' onClick={e => setSelectedCustomer(data)} type="checkbox" >Select</button>
           <div style={{background:'aqua', padding:15, width:20, height:20, fontSize:13, margin:10}} className="user-info-avartar">
               {data.firstName?.toUpperCase()?.charAt(0)}                    
           </div>
           {/*  <img  src={defaultIcon} style={{height:50, background:'none', borderRadius:5,   margin:5, marginRight:20}} alt="logo" />  */}
           <div style={{display:'flex', flexGrow:1, flexDirection:'column'}}>

           <div style={{display:'flex'}}>{`${data.firstName} ${data.lastName}`}</div>
           <div style={{display:'flex', color:'#888888', fontSize:14}}>{data.email}</div>
           <div style={{display:'flex', color:'#888888', fontSize:14}}>{data.phoneNumber}</div>
           <div style={{display:'flex', color:'#888888', fontSize:14}}>{data.address}</div>          
       
       </div>
      
       </div>
   </div>)
      }

    return(<>
    
    {/*<div style={{display:'flex'}}><span style={{color: isPlacingOrder ? 'black' : '#CCCCCC'}}>Place Order</span>
     <ToggleSelect onChange={(s)=>setIsPlacingOrder(s)} isTrue={isPlacingOrder}></ToggleSelect><span  style={{color: isPlacingOrder ? '#CCCCCC' : 'black'}}>Make Inquiry</span></div> */}
    {/** this button is only possible if the user is the merchant : alternatively make the name and email searchable */}
        
        <div  style={{display:'flex', padding:20, width:'98%', borderRadius:10, boxShadow:'2px 2px 20px -3px rgba(30,30,30,0.1)',  flexDirection:'column'}}>
             <div style={{display: 'inline-grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(220px, 1fr))',}}>
            {[...fieldDetails].map((ff, index) => 
           
              
                
                RenderControl({fieldInfo:ff,dataSet:stageFormData,onChangeHandler: onFieldChangeHandler} )
                //console.log("FF",ff)
                
                
            
            )}</div>
            
          

        </div>

        
    </>)
}

export default FeedbackForm;