import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch} from 'react-redux';
import {v4 as uuidv4} from 'uuid';
import { BaseURL } from '../../constants/URLs';
import PopUp from '../PopUp/PopUp';
import SearchListView from '../SearchListView/SearchListView';
import defaultIcon  from '../../assets/images/default-inventory-icon.svg';
import './invoice-style.css'
import { useMemo } from 'react';
import { memo } from 'react';
import NumberField from '../NumberField/NumberField';
import SignatureBox from '../Form/fieldComponents/SignatureBox';



const OrderItemsListView = (props) => {
  
    const [items, setItems] = useState(props.items ? [...props.items] : [])
    const [tax, setTax] = useState(props.tax ? props.tax : 0)
    const [shipping, setShipping] = useState(props.shipping ? props.shipping : 0)
    const [discount, setDiscount] = useState(props.discount ? props.discount : 0)
    const [customerSignature, setCustomerSignature] = useState(props.customerSignature)
   
    
    useEffect(()=>{
        setItems(props.items ? [...props.items] : [])
    },[props.items])

    useEffect(()=>{
        console.log("LLK", {customerSignature, prop: props.customerSignature, idata:props.invoiceData})
        setCustomerSignature(props.customerSignature)
    },[props.customerSignature, props.invoiceData])


    useEffect(()=>{
        props.handeFieldChange && props.handeFieldChange({shipping, discount, tax, customerSignature})
    }, [shipping, discount, tax, customerSignature])

   

    const removeItem = (id) => {

        props.onItemChange && props.onItemChange([...items.filter(i => i.id !== id)])
        setItems(itemList =>  [
            ...itemList.filter(i => i.id !== id)] )
    }

    const updateItemQuantity = (e) => {
        let {name, value} = e;//e.target;

        // name is the 'id' of the item

        props.onItemChange && props.onItemChange([
            ...items.map(
                (m, i) => m.id === name ? {...m, quantity:parseFloat(value) || 0}
                                        : m
            )                  
             ] )
        setItems(itemList =>  [
            ...itemList.map(
                (m, i) => m.id === name ? {...m, quantity:parseFloat(value) || 0}
                                        : m
            )                  
             ] )


    }

    /* const showItemPopup = () =>{
        setSelectedItems([])
        setShowAddItemPopup(true)
    } */

    useEffect(()=>{

        
        //props.onItemChange && props.onItemChange(items)
    }, [items])

    

    return(<>
        <table className='invoice-table' style={{width:'100%', minWidth:400}} cellSpacing={0} cellPadding={5}>
            <thead>
            <tr style={{padding:5, background:props.theme?.color1 || null, borderBottom:'1px solid #565656'}}>
                        <th>SN</th>
                        <th>Item</th>
                        <th>Unit Cost</th>
                        <th>Quantity</th>
                        <th>Total</th>
                        {props.isEditable && <th></th>}
                    </tr>
            </thead>
            <tbody>
                    
                {items.map((ff, index) => 
                
                                                          
                    <tr >
                        <td>{index+1}</td>
                        <td className='c'>{ff.title}</td>
                        <td className='c'>{ff.unitPrice}</td>
                        {/* <td>{ff.quantity}</td>  */} 
                        <td className='c'>
                        {props.isEditable ?  
                        <NumberField name={ff.id} onChange={updateItemQuantity} value={ff.quantity} />
                        : ff.quantity}
                        </td>                     
                        <td className='c'>{ff.quantity * ff.unitPrice}</td>
                        {props.isEditable && <td className='c'>
                            <div className='row-delete-button' onClick={()=>removeItem(ff.id)}>
                            <svg height="14" width="14" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-19bqh2r"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
                            </div>
                        </td>}
                    </tr>
                  
                  

                )}
                </tbody>
                <tfoot>
                {props.isEditable &&<tr style={{backgroundColor:'white'}}>                        
                        <td colSpan={props.isEditable ? 6  : 5}  className='c'>
                            <button onClick={()=>props.showItemPopup && props.showItemPopup()} className='app-button-secondary-small'>Add Item</button>
                        </td> 
                    </tr>}
                    <tr style={{background:props.theme?.color2 || null,}}>                        
                        <td style={{textAlign:'right'}}  colSpan={4}>Sub Total</td>                      
                        <td>{items.reduce((p,c)=> p + (c.quantity*c.unitPrice), 0)}</td>
                        {props.isEditable && <td></td>}
                    </tr>
                    <tr style={{background:props.theme?.color2 || null,}}>                        
                        <td style={{textAlign:'right'}} colSpan={4}>Tax (VAT)</td>                      
                        <td> 
                        {props.isEditable ? <input style={{width:50}} onChange={(e)=>setTax(parseFloat(e.target.value))} name={'tax'} value={tax} /> 
                            : (tax)}%</td>
                        {props.isEditable && <td></td>}
                    </tr>
                    <tr style={{background:props.theme?.color2 || null,}}>                        
                        <td style={{textAlign:'right'}} colSpan={4}>Discount</td>                      
                        <td> 
                        {props.isEditable ?  <input style={{width:50}} onChange={(e)=>setDiscount(parseFloat(e.target.value))} name={'discount'} value={discount} /> 
                        : (discount) }%</td>
                         {props.isEditable && <td></td>}
                    </tr>
                    <tr style={{background:props.theme?.color2 || null,}}>                        
                        <td style={{textAlign:'right'}} colSpan={4}>Shipping & Handling</td>                      
                        <td> 
                        {props.isEditable ?  <input style={{width:50}} onChange={(e)=>setShipping(parseFloat(e.target.value))} name={'discount'} value={shipping} /> 
                        : (shipping) }</td>
                         {props.isEditable && <td></td>}
                    </tr>
                    
                    <tr  style={{background:'none'}}>                        
                        <td style={{textAlign:'right', borderTop:`1px solid ${ props.theme?.color1 || 'grey'}`}}  colSpan={4}>Total</td>                      
                        <td style={{fontSize:20, borderTop:`1px solid ${ props.theme?.color1 || 'grey'}`}}>{items.reduce((p,c)=> p + (c.quantity*c.unitPrice), 0) * (100.0-discount+tax)/100.0}</td>
                        {props.isEditable && <td></td>}
                    </tr>
                    <tr  style={{backgroundColor:'white'}}>                        
                        <td align='left' valign='bottom' colSpan={4}>
                            <div style={{display:'inline-flex', flexDirection:'column'}}>
                            <SignatureBox isEditable={props.isEditable} dataChangeHandler={(d)=>setCustomerSignature(d.imageData)} name={'accountSignature'} className={'text-input'} value={customerSignature} type="text"/>
                            
                                    <span style={{borderTop:'1px solid black'}}>Customer Signature</span>
                            </div>
                        
                        </td>                      
                        <td  valign='bottom'>
                            <div style={{display:'flex', flexDirection:'column'}}>
                                    <img width={80} src={props.accountSignature}/>
                                    <span style={{borderTop:'1px solid black'}}>Authorised Signature</span>
                            </div>
                        </td>
                        {props.isEditable && <td></td>}
                    </tr>
                    </tfoot>
                </table>

               

    </>)
}//memo(, (p, c)=>p.items === c.items)


export default OrderItemsListView;


