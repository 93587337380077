import React, {useEffect, useState} from 'react';
import {
    Link,
    useNavigate
} from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';
import { useSpring, animated as a, config } from 'react-spring';
import LayoutWrapper from '../layout/LayoutWrapper';
import ViewWrapper from './ViewWrapper';
//import { deleteAsset } from '../redux/actions/assetActions';
import Prompt from '../components/Prompt/Prompt';
import defaultIcon  from '../assets/images/user-icon-01.svg';
import searchIcon from '../assets/images/search.svg'
import PopUp from '../components/PopUp/PopUp';
//import DropIns from '../games/components/DropIns/DropIns';
import backgroundImage from '../assets/images/background_01.svg';
import imageO1 from '../assets/images/cognitive-areas/01.svg';
import imageO2 from '../assets/images/cognitive-areas/02.svg';
import imageO3 from '../assets/images/cognitive-areas/03.svg';
import imageO4 from '../assets/images/cognitive-areas/04.svg';
import Moment from 'moment';
import ToggleSelect from '../components/ToggleSelect/ToggleSelect';
import {v4 as uuidv4} from 'uuid';

import axios from 'axios';
import { BaseURL } from '../constants/URLs';

import { getEntities, postEntities, putEntities, deleteEntities } from '../api/restUtilities';

const ReceiptsView = (props)  => {

   
 
    //const notifications = useSelector(state => state.notifications)

    const dispatch = useDispatch(); 

    let history = useNavigate();

    const user = useSelector(state => state.user);
    
    const [openPromptDialog, setOpenPromptDialog] = useState(false);
    const [promptOkAction, setPromptOkAction] = useState(null);
    const [searchString, setSearchString] = useState("");
    const [showCreateNewOrderPopup, setShowCreateNewOrderPopup] = useState(false);
    
    const [selectedGame, setSelectedGame] = useState(null);
    const [selectedGamePlayOptions, setSelectedGamePlayOptions] = useState(null);
    
    const [selectedOrderCatalogue, setSelectedOrderCatalogue] = useState(null);
    const [notifications, setNotifications] = useState([])

    const currentUser  = JSON.parse(localStorage.getItem('currentUser'));
    
    useEffect(()=>{

        getEntities({
            url:BaseURL + 'api/v1/notifications/getusernotifications?accountId=' + currentUser?.accountId, 
            successCallback:setNotifications});
      
    },[])
   

    const filterList = (searchStr, data, fieldName) => {
        if(data && fieldName)
        {
            return data.filter( d => d[`${fieldName}`]?.toLowerCase().match(searchStr.toLowerCase()))
        }
        else
        {
           return []; 
        }
    }

   

    const createNewOrder = () => {
        /* let newOrderId = uuidv4();

        let templateData = selectedOrderCatalogue ? {...selectedOrderCatalogue} : {}

        dispatch({type:'CREATE_ORDER', data: {...newOrder, catalogue:{...templateData}, id:newOrderId}}); */

        //history(`/edit-order-catalogue/${newOrderCatalogueId}`);

        //history(`/edit-order/${newOrderId}`)
    }

    const getFriendlyDateTime = (lastPlayed) => {
        
      

        if(lastPlayed && lastPlayed !== -Infinity)
        {
           
            return Moment(new Date(lastPlayed)).startOf('second').fromNow()
        }
        return "Now";
        
    }


    return(<><ViewWrapper title={'Receipts'}>
   
            <Prompt promptOptions={{...promptOkAction}} OkLabel={"Yes"}  isOpen={openPromptDialog} modalClosed={()=> setOpenPromptDialog(false)}/>
                 
                <div style={{margin:5, position:'relative'}}>
                    <div style={{display:'flex', position:'sticky', flexDirection:'row', width:'100%', margin:'10px 0px', alignItems:'center', justifyContent:'center'}}>
                    {searchString && <div style={{color:'#ab571e', marginRight:10}}  onClick={e=>setSearchString("")} >Clear</div>}    
                    <input className='search-input-wide'  value={searchString} onChange={e=>setSearchString(e.target.value)} placeholder="Search" style={{backgroundImage:`url('${searchIcon}')`, backgroundRepeat:'no-repeat', backgroundPositionX:'98%',  backgroundPositionY:'50%',}}/>
                    
                    </div>      
                </div>

                <div style={{margin:5}}>
                <div style={{ width:'100%',display: 'flex',
                flexDirection: 'column',  minHeight:'calc(100vh-50px)'}}>
              
                {filterList(searchString, notifications, "title").length > 0 ? 
                
                filterList(searchString, notifications, "title").map((g,i) => 
                <div className='item-card' style={{margin:0, height:120, boxShadow:'2px 2px 11px -2px rgb(20 20 20 / 20%)', marginBottom:10, alignItems:'center', width:'100%'}}>
                     <div style={{padding:15, display:'flex', width:'100%', justifyContent:'left', alignItems:'center', fontSize:20}}>
                        <div style={{background:'lightgreen', width:20, height:20, margin:20, borderRadius:'50%'}}>

                        </div>
                        
                        <div style={{display:'flex', flexGrow:1, flexDirection:'column'}}>

                        <div style={{display:'flex'}}>{g.title}</div>
                        <div style={{display:'flex', color:'#888888', fontSize:14}}>{getFriendlyDateTime(g.dateTime)}</div>                       
                        <div style={{display:'flex', justifyContent:'flex-end'}}>
                            <button className='app-button-secondary-small'>Open</button>
                        </div>
                    
                    </div>
                   
                    </div>
                </div>
                )
                :
                <div style={{width:'100%', height:100, fontSize:20, color:'#CECECE', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <span>No Record found</span>    
                </div>}
                </div>
                
                <div style={{height:80}}></div>
                
                </div>
    </ViewWrapper>
    {<PopUp open={showCreateNewOrderPopup} >
    <div style={{width:'100%', height:'100%', display:'flex', flexDirection:'column',
        padding:0,
        borderRadius:10, 
        overflow:'hidden',
        boxShadow:'2px 2px 5px rgba(10,10,10,0.7)', minHeight:40, background:'white'}}>
            <div style={{padding:15, display:'flex', justifyContent:'left', alignItems:'center', fontSize:20}}>
           {/*  <img  src={defaultIcon} style={{height:50, background:'#ecf5ef', borderRadius:5,   margin:2, marginRight:20}} alt="logo" /> 
            */} <div style={{display:'flex', flexDirection:'column'}}>

                <div style={{display:'flex'}}>{'Clear All Notifications'}</div>
               
            </div>
            
            </div>
            
            <div style={{width:'100%', 
                padding:10,                
                background:'#EEEEEE', display:'flex', justifyContent:'space-between'}}>
                <button className='app-button-primary' onClick={() => createNewOrder()} >Clear</button>
                <button className='app-button-primary' onClick={() => setShowCreateNewOrderPopup(false)}>Cancel</button>
            </div>            
        </div>
        </PopUp>}
    </>)

}   

export default LayoutWrapper(ReceiptsView, {showNavigationBar:true, showSideMenu:true,backgroundImage});
