// Fisher–Yates shuffle, see the Wikipedia article
// https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle
export function shuffle(sourceArray) {

    let array = [...sourceArray]; //To prevent mutation of source

    var m = array.length, t, i;
    
    // While there remain elements to shuffle…
    while (m) {
    
        // Pick a remaining element…
        i = Math.floor(Math.random() * m--);
    
        // And swap it with the current element.
        t = array[m];
        array[m] = array[i];
        array[i] = t;
    }
    
    return array;
}

// Matrix Utility.

//1. Rotate an nxn matrix.
// [1,2,3]      [7,4,1]
// [4,5,6] =>   [8,5,2]
// [7,8,9]      [9,6,3]
export const rotate = (array, left) =>
{
    let rotatedArray = [];

    // R-to-L Diagonal flip
    /* for(let r = 0; r < array.length; r++ )
    {
        console.log(array[0].map((val, index)=>array[index][r]))
    } */

    //Rotate - 90 * 1
    for(let r = 0; r < array.length; r++ )
    {
       // rotatedArray.push(array[0].map((val, index)=>array[array.length-index-1][r]))
    }

    //Rotate - 90 * 1
    for(let r = 0; r < array.length; r++ )
    {
         left ?
        rotatedArray.push(array[0].map((val, index)=>array[index][array.length-r-1])):
        rotatedArray.push(array[0].map((val, index)=>array[array.length-index-1][r])) 
    }

    return rotatedArray;
}

export const flipX = (array, left) =>
{
    let rotatedArray = [];

    
    for(let r = 0; r < array.length; r++ )
    {
       rotatedArray.push(array[0].map((val, index)=>array[r][array.length-index-1]))
    }
  

    return rotatedArray;
}

export const shiftArrayX = (array, factor) =>
{
    let rotatedArray = [];

    
    for(let r = 0; r < array.length; r++ )
    {
       rotatedArray.push(shiftX(array[r],1))
    }
  

    return rotatedArray;
}

export const shiftArrayY = (array, factor) =>
{
    let rotatedArray = [];

    array = rotate(array,true)

    for(let r = 0; r < array.length; r++ )
    {
       rotatedArray.push(shiftX(array[r],1))
    }
  
    rotatedArray = rotate(rotatedArray,false)

    return rotatedArray;
}

export const flipY = (array, left) =>
{
    let rotatedArray = [];

    array = rotate(array,true)
    
    for(let r = 0; r < array.length; r++ )
    {
       rotatedArray.push(array[0].map((val, index)=>array[r][array.length-index-1]))
    }
  
    rotatedArray = rotate(rotatedArray,false)

    return rotatedArray;
}

//1. Rotate an nxn matrix.
// [1,2,3]      [7,4,1]
// [4,5,6] =>   [8,5,2]
// [7,8,9]      [9,6,3]
export const shiftX = (array, factor) =>
{
    let shiftedArray = [];

    let shiftFactor = factor % array.length;
        
    for(let r = shiftFactor; r < array.length; r++ )
    {
        shiftedArray.push(array[r])
    }

    for(let r = 0; r < shiftFactor; r++ )
    {
        shiftedArray.push(array[r])
    }

    return shiftedArray;
}

export const moveByIndexField = (from, to, arr, indexField) => {

    let result = [...arr];

    if(to > from) result.reverse();

    let srcItem = arr.find( f => f[indexField] === from)

    result = result.map(a => {

        if(from > to)
        {
            if(a[indexField] >= to && a[indexField] < from)
            return {...a, [indexField]:a[indexField]+1};
        }
        else
        {
            if(a[indexField] > from && a[indexField] <= to)
            return {...a, [indexField]:a[indexField]-1};
        }
       
        if(a[indexField] == from)
        return {...srcItem, [indexField]:to};

        return a;
        
    })

    // return result sorted by the [indexField]
    return result.sort((a,b) => a[indexField] - b[indexField])
}

