import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import { Route, Routes,BrowserRouter} from 'react-router-dom';  
import NotificationContainer from './notification/NotificationContainer';
import './assets/css/app-main.css'

// Loads the Pages,
import DashboardView from './views/DashboardView';
import HelpView from './views/HelpView';
import SplashScreen from './views/SplashScreen'
import Settings from './views/Settings';
import AllUserCatalogues from './views/AllUserCatalogues';
import CreateOrderCatalogue from './views/CreateOrderCatalogue';
import TrackOrderView from './views/TrackOrderView';
import AllOrdersView from './views/AllOrdersView';

/*import { DndProvider } from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch'; // or any other pipeline*/


/* import { DndProvider } from 'react-dnd';
import MultiBackend from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch'; */


import { TouchBackend } from 'react-dnd-touch-backend'
import { DndProvider } from 'react-dnd'
import SetupView from './views/SetupView';
//import TrainingView from './views/TrainingView';
import ReactGA from 'react-ga';
import EditOrderView from './views/EditOrderView';
import MerchantLoginView from './views/MerchantLoginView';
import SubAccountLoginView from './views/SubAccountLoginView';
import CustomersView from './views/CustomersView';
import SubAccountsView from './views/SubAccountsView';
import InventoryView from './views/InventoryView'
import NotificationsView from './views/NotificationsView';
import UserRegistrationView from './views/UserRegistrationView';
import Loader from './components/Loader/Loader';
import axios from 'axios';
import ReceiptsView from './views/ReceiptsView';
import InvoicesView from './views/InvoicesView';

import { useDispatch, useSelector} from 'react-redux';
import { getEntities } from './api/restUtilities';
import { BaseURL } from './constants/URLs';
import StoreCatalogues from './views/StoreCatalogues';
import CustomerPage from './views/Customer/CustomerPage';
import CreateOrderView from './views/Customer/CreateOrderView';
import CustomerInventoryView from './views/Customer/CustomerInventoryView';
import CustomerLoginPage from './views/Customer/CustomerLoginPage';
import CustomerRegistrationPage from './views/Customer/CustomerRegistrationPage';
import NewOrderSetupView from './views/Customer/NewOrderSetupView';
import PrivateRoute from './hocs/PrivateRoute';
import PasswordRecoveryView from './views/PasswordRecoveryView';
import PasswordResetView from './views/PasswordResetView';
import CustomerPasswordRecoveryView from './views/Customer/CustomerPasswordRecoveryView';
import CustomerHistoryView from './views/Customer/CustomerHistoryView';


const TRACKING_ID = "UA-104993010-2";//"G-V1EQ4ZSE1K";

ReactGA.initialize(TRACKING_ID);

// utils.js
const useBeforeRender = (callback, deps) => {
  const [isRun, setIsRun] = useState(false);

  if (!isRun) {
      callback();
      setIsRun(true);
  }

  useEffect(() => () => setIsRun(false), deps);
};

// yourComponent.js


function App() {

  //const dispatch = useDispatch();    

  const currentUser = useSelector(state => state.user);

  const customerAccount = useSelector(state => state.customerAccount); 

  const dispatch = useDispatch();

  useEffect(()=>{
    
    setUpInterceptor();

    getEntities({url: BaseURL + 'api/v1/accountconfiguration/getconfiguration?Id=' + currentUser?.accountId, successCallback: (d)=>{
      dispatch({type:"SET_ACCOUNT_CONFIGURATION", data:{...d}});
      localStorage.setItem('accountConfiguration', JSON.stringify(d));
    }});
      
    
  
  },[currentUser])

  const setUpInterceptor = () => {
    axios.interceptors.request.use(config => {
      //let currentUser  = JSON.parse(localStorage.getItem('currentUser'));
      //console.log("interceptor setup")

      if (customerAccount && customerAccount.token) {                
        config.headers = { ...config.headers, 'Access-Control-Allow-Origin': '*', ['customer-authentication']:customerAccount?.token };            
        } 

      if (currentUser && currentUser.token) {                
      config.headers = { ...config.headers, Authorization: `Bearer ${currentUser.token}`, 'Access-Control-Allow-Origin': '*', ['customer-authentication']:customerAccount?.token };            
      } 
      else {                
      config.headers = { ...config.headers, 'Access-Control-Allow-Origin': '*' };            
      }
      //console.log("stored current user", config.headers)
        return config;        
    })
  }
  
  useBeforeRender(() => setUpInterceptor(), []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  {/*** An order is a customer filled and submitted catalogue.
        User is the merchant */}

  return (
    <DndProvider backend={TouchBackend} options={{enableMouseEvents:true}} /* backend={MultiBackend} options={HTML5toTouch} */>
    <div className="App">
      <Routes>  
        <Route path='/orders' element={<PrivateRoute><AllOrdersView/></PrivateRoute>} />
        {/* <Route path='/order/:orderId' element={<OrderView/>} /> */}
        <Route path='/order-catalogues' element={<PrivateRoute><AllUserCatalogues/></PrivateRoute>} />
        <Route path='/create-order-catalogue' element={<PrivateRoute><CreateOrderCatalogue/></PrivateRoute>} />
        <Route path='/edit-order-catalogue/:orderCatalogueId' element={<PrivateRoute><CreateOrderCatalogue/></PrivateRoute>} />
        <Route path='/order/:orderId' element={<PrivateRoute><EditOrderView/></PrivateRoute>}/>
        <Route path='/merchant-login' element={<MerchantLoginView/>} />
        <Route path='/sub-account-login' element={<SubAccountLoginView/>} />
        <Route path='/customers' element={<PrivateRoute><CustomersView/></PrivateRoute>}/>
        <Route path='/team' element={<PrivateRoute><SubAccountsView/></PrivateRoute>}/>
        <Route path='/inventory' element={<PrivateRoute><InventoryView/></PrivateRoute>}/>
        <Route path='/notifications' element={<PrivateRoute><NotificationsView/></PrivateRoute>}/>        
        <Route path='/help' element={<HelpView/>}/>
        <Route path='/settings' element={<PrivateRoute><Settings/></PrivateRoute>} />
        <Route path='/register' element={<UserRegistrationView/>}/>
        <Route path='/store-catalogues/:accountDomainName' element={<StoreCatalogues/>}/>
        <Route path='/:accountDomainName/home' element={<CustomerPage/>}/>
        <Route path='/:accountDomainName/track-order' element={<TrackOrderView/>}/>
        <Route path='/:accountDomainName/create-order' element={<NewOrderSetupView/>}/>
        <Route path='/:accountDomainName/manage-order/:orderId' element={<CreateOrderView/>}/>
        
        <Route path='/password-recovery' element={<PasswordRecoveryView/>}/>
        <Route path='/password-reset' element={<PasswordResetView/>}/>
        
        
        <Route path='/:accountDomainName/inventory' element={<CustomerInventoryView/>}/>
        <Route path='/:accountDomainName/customer-login' element={<CustomerLoginPage/>}/>
        <Route path='/:accountDomainName/customer-registration' element={<CustomerRegistrationPage/>}/>
        <Route path='/:accountDomainName/password-recovery' element={<CustomerPasswordRecoveryView/>}/>
        <Route path='/:accountDomainName/order-history' element={<CustomerHistoryView/>}/>
        
        <Route path='/invoices' element={<PrivateRoute><InvoicesView/></PrivateRoute>}/>
        
        <Route path='/receipts' element={<PrivateRoute><ReceiptsView/></PrivateRoute>}/>
        
        <Route path='/dashboard' element={<PrivateRoute><DashboardView/></PrivateRoute>}/>
         {/*  <Route path='/place-order/:orderCatalogueId' element={<PlaceOrder/>} />
        <Route path='/track-order/:orderId' element={<TrackOrder/>} />
        <Route path='/all-customer-orders/:orderId' element={<AllCustommerOrders/>} />
       
        
        <Route path='/login' element={<Login/>} /> 
        <Route path='/sign-up' element={<SignUp/>} /> 
        <Route path='/password-recovery' element={<PasswordRecovery/>} />
        <Route path='/contact-us' element={<ContactUs/>} />
        <Route path='/view-catalogues' element={<HelpView/>} /> 
         */}
        <Route path='/setup' element={<SetupView/>} />
        <Route path='/track-order' element={<TrackOrderView/>} />
        
        {/* <Route path='/user-statistics' element={<DashboardView/>} /> */}
        {/* <Route path='/notifications' element={<PrivateRoute><UserNotifications/></PrivateRoute>} />  */}
        <Route path='/' element={<SplashScreen/>} />
      </Routes>
      <Loader/>
      <NotificationContainer/>
    </div>
    </DndProvider>
  );
}

export default App;
