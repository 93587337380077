import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch} from 'react-redux';
import { BaseURL } from '../../constants/URLs';
import FileUploader from '../FileUploader/FileUploader';
import {Buffer} from 'buffer';
import PopUp from '../PopUp/PopUp';
import ImageBox from '../ImageBox/ImageBox';

import defaultIcon  from '../../assets/images/default-inventory-icon.svg';
import { memo } from 'react';

export const PaymentView = memo(({orderId, orderData, stageInfo}) => {

    const [items] = useState(orderData?.invoice?.invoiceItems) //useSelector(state => state.orderItems.filter(o => o.orderId == orderId));

    const [attachments, setAttachments] = useState(stageInfo.attachments ? stageInfo.attachments : [])

    const onFileUpload = (d) => {
        setAttachments(a => [...a, d])
    }
    //const orders = useSelector(state => state.orders.filter(o => o.orderId == orderId));

    useEffect(()=>{
        console.log(" o Stage Info", orderData)
    },[orderData])


    const download = (url, fileName) => {
        axios.get(url, {responseType: 'blob'})
  .then((response) => {
  
    console.log(response)
  return response.data//.data.blob()
    })
  .then((blob) => {

   
    // Create blob link to download
    const url = window.URL.createObjectURL(
      new Blob([blob]),
    );

    console.log("bblo", url)

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      fileName || `FileName.pdf`,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  });
    }

    const deleteFile = (key) => {
        axios.delete(BaseURL + `api/v1/Order/DeleteFileAsMerchant?key=${key}`)
        .then(r => console.log("del", r))

    }
    

    const [images, setImages] = useState({});
    const [showImagePopUp, setShowImagePopUp] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageZoomScale, setImageZoomScale] = useState(1.0)

    // catche the images with key as 'key'
    const getImage = (key, type) => {
       
        console.log("getting", key)
        var stringifiedData = '';
        if(!images[key])
        {
            //responseType:"arraybuffer"}
            axios.get(key, {responseType:"arraybuffer"}).then(d => {
                let bdata = Buffer.from(d.data, "binary").toString('base64')//Buffer.from(d.data,'base64');
                //let bdata = btoa(data)
                console.log(bdata);
                 stringifiedData = `data:${type};charset=utf-8;base64, ${bdata}`
                setImages( i => ({...i, [key]:stringifiedData}))
            })
        }
       
        //let d = Buffer.from(r.data).toString('base64');
        //console.log(d)
        //setImages( i = ({...i, [key]:d}))
        return stringifiedData;//`data:image/png;base64, ${d}`;
    }

    const showImage = (key) => {
        setSelectedImage(images[key]);
        setShowImagePopUp(true)
    }
    useEffect(()=>{
        console.log("blogImages",images)
    },[images])

    useEffect(()=>{
        attachments?.map(a =>a.fileClass == 'IMAGE' && getImage(BaseURL + `api/v1/Order/GetFileByKey?key=${a.link}`, a.fileType));
    },[attachments])
    return(
        <div style={{padding:25, display:'flex', alignItems:'center', flexDirection:'column', justifyContent:'center', minWidth:320, width:'100%'}}>
            <div className='input-field'>
                <label className='form-label'>Upload Proof of payment</label>
                {/* <input type={'file'} className='form-text-input'/> */}
                <FileUploader onFileUpload={onFileUpload} url={BaseURL + `api/v1/Order/UploadStageAttachementAsMerchant?orderId=${orderId}&stageId=${stageInfo?.orderStageId}`}/>
            </div>
            <div style={{display:'inline-grid', width:'80%', gridTemplateColumns:'repeat(auto-fill, minmax(160px, 1fr))', flexWrap:'wrap'}}>
            {/* <img onClick={()=>showImage(BaseURL + `api/v1/Order/GetFileByKey?key=${a.link}`)} src={images[BaseURL + `api/v1/Order/GetFileByKey?key=${a.link}`]} height={50} ></img>  */}
            {attachments?.map(a => <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                {a.fileClass == "IMAGE" ? 
                <ImageBox  onClick={()=>showImage(BaseURL + `api/v1/Order/GetFileByKey?key=${a.link}`)} src={images[BaseURL + `api/v1/Order/GetFileByKey?key=${a.link}`]} style={{height:'auto', width:80, background:'#d6f7ef' , borderRadius:5, padding:5,  margin:5}} alt={a.title} /> 
                        
                :
                <svg style={{width:64}} fill="#000000" viewBox="0 0 24 24" id="file-12" xmlns="http://www.w3.org/2000/svg" className="icon flat-color"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path id="primary" d="M4.29,4.29l2-2A1,1,0,0,1,7,2H18a2,2,0,0,1,2,2V20a2,2,0,0,1-2,2H6a2,2,0,0,1-2-2V5A1,1,0,0,1,4.29,4.29Z" style={{fill: '#20fea9'}}></path><path id="secondary" d="M7,17a1,1,0,0,1,1-1h8a1,1,0,0,1,0,2H8A1,1,0,0,1,7,17Zm0-4a1,1,0,0,1,1-1h8a1,1,0,0,1,0,2H8A1,1,0,0,1,7,13ZM7,9A1,1,0,0,1,8,8h8a1,1,0,0,1,0,2H8A1,1,0,0,1,7,9ZM4,5H6A1,1,0,0,0,7,4V2a1,1,0,0,0-.71.29l-2,2A1,1,0,0,0,4,5Z" style={{fill:"#1b1d1d"}}></path></g></svg>}
                <div className='catalogue-tag' onClick={()=> download(BaseURL + `api/v1/Order/GetFileByKey?key=${a.link}`, a.name)} download >{a.name}
            </div>
            {/* <span onClick={()=>deleteFile(a.link)} style={{fontSize:10, color:'red'}}>Delete</span>  */}
            <div className='row-delete-button' onClick={()=>deleteFile(a.link)}>
            <svg height="14" width="14" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-19bqh2r"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
            </div>
            </div>)}
            </div>
            <div className='input-field'>
                You have a total of NGN {items.reduce((p,c)=> p + (c.quantity*c.unitPrice), 0)} to pay.

                Kindly make the payment and confirm here.
            </div>
            <button className='app-button-primary' >Confirm Payment</button>  
            <PopUp styles={{borderRadius:9}} fillWidth={true} isEmbedded={false} open={showImagePopUp} >
            <button onClick={()=>setShowImagePopUp(false)}>Close</button>
            <button onClick={()=>setImageZoomScale(z => z < 3.5 ? z  + 0.2 : z)}>Zoom in</button>
            <button onClick={()=>setImageZoomScale(z => z >0.5 ? z -0.2 : z)}>Zoom out</button>
                <div style={{maxWidth:'90%', maxHeight:'80vh', overflow:'auto'}}>
                    <img style={{maxWidth:'100%', transformOrigin:'left top', transform:`scale(${imageZoomScale})`}} src={selectedImage}/>
                
                </div>
                
            </PopUp>
        </div>
    )
})
