import React from "react";
import StageCardInfoField from "./StageCardInfoField";

const StageCard = ({stageInfo,stageCallbackHandler, index}) => {
    return(
        <div style={{minHeight:200, minWidth:270, width:'60%', margin:10, marginLeft:15, marginBottom:0,
            borderRadius:10, 
            display:'flex',
            flexDirection:'column',
           
            //boxShadow:'1px 1px 4px rgba(20,20,20,0.3)',  
            //overflow:'hidden',                                 
            //background:'#fff1ca'
            }}>

            {index === 0 &&  <div style={{display:'flex', cursor:'pointer', color:'#ff7f10', alignItems:'center', padding:10, flexGrow:1, justifyContent:'space-around', fontSize:14}}>               
                <span onClick={()=>stageCallbackHandler("addStage", {position:stageInfo.position})}>+ Add Stage</span>
           </div>}
            
            <div style={{minHeight:170, minWidth:270, 
                width:'60%',
                padding:15, 
                borderRadius:10, 
                display:'inline-grid',
                flexDirection:'column',
                boxShadow:'1px 1px 4px rgba(20,20,20,0.3)',  
                gridTemplateColumns:'repeat(auto-fill, minmax(100px, 1fr))',                                 
                //background:'#FEFEFE'
                }}>
                <span style={{gridColumn:'span 2', paddingBottom:5}}>{stageInfo.title}</span>
                <div>
                    <label  className='form-label'>{'Reviewer(s)'}</label>
                    {stageInfo?.reviewers.map((r, index) => <div style={{paddingRight:3,overflow:"hidden", textOverflow:'ellipsis', fontSize:12, whiteSpace:'nowrap'}}>{`${index +1}. ${r.email}`}</div>)} 
                </div>
                {/* <StageCardInfoField data={'19th November 2022'} label={'Feedback Date'}/> */}
                <StageCardInfoField label={'Maximum Feedback Duration'}>
                    {`${stageInfo?.duration} Hrs`}
                </StageCardInfoField>
                <StageCardInfoField gridColumn={'span 2'} label={'Description'}>
                {stageInfo?.description}
                </StageCardInfoField>
                
                
                
            </div>
            <div style={{display:'flex', alignItems:'center', padding:5, flexGrow:1, justifyContent:'space-around', fontSize:12}}>
                <span onClick={()=>stageCallbackHandler("openStageEditor", stageInfo)}>+ Edit</span>
                <span onClick={()=>stageCallbackHandler("showDeletePrompt", stageInfo)}>+ Delete</span>
                <span onClick={()=>stageCallbackHandler("moveStage", {newPosition:stageInfo.position-1, stage: stageInfo})} >+ Move Up</span>
                <span onClick={()=>stageCallbackHandler("moveStage", {newPosition:stageInfo.position+1, stage: stageInfo})} >+ Move Down</span>
            </div>
            <div style={{display:'flex', cursor:'pointer', color:'#ff7f10', alignItems:'center', padding:10, flexGrow:1, justifyContent:'space-around', fontSize:14}}>
               
                <span onClick={()=>stageCallbackHandler("addStage", {position:stageInfo.position+1})}>+ Add Stage</span>
            </div>
        
        </div>

        
    )
}


export default StageCard;