import React from 'react'
import styled, {keyframes,css} from 'styled-components'; 
import closeButtonIcon from '../../assets/images/close-button.svg';  
import {useNavigate} from 'react-router-dom';
import { useSelector } from 'react-redux';
import Logo1  from '../../assets/images/brain-trainer-logo.svg';

const GameHeader = ({titleSubText, logo, activityTitle, activityName, moduleActions, redirectURL, backgroundColor, height, within}) => {

    const navigate = useNavigate();

    const gamePlayData = useSelector(state => state.gamePlayData);

    const closeActivity = () => {
        //history.push(redirectURL || '/lessons');
        if(redirectURL)
        {
            navigate(redirectURL);
        }
        else
        {
            navigate(-1);
        }
    }

    return (
    <>
    {/*<div style={{position:'fixed',zIndex:10, backgroundColor:'#FFFFFF', display:'flex', flexDirectio:'column', justifyContent:'space-between', width:'100%', alignItems:'center', height:30}}>
        <img style={{width:30}} src={LogoIcon}/>
        <CustomButton1>End</CustomButton1>
    </div>*/}
    <div style={{position: within? 'static' : 'fixed', zIndex:10, top:0, /*boxShadow:'0px 2px 2px rgba(0, 0, 0, 0.2)', */
    //background:backgroundColor? backgroundColor :'linear-gradient(135deg, rgb(168, 237, 234) 0%, rgb(254, 214, 227) 100%)',
    background:'#f1ede4',
    background:'white',
    //background:'linear-gradient(180deg, #cbcaca, transparent)',
     display:'flex', /* flexDirection:'column', */ width:'100%', alignItems:'center', justifyContent:'space-between', height: height ? height : 50}}>                
                <div style={{padding:'10px 15px', display:'flex'}}>
                <img src={logo || Logo1} style={{height:30,fontFamily:'Dancing Script'}} alt="logo" />
                <div style={{display:'flex', marginLeft:10, flexDirection:'column', alignItems:'start'}}>
                    <div className='app-module-title' style={{ paddingLeft:0, color:'black', fontSize:12,/* maxWidth:140, whiteSpace:'nowrap', textOverflow:'ellipsis', overflow:'hidden', display:'block', fontWeight:'bold', textShadow: '0px 1px 0px rgba(255,255,255,.3), 0px -1px 0px rgba(0,0,0,.5)', color:'#cc6700', fontFamily:'Dancing Script', fontSize:26 */}}
                    >{activityTitle || '- - - - - -'}</div>
                    <div  className='app-module-title' style={{ display:'inline-block', fontWeight:'normal', color:'darkgrey', fontSize:10}}>{titleSubText || '- - - -'}</div>                    
                    {/* <span style={{ display:'inline-block', fontWeight:'normal', padding:'0px 10px', color:'#555555', fontSize:12}}><ArrowRight/></span>
                    <span style={{ display:'inline-block', fontWeight:'normal', color:'rgba(255,255,255,0.7)', fontSize:12}}>{activityName}</span> */}
              
                </div>               
                </div>  
                
                <div style={{/* width:'180px', */ height:'100%', padding:10, cursor:'pointer', display:'flex', justifyContent:'space-around', alignItems:'center', backgroundColor:'none',  borderRadius:'0%', margin:0}}>
                {moduleActions && moduleActions.map( ma => <button onClick={()=>ma.actionFunc && ma.actionFunc()}  className='header-button-primary' >{ma.actionCaption}</button>)}
                <button onClick={closeActivity}  className='header-button-primary' >Exit</button>
                    {/* <img style={{width:20}} src={closeButtonIcon}/> */}    
                </div>     
            </div>
    </>)
}

export default GameHeader;