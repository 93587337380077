import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch} from 'react-redux';
//import OrderItemsListView from '../Invoice/OrderItemsListView';
import PopUp from '../PopUp/PopUp';
import ToggleSelect from '../ToggleSelect/ToggleSelect';
import { LongText, Options, ShortText, NumberField, formControlDefinitions, RenderControl, renderControlConfig } from './FormControls';
import {v4 as uuidv4} from 'uuid';
import './formStyles.css';
import moveDownIcon from './icons/move-item-down-icon.png';
import moveUpIcon from './icons/move-item-up-icon.png';

import deleteIcon from './icons/delete-item-icon.png';
import addIcon from './icons/add-item-icon.png';

import editIcon from './icons/edit-item-icon.png';

const moveByIndexField = (from, to, arr, indexField) => {

    let result = [...arr];

    if(to > from) result.reverse();

    let srcItem = arr.find( f => f[indexField] === from)

    result = result.map(a => {

        if(from > to)
        {
            if(a[indexField] >= to && a[indexField] < from)
            return {...a, [indexField]:a[indexField]+1};
        }
        else
        {
            if(a[indexField] > from && a[indexField] <= to)
            return {...a, [indexField]:a[indexField]-1};
        }
       
        if(a[indexField] == from)
        return {...srcItem, [indexField]:to};

        return a;
        
    })

    // return result sorted by the [indexField]
    return result.sort((a,b) => a[indexField] - b[indexField])
}


const FormControlSelector = ({onItemSelect, newField}) => {

   

    return(
        <div style={{padding:15, display:'flex', justifyContent:'center', alignItems:'center', fontWeight:'normal', fontSize:16}}>
        {/*  <img  src={defaultIcon} style={{height:50, background:'#ecf5ef', borderRadius:5,   margin:2, marginRight:20}} alt="logo" />  */}
         <div className='form-control-list' style={{maxHeight:'40vh', overflowY:'auto'}}>

         {formControlDefinitions.map(r => <div key={r.id} style={{border: newField?.itemType === r.itemType ? '1px solid orange' : 'none'}} className='form-control-list-item' >
             
             <div style={{display:'flex', flexDirection:'column'}}>
             <span>{r.name}</span>
             </div>
             <button className='app-button-secondary-small' onClick={() => onItemSelect && onItemSelect(r)}>Select</button>
         </div>)}
         
         </div>
         
         </div>
    )
}


const FormItemSelectorPopup = ({showAddFieldPrompt,fieldItemInfo,setIsEditingField,formFields, setFormFields, hideAddFieldPrompt, isEditingField}) => {
    
    //const [showAddFieldPrompt, setShowAddFieldPrompt] = useState(false);
    const emptyNewField = {itemType:'shortText'}
    const [newField, setNewField] = useState(fieldItemInfo ? {...fieldItemInfo} : {});
    const [currentTabIndex, setCurrentTabIndex] = useState(1);

    const handleNewFieldChange = e => {
        
        const {name, value, checked} = e.target;

        setNewField(nf => ({...nf, [name]: value}))
            
    }

    useEffect(()=>{
        if(newField != null)
        {
            let modifiedNewField = {...newField};

            let fieldItemDefinitions = formControlDefinitions.find(fd => fd.itemType === modifiedNewField.itemType)

            console.log("ss",{newField, fieldItemDefinitions})
            if((modifiedNewField.caption === undefined || modifiedNewField.caption === '' ) && fieldItemDefinitions)
            {
                modifiedNewField = {...modifiedNewField, caption: fieldItemDefinitions.defaultCaption}

                console.log("ss",{newField, fieldItemDefinitions})
                setNewField(n => ({...n, caption: fieldItemDefinitions.defaultCaption}))
            }

            if((modifiedNewField.name === undefined || modifiedNewField.name === '' ) && fieldItemDefinitions)
            {
                modifiedNewField = {...modifiedNewField, name: fieldItemDefinitions.defaultCaption}

                console.log("ss",{newField, fieldItemDefinitions})
                setNewField(n => ({...n, name: fieldItemDefinitions.name+Math.ceil(10000*Math.random())}))
            }
        }
    },[newField])

    useEffect(()=>{
        setNewField(fieldItemInfo ? {...fieldItemInfo} : {})
    },[fieldItemInfo])
    const handleNewFieldOptionsChange = e => {
        
        const {name, value, checked, type} = e.target;

        if(type === 'checkbox' || type === 'radio')
        {
            setNewField(nf => ({...nf, config:nf.config ?{ ...nf.config, [name]: checked} : {[name]: checked}}))
        }
        else
        {
            setNewField(nf => ({...nf, config:nf.config ?{ ...nf.config, [name]: value} : {[name]: value}}))
        }
            
    }

    const onFieldItemSelect = (fieldData) => {

        setNewField(nf => ({ ...nf, itemType:fieldData.itemType}))

        
    }
  
    const updateField = () => {        
        
        let newFields = [...formFields.map(f => f.id === newField.id ? {...newField} : {...f})]//newField, caption:newField.defaultCaption} ]
        console.log("update field", newFields)
        setFormFields( ff => [...ff.map(f => f.id === newField.id ? {...newField} : {...f})]);

        //saveForm(newFields)
        
        setIsEditingField(false);
        hideAddFieldPrompt(false);
    }

    const addSelectedField = () => {

        //let newField = fieldsCatalogue.find(f => f.type === fieldType);

        if(newField) 
        {
            let newFieldId = uuidv4();


            //let newFields = [...formFields, {id:newFieldId, ...newField}]//newField, caption:newField.defaultCaption} ]
            //console.log("new Field", newField)
            setFormFields(ff => [...ff, {id:newFieldId, position:ff.length +1, ...newField} ])
            //saveForm(newFields)
        }

        hideAddFieldPrompt(false);
        setNewField({...emptyNewField})
    }


    return(
        <PopUp styles={{borderRadius:9}} isEmbedded={true} open={showAddFieldPrompt} >
        <div style={{width:'100%', height:'100%', display:'flex', flexDirection:'column',
        padding:0,
        fontSize:13,
        color:'grey',
        borderRadius:10, 
        overflow:'hidden',
        boxShadow:'2px 2px 5px rgba(10,10,10,0.7)', minHeight:50, background:'white'}}>
            <div style={{display:'flex',flexDirection:'column', padding:10}}>
            <div style={{
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'flex-start',
                        margin:10
            }}>
                <label className='text-input-label'>Field Caption</label>
                <input onChange={handleNewFieldChange} value={newField.caption} name="caption"  className='text-input' />
            </div>
            <div style={{
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'flex-start',
                        margin:10
            }}>
                <label className='text-input-label'>Field Name</label>
                <input onChange={handleNewFieldChange} value={newField.name} name="name"  className='text-input' />
            </div>
            <div style={{
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'flex-start',
                        margin:10
            }}>
                <label className='text-input-label'>Field Description</label>
                <input onChange={handleNewFieldChange} value={newField.description} name="description"  className='text-input' />
            </div>
            </div>

            <div style={{display:'flex', padding:10, margin:10}}>
                <button onClick={()=>setCurrentTabIndex(1)}>Select Field Type</button>
                <button onClick={()=>setCurrentTabIndex(2)}>Edit Options</button>               
            </div>
            {currentTabIndex === 1 &&
           (isEditingField? <div>{newField.itemType}</div> :<FormControlSelector newField={newField} onItemSelect={onFieldItemSelect}/>)
            }

            {currentTabIndex === 2 && <div style={{
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'flex-start',
                        margin:10,
                        padding:10
            }}>{renderControlConfig(newField, handleNewFieldOptionsChange)}</div>}
                 
            <div style={{width:'100%', 
        padding:10,
         
        background:'#EEEEEE', display:'flex', justifyContent:'space-between'}}>
                <button className='app-button-primary' onClick={()=> isEditingField ? updateField() : addSelectedField()} >{isEditingField? 'Update' : 'Add'}</button>
                <button className='app-button-primary' onClick={()=>hideAddFieldPrompt(false)} >Close</button>
            </div>            
        </div>
    </PopUp>
    )
}

const OrderFormEditor = ({formFieldsDef, dataSet, isEditing, onSchemaChange, saveHandler, isParentForm = true, stageCallbackHandler}) => {

    const emptyNewField = {itemType:'shortText', parentItemName:null}
    const [showAddFieldPrompt, setShowAddFieldPrompt] = useState(false);
   
    const [newField, setNewField] = useState({...emptyNewField});
    const [formFields, setFormFields] =  useState(formFieldsDef && formFieldsDef.length > 0 ? [...formFieldsDef] : [])
    const [isEditingField, setIsEditingField] = useState(false);
    const [isEditModeOn, setIsEditModeOn] = useState(isEditing);
    const [selectedFieldItem, setSelectedFieldItem] = useState(null);
    const [formDataSet, setFormDataSet] = useState(dataSet ? [...dataSet] : [])


    useEffect(()=>{
        setIsEditModeOn(isEditing);
    },[isEditing])

    useEffect(()=>{
        
        dataSet && setFormDataSet(dataSet);

        console.log("Data Set", {dataSet,isParentForm})
    },[dataSet])

    const editField = (field) => {
        setNewField({...field});
        setShowAddFieldPrompt(true);
        setIsEditingField(true)
    }

    const dataSetChangeHandler = ({name, value, fieldInfo}) => {
        console.log({name, value, fieldInfo})

        setFormDataSet( ff => ff.find(f=>f.formItemName === name) ? [...ff.map(f => f.formItemName === name ? {...f,value} : {...f})] : [...ff,        
        {
            value,
            dataType: fieldInfo?.itemType,
            formItemName: name,
            formItemType: fieldInfo?.itemType,
            
        }]);

    }

    useEffect(()=>{
        console.log(formFields)
        onSchemaChange && onSchemaChange(formFields);
    },[formFields])

    

    const moveField = ({newPosition, field}) =>
    {
        
        setFormFields(od => moveByIndexField(field.position, newPosition, od, "position"))
    }

    const deleteField = (fieldId) => {       
        let newFields = [...formFields.filter(f => f.id !== fieldId)];
        setFormFields( ff => [...ff.filter(f => f.id !== fieldId)]);
        saveForm(newFields)
    }

    const saveForm = (newFormFields) => {
        //stageCallbackHandler("updateForm", [...newFormFields])
    }

    const showAddFieldModal = () => {
        setShowAddFieldPrompt(true);
        setIsEditingField(false);
        setNewField({...emptyNewField})
    }

    const onChildSchemaChange = (schemaData, fieldDesc) => {
        console.log("childSchemaData", {schemaData,fieldDesc})

        let changedChildSchema = formFields.find(f => f.id === fieldDesc.id);

        if(changedChildSchema && schemaData)
        {
            changedChildSchema = {...changedChildSchema, childItems:[...schemaData.map(s => ({...s, parentId: changedChildSchema.id, parentName: changedChildSchema.name}))]}

            setFormFields(ff => ff.map(f => f.id === changedChildSchema.id ? {...changedChildSchema} : f))
        }

    }


    return(<>
    {/* <div>Form Editor</div> */}
        {isParentForm && <div style={{display:'flex', margin:10, alignItems:'center', fontSize:12}}>
            <button onClick={()=>saveHandler && saveHandler(formDataSet)}>Save</button>            
            <button>Clear</button>
        </div>}
        {isParentForm && <div style={{display:'flex', margin:10, alignItems:'center', fontSize:12}}>
            <span>Edit</span>
            <ToggleSelect isTrue={isEditModeOn} onChange={setIsEditModeOn}/>
            <span>Preview</span>
        </div>}
        <div>
            {isEditModeOn && <div style={{display:'flex', cursor:'pointer', color:'#ff7f10', alignItems:'center', padding:10, flexGrow:1, justifyContent:'center', fontSize:14}}>               
            <img width={20} src={addIcon}/><span onClick={()=>showAddFieldModal()}>Add Field</span>
            </div>}
            {formFields && formFields?.map((ff, index) => 
            <div className={ selectedFieldItem?.id == ff.id? 'stripes' : ''} onClick={()=>{console.log(ff);setSelectedFieldItem(ff)}} style={{display:'flex',
            border: selectedFieldItem?.id == ff.id ? '1px dotted grey' : '0',
            padding:10,
            borderRadius:5,
            flexDirection:'column'}}>
                {/* index === 0 &&  <div style={{display:'flex', cursor:'pointer', color:'#ff7f10', alignItems:'center', padding:10, flexGrow:1, justifyContent:'space-around', fontSize:14}}>               
                        <span onClick={()=>stageCallbackHandler("addStage", {position:ff.position})}>+ Add Stage</span>
                </div> */}
                
                {/* {renderControl({fieldInfo:ff, dataSet: formDataSet,onChangeHandler:dataSetChangeHandler, isEditing:isEditModeOn})}
                 */}
                 <RenderControl
                    fieldInfo={ff}
                    dataSet={formDataSet}
                    onChangeHandler={dataSetChangeHandler}
                    isEditing={isEditModeOn}
                    onSchemaChange={onChildSchemaChange}
                 />
                {isEditModeOn && selectedFieldItem?.id == ff.id && <div style={{display:'flex', alignItems:'center', padding:'0px 4px', flexGrow:1, justifyContent:'space-around',
                //background:'white', borderRadius:25, 
                //boxShadow:'1px 1px 2px', 
                fontSize:12}}>
                    <div style={{borderRadius:'50%', margin:0, display:'inline-flex',padding:4, background:'green',}} onClick={()=>showAddFieldModal(ff)}>
                        <img width={12} src={addIcon}/>
                    </div>
                    <div style={{borderRadius:'50%', margin:5, display:'inline-flex',padding:4, background:'black',}} onClick={()=>editField(ff)}>
                        <img width={12} src={editIcon}/>
                    </div>
                    <div style={{borderRadius:'50%', margin:5, display:'inline-flex',padding:4, background:'red',}} onClick={()=>deleteField(ff.id)}>
                        <img width={12} src={deleteIcon}/>
                    </div>
                    <div style={{borderRadius:'50%', margin:5, display:'inline-flex',padding:4, background:'black',}} onClick={()=>moveField({newPosition:ff.position-1, field: ff})} >
                        <img width={12} src={moveUpIcon}/>
                    </div>
                    <div style={{borderRadius:'50%', margin:0, display:'inline-flex',padding:4, background:'black',}} onClick={()=>moveField({newPosition:ff.position+1, field: ff})} >
                        <img width={12} src={moveDownIcon}/>
                    </div>
                </div>}
                {/*** TODO: Move outside the loop, do same for stages */}
                {/* {isEditModeOn && <div style={{display:'flex', cursor:'pointer', color:'#ff7f10', alignItems:'center', padding:10, flexGrow:1, justifyContent:'center', fontSize:14}}>
               
                <img width={20} src={addIcon}/><span onClick={()=>showAddFieldModal()}>Add Field</span>
                </div>} */}
            </div>
            )}

                {/* Consider moving this to the Object/Child List component */}
        {!isParentForm && !isEditModeOn &&<button onClick={()=>saveHandler && saveHandler(formDataSet)}>Add</button>}

        {!isParentForm && !isEditModeOn &&
        
            <table style={{fontSize:12, border:1, with:'100%'}}>
                <tr>
                    <th>SN</th>
                    {formFields?.map((ff,index) => <th>{ff.caption}</th>)}
                    <th>Action</th>
                </tr>
                {formDataSet.map(ds => <tr>
                    <td>SN</td>
                    {/* {formFields?.map((ff,index) => <td>{ds?.value?.find(ds => ds.formItemName === ff.name)?.value}</td>)} */}
                    {formFields?.map((ff,index) => <td>{console.log("DS", {data: ds?.value})}</td>)}
                    <td>Remove</td>
                </tr>)}
            </table>

        }

<FormItemSelectorPopup showAddFieldPrompt={showAddFieldPrompt}
    hideAddFieldPrompt={()=>setShowAddFieldPrompt(false)}
    fieldItemInfo={newField}
    formFields={formFields}
    setFormFields={setFormFields}
    setIsEditingField={setIsEditingField}
    isEditingField={isEditingField}/>
    
        </div>
    </>)
}



export const FormChildObjectEditor = ({formFieldsDef, listFieldInfo, dataSet, onSchemaChange, isEditing, saveHandler, isParentForm = true, stageCallbackHandler}) => {

    const emptyNewField = {itemType:'shortText'}
    const [showAddFieldPrompt, setShowAddFieldPrompt] = useState(false);
    const [currentTabIndex, setCurrentTabIndex] = useState(1);
    const [newField, setNewField] = useState({...emptyNewField});
    const [formFields, setFormFields] =  useState(formFieldsDef && formFieldsDef.length > 0 ? [...formFieldsDef] : [])
    const [isEditingField, setIsEditingField] = useState(false);
    const [isEditModeOn, setIsEditModeOn] = useState(isEditing);
    const [selectedFieldItem, setSelectedFieldItem] = useState(null);
    const [formDataSet, setFormDataSet] = useState(dataSet ? [...dataSet] : [])
    const [newDataSetEntry, setNewDataSetEntry] = useState([])
    const [selectedDataEntry, setSelectedDataEntry] = useState(null)

    useEffect(()=>{
        console.log(formFields)
        onSchemaChange && onSchemaChange(formFields, listFieldInfo);
    },[formFields])

    useEffect(()=>{
        console.log("New Row Entry", newDataSetEntry)
        
    },[newDataSetEntry])

    useEffect(()=>{
        setIsEditModeOn(isEditing);
    },[isEditing])

    useEffect(()=>{
        // TODO: Check for render-issue
        //dataSet && setFormDataSet(dataSet);

        console.log("Data Set", {dataSet,isParentForm})
    },[dataSet])

    useEffect(()=>
    {
        //console.log("child from ds", formDataSet)
        saveHandler && saveHandler(formDataSet)
    },[formDataSet])

    const editField = (field) => {
        setNewField({...field});
        setShowAddFieldPrompt(true);
        setIsEditingField(true)
    }

    const dataSetChangeHandler = ({name, value, fieldInfo}) => {
        //console.log({name, value, fieldInfo})
        let newDataSetId = uuidv4();
        //setFormDataSet
        setNewDataSetEntry( ff => ff.find(f=>f.formItemName === name) ? [...ff.map(f => f.formItemName === name ? {...f,value} : {...f})] : [...ff,        
        {
            id:newDataSetId,
            value,
            dataType: fieldInfo?.itemType,
            formItemName: name,
            formItemType: fieldInfo?.itemType,            
        }]);

    }

    const addDataSetRow = () => {
        let newRowEntry = [...newDataSetEntry];
        let newDataSetRowId = uuidv4();
        setFormDataSet(ds => [...ds, {
            id:newDataSetRowId,
            "formItemName":  listFieldInfo.name,
            "formItemType": 'objectListRow',//listFieldInfo.itemType,
            "dataType":  'objectListRow',//listFieldInfo.itemType,
            value: [...newRowEntry]
        }])

        setNewDataSetEntry(ff => [...ff.map(f => ({...f,value :undefined})) ])
    }

    const updateDataSetRow = () => {
        let newRowEntry = [...newDataSetEntry];
        //let newDataSetRowId = uuidv4();
        setFormDataSet(ds => [...ds.map(d => d.id === selectedDataEntry.id ? {
            id:selectedDataEntry.id,
            "formItemName":  listFieldInfo.name,
            "formItemType": listFieldInfo.itemType,
            "dataType":  listFieldInfo.itemType,
            value: [...newRowEntry]
        } : {...d})])

        setNewDataSetEntry(ff => [...ff.map(f => ({...f,value :undefined})) ])
        setSelectedDataEntry(null)
    }

    const editDataSetRow = (dataSetId) => {
        //console.log("deleting....")
        //let newRowEntry = [...newDataSetEntry];
        //console.log("deleting",{ dataSetId, formDataSet})
        //let newDataSetRowId = uuidv4();

        console.log("selected entry",{fs:formDataSet.find(d => d.id === dataSetId), gh:formFieldsDef})
        setSelectedDataEntry(formDataSet.find(d => d.id === dataSetId))
        setNewDataSetEntry(formDataSet.find(d => d.id === dataSetId)?.value)

        //setNewDataSetEntry(ff => [...ff.map(f => ({...f,value :undefined})) ])
    }

    const removeDataSetRow = (dataSetId) => {
        console.log("deleting....")
        //let newRowEntry = [...newDataSetEntry];
        console.log("deleting",{ dataSetId, formDataSet})
        //let newDataSetRowId = uuidv4();
        setFormDataSet(ds => [...ds.filter(d => d.id !== dataSetId)])

        //setNewDataSetEntry(ff => [...ff.map(f => ({...f,value :undefined})) ])
    }
    /* const updateField = () => {        
        
        let newFields = [...formFields.map(ff => ff.id === newField.id ? {...newField} : {...ff})]//newField, caption:newField.defaultCaption} ]

        setFormFields( ff => [...ff.map(ff => ff.id === newField.id ? {...newField} : {...ff})]);

        saveForm(newFields)
        
        setIsEditingField(false);
        setShowAddFieldPrompt(false);
    } */
    
    

   

    

    useEffect(()=>
    {
        console.log("new Field",newField)
    },[newField])

   
    const moveField = ({newPosition, field}) =>
    {
        //let newFields = [...formFields.filter(f => f.id !== fieldId)];
        //saveForm(newFields)
        setFormFields(od => moveByIndexField(field.position, newPosition, od, "position"))
    }

    const deleteField = (fieldId) => {       
        let newFields = [...formFields.filter(f => f.id !== fieldId)];
        setFormFields( ff => [...ff.filter(f => f.id !== fieldId)]);
        saveForm(newFields)
    }

    const saveForm = (newFormFields) => {
        //stageCallbackHandler("updateForm", [...newFormFields])
    }

    const showAddFieldModal = () => {
        setShowAddFieldPrompt(true);
        setIsEditingField(false);
        setNewField({...emptyNewField})
    }

    const renderValue = (ds, columnName, itemType,formFields ) => {

        console.log("passed data", {ds, columnName, itemType,formFields})
        if( itemType === 'objectList')
        {
            let rowsDataSet = ds?.value?.find(f => f.formItemName === columnName)?.value;
            let columns = formFields?.find(f => f.name === columnName)?.childItems//dataFormItemOptions?.items
            console.log("Table renderer", {rowsDataSet,columns, columnName, itemType, items:formFields})
            return (
                rowsDataSet && rowsDataSet.length> 0 ? 
                <table style={{fontSize:12, border:1, with:'100%'}}>
                <tr>
                    <th>SN</th>
                    {columns?.map((ff,index) => <th>{ff.caption}</th>)}
                    {/* <th>Action</th> */}
                </tr>
                {rowsDataSet?.map((d, index) => 
                <tr>
                    <td>{index + 1}</td>
                    
                    {columns?.map((ff,index) => <td>{renderValue(d, ff.name, ff.itemType,columns )}</td>)}
                    
                    {/* <td onClick={()=>removeDataSetRow(d.id)}>Remove</td> */}
                </tr>)}
            </table> 
            : <div>No Data</div>
            )
        }
        else if( itemType === 'childObject')
        {
            let rowsDataSet = ds?.value?.find(f => f.formItemName === columnName)?.value;
            let columns = formFields?.find(f => f.name === columnName)?.childItems//dataFormItemOptions?.items
            console.log("Table renderer", {rowsDataSet,columns, columnName, itemType, items:formFields})
            //return "object Data"
             return (
                rowsDataSet && rowsDataSet.length> 0 ? 
                <table style={{fontSize:12, border:1, with:'100%'}}>
                <tr>
                    <th>SN</th>
                    {columns?.map((ff,index) => <th>{ff.caption}</th>)}
                   
                </tr>
                <tr>
                    <td>{'values'}</td>
                {rowsDataSet?.map((d, index) => 
                
                    
                    {return columns?.map((ff,index) => ff.name ===d.formItemName ? <td>{renderValue(d, ff.name, ff.itemType,columns )}</td> : null)}
                    
                   
                )}
                </tr>
            </table> 
            : <div>No Data</div>
            ) 
        }
        else{
            console.log( "renderer", {dd: Array.isArray(ds?.value) ? ds?.value?.find(d => d.formItemName === columnName)?.value : ds?.value})
            return Array.isArray(ds?.value) ? ds?.value?.find(d => d.formItemName === columnName)?.value : ds?.value
        }
        
    }

    const onChildSchemaChange = (schemaData, fieldDesc) => {
        console.log("childSchemaData", {schemaData,fieldDesc})

        let changedChildSchema = formFields.find(f => f.id === fieldDesc.id);

        if(changedChildSchema && schemaData)
        {
            changedChildSchema = {...changedChildSchema, childItems:[...schemaData.map(s => ({...s, parentId: changedChildSchema.id, parentName: changedChildSchema.name}))]}

            setFormFields(ff => ff.map(f => f.id === changedChildSchema.id ? {...changedChildSchema} : f))
        }

    }


    return(<>
    {/* <div>Form Editor</div> */}
        {isParentForm && <div style={{display:'flex', margin:10, alignItems:'center', fontSize:12}}>
            <button onClick={()=>saveHandler && saveHandler(formDataSet)}>Save</button>            
            <button>Clear</button>
        </div>}
        {isParentForm && <div style={{display:'flex', margin:10, alignItems:'center', fontSize:12}}>
            <span>Edit</span>
            <ToggleSelect isTrue={isEditModeOn} onChange={setIsEditModeOn}/>
            <span>Preview</span>
        </div>}
        <div>
            {isEditModeOn && <div style={{display:'flex', cursor:'pointer', color:'#ff7f10', alignItems:'center', padding:10, flexGrow:1, justifyContent:'center', fontSize:14}}>               
            <img width={20} src={addIcon}/><span onClick={()=>showAddFieldModal()}>Add Field</span>
            </div>}
            {formFields?.map((ff, index) => 
            <div className={ selectedFieldItem?.id == ff.id? 'stripes' : ''} onClick={()=>{console.log(ff);setSelectedFieldItem(ff)}} style={{display:'flex',
            border: selectedFieldItem?.id == ff.id ? '1px dotted grey' : '0',
            padding:10,
            borderRadius:5,
            flexDirection:'column'}}>
                {/* index === 0 &&  <div style={{display:'flex', cursor:'pointer', color:'#ff7f10', alignItems:'center', padding:10, flexGrow:1, justifyContent:'space-around', fontSize:14}}>               
                        <span onClick={()=>stageCallbackHandler("addStage", {position:ff.position})}>+ Add Stage</span>
                </div> */}
                
                {/* {renderControl({fieldInfo:ff, dataSet: newDataSetEntry,onChangeHandler:dataSetChangeHandler, isEditing:isEditModeOn})}
                 */}
                 <RenderControl
                    fieldInfo={ff}
                    dataSet={newDataSetEntry}
                    onChangeHandler={dataSetChangeHandler}
                    isEditing={isEditModeOn}
                    onSchemaChange={onChildSchemaChange}
                 />
                {isEditModeOn && selectedFieldItem?.id == ff.id && <div style={{display:'flex', alignItems:'center', padding:'0px 4px', flexGrow:1, justifyContent:'space-around',
                //background:'white', borderRadius:25, 
                //boxShadow:'1px 1px 2px', 
                fontSize:12}}>
                    <div style={{borderRadius:'50%', margin:0, display:'inline-flex',padding:4, background:'green',}} onClick={()=>showAddFieldModal(ff)}>
                        <img width={12} src={addIcon}/>
                    </div>
                    <div style={{borderRadius:'50%', margin:5, display:'inline-flex',padding:4, background:'black',}} onClick={()=>editField(ff)}>
                        <img width={12} src={editIcon}/>
                    </div>
                    <div style={{borderRadius:'50%', margin:5, display:'inline-flex',padding:4, background:'red',}} onClick={()=>deleteField(ff.id)}>
                        <img width={12} src={deleteIcon}/>
                    </div>
                    <div style={{borderRadius:'50%', margin:5, display:'inline-flex',padding:4, background:'black',}} onClick={()=>moveField({newPosition:ff.position-1, field: ff})} >
                        <img width={12} src={moveUpIcon}/>
                    </div>
                    <div style={{borderRadius:'50%', margin:0, display:'inline-flex',padding:4, background:'black',}} onClick={()=>moveField({newPosition:ff.position+1, field: ff})} >
                        <img width={12} src={moveDownIcon}/>
                    </div>
                </div>}
                {/*** TODO: Move outside the loop, do same for stages */}
                {/* {isEditModeOn && <div style={{display:'flex', cursor:'pointer', color:'#ff7f10', alignItems:'center', padding:10, flexGrow:1, justifyContent:'center', fontSize:14}}>
               
                <img width={20} src={addIcon}/><span onClick={()=>showAddFieldModal()}>Add Field</span>
                </div>} */}
            </div>
            )}

                {/* Consider moving this to the Object/Child List component */}
        {!isParentForm && !isEditModeOn && formFields.length > 0 && selectedDataEntry === null &&<button onClick={()=>addDataSetRow()/* saveHandler && saveHandler(formDataSet) */}>Add</button>}

        {!isParentForm && !isEditModeOn && selectedDataEntry != null && 
        <div>
            <button onClick={()=>updateDataSetRow()}>Update</button>
            <button onClick={()=>setSelectedDataEntry(null)}>Cancel</button>
        </div>
        }

        {!isParentForm && !isEditModeOn &&
        
         <table style={{fontSize:12, border:1, with:'100%'}}>
                <tr>
                    <th>SN</th>
                    {formFields?.map((ff,index) => <th>{ff.caption}</th>)}
                    <th>Action</th>
                </tr>
                {formDataSet.map((ds, index) => <tr>
                    <td>{index + 1}</td>
                    {/* {formFields?.map((ff,index) => <td>{ds?.value?.find(ds => ds.formItemName === ff.name)?.value}</td>)}
                     */}{/* {formFields?.map((ff,index) => <td>{console.log("DS", {data: ds?.value})}</td>)} */}
                    
                    {formFields?.map((ff,index) => <td>{renderValue(ds, ff.name, ff.itemType, formFields )}</td>)}
                    
                    <td>
                        <button onClick={()=>editDataSetRow(ds.id)}>Edit</button>
                        <button onClick={()=>removeDataSetRow(ds.id)}>Remove</button>
                    </td>
                </tr>)}
            </table>

        }


<FormItemSelectorPopup showAddFieldPrompt={showAddFieldPrompt}
    hideAddFieldPrompt={()=>setShowAddFieldPrompt(false)}
    fieldItemInfo={newField}
    formFields={formFields}
    setFormFields={setFormFields}
    setIsEditingField={setIsEditingField}
    isEditingField={isEditingField}/>
    

        </div>
    </>)
}

export const ChildObjectEditor = ({formFieldsDef, objectFieldInfo, dataSet, isEditing, saveHandler, onSchemaChange, isParentForm = true, stageCallbackHandler}) => {

    const emptyNewField = {itemType:'shortText'}
    const [showAddFieldPrompt, setShowAddFieldPrompt] = useState(false);
    const [currentTabIndex, setCurrentTabIndex] = useState(1);
    const [newField, setNewField] = useState({...emptyNewField});
    const [formFields, setFormFields] =  useState(formFieldsDef && formFieldsDef.length > 0 ? [...formFieldsDef] : [])
    const [isEditingField, setIsEditingField] = useState(false);
    const [isEditModeOn, setIsEditModeOn] = useState(isEditing);
    const [selectedFieldItem, setSelectedFieldItem] = useState(null);
    const [formDataSet, setFormDataSet] = useState(dataSet ? [...dataSet] : [])


    useEffect(()=>{
        setIsEditModeOn(isEditing);
    },[isEditing])

    useEffect(()=>{
        console.log("Child Object", formDataSet)

        let valueData = {
            "formItemName":  objectFieldInfo.name,
            "formItemType": objectFieldInfo.itemType,
            "dataType":  objectFieldInfo.itemType,
            value: formDataSet
        }
        saveHandler(formDataSet)
    },[formDataSet])

    useEffect(()=>{        
        
        // TODO: Check for render-issue
        //dataSet && setFormDataSet(dataSet);

        console.log("Data Set", {dataSet,isParentForm})
    },[dataSet])

    const editField = (field) => {
        setNewField({...field});
        setShowAddFieldPrompt(true);
        setIsEditingField(true)
    }

    const dataSetChangeHandler = ({name, value, fieldInfo}) => {
        console.log({name, value, fieldInfo})

        setFormDataSet( ff => ff.find(f=>f.formItemName === name) ? [...ff.map(f => f.formItemName === name ? {...f,value} : {...f})] : [...ff,        
        {
            value,
            dataType: fieldInfo?.itemType,
            formItemName: name,
            formItemType: fieldInfo?.itemType,
            
        }]);

    }

    useEffect(()=>{

        onSchemaChange && onSchemaChange(formFields,objectFieldInfo);
    },[formFields])

   /*  const updateField = () => {        
        
        let newFields = [...formFields.map(ff => ff.id === newField.id ? {...newField} : {...ff})]//newField, caption:newField.defaultCaption} ]

        setFormFields( ff => [...ff.map(ff => ff.id === newField.id ? {...newField} : {...ff})]);

        saveForm(newFields)
        
        setIsEditingField(false);
        setShowAddFieldPrompt(false);
    } */
    
    

    

    


    useEffect(()=>
    {
        console.log("Field",newField)
    },[newField])

   

    const moveField = ({newPosition, field}) =>
    {
        //let newFields = [...formFields.filter(f => f.id !== fieldId)];
        //saveForm(newFields)
        setFormFields(od => moveByIndexField(field.position, newPosition, od, "position"))
    }

    const deleteField = (fieldId) => {       
        let newFields = [...formFields.filter(f => f.id !== fieldId)];
        setFormFields( ff => [...ff.filter(f => f.id !== fieldId)]);
        saveForm(newFields)
    }

    const saveForm = (newFormFields) => {
        //stageCallbackHandler("updateForm", [...newFormFields])
    }

    const showAddFieldModal = () => {
        setShowAddFieldPrompt(true);
        setIsEditingField(false);
        setNewField({...emptyNewField})
    }

    const onChildSchemaChange = (schemaData, fieldDesc) => {
        console.log("childSchemaData", {schemaData,fieldDesc})

        let changedChildSchema = formFields.find(f => f.id === fieldDesc.id);

        if(changedChildSchema && schemaData)
        {
            changedChildSchema = {...changedChildSchema, childItems:[...schemaData.map(s => ({...s, parentId: changedChildSchema.id, parentName: changedChildSchema.name}))]}

            setFormFields(ff => ff.map(f => f.id === changedChildSchema.id ? {...changedChildSchema} : f))
        }

    }


    return(<>
    {/* <div>Form Editor</div> */}
       {/*  {isParentForm && <div style={{display:'flex', margin:10, alignItems:'center', fontSize:12}}>
            <button onClick={()=>saveHandler && saveHandler(formDataSet)}>Save</button>            
            <button>Clear</button>
        </div>}
         */}
        <div>
            {isEditModeOn && <div style={{display:'flex', cursor:'pointer', color:'#ff7f10', alignItems:'center', padding:10, flexGrow:1, justifyContent:'center', fontSize:14}}>               
            <img width={20} src={addIcon}/><span onClick={()=>showAddFieldModal()}>Add Field</span>
            </div>}
            {formFields?.map((ff, index) => 
            <div className={ selectedFieldItem?.id == ff.id? 'stripes' : ''} onClick={()=>{console.log(ff);setSelectedFieldItem(ff)}} style={{display:'flex',
            border: selectedFieldItem?.id == ff.id ? '1px dotted grey' : '0',
            padding:10,
            borderRadius:5,
            flexDirection:'column'}}>
                {/* index === 0 &&  <div style={{display:'flex', cursor:'pointer', color:'#ff7f10', alignItems:'center', padding:10, flexGrow:1, justifyContent:'space-around', fontSize:14}}>               
                        <span onClick={()=>stageCallbackHandler("addStage", {position:ff.position})}>+ Add Stage</span>
                </div> */}
                
                {/* {renderControl({fieldInfo:ff, dataSet: formDataSet,onChangeHandler:dataSetChangeHandler, isEditing:isEditModeOn})}
                 */}
                 <RenderControl
                    fieldInfo={ff}
                    dataSet={formDataSet}
                    onChangeHandler={dataSetChangeHandler}
                    isEditing={isEditModeOn}
                    onSchemaChange={onChildSchemaChange}
                 />
                {isEditModeOn && selectedFieldItem?.id == ff.id && <div style={{display:'flex', alignItems:'center', padding:'0px 4px', flexGrow:1, justifyContent:'space-around',
                //background:'white', borderRadius:25, 
                //boxShadow:'1px 1px 2px', 
                fontSize:12}}>
                    <div style={{borderRadius:'50%', margin:0, display:'inline-flex',padding:4, background:'green',}} onClick={()=>showAddFieldModal(ff)}>
                        <img width={12} src={addIcon}/>
                    </div>
                    <div style={{borderRadius:'50%', margin:5, display:'inline-flex',padding:4, background:'black',}} onClick={()=>editField(ff)}>
                        <img width={12} src={editIcon}/>
                    </div>
                    <div style={{borderRadius:'50%', margin:5, display:'inline-flex',padding:4, background:'red',}} onClick={()=>deleteField(ff.id)}>
                        <img width={12} src={deleteIcon}/>
                    </div>
                    <div style={{borderRadius:'50%', margin:5, display:'inline-flex',padding:4, background:'black',}} onClick={()=>moveField({newPosition:ff.position-1, field: ff})} >
                        <img width={12} src={moveUpIcon}/>
                    </div>
                    <div style={{borderRadius:'50%', margin:0, display:'inline-flex',padding:4, background:'black',}} onClick={()=>moveField({newPosition:ff.position+1, field: ff})} >
                        <img width={12} src={moveDownIcon}/>
                    </div>
                </div>}
                {/*** TODO: Move outside the loop, do same for stages */}
                {/* {isEditModeOn && <div style={{display:'flex', cursor:'pointer', color:'#ff7f10', alignItems:'center', padding:10, flexGrow:1, justifyContent:'center', fontSize:14}}>
               
                <img width={20} src={addIcon}/><span onClick={()=>showAddFieldModal()}>Add Field</span>
                </div>} */}
            </div>
            )}

                {/* Consider moving this to the Object/Child List component */}
       
      

                <FormItemSelectorPopup showAddFieldPrompt={showAddFieldPrompt}
    hideAddFieldPrompt={()=>setShowAddFieldPrompt(false)}
    fieldItemInfo={newField}
    formFields={formFields}
    setFormFields={setFormFields}
    setIsEditingField={setIsEditingField}
    isEditingField={isEditingField}/>
    

        </div>
    </>)
}

export default OrderFormEditor;