import React, { useEffect, useState } from "react";
import LayoutWrapper from "../layout/LayoutWrapper";
import backgroundImage from '../assets/images/background_01.svg';
import { getEntities } from "../api/restUtilities";
import { BaseURL } from "../constants/URLs";
import ViewWrapper from "./ViewWrapper";
import DataWidget from "./Statistics/Widgets/DataWidget";
import './Statistics/Widgets/dataWidget.css';
import ChartWidget from "./Statistics/Widgets/ChartWidget";



const DashboardView = (props) =>
{
    const [accountStats, setAccountStats] = useState({})

    const refreshData = () =>
    {
        getEntities({url:BaseURL + `api/v1/AccountStatistics/GetStatistics`, successCallback:(d)=> setAccountStats({...d})});
      
    }
  
    useEffect(()=>{

       refreshData();
        
    },[])

    useEffect(()=>{

        console.log(accountStats)
         
     },[accountStats])

    return(<ViewWrapper title={'Dashboard'}>
        <div className="statistics-summary-info-banner">
            <div style={{display:'flex', flexDirection:'column', fontSize:20}}>
                <span style={{fontSize:12, color:'#454545'}}>Pending Orders</span>
                <span style={{paddingTop:10}}>NGN {accountStats?.totalInflowInProgress?.toLocaleString('en-US') || 0}</span>
            </div>
            <div style={{display:'flex', flexDirection:'column', fontSize:20}}>
                <span style={{fontSize:12, color:'#454545'}}>Completed Orders</span>
                <span style={{paddingTop:10}}>NGN {accountStats?.totalInflowCompleted?.toLocaleString('en-US') || 0}</span>
            </div>
            
        </div>
{/*         
<ChartWidget /> */}

            <div style={{padding:10}} className="data-widget-container">
           
        <DataWidget caption={'Orders'} data={accountStats?.ordersCount} label={'Total'}/>
        <DataWidget caption={'Orders'} data={accountStats?.pendingOrders} label={'Pending'}/>
        <DataWidget caption={'Orders'} data={accountStats?.closedOrders} label={'Closed'}/>
        <DataWidget caption={'Orders'} data={accountStats?.cancelledOrders} label={'Cancelled'}/>
        <DataWidget caption={'Accounts'} data={accountStats?.subAccountsCount} label={'Team Members'}/>
        <DataWidget caption={'Accounts'} data={accountStats?.subAccountsCount} label={'Customers'}/>
        <DataWidget caption={'Accounts'} data={accountStats?.subAccountsCount} label={'Registered Customers'}/>
        <DataWidget caption={'Inventory'} data={accountStats?.productsInventoryCount} label={'Products'}/>
        <DataWidget caption={'Inventory'} data={accountStats?.servicesInventoryCount} label={'Services'}/>

        
            </div>
    </ViewWrapper>
       
    )
}

export default LayoutWrapper(DashboardView, {showNavigationBar:true, showSideMenu:true,backgroundImage}); 