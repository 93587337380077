import React, {memo, useState, useEffect} from 'react';
import { useSelector, useDispatch} from 'react-redux';
//import OrderItemsListView from '../Invoice/OrderItemsListView';
import PopUp from '../PopUp/PopUp';
import SearchListView from '../SearchListView/SearchListView';
import ToggleSelect from '../ToggleSelect/ToggleSelect';
import { RenderControl, ShortText } from './FormControls';
import { BaseURL } from '../../constants/URLs';
//import OrderItemsListView from '../Invoice/OrderItemsListView';
import { getEntities } from '../../api/restUtilities';
import {v4 as uuidv4} from 'uuid';
import defaultIcon  from '../../assets/images/default-inventory-icon.svg';
import {Buffer} from 'buffer';
import axios from 'axios';
import ImageBox from '../ImageBox/ImageBox';
import NumberField from '../NumberField/NumberField';
import { useCallback } from 'react';


const OrderItemsListView = memo((props) => {
    const currentUser  = useSelector(state => state.user);
    //const inventory = useSelector(state => state.inventory) 
    const [showAddItemPopup, setShowAddItemPopup] = useState(false)
    const [selectedItems, setSelectedItems] = useState([])
    const [items, setItems] = useState(props.items ? [...props.items] : [])
    const [tax, setTax] = useState(props.tax ? props.tax : 0)
    const [discount, setDiscount] = useState(props.discount ? props.discount : 0)
    const [images, setImages] = useState({});

    useEffect(()=>{
        //setItems(props.items)
        console.log("list items", props.items)
    },[props.items])

    const handleItemSelectionChange = (item) =>{
        setSelectedItems(s => s.some(si => si.inventoryItemId === item.id) ? [...s.filter(si => si.inventoryItemId != item.id)] : [...s,{...item,id:uuidv4(),  inventoryItemId:item.id, name:item.name, unitCost: item.sellingPrice, quantity:0, availableQuantity: item.quantity}])
    }

    const addItem = () => {
        setItems(i => [...i, ...selectedItems]);
        setSelectedItems([])
    }

    const removeItem = (id) => {
        //setItems(itemList =>  [
        //    ...itemList.filter(i => i.id !== id)] )
        let itemList = [...props.items.filter(i => i.id !== id)] 
            props.onItemChange && props.onItemChange(itemList)
    }

    const updateItemQuantity = (e) => {
        let {name, value} = e;

        // name is the 'id' of the item

        setItems(itemList =>  [
            ...itemList.map(
                (m, i) => m.id === name ? {...m, quantity:parseFloat(value) || 0}
                                        : m
            )                  
             ] )


    }

    const showItemPopup = () =>{
        setSelectedItems([])
        setShowAddItemPopup(true)
    }

    const getImage = useCallback((key, type) => {
       
        console.log("Loading Image pre", {key, k:images[key]})
        var stringifiedData = '';
        if(images[key] === undefined)//!images[key])
        {
            console.log("Loading Image", key)
            setImages( i => ({...i, [key]:null}))// or default
            //responseType:"arraybuffer"}
            axios.get(key, {responseType:"arraybuffer"}).then(d => {
                let bdata = Buffer.from(d.data, "binary").toString('base64')//Buffer.from(d.data,'base64');
                //let bdata = btoa(data)
                console.log(bdata);
                stringifiedData = `data:${type};charset=utf-8;base64, ${bdata}`
                setImages( i => ({...i, [key]:stringifiedData}))
            })
        }
        else
        {
            stringifiedData = images[key]
        }
       
        //let d = Buffer.from(r.data).toString('base64');
        //console.log(d)
        //setImages( i = ({...i, [key]:d}))
        return stringifiedData;//`data:image/png;base64, ${d}`;
    }, [props.items])

    useEffect(()=>{


        
        //props.onItemChange && props.onItemChange(items)

        console.log("Loading Image pre items", {items})

        //items?.images?.map(a =>a.fileClass == 'IMAGE' && getImage(BaseURL + `api/v1/Inventory/GetFileByKey?key=${a.link}`, a.fileType));
        props.items?.map(a =>a.defaultImageFile && a.defaultImageFile.fileClass == 'IMAGE' && getImage(BaseURL + `api/v1/Inventory/GetFileByKey?key=${a.defaultImageFile.link}`, a.defaultImageFile.fileType));
    

    }, [props.items])

    const renderComponent = ({data}) => {
       
        if(data?.defaultImageFile)
        {
            //getImage(BaseURL + `api/v1/Inventory/GetFileByKey?key=${data.defaultImageFile?.link}`, data.defaultImageFile?.fileType )
            console.log("calling get Image")
        } 
        return (<div className='item-card' onClick={e => handleItemSelectionChange(data)} style={{margin:0, height:120, height:'auto', boxShadow:'2px 2px 11px -2px rgb(20 20 20 / 20%)', border:selectedItems.some(s =>s.inventoryItemId === data?.id) ? '2px solid orange' :0,  marginBottom:10, alignItems:'center', width:'100%'}}>
        <div style={{padding:0, display:'flex', width:'100%', justifyContent:'left', alignItems:'center', fontSize:20}}>
        <div style={{padding:0, display:'flex', flexDirection:'column', width:'110px', justifyContent:'left', alignItems:'center', fontSize:20}}>
        <ImageBox  src={data.defaultImageFile ? images[BaseURL + `api/v1/Inventory/GetFileByKey?key=${data.defaultImageFile.link}`]: defaultIcon}  style={{height:'auto', width:100, background:'none', borderRadius:5,   margin:5, marginRight:10}} alt="logo" /> 
                       
            <button  className='app-button-secondary-small'  type="checkbox" >Select</button>
          {/*  <div style={{background:'aqua', padding:15, width:20, height:20, fontSize:13, margin:10}} className="user-info-avartar">
               {data.firstName?.toUpperCase()?.charAt(0)}                    
           </div> */}</div>
           {/*  <img  src={defaultIcon} style={{height:50, background:'none', borderRadius:5,   margin:5, marginRight:20}} alt="logo" />  */}
           <div style={{padding:15, display:'flex', width:'100%', justifyContent:'left', alignItems:'center', fontSize:20}}>
                       
                       <div style={{display:'flex', flexGrow:1, flexDirection:'column'}}>

                           <div style={{display:'flex'}}>{data.title}</div>
                           <div style={{display:'flex', color:'#939090', fontSize:11}}>{data.description}</div>
                           <div style={{display:'flex', color:'#888888', paddingTop:5, fontSize:16}}>{`${data.availableQuantity} Available`}</div>
                           <div style={{display:'flex', color:'green', paddingTop:5, fontSize:16}}>{/* `\u20A6` */}{`NGN ${data.unitPrice} `}</div>
                           
                       
                       </div>
                  
                   </div>
      
       </div>
   </div>)
      }

    return(<>
        <table className='invoice-table' style={{width:'100%', minWidth:400}} cellSpacing={0} cellPadding={5}>
            <thead>
            <tr style={{padding:5, borderBottom:'1px solid #565656'}}>
                        <th>SN</th>
                        <th></th>
                        <th>Item</th>
                        <th>Unit Cost</th>
                        <th>Quantity</th>
                        <th>Total</th>
                        <th></th>
                    </tr>
            </thead>
            <tbody>
                    
                {props.items.map((ff, index) => 
                
                                                          
                    <tr >
                        <td>{index+1}</td>
                        <td style={{width:100}}>
                        {/* <ImageBox  src={ff.defaultImageFile ? getImage(BaseURL + `api/v1/Inventory/GetFileByKey?key=${ff.defaultImageFile.link}`, ff.defaultImageFile.fileType): defaultIcon} style={{height:'auto', width:80, background:ff.type === 'Service'? '#d6f7ef' :'white', borderRadius:5, padding:5,  margin:5, marginRight:20}} alt={ff.title} /> 
                         */}   
                        <ImageBox  src={ff.defaultImageFile ? images[BaseURL + `api/v1/Inventory/GetFileByKey?key=${ff.defaultImageFile.link}`]: defaultIcon} style={{height:'auto', width:80, background:ff.type === 'Service'? '#d6f7ef' :'white', borderRadius:5, padding:5,  margin:5, marginRight:20}} alt={ff.title} /> 
                        
                        </td>
                        <td>{ff.title} <span style={{margin:5, borderRadius:20, padding:'3px 16px', background:'grey', color:'white', fontSize:9}}>{ff.availableQuantity}</span></td>
                        <td>{ff.unitPrice}</td>
                        {/* <td>{ff.quantity}</td>  */} 
                        <td>
                        {props.isEditable ?  
                        <NumberField name={ff.id} minValue={0}  onChange={updateItemQuantity} key={ff.quantity} value={ff.quantity} />
                         : ff.quantity}
                        </td>                     
                        <td>{ff.quantity * ff.unitPrice}</td>
                        <td>
                            <div className='row-delete-button' onClick={()=>removeItem(ff.id)}>
                            <svg height="14" width="14" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-19bqh2r"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
                            </div>
                        </td>
                    </tr>
                  
                  

                )}
                </tbody>
                <tfoot>
                    
                {props.isEditable && <tr style={{backgroundColor:'white'}}>                        
                        <td colSpan={7} align='center'>
                            <div style={{display:'flex', justifyContent:'center'}}>
                                <button onClick={()=>showItemPopup()} style={{padding:'10px 20px', fontSize:14}} className='app-button-secondary-small'>+ Add Item</button>                     
                            </div>
                        </td> 
                    </tr>}
                   
                    
                    <tr>                        
                        <td style={{textAlign:'right'}}  colSpan={5}>Total</td>                      
                        <td style={{fontSize:20}}>{props.items.reduce((p,c)=> p + (c.quantity*c.unitPrice), 0) * (100.0-discount+tax)/100.0}</td>
                        <td></td>
                    </tr>
                    <tr style={{background:"none"}} >
                        <td style={{height:55, background:'none', padding:0}} colSpan={7}>
                        <div className="zigzag"></div>
                        </td>

                    </tr>
                    </tfoot>
                </table>
                

                {props.isEditable && <PopUp isEmbedded={true} open={showAddItemPopup} >
        <div style={{width:'100%', height:'100%', display:'flex', flexDirection:'column',
        padding:0,
        borderRadius:10, 
        overflow:'hidden',
        boxShadow:'2px 2px 5px rgba(10,10,10,0.7)', minHeight:50, background:'white'}}>
            <div style={{padding:15, display:'flex', justifyContent:'center', alignItems:'center', fontWeight:'normal', fontSize:16}}>
           {/*  <img  src={defaultIcon} style={{height:50, background:'#ecf5ef', borderRadius:5,   margin:2, marginRight:20}} alt="logo" />  */}
            <div style={{display:'flex', color:'#444444', padding:0, flexDirection:'column'}}>
            {/** An async call should be made to fetch inventory */}
            
              

            <SearchListView renderComponent={renderComponent} dataUrl={BaseURL + `api/v1/Inventory/GetInventories?accountId=${currentUser?.accountId}`}/>
            
            </div>
            
            </div>
           
                 
            <div style={{width:'100%', 
        padding:10,
         
        background:'#EEEEEE', display:'flex', justifyContent:'space-between'}}>
                <button className='app-button-primary' onClick={()=>{addItem();setShowAddItemPopup(false)}} >Add</button>
                <button className='app-button-primary' onClick={()=>setShowAddItemPopup(false)} >Close</button>
            </div>            
        </div>
    </PopUp>}

    </>)
})

export const OrderForm = ({formFields, stageInfo, orderData, orderDataSet, isEditable = true, formData, orderId, isOrderPlacementForm, items, stageCallbackHandler}) => {

    const defaultOrderFields = isOrderPlacementForm ? [
        {
            name:'customerName',
            type:'phone-number',
            caption:"Customer Name",
            id:"12233-112233-aaaa",
            isDefaultOrderField:true,
            position: 1,
            type: "short-text",
        },
        {
            name:'customerEmail',
            type:'phone-number',
            caption:"Customer Email",
            id:"12233-112233-bbbb",
            isDefaultOrderField:true,
            position: 1,
            type: "short-text",
        },
        {
            name:'customerPhoneNumber',
            type:'phone-number',
            caption:"Customer Phone-number",
            id:"12233-112233-cccc",
            isDefaultOrderField:true,
            position: 1,
            type: "short-text",
        },
        {
            name:'customerAddress',
            type:'phone-number',
            caption:"Customer Address",
            isDefaultOrderField:true,
            id:"12233-112233-dddd",
            position: 1,
            type: "short-text",
        }
    ] : [];


    const dispatch = useDispatch();

    //const orderItems = useSelector(state => state.orderItems.filter(o => o.orderId ==orderId));
    //const stageFormData = useSelector(state => state.stageFormData.filter(o => o.orderId ==orderId));
    const customers = useSelector(state => state.customers)

    

    const [showAddFieldPrompt, setShowAddFieldPrompt] = useState(false);
    const [isPlacingOrder, setIsPlacingOrder] = useState(true)
    const [selectedCustomer, setSelectedCustomer] = useState(null)
    const [showSelectCustomerPopup, setShowSelectCustomerPopup] = useState(false)
    const [fieldDetails, setFieldDetails] = useState(formFields ? [...defaultOrderFields,...formFields] : [...defaultOrderFields]);
    const [stageFormData, setStageFormData] = useState(orderDataSet && Array.isArray(orderDataSet) ? [...orderDataSet] : []);
    const [orderItems, setOrderItems] = useState(orderData?.invoice && Array.isArray(orderData?.invoice?.invoiceItems)? [...orderData.invoice.invoiceItems] : []);

    useEffect(()=>{
        console.log("Items 333", items)
        if(!isEditable)
        {
            setOrderItems(items)
        }
    },[items])
    useEffect(()=>{
        // Get form data

        console.log("The entered data", formFields)
        //setFieldDetails(formFields)
        //stageCallbackHandler && stageCallbackHandler("updateFormData", formFields)
    },[formFields])
    
    useEffect(()=>{
        // Get form data

        console.log("The entered data", stageFormData)
        stageCallbackHandler && stageCallbackHandler("updateFormData", stageFormData)
    },[stageFormData])

    useEffect(()=>{
        console.log("stage info", stageInfo)
        getEntities({
            url:`${BaseURL}api/v1/ordercatalogue/getorderstage?id=${stageInfo?.orderStageId}&catalogueId=${orderData?.catalogueId}`, 
            successCallback:(d)=>{
                console.log("stage data", d);
                setFieldDetails(d.dataForm.items)
            
            }
            });

            getEntities({
                url:BaseURL + 'api/v1/order/GetOrderStageData?orderId=' + orderData?.id + '&stageId=' + stageInfo?.orderStageId, 
                successCallback:(od)=>{
                    //setOrder(d);

                    setStageFormData(od.dataSet.data)
                    
                    console.log("CURR 2",{od})
                }});
    },[stageInfo,orderData])

    //const [orderData, setOrderData] = useState(formData)

    useEffect(()=>{
        console.log("setting formfields",formFields);
        setFieldDetails(fd => [...fd.map(f => stageFormData.some(s => s.id === f.id) ? ({...stageFormData.find(s => s.id === f.id)}) : ({...f}))])
    },[])

    
    const onFieldChangeHandler = ({name, value}) => {

      
        /*setFieldDetails(fd => fd.some(m => m.formItemName === name) ? [...fd.map(
            (m, i) => m.formItemName === name? {...m, value, formItemName:name, orderId}
                                    : m)], [...fd, {}]);*/
        
        // get form schema
        let fieldDetail = fieldDetails.find(f => f.name === name)
        console.log("ENTERED", {name, value, fieldDetail})
        if(fieldDetail)
        setStageFormData(fd => fd.some(m => m.formItemName === name) ? [...fd.map(
            (m, i) => m.formItemName === name? {...m, value, formItemName:name, orderId}
                                    : m)]: [...fd, {...fieldDetail, formItemType:fieldDetail.itemType, formItemName:name, value}])
 
                                    //setFieldDetails()  
                                    
     }

     const [customer] = useState(orderData?.customerInfo ? {...orderData?.customerInfo} : {})
    const [order] = useState(orderData? {...orderData} : {})
    //const [customer, setInvoiceItems] = useState(orderData?.invoice ? [])


    const user = useSelector(state => state.user);

    const accountConfiguration = useSelector(state => state.accountConfiguration);

    console.log("accountConfiguration", accountConfiguration)

    const handleItemChange = (itms) => {
        //stageCallbackHandler("updateItems", [...itms])
        //setOrderItems([...itms]);
        //itms.map(itm => dispatch({type:'SET_ORDER_ITEM', data:{...itm,orderId}}));

        setOrderItems(itms);

        //console.log("invoice items", itms)
        /* <span>{order?.customerInfo?.firstName}</span>
                    <span>{order?.customerInfo?.email}</span>
                    <span>{order?.customerInfo?.address}</span>
                    <span>{order?.customerInfo?.phoneNumber}</span>
                    */
        let invoiceData = {
            
                "invoiceNumber": orderData?.orderNumber,
                "title": orderData?.orderNumber,
                "description": "string",
                "payTo": {
                    bankName: accountConfiguration?.bankName,
                    bankAccountName: accountConfiguration?.bankAccountName,                
                    bankAccountNumber: accountConfiguration?.bankAccountNumber,
                    currency:'NGN'
                },
                
                "billTo": orderData && orderData?.customerInfo ? {...orderData?.customerInfo} : null,
                "logoData": accountConfiguration?.businessLogo,
                "merchantSignature":"",
                "customerSignature":"",
                "layout": "1",
                "theme":{
                    "layout":'BASIC_LAYOUT_1',
                    "color1":'orange',
                    "color2":'lightOrange',
                    "color3":'black',
                    "color4":'yellow',
                },
                "invoiceReminderDuration":24,
                "logoData": accountConfiguration?.businessLogo,
            "layout": "1",
            invoiceItems:[...itms]
        }

        stageCallbackHandler && stageCallbackHandler("updateInvoiceItems",invoiceData)// [...itms])
       
    }

    useEffect(()=>{     
        
        console.log("field details changed",fieldDetails)
        //stageCallbackHandler("updatefieldDetails", [...fieldDetails])
        fieldDetails.map(itm => {
            
            //dispatch({type:'SET_STAGE_FORM_DATA', data:itm})

            //if(itm.isDefaultOrderField)
            //{
             //   stageCallbackHandler("updateOrderInfo", {field:[itm.name], value:itm.value})
            //}
            
        })

        stageCallbackHandler && stageCallbackHandler("updatefieldDetails", fieldDetails)
   

    },[fieldDetails])

    /*const getData=(fieldDataInfo, fieldInfo)=>
    {
        console.log("Field data",{fieldDataInfo, fieldInfo})

        let data = fieldDataInfo.find(fd => fd.name === fieldInfo.name)

        return data ? data.value : '';
    }*/

    const handleItemSelectionChange = () => 
    {
        //console.log(selectedCustomer)
        onFieldChangeHandler({name:'customerName', value:selectedCustomer.name})
        onFieldChangeHandler({name:'customerEmail', value:selectedCustomer.email})
        onFieldChangeHandler({name:'customerPhoneNumber', value:selectedCustomer.phoneNumber})
    }

    const renderComponent = ({data}) => {
       
        return (<div className='item-card' style={{margin:0, height:120, boxShadow:'2px 2px 11px -2px rgb(20 20 20 / 20%)', border:selectedCustomer?.id === data?.id ? '2px solid orange' :0,  marginBottom:10, alignItems:'center', width:'100%'}}>
        <div style={{padding:15, display:'flex', width:'100%', justifyContent:'left', alignItems:'center', fontSize:20}}>
           
            <button  className='app-button-secondary-small' onClick={e => setSelectedCustomer(data)} type="checkbox" >Select</button>
           <div style={{background:'aqua', padding:15, width:20, height:20, fontSize:13, margin:10}} className="user-info-avartar">
               {data.firstName?.toUpperCase()?.charAt(0)}                    
           </div>
           {/*  <img  src={defaultIcon} style={{height:50, background:'none', borderRadius:5,   margin:5, marginRight:20}} alt="logo" />  */}
           <div style={{display:'flex', flexGrow:1, flexDirection:'column'}}>

           <div style={{display:'flex'}}>{`${data.firstName} ${data.lastName}`}</div>
           <div style={{display:'flex', color:'#888888', fontSize:14}}>{data.email}</div>
           <div style={{display:'flex', color:'#888888', fontSize:14}}>{data.phoneNumber}</div>
           <div style={{display:'flex', color:'#888888', fontSize:14}}>{data.address}</div>          
       
       </div>
      
       </div>
   </div>)
      }

    return(<>
    <div style={{padding:10,}}>Cart</div>
    {isEditable && <div style={{display:'flex', fontSize:12, alignItems:'center'}}><span style={{color: isPlacingOrder ? 'black' : '#CCCCCC'}}>Edit Mode</span>
    <ToggleSelect onChange={(s)=>setIsPlacingOrder(s)} isTrue={isPlacingOrder}></ToggleSelect><span  style={{color: isPlacingOrder ? '#CCCCCC' : 'black'}}>Preview Mode</span>
    </div>}
    {/** this button is only possible if the user is the merchant : alternatively make the name and email searchable */}
        {/* <div style={{width:'100%', padding:10}}>
        <button className='app-button-primary'  onClick={()=>setShowSelectCustomerPopup(true)} >Select existing Customer</button>
        </div> */}
        <div  style={{display:'flex', padding:20, width:'98%', alignItems:'center', 
        //borderRadius:10, 
        //boxShadow:'2px 2px 20px -3px rgba(30,30,30,0.1)',  
        flexDirection:'column'}}>
             <div style={{display: 'inline-grid', width:'100%',
            gridTemplateColumns: 'repeat(auto-fill, minmax(220px, 1fr))',}}>
            {[...fieldDetails].map((ff, index) => 
           
              
                
                RenderControl({fieldInfo:ff,dataSet:stageFormData,onChangeHandler: onFieldChangeHandler} )
                //console.log("FF",ff)
                
                
            
            )}</div>
            <OrderItemsListView items={orderItems} onItemChange={handleItemChange} isEditable={isPlacingOrder && isEditable}/> 

            {/* <div style={{display:'flex', justifyContent:'flex-end'}}>
            <button className='app-button-primary' >Save</button>
            </div> */}


        </div>

        {<PopUp styles={{borderRadius:9}} isEmbedded={true} open={showSelectCustomerPopup} >
        <div style={{width:'100%', height:'100%', display:'flex', flexDirection:'column',
        padding:0,
        borderRadius:10, 
        overflow:'hidden',
        boxShadow:'2px 2px 5px rgba(10,10,10,0.7)', minHeight:50, background:'white'}}>
            <div style={{padding:0, display:'flex', justifyContent:'center', alignItems:'center', fontWeight:'normal', fontSize:16}}>
           {/*  <img  src={defaultIcon} style={{height:50, background:'#ecf5ef', borderRadius:5,   margin:2, marginRight:20}} alt="logo" />  */}
            <div style={{display:'flex', color:'#444444', padding:20, flexDirection:'column'}}>
            {/** An async call should be made to fetch inventory */}
            {/* 
                <div style={{display:'flex'}}><input></input><button>Search</button></div>
            
            {customers.map(r => <div style={{display:'flex', border:selectedCustomer?.id === r.id ? '1px solid orange' :0}}>
                <button  onClick={e => setSelectedCustomer(r)} type="checkbox" >Select</button>
                <div style={{display:'flex', flexDirection:'column'}}>
                <span>{r.name}</span>
                <span>{r.email}</span>
                <span>{r.phoneNumber}</span>
                </div>
            </div>)} */}

            <SearchListView renderComponent={renderComponent} dataUrl={BaseURL + `api/v1/customer/getcustomers`}/>
            
            </div>
            
            </div>
           
                 
            <div style={{width:'100%', 
        padding:10,
         
        background:'#EEEEEE', display:'flex', justifyContent:'space-between'}}>
                <button className='app-button-primary' onClick={()=>{handleItemSelectionChange();setShowSelectCustomerPopup(false)}} >Add</button>
                <button className='app-button-primary' onClick={()=>setShowSelectCustomerPopup(false)} >Close</button>
            </div>            
        </div>
    </PopUp>}
    </>)
}

export default OrderForm;