import React, {useEffect, useState} from 'react';

const CatalogueDetailsForm = (props) => {

    //const [Catalogue]

    const handleChange = (e) => {
        const {value, name} = e.target;

        props.stageCallbackHandler && props.stageCallbackHandler("updateOrderDetails", {value, name})
    }

    return(
        <div style={{padding:25, display:'flex', alignItems:'center', flexDirection:'column', justifyContent:'center', minWidth:320, width:'100%'}}>
            <div className='input-field'>
                <label className='form-label'>Title</label>
                <input name={'title'} onChange={handleChange} value={props.orderCatalogue.title} className='form-text-input'/>
            </div>
            <div className='input-field'>
                <label className='form-label'>Description</label>
                <textarea  name={'description'} onChange={handleChange} value={props.orderCatalogue.description} rows={5} className='form-text-input'/>
            </div>                
        </div>
        
    )
}

export default CatalogueDetailsForm;