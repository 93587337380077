import React from 'react';

class ErrorBoundary  extends React.Component {
  state = {
    errorMessage: '',
  };

  static getDerivedStateFromError(error) {
    return { errorMessage: error.toString() };
  }

  componentDidCatch(error, info) {
    this.logErrorToServices(error.toString(), info.componentStack);
  }

  // A fake logging service.
  logErrorToServices = console.log;

  render() {
    if (this.state.errorMessage) {
      /* return <p>{this.state.errorMessage}</p>; */
      return <div style={{ width:'100vw', height:'100vh', background:'#efefef', display:'flex', 
      flexDirection:'column',
      justifyContent:'center', alignItems:'center'}}>Opps! <div style={{fontSize:11, color:'grey'}}>Something went wrong. Please refresh the page.</div></div>
    }
    return this.props.children;
  }
}

export default ErrorBoundary;