import React, {useEffect, useState} from 'react';
import {
    Link,
    useNavigate
} from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';
import OrderFormEditor from '../Form/FormEditor';
import PopUp from '../PopUp/PopUp';
import SearchListView from '../SearchListView/SearchListView';
import { BaseURL } from '../../constants/URLs';
import TabbedView from '../TabbedView/TabbedView';

const ReviewersListView = ({stageCallbackHandler, stageReviewers}) => {

    console.log("--stageReviewers-", stageReviewers)
    const [reviewers, setReviewers] = useState(stageReviewers ? [...stageReviewers] : [])
    const confirmedReviewers = useSelector(state => state.reviewers);
    const [showAddReviewerPrompt, setShowAddReviewerPrompt] = useState(false);
    const [selectedReviewers, setSelectedReviewers] = useState([])

    const updateReviewers = () => {
        console.log("1--stageReviewers-", reviewers)
        stageCallbackHandler("updateStageReviewers",[...reviewers])
    }

    const renderComponent = ({data}) => {

        /* return (    
            <div style={{display:'flex', justifyContent:'left', alignItems:'center'}}>
            <input style={{margin:10}} value={data.id} onChange={e => handleReviewerChange(e)} type="checkbox" />
            <div style={{display:'flex', flexDirection:'column'}}>
            <span>{data.name}</span>
            <span  style={{fontWeight:'normal', fontSize:11}}>{data.email}</span>
            </div>
        </div>   
        )    
 */
        return (<div className='item-card' style={{margin:0, height:120, boxShadow:'2px 2px 11px -2px rgb(20 20 20 / 20%)',  marginBottom:10, alignItems:'center', width:'100%'}}>
        <div style={{padding:15, display:'flex', width:'100%', justifyContent:'left', alignItems:'center', fontSize:20}}>
           
           <input style={{margin:10}} value={data.id} onChange={e => handleReviewerChange(e,data)} type="checkbox" />
           <div style={{background:'aqua', padding:15, width:20, height:20, fontSize:13, margin:10}} className="user-info-avartar">
               {data.firstName?.toUpperCase()?.charAt(0)}                    
           </div>
           {/*  <img  src={defaultIcon} style={{height:50, background:'none', borderRadius:5,   margin:5, marginRight:20}} alt="logo" />  */}
           <div style={{display:'flex', flexGrow:1, flexDirection:'column'}}>

           <div style={{display:'flex'}}>{`${data.firstName} ${data.lastName}`}</div>
           <div style={{display:'flex', color:'#888888', fontSize:14}}>{data.email}</div>
           <div style={{display:'flex', color:'#888888', fontSize:14}}>{data.phoneNumber}</div>
           <div style={{display:'flex', color:'#888888', fontSize:14}}>{data.address}</div>          
       
       </div>
      
       </div>
   </div>)
      }
    

    useEffect(()=>{
       // updateReviewers();
    },[reviewers])

    const handleReviewerChange = (e, data) => {
        const {name, value, checked} = e.target;

        if(checked)
        {
            let reviewer = confirmedReviewers.find(r => r.id === value)
            setReviewers(r => [...r,{...data}])
        }
        else
        {          

            setReviewers(r => [...r.filter(fr => fr.id !== data.id)])
        }

    }
    return(<>
    <div>Reviewers</div>
        <button className='app-button-secondary-small' style={{margin:'10px 0px'}} onClick={()=>setShowAddReviewerPrompt(true)}>Add Reviewer</button>
        <div>
            {reviewers?.map(rvwr => 
            <div className='form-control-list-item'>
            <div style={{display:'flex', flexDirection:'column'}}>
                <span>{`${rvwr.firstName} ${rvwr.lastName}`}</span>
                <span style={{fontWeight:'normal', fontSize:11}}>{rvwr.email}</span>
            </div>
            <div className='app-button-secondary-small' onClick={() => setReviewers(r => [...r.filter(fr => fr.id !== rvwr.id)])}>Remove</div>
            </div>
            )}
        </div>
    {<PopUp styles={{borderRadius:9}} isEmbedded={true} open={showAddReviewerPrompt} >
        <div style={{width:'100%', height:'100%', display:'flex', flexDirection:'column',
        padding:0,
        borderRadius:10, 
        overflow:'hidden',
        boxShadow:'2px 2px 5px rgba(10,10,10,0.7)', minHeight:50, background:'white'}}>
            <div style={{padding:0, display:'flex', justifyContent:'center', alignItems:'center', fontWeight:'normal', fontSize:16}}>
           {/*  <img  src={defaultIcon} style={{height:50, background:'#ecf5ef', borderRadius:5,   margin:2, marginRight:20}} alt="logo" />  */}
            <div style={{display:'flex', color:'#444444', minHeight:'40vh', overflowY:'auto', padding:20, flexDirection:'column', width:'100%'}}>

           {/*  {confirmedReviewers.map(r => <div style={{display:'flex', justifyContent:'left', alignItems:'center'}}>
                <input style={{margin:10}} value={r.id} onChange={e => handleReviewerChange(e)} type="checkbox" />
                <div style={{display:'flex', flexDirection:'column'}}>
                <span>{r.name}</span>
                <span  style={{fontWeight:'normal', fontSize:11}}>{r.email}</span>
                </div>
            </div>)} */}

            <SearchListView renderComponent={renderComponent} dataUrl={BaseURL + `api/v1/customer/getcustomers`}/>
            
            </div>
            
            </div>
           
                 
            <div style={{width:'100%', 
        padding:10,
         
        background:'#EEEEEE', display:'flex', justifyContent:'space-between'}}>
                <button className='app-button-primary' onClick={()=>{updateReviewers();setShowAddReviewerPrompt(false)}} >Update</button>
                <button className='app-button-primary' onClick={()=>setShowAddReviewerPrompt(false)} >Close</button>
            </div>            
        </div>
    </PopUp>}
    </>
   )
}

const StageEditor = ({stageInfo,stageCallbackHandler}) => {

    const [currentTabIndex, setCurrentTabIndex] = useState(1);
   
    
    const [reviewers, setReviewers] = useState([...stageInfo?.reviewers])

    /*const updateReviewers = () => {
        stageCallbackHandler("updateStageReviewers",[...reviewers])
    }*/

    //console.log("init",stageInfo?.reviewers)

    useEffect(()=>{
        console.log("---", reviewers)
    },[reviewers])

   /*  const handleReviewerChange = (e) => {
        const {name, value, checked} = e.target;

        if(checked)
        {
            let reviewer = confirmedReviewers.find(r => r.id === value)
            setReviewers(r => [...r,{...reviewer}])
        }
        else
        {          

            setReviewers(r => [...r.filter(fr => fr.id !== value)])
        }

    } */

    const onSchemaChange = (schema) => {
        console.log("saved", schema)

        stageCallbackHandler("updateForm", schema)
    }

    return( <>
    <div style={{display:'flex', flexDirection:'column', padding:10, minHeight:300,width:`100%`}}>

    <div style={{display:'flex', padding:'5px 10px'}}>{`Edit Stage: ${stageInfo.title}`}</div>
   {/*  <div style={{display:'flex', }}>
        <button onClick={()=>setCurrentTabIndex(1)}>Stage Details</button>
        <button onClick={()=>setCurrentTabIndex(2)}>Edit Form</button>
        <button onClick={()=>setCurrentTabIndex(3)}>Reviewers</button>
    </div> */}
    <TabbedView tabbedViewCaptions={["Stage Details", "Edit Form", "Reviewers"]} style={{width:'100%'}}>
    <div style={{padding:5, display:'flex', alignItems:'center', flexDirection:'column', /* justifyContent:'center', */ minWidth:320, width:'100%'}}>
            <div className='input-field'>
                <label className='form-label'>Title</label>
                <input
                onChange={e => {stageCallbackHandler("setSelectedStageField", {field:"title", value:e.target.value})}}
                value={stageInfo.title} className='form-text-input'/>
            </div>
            <div className='input-field'>
                <label className='form-label'>Type</label>
                <select 
                onChange={e => {stageCallbackHandler("setSelectedStageField", {field:"type", value:e.target.value})}}
                value={stageInfo.type} 
                className='form-text-input'>
                    <option value={'OrderPlacement'}>Inquiry/Order Placement</option>
                    <option>Payment</option>
                    <option>Invoice</option>
                    <option>Fulfilment</option>
                    <option>Receipt</option>
                    <option>Confirmation</option>
                    <option>Feedback</option>
                </select>
            </div>
            <div className='input-field'>
                <label className='form-label'>Action Duration (Hrs)</label>
                <input 
                onChange={e => {stageCallbackHandler("setSelectedStageField", {field:"duration", value:parseFloat(e.target.value) || 0})}}
                value={stageInfo.duration} className='form-text-input'/>
            </div>
            <div className='input-field'>
                <label className='form-label'>Description</label>
                <textarea
                onChange={e => {stageCallbackHandler("setSelectedStageField", {field:"description", value:e.target.value})}}
                value={stageInfo.description} rows={5} className='form-text-input'/>
            </div>   
            <div className='input-field'>
                <label className='form-label'>Acknowledgement</label>
                <textarea onChange={e => {stageCallbackHandler("setSelectedStageField", {field:"acknowledgement", value:e.target.value})}}
                value={stageInfo.acknowledgement} rows={5} className='form-text-input'/>
            </div>              
    </div>
    <div>
        <OrderFormEditor onSchemaChange={onSchemaChange} formFieldsDef={stageInfo.dataForm?.items}/>
    </div>
    <div>
        <ReviewersListView  stageCallbackHandler={stageCallbackHandler} stageReviewers={stageInfo.reviewers}/>
    </div>
    </TabbedView>

   {/*  {currentTabIndex ===1 &&
    <div style={{ maxHeight:'55vh', overflowY:'auto'}}>
        <div style={{padding:5, display:'flex', alignItems:'center', flexDirection:'column', justifyContent:'center', minWidth:320, width:'100%'}}>
            <div className='input-field'>
                <label className='form-label'>Title</label>
                <input
                onChange={e => {stageCallbackHandler("setSelectedStageField", {field:"title", value:e.target.value})}}
                value={stageInfo.title} className='form-text-input'/>
            </div>
            <div className='input-field'>
                <label className='form-label'>Type</label>
                <select 
                onChange={e => {stageCallbackHandler("setSelectedStageField", {field:"type", value:e.target.value})}}
                value={stageInfo.type} 
                className='form-text-input'>
                    <option value={'OrderPlacement'}>Inquiry/Order Placement</option>
                    <option>Payment</option>
                    <option>Invoice</option>
                    <option>Fulfilment</option>
                    <option>Receipt</option>
                    <option>Confirmation</option>
                    <option>Feedback</option>
                </select>
            </div>
            <div className='input-field'>
                <label className='form-label'>Action Duration (Hrs)</label>
                <input 
                onChange={e => {stageCallbackHandler("setSelectedStageField", {field:"duration", value:parseFloat(e.target.value) || 0})}}
                value={stageInfo.duration} className='form-text-input'/>
            </div>
            <div className='input-field'>
                <label className='form-label'>Description</label>
                <textarea
                onChange={e => {stageCallbackHandler("setSelectedStageField", {field:"description", value:e.target.value})}}
                value={stageInfo.description} rows={5} className='form-text-input'/>
            </div>   
            <div className='input-field'>
                <label className='form-label'>Acknowledgement</label>
                <textarea onChange={e => {stageCallbackHandler("setSelectedStageField", {field:"acknowledgement", value:e.target.value})}}
                value={stageInfo.acknowledgement} rows={5} className='form-text-input'/>
            </div>              
        </div>
    </div>}
    {currentTabIndex === 2 &&
    <div>
        <OrderFormEditor onSchemaChange={onSchemaChange} formFieldsDef={stageInfo.dataForm?.items}/>
    </div>}

    {currentTabIndex === 3 &&
    <div>
        <ReviewersListView  stageCallbackHandler={stageCallbackHandler} stageReviewers={stageInfo.reviewers}/>
    </div>}
 */}
    </div>

    

    </>

    )
}

export default StageEditor;