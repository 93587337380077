import React, {useState, useEffect} from 'react';
import {
    Link,
    useNavigate
} from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';
import LayoutWrapper from '../layout/LayoutWrapper';
import ViewWrapper from './ViewWrapper';
//import { deleteAsset } from '../redux/actions/assetActions';
import Prompt from '../components/Prompt/Prompt';
import defaultIcon  from '../assets/images/default-game-icon.svg';
import ToggleSelect from '../components/ToggleSelect/ToggleSelect';
import ImageFileField from '../components/ImageFileField/ImageFileField';

import { BaseURL } from '../constants/URLs';
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import NotificationManager from '../notification/NotificationManager';
import { putEntities } from '../api/restUtilities';
import SignatureBox from '../components/Form/fieldComponents/SignatureBox';
import { a } from 'react-spring';

const CopyTextBox = (props) => {

    const copyText = () => {
        navigator.clipboard.writeText(props.text);

        NotificationManager.success({
            title:"Success",
            message: "Text copied to clipboard."
        })
    }

    return(
        <div style={{display:'flex',width:'100%',margin:'10px 0px'}}>
            <input className='text-input' style={{border:'1px dotted grey', fontWeight:'bold', margin:0}} disabled={true} value={props.text}/>
            <button className='app-button-primary'  style={{border:'1px dotted grey',borderRadius:0, margin:0}}  onClick={copyText}>Copy</button>
        </div>
    )
}

const CopyTextArea = (props) => {

    const copyText = () => {
        navigator.clipboard.writeText(props.text);

        NotificationManager.success({
            title:"Success",
            message: "Text copied to clipboard."
        })
    }

    return(
        <div style={{display:'flex', width:'100%', margin:'10px 0px'}}>
            <textarea rows={5} className='text-input' style={{border:'1px dotted grey', height:'auto', fontWeight:'bold', margin:0}} disabled={true} value={props.text}/>
            <button className='app-button-primary'  style={{border:'1px dotted grey',borderRadius:0, margin:0}}  onClick={copyText}>Copy</button>
        </div>
    )
}

const getEntities = (url, successCallback, failedCallback) => 
{
    let data = [];
    trackPromise(axios.get(url).then((r)=>
    {
        console.log(r)
        data = r.data;
        successCallback && successCallback(r.data)
    }).catch((e)=>{
        console.log(e)
    }))

    return data;
}

/* const putEntities = (url,data, successCallback, failedCallback) => 
{
    
    trackPromise(axios.put(url,data).then((r)=>
    {
        console.log(r)
        //data = r.data;

        NotificationManager.success({
            title:'Customer Updated',
            'message':'Customer successfuly updated '
        })

        successCallback && successCallback(r)
    }).catch((e)=>{
        console.log(e)

        NotificationManager.success({
            title:'Error',
            'message':'Failed to update inventory'
        })

        failedCallback && failedCallback(e);
    }))

    return data;
} */

const Settings = (props)  => {

    let history = useNavigate();

    
    const dispatch = useDispatch(); 

    //const user = useSelector(state => state.user);
    

    const [openPromptDialog, setOpenPromptDialog] = useState(false);
    const [promptOkAction, setPromptOkAction] = useState(null);

    const [accountConfiguration, setAccountConfiguration] = useState({});
    
    const updateConfigField = (e) => {
        const {name, value} = e.target;
        console.log("Signature", {name, value, accountConfiguration})
        setAccountConfiguration(u => ({...u, [name]:value}));    

    }

    const currentUser  = JSON.parse(localStorage.getItem('currentUser'));

    useEffect(()=>{

        getEntities(BaseURL + 'api/v1/accountconfiguration/getconfiguration?Id=' + currentUser?.accountId, setAccountConfiguration);
      
    },[])

    useEffect(()=>{
        console.log(accountConfiguration)
    },[accountConfiguration])

    const save = () => {
       
        //dispatch({type:"UPDATE_USER_INFO", data:{...accountConfiguration, sessionTime:new Date().toISOString()}});    
        putEntities({url:BaseURL + 'api/v1/accountconfiguration/update', data: {...accountConfiguration},
        successCallback :(d)=>{
            //setShowCreateNewOrderPopup(false);
            //getEntities(BaseURL + 'api/v1/inventory/getinventories?accountId=' + currentUser?.accountId,setInventories);
            //setInventory({})
            dispatch({type:"SET_ACCOUNT_CONFIGURATION", data:{...d}});
            localStorage.setItem('accountConfiguration', JSON.stringify(d));
        },
        successNotification:{title:"Success", message:'Account config updated'},
        errorNotification:{title:"Failed", message:'Account config update failed'},
    }
        )
    }

    const forwardedRef = React.createRef();

    const handlePhotoUploadClick= ()=>
    {
        console.log(forwardedRef);
        forwardedRef.current.click();
    }

    const uploadProfilePicture = (e) =>{
        console.log("files", e);
        setAccountConfiguration(u => ({...u, businessLogo:e[0].base64}))
    }
    /* const deleteAssetRecord = (id) =>
    {
        setOpenPromptDialog(true);
        setPromptOkAction({message: "Do you want to delete the asset?",OkAction:()=>{dispatch(deleteAsset(id));setOpenPromptDialog(false)}});
    } */

    return( <ViewWrapper title={'Settings'}>
   
            <Prompt promptOptions={{...promptOkAction}} OkLabel={"Yes"}  isOpen={openPromptDialog} modalClosed={()=> setOpenPromptDialog(false)}/>


                {/* <div style={{margin:5}}>
                        <div style={{display:'flex', flexDirection:'column', width:'98%', margin:'10px 0px', alignItems:'center', justifyContent:'center'}}>
                        <input  value={searchString} onChange={e=>setSearchString(e.target.value)} placeholder="Search" style={{boxSizing:'border-box', boxShadow:'0px 0px 2px rgba(10,10,10,0.2)', background:"#eeeeee", padding:'2px 10px', outline:'none', height:40, border:'0px solid #CCCCCC', width:'100%', borderRadius:5}}/>
                        </div>      
                </div> */}

                <div style={{margin:5}}>
                <div style={{ width:'100%',display: 'flex', flexDirection:'column',
            gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))', minHeight:'calc(100vh-50px)'}}>
                <div style={{width:'100%', display:'flex', justifyContent:'center'}}>
                {accountConfiguration.businessLogo && <img width={100} style={{padding:5, margin:10, border:'1px dotted orange', }} src={accountConfiguration.businessLogo}/>}
                </div>
                    
                    {<>
                    <div style={{width:'100%', display:'flex', justifyContent:'center'}}>
                    <button className='app-button-primary' onClick={handlePhotoUploadClick}>Change Company Logo</button>
                    
                    </div>
                    <div style={{visibility:'hidden'}}>
                    <ImageFileField style={{fontSize:10}}
                    forwardedRef={forwardedRef}
                    multiple={ true }
                    onDone={ uploadProfilePicture} />
                    </div>  </>}  
                    <div style={{
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'flex-start',
                        margin:10

                    }}>
                        <label className='text-input-label'> Business Page URL</label>
                        <CopyTextBox text={`https://app.odanow.net/#/${accountConfiguration.name}/home/`}/>
                        
                    
                    </div>  
                    <div style={{
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'flex-start',
                        margin:10

                    }}>
                        <label className='text-input-label'> Embed Customer Page</label>
                        <CopyTextArea text={`<iframe src="https://app.odanow.net/#/${accountConfiguration.name}/home/"></iframe>`}/>
                        
                    
                    </div>  
                    <div style={{
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'flex-start',
                        margin:10

                    }}>
                        <label className='text-input-label'>Business Name</label>
                        <input onChange={updateConfigField} value={accountConfiguration.businessName} name="businessName"  className='text-input'/>
                        
                    
                    </div>

                    <div style={{
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'flex-start',
                        margin:10

                    }}>
                        <label className='text-input-label'> Business Email</label>
                        <input onChange={updateConfigField} value={accountConfiguration.businessEmail} name="businessEmail"  className='text-input' />
                        
                    
                    </div>
                    <div style={{
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'flex-start',
                        margin:10

                    }}>
                        <label className='text-input-label'> Business Phone Number</label>
                        <input onChange={updateConfigField} value={accountConfiguration.businessPhoneNumber} name="businessPhoneNumber"  className='text-input' />
                        
                    
                    </div>
                    <div style={{
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'flex-start',
                        margin:10

                    }}>
                        <label className='text-input-label'> Business Address</label>
                        <input onChange={updateConfigField} value={accountConfiguration.businessAddress} name="businessAddress"  className='text-input' />
                        
                    
                    </div>
                    <div style={{
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'flex-start',
                        margin:10

                    }}>
                        <label className='text-input-label'> Business Website</label>
                        <input onChange={updateConfigField} value={accountConfiguration.businessWebsite} name="businessWebsite"  className='text-input' />
                        
                    
                    </div>
                    <div style={{
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'flex-start',
                        margin:10

                    }}>
                        <label className='text-input-label'> Business Bank Name</label>
                        <input onChange={updateConfigField} value={accountConfiguration.bankName} name="bankName"  className='text-input' />
                        
                    
                    </div>
                    <div style={{
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'flex-start',
                        margin:10

                    }}>
                        <label className='text-input-label'> Business Account Name</label>
                        <input onChange={updateConfigField} value={accountConfiguration.bankAccountName} name="bankAccountName"  className='text-input' />
                        
                    
                    </div>
                    <div style={{
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'flex-start',
                        margin:10

                    }}>
                        <label className='text-input-label'> Business Account Number</label>
                        <input onChange={updateConfigField} value={accountConfiguration.bankAccountNumber} name="bankAccountNumber"  className='text-input' />
                        
                    
                    </div>
                    <div style={{
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'flex-start',
                        margin:10

                    }}>
                        <label className='text-input-label'> Signature</label>
                        <input onChange={updateConfigField} value={accountConfiguration.bankAccountNumber} name="bankAccountNumber"  className='text-input' />
                        <SignatureBox isEditable={true} dataChangeHandler={(d)=>setAccountConfiguration(a => ({...a, accountSignature:d.imageData}))} name={'accountSignature'} className={'text-input'} value={accountConfiguration.accountSignature} type="text"/>
                    
                    </div>
                    
                    
                   {/*  <div style={{
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'flex-start',
                        margin:10

                    }}>
                        <label className='text-input-label'>Audio</label>
                        <div  style={{
                        display:'flex',
                        //flexDirection:'column',
                        alignItems:'flex-start',
                        margin:10

                    }}>
                            <span className='text-input-label'>On</span>
                            <ToggleSelect onChange={s=>setAccountConfiguration(u => ({...u,audioOn:s}))} isTrue={accountConfiguration.audioOn}/>
                            <span className='text-input-label'>Off</span>
                        </div>
                        
                    
                    </div>
                    <div style={{
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'flex-start',
                        margin:10

                    }}>
                        <label className='text-input-label'>Volume</label>
                        <div  style={{
                        display:'flex',
                        //flexDirection:'column',
                        alignItems:'flex-start',
                        margin:10

                    }}>
                            <input type={'range'}  onChange={updateConfigField} value={accountConfiguration.audioVolume} name="audioVolume"  min={1} max={10}/>
                        </div>
                        
                    
                    </div> */}
                </div>

                <div style={{display:'flex', justifyContent:'center'}}>
                    <button style={{fontFamily:'Righteous'}} onClick={()=>save()} className='app-button-primary'>SAVE</button>
                </div>
                
                </div>

    </ViewWrapper>)

}   

export default LayoutWrapper(Settings, {showNavigationBar:true, showSideMenu:true});