import React, {useState, useEffect, useRef} from 'react';
import SignatureBox from './fieldComponents/SignatureBox';
import OrderFormEditor, {ChildObjectEditor, FormChildObjectEditor} from './FormEditor';


export const RenderControl = ({fieldInfo, dataSet, onChangeHandler, onSchemaChange, isEditing}) =>
{
    console.log("new entry",{dataSet,fieldInfo})
    let data =dataSet && Array.isArray(dataSet) ? dataSet?.find(ds => ds.formItemName === fieldInfo.name)?.value : [];

    console.log("da change, data", data)
    switch(fieldInfo?.itemType)
    {
        case "shortText" : return <ShortText fieldInfo={fieldInfo} data={data} onChange={onChangeHandler} />
        case "checkBox" : return <CheckBox fieldInfo={fieldInfo} data={data} onChange={onChangeHandler} />
        case "basicList" : return <BasicList fieldInfo={fieldInfo} data={data} onChange={onChangeHandler} />
        case "longText" : return <LongText fieldInfo={fieldInfo}  data={data} onChange={onChangeHandler}/>
        case "signature" : return <Signature fieldInfo={fieldInfo}  data={data} onChange={onChangeHandler}/>
        case "email" : return <ShortText fieldInfo={fieldInfo}  data={data} onChange={onChangeHandler}/>
        case "url" : return <ShortText fieldInfo={fieldInfo}  data={data} onChange={onChangeHandler}/>
        case "phoneNumber" : return <ShortText fieldInfo={fieldInfo}  data={data} onChange={onChangeHandler}/>
        case "options" : return <Options fieldInfo={fieldInfo}  data={data} onChange={onChangeHandler}/>
        case "number" : return <NumberField fieldInfo={fieldInfo}  data={data} onChange={onChangeHandler}/>
        case "currency" : return <ShortText fieldInfo={fieldInfo}  data={data} onChange={onChangeHandler}/>
        case "objectList" : return <div> <span className='form-field-caption'>{fieldInfo.caption}</span><div style={{border:'1px dotted red'}}><FormChildObjectEditor onSchemaChange={onSchemaChange}  listFieldInfo={fieldInfo} saveHandler={(d)=>onChangeHandler && onChangeHandler({name:fieldInfo.name,fieldInfo,  value:d})} name={fieldInfo.name} dataSet={data} formFieldsDef={fieldInfo?.childItems} isParentForm={false} isEditing={isEditing}/></div></div> 
        case "childObject" : return <div> <span className='form-field-caption'>{fieldInfo.caption}</span><div style={{border:'1px dotted red'}}><ChildObjectEditor onSchemaChange={onSchemaChange} objectFieldInfo={fieldInfo} saveHandler={(d)=>onChangeHandler && onChangeHandler({name:fieldInfo.name, fieldInfo, value:d})} name={fieldInfo.name} dataSet={data} formFieldsDef={fieldInfo?.childItems} isParentForm={false} isEditing={isEditing}/></div></div> 
       
        default : <div>No Field</div>
    }
}

export const renderControlConfig = (fieldInfo, onChangeHandler) =>
{    
    switch(fieldInfo.itemType)
    {
        case "shortText" : return <ShortTextConfig fieldInfo={fieldInfo} onChange={onChangeHandler} />
        case "longText" : return <LongText fieldInfo={fieldInfo}  onChange={onChangeHandler}/>
        case "email" : return <ShortTextConfig fieldInfo={fieldInfo} onChange={onChangeHandler}/>
        case "url" : return <ShortTextConfig fieldInfo={fieldInfo}  onChange={onChangeHandler}/>
        case "phoneNumber" : return <ShortText fieldInfo={fieldInfo} onChange={onChangeHandler}/>
        case "options" : return <OptionsFieldConfig fieldInfo={fieldInfo}  onChange={onChangeHandler}/>
        case "basicList" : return <div>Basic Config Options</div>
        case "objectList" : return <div>Object List Config Options</div>
        case "childObject" : return <div>Object Config Options</div>
        case "number" : return <NumberFieldConfig fieldInfo={fieldInfo} onChange={onChangeHandler}/>
        case "currency" : return <ShortTextConfig fieldInfo={fieldInfo} onChange={onChangeHandler}/>
        default : return <ShortTextConfig fieldInfo={fieldInfo} onChange={onChangeHandler}/>
    }
}

const renderConfigField = (configVariable,onChange,fieldInfo) => {
    console.log("FNrenderConfigFieldFNF", fieldInfo)
    switch(configVariable)
    {
        case "isRequired" : return <div>{controlsConfigDefinitions[configVariable].caption} <input name={configVariable} checked={fieldInfo?.config && fieldInfo?.config[configVariable]} onChange={onChange}  type={'checkbox'}/></div>
        case "maxValue" : return <div>{controlsConfigDefinitions[configVariable].caption} <input name={configVariable} value={fieldInfo?.config && fieldInfo?.config[configVariable]} onChange={onChange} /></div>
        case "minValue" : return <div>{controlsConfigDefinitions[configVariable].caption} <input name={configVariable} value={fieldInfo?.config && fieldInfo?.config[configVariable]} onChange={onChange}  /></div>
        case "maxlength" : return <div>{controlsConfigDefinitions[configVariable].caption} <input name={configVariable} value={fieldInfo?.config && fieldInfo?.config[configVariable]} onChange={onChange}  /></div>
        case "minlength" : return <div>{controlsConfigDefinitions[configVariable].caption} <input name={configVariable} value={fieldInfo?.config && fieldInfo?.config[configVariable]} onChange={onChange}  /></div>
        case "maxlength" : return <div>{controlsConfigDefinitions[configVariable].caption} <input name={configVariable} value={fieldInfo?.config && fieldInfo?.config[configVariable]} onChange={onChange}  /></div>
        case "isMultiSelect" : return <div>{controlsConfigDefinitions[configVariable].caption} <input name={configVariable} checked={fieldInfo?.config && fieldInfo?.config[configVariable]} onChange={onChange}  type={'checkbox'} /></div>
        case "options" : return <div>{controlsConfigDefinitions[configVariable].caption} <span>Use ';' as a separator</span> <input name={configVariable} value={fieldInfo?.config && fieldInfo?.config[configVariable]} onChange={onChange}  /></div>
        {/* <div>
            <div>
                <input></input><button>Add</button>
                <div>
                    List
                </div>
            </div>
        </div> */}
        default : return <div>No field</div>
    }
}

/** Field Options */
const fieldConfig =
{
	"shortText": ["isRequired", "maxlength", "minlength"],
	"email": ["isRequired"],
    "url": ["isRequired"],
	"number": ["isRequired", "maxValue", "minValue"],
	"currency": ["isRequired", "maxValue", "minValue"],
	"phoneNumber": ["isRequired"] ,
	"longText": ["isRequired", "maxlength", "minlength"],
	"options": ["isRequired", "isMultiSelect", "options"] 
}

export const controlsConfigDefinitions =
{
"isRequired" : {
	caption: "Is Requried",
	defaultValue: false,
	type:'boolean'
},
"maxlength" : {
	caption: "Max Length",
	defaultValue: 0,
	type:'number'
},
"minValue" : {
	caption: "Min Value",
	defaultValue: 0,
	type:'number'
},
"maxValue" : {
	caption: "Max Value",
	defaultValue: 0,
	type:'number'
},
"minlength" : {
	caption: "Min Length",
	defaultValue: 0,
	type:'number'
},
"isMultiSelect" : {
	caption: "Is Multi-select",
	defaultValue: false,
	type:'boolean'
},
"options" : {
	caption: "Options",
	defaultValue: [],
	type:'list'
}
}

 /** Form Control/Fields */
export const formControlDefinitions = [
    {
        name:'text',
        itemType:'shortText',
        defaultCaption:"Text"
    },
    {
        name:'email',
        itemType:'email',
        defaultCaption:"Email"
    },
    {
        name:'url',
        itemType:'url',
        defaultCaption:"Website"
    },
    {
        name:'Phone Number',
        itemType:'phoneNumber',
        defaultCaption:"Phone Number"
    },
    {
        name:'number',
        itemType:'number',
        defaultCaption:"Number"
    },
    {
        name:'options',
        itemType:'options',
        defaultCaption:"Options"
    },
    {
        name:'objectList',
        itemType:'objectList',
        defaultCaption:"Object List"
    },
    {
        name:'object',
        itemType:'childObject',
        defaultCaption:"Object"
    },
    {
        name:'basicList',
        itemType:'basicList',
        defaultCaption:"Basic List"
    },
    {
        name:'checkBox',
        itemType:'checkBox',
        defaultCaption:"Check Box"
    },
    {
        name:'longText',
        itemType:'longText',
        defaultCaption:"Long Text"
    }
]
export const BasicList = ({fieldInfo, data, config, onChange}) => {

    const [value, setValue] = useState(data);
    const [isEditing, setIsEditing] = useState(false);

    const textareaRef = useRef();

    useEffect(()=>{
        if(textareaRef && textareaRef.current)
        {
            textareaRef.current.onpaste = function(e) {
                var pastedText = undefined;
                if (window.clipboardData && window.clipboardData.getData) { // IE
                  pastedText = window.clipboardData.getData('Text');
                } else if (e.clipboardData && e.clipboardData.getData) {
                  pastedText = e.clipboardData.getData('text/plain');
                }

                let newValue = pastedText.replace(/\n/g,';')
                // setValue(pastedText.replace(/\n/g,';').split(';'))
                //setValue(newValue.split(';'));
                //onChange && onChange({name:fieldInfo.name, value: newValue.split(";"), fieldInfo})
                return false; // Prevent the default handler from running.
              };
        } 
    },[textareaRef])

    const onChangeHandler = (e) => {
        const {name, value} = e.target;
        console.log("change handelr called")
        if(value)
        {
            onChange && onChange({name, value: value.replace(/\n/g,';').split(";"), fieldInfo})
        }
            
    }

    useEffect(()=>{
        console.log("setting Data", {n:fieldInfo.name, data})
        setValue(data === undefined ? '' : data)
    }, [data])

    return <div style={{display:'flex', margin:5, alignItems:'flex-start', flexDirection:'column'}}>
        <span className='form-field-caption'>{fieldInfo.caption}</span>
        {isEditing ? <textarea ref={textareaRef} row={5} onChange={onChangeHandler} name={fieldInfo.name} className={'text-input'} value={Array.isArray(value) ? value?.join(';') : ''} type="text"></textarea>
        :
        <div style={{maxHeight:100, width:'100%', textAlign:'left', overflowY:'auto'}}>
                {value && Array.isArray(value) && value?.map((v, index) => <div style={{background:'white', margin:2, fontSize:12, color:'grey'}}>{`${index+1}. ${v}`}</div>)}
        </div>
        }
        <button onClick={()=>setIsEditing(b => !b)}>{isEditing? "Cancel" : "Edit"}</button>
        </div>
}

export const ShortText = ({fieldInfo, data, config, onChange}) => {

    const [value, setValue] = useState(data);

    const onChangeHandler = (e) => {
        const {name, value} = e.target;
        onChange && onChange({name, value, fieldInfo})
    }

    useEffect(()=>{
        console.log("setting Data", {n:fieldInfo.name, data})
        setValue(data === undefined ? '' : data)
    }, [data])

    return <div style={{display:'flex', margin:5, alignItems:'flex-start', flexDirection:'column'}}>
        <span className='form-field-caption'>{fieldInfo.caption}</span>
        <input onChange={onChangeHandler} name={fieldInfo.name} className={'text-input'} value={value} type="text"></input>
        </div>
}

export const CheckBox = ({fieldInfo, data, config, onChange}) => {

    const [value, setValue] = useState(data);

    const onChangeHandler = (e) => {
        const {name, value:val, checked} = e.target;
        onChange && onChange({name, value:checked, fieldInfo})
    }

    useEffect(()=>{
        console.log("setting Data", {n:fieldInfo.name, data})
        setValue(data === undefined ? '' : data)
    }, [data])

    return <div style={{display:'flex', margin:5, alignItems:'flex-start', flexDirection:'column'}}>
        <span className='form-field-caption'>{fieldInfo.caption}</span>
        <input type={'checkbox'} onChange={onChangeHandler} name={fieldInfo.name} className={'text-input'} value={value} checked={value}></input>
        </div>
}

export const Signature = ({fieldInfo, data, config, onChange}) => {

    const [value, setValue] = useState(data);

    const onChangeHandler = (e) => {
        const {name, value} = e.target;
        onChange && onChange({name, value, fieldInfo})
    }

    useEffect(()=>{
        console.log("setting Data", {n:fieldInfo.name, data})
        setValue(data === undefined ? '' : data)
    }, [data])

    return <div style={{display:'flex', margin:5, alignItems:'flex-start', flexDirection:'column'}}>
        <span className='form-field-caption'>{fieldInfo.caption}</span>
        <SignatureBox onChange={onChangeHandler} name={fieldInfo.name} className={'text-input'} value={value} type="text"/>
        </div>
}

export const LongText = ({fieldInfo, data, config, onChange, rows}) => {

    const onChangeHandler = (e) => {
        const {name, value} = e.target;
        onChange && onChange({name, value})
    }



    return <div style={{display:'flex', margin:10, flexDirection:'column'}}>
        <span>{fieldInfo.caption}</span>
        <textarea rows={rows && 3} onChange={onChangeHandler} name={fieldInfo.name} className={'text-input'} value={data}></textarea>
        </div>
}

export const NumberField = ({fieldInfo, data, config, onChange}) => {

    const [value, setValue] = useState(data ? parseInt(data) : 0);

    useEffect(()=>{
        onChange && onChange({name: fieldInfo.name, value})
    },[value])

    useEffect(()=>{
        console.log("Number",data)
        //setValue(data)
    },[data])

    const onChangeHandler = (e) => {
        const {name, value : v} = e.target;
        console.log("vvv",v)
        //setValue(parseInt(v))
    }

    return <div style={{display:'flex', margin:5, alignItems:'flex-start', flexDirection:'column'}}>
         <span className='form-field-caption'>{fieldInfo.caption}</span>
        <div style={{display:'flex'}}>
        <button style={{width:60, margin:'10px 0px'}} onClick={()=>setValue(v => parseInt(v) - 1)}>
            -
        </button>
        <input style={{textAlign:'center'}} onChange={onChangeHandler} name={fieldInfo.name} className={'text-input'} value={value} type="text"></input>
        <button style={{width:60, margin:'10px 0px'}} onClick={()=>setValue(v => parseInt(v) + 1)}>
            +
        </button>
        </div>
        </div>
}

export const Options = ({fieldInfo, data, options, config, onChange, isMultiSelect, }) => {

    const [value, setValue] = useState(data);

    useEffect(()=>{
        onChange && onChange({name: fieldInfo.name, value})
    },[value])

    useEffect(()=>{
        setValue(data)
    },[])//data

    
    const onChangeHandler = (e) => {
        const {name, value:val, checked} = e.target;

        if(fieldInfo?.config?.isMultiSelect)
        {
            let newData = Array.isArray(value) ? [...value] : []
            if(checked)
            {
                if(!newData.some(v => v === val))
                {
                    newData.push(val)
                }
            }
            else
            {
                newData = newData.filter(d => d != val)
            }
            console.log("NEW Data", {newData,checked, ele:e.target})
            setValue([...newData])
            //onChange && onChange({name, value:[...newData]})
        }
        else
        {
            setValue(val)
            //onChange && onChange({name, val})
        }        
    }

    console.log("UUDD", {value,data})
    return <div style={{display:'flex', margin:10, flexDirection:'column'}}>
        <span>{fieldInfo?.caption}</span>
        { fieldInfo?.config?.options?.split(';').map(o => 
        
        <div>

<input itemType={ fieldInfo?.config?.isMultiSelect ? 'checkbox' : 'radio'} onChange={onChangeHandler} checked={fieldInfo?.config?.isMultiSelect ? value && value?.some(ml => ml === o ) : o === data} name={fieldInfo?.name} value={o}></input>
<span>{o}</span>
        </div>)}
        
        </div>
}



const ShortTextConfig = ({fieldInfo,onChange}) => {

    const config = fieldConfig[fieldInfo.itemType];

    
    return(<>
        {config ? 
        <div>
            {config.map(c => renderConfigField(c,onChange,fieldInfo))}
        </div> : <div>No Configurations</div>}
        </>
    )
}

const OptionsFieldConfig = ({fieldInfo,onChange}) => {

    const config = fieldConfig[fieldInfo.itemType];

    
    return(<>
        {config ? 
        <div>
            {config.map(c => renderConfigField(c,onChange,fieldInfo))}
        </div> : <div>No Configurations</div>}
        </>
    )
}

const NumberFieldConfig = ({fieldInfo, onChange}) => {

    const config = fieldConfig[fieldInfo.itemType];

    
    return(<>
        {config ? 
        <div>
            {config.map(c => renderConfigField(c, onChange,fieldInfo))}
        </div> : <div>No Configurations</div>}
        </>
    )
}

