import React from "react"

const StageActionView = (props) => {

    return(
        <div style={{padding:25, display:'flex', alignItems:'center', flexDirection:'column', justifyContent:'center', minWidth:320, width:'100%'}}>
            
            <div className='input-field'>
                <label className='form-label'>Comment</label>
                <input className='form-text-input'/>
            </div>
            <button className='app-button-primary' >Confirm or Post</button>      
        </div>
    )
}

export default StageActionView;
