import React, {useEffect, useState} from 'react';
import {
    Link,
    useNavigate,
    useParams
} from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';
import { useSpring, animated as a, config } from 'react-spring';
import LayoutWrapper from '../../layout/LayoutWrapper';
import ViewWrapper from '../ViewWrapper';
//import { deleteAsset } from '../redux/actions/assetActions';
import Prompt from '../../components/Prompt/Prompt';
import Moment from 'moment';
import {v4 as uuidv4} from 'uuid';

import { BaseURL } from '../../constants/URLs';
import { getEntities, postEntities, putEntities, deleteEntities } from '../../api/restUtilities';
import PaginationView from '../../components/PaginationView/PaginationView';
import { useRef } from 'react';
import CustomerNavigationView from './components/CustomerNavigationView';
import CustomerViewLayoutWrapper from './layout/CustomerViewLayoutWrapper';


const CustomerPage = (props)  => {

   
    const {accountDomainName} = useParams()
    
    const orderCatalogueTemplates = useSelector(state => state.orderCatalogueTemplates)

    const dispatch = useDispatch(); 

    let history = useNavigate();

    const user = useSelector(state => state.user);
    
    const [openPromptDialog, setOpenPromptDialog] = useState(false);
    const [promptOkAction, setPromptOkAction] = useState(null);
    const [searchString, setSearchString] = useState("");
    const [showNewCatalogueModal, setShowNewCatalogueModal] = useState(false);
    const [selectedOrderTemplate, setSelectedOrderTemplate] = useState(null);
    const [newOrderCatalogue, setNewOrderCatalogue] = useState(null)
    //const [accountInfo, setAccountInfo] = useState(null)

    const [currentPageIndex, setCurrentPageIndex] = useState(0) 
    const [totalRecords, setTotalRecords] = useState(0) 
    const [totalPageCount, setTotalPageCount] = useState(0)
    
    const [selectedGame, setSelectedGame] = useState(null);
    const [orderCatalogues, setOrderCatalogues] = useState([]);

    const delayTimer = useRef();

    const dummyOrderCataloge = {
        id:uuidv4(),
        title:'Spare Parts',
        description: 'This is a long text description',
        lastEdited: new Date().toISOString()
    }

   /*  useEffect(()=>{
        getEntities({
            url:BaseURL + `api/v1/AccountConfiguration/GetAccountInfo?accountDomainName=${accountDomainName}`, 
            successCallback:setAccountInfo});
    },[]) */

    useEffect(()=>{

        getEntities({
            url:BaseURL + `api/v1/ordercatalogue/getpublicordercatalogues?accountDomainName=${accountDomainName}&maxPerPage=${maxPerPage}&pageNumber=${currentPageIndex}&searchString=${searchString}`, 
            successCallback:setCatalogueRecordsData});
      
    },[currentPageIndex])

    useEffect(()=>{
        clearTimeout(delayTimer?.current);
        delayTimer.current = setTimeout(function() {
        getEntities({
            url:BaseURL + `api/v1/ordercatalogue/getpublicordercatalogues?accountDomainName=${accountDomainName}&maxPerPage=${maxPerPage}&pageNumber=${currentPageIndex}&searchString=${searchString}`, 
            successCallback:setCatalogueRecordsData});
        }, 1000);
      
    },[searchString])

   /*  useEffect(()=>{
       
             
        getEntities({url:BaseURL + `api/v1/inventory/getinventories?accountId=${currentUser?.accountId}&maxPerPage=${maxPerPage}&pageNumber=${currentPageIndex}&searchString=${searchString}`, successCallback: setInventoryRecordsData});
      
      
    },[currentPageIndex]) 

    useEffect(()=>{

        clearTimeout(delayTimer?.current);
        delayTimer.current = setTimeout(function() {
            getEntities({url:BaseURL + `api/v1/inventory/getinventories?accountId=${currentUser?.accountId}&maxPerPage=${maxPerPage}&pageNumber=${currentPageIndex}&searchString=${searchString}`, successCallback: setInventoryRecordsData});
      
        }, 1000);
        
    },[searchString])*/


    const maxPerPage = 3

    const setCatalogueRecordsData = (data) => {
        const {listData, pageNumber,totalRecords,totalPageCount} = data;
        setCurrentPageIndex(pageNumber);
        setTotalRecords(totalRecords);
        setTotalPageCount(totalPageCount);
        setOrderCatalogues(listData);
    }
   
   
    const createNewOrderCatalogue = () => {
        let newOrderCatalogueId = uuidv4();

        let templateData = selectedOrderTemplate ? {...selectedOrderTemplate} : {}

        //dispatch({type:'CREATE_NEW_CATALOGUE', data: {...templateData,...newOrderCatalogue, id:newOrderCatalogueId}});

        //history(`/edit-order-catalogue/${newOrderCatalogueId}`);

        let newId = uuidv4();

        postEntities(
            {
                url:BaseURL + 'api/v1/ordercatalogue/savecatalogue', 
                data:{...templateData, ...newOrderCatalogue, id:newOrderCatalogueId},
                successCallback: (d)=>{
                //setShowCreateNewOrderPopup(false);
                setNewOrderCatalogue({...d})
                getEntities({
                    url:BaseURL + 'api/v1/ordercatalogue/getordercatalogues', 
                    successCallback:setOrderCatalogues});
                    //setNewOrderCatalogue({...dummyOrderCataloge})
                    history(`/edit-order-catalogue/${d.id}`)
                },
                successNotification:{title:"Success", message:'Catalogue created'},
                errorNotification:{title:"Failed", message:'Catalogue creation failed'},
            }        
        )

        //history(`/edit-order-catalogue/${newOrderCatalogueId}`)
    }

    const toggleSelectedTemplate = (id) => {
        if(id === null || selectedOrderTemplate && selectedOrderTemplate.id === id)
        {
            setSelectedOrderTemplate(null);
        }
        else
        {
            setSelectedOrderTemplate(orderCatalogueTemplates.find(ot => ot.id === id));
        }
    }

    

    /* useEffect(()=>{

        getEntities({
            url:BaseURL + 'api/v1/ordercatalogue/getordercatalogues', 
            successCallback:setOrderCatalogues});
      
    },[]) */

    const filterList = (searchStr, data, fieldName) => {
        if(data && fieldName)
        {
            return data.filter( d => d[`${fieldName}`]?.toLowerCase().match(searchStr.toLowerCase()))
        }
        else
        {
           return []; 
        }
    }

    const linkClickHandler = (code) => {

        //history(`/game/${code}`);
        

        //console.log({code, d:gameInfo.find(g => g.code === code)})

        //setSelectedGame(gameInfo.find(g => g.code === code))

        //setShowGameInfoPopup(true);
        history(`/${accountDomainName}/${code}`);

    }

    
   
    const launchNewCatalogueModal = () =>
    {
        setNewOrderCatalogue({...dummyOrderCataloge})
        setShowNewCatalogueModal(true)
        //history(`/create-order-catalogue/`)
    }
    /* const deleteAssetRecord = (id) =>
    {
        setOpenPromptDialog(true);
        setPromptOkAction({message: "Do you want to delete the asset?",OkAction:()=>{dispatch(deleteAsset(id));setOpenPromptDialog(false)}});
    } */

    const customerPageLinks = [
        {
            title: 'New Order',
            link: 'create-order',
            description:'',
        },
        {
            title: 'Track Order',
            link: 'track-order',
            description:'',
        },
        {
            title: 'Inventory',
            link: 'inventory',
            description:'',
        },
        {
            title: 'Order History',
            link: 'order-history',
            description:'',
        },
        {
            title: 'Update Profile',
            link: 'update-profile',
            description:'',
        }
    ]
     
    return(<>
   
   {accountDomainName === null ? <div>Store account not found!</div> : 
   <div style={{padding:20, width:'100%', justifyContent:'center'}}>
 <Prompt promptOptions={{...promptOkAction}} OkLabel={"Yes"}  isOpen={openPromptDialog} modalClosed={()=> setOpenPromptDialog(false)}/>
                {/* <div style={{margin:5, position:'relative'}}>
                    <div style={{display:'flex', fontSize:20, position:'sticky', flexDirection:'row', width:'100%', margin:'0px', alignItems:'center', justifyContent:'space-between', color:'grey'}}>
                   <img height={80} src={accountInfo?.businessLogo} />
                    {`@${accountDomainName}`}
                    </div>      
                </div> */}
                {/* <CustomerNavigationView/> */}

               {/*  <div style={{margin:5, position:'relative'}}>
                    <div style={{display:'flex', position:'sticky', flexDirection:'row', width:'100%', margin:'10px 0px', alignItems:'center', justifyContent:'center'}}>
                    {searchString && <div style={{color:'#ab571e', marginRight:10}}  onClick={e=>setSearchString("")} >Clear</div>}    
                    <input className='search-input-wide'  value={searchString} onChange={e=>setSearchString(e.target.value)} placeholder="Search" style={{backgroundImage:`url('${searchIcon}')`, backgroundRepeat:'no-repeat', backgroundPositionX:'98%', backgroundPositionY:'50%',}}/>
                    </div>      
                </div> */}
               

                <div style={{margin:5, padding:20}}>
                <div style={{ width:'100%',display: 'inline-grid', columnGap:35,
                gridTemplateColumns: 'repeat(auto-fill, minmax(160px, 1fr))', rowGap:35, minHeight:'calc(100vh-50px)'}}>
              
                {customerPageLinks.map((g,i) => 

<div key={g.link} className={'item-card'} style={{height:200, width:'100%'}}  onClick={()=>linkClickHandler(g.link)}>
    <div /* to={`/game/${g.code}`} onClick={()=>launchGame(code)} */
style={{padding:10,fontSize:18, color:'#322f2f', minHeight:50, textAlign:'center', position:'absolute', cursor:'pointer', borderRadius:0, background:'#f4eae1', width:'100%'}}
>{g.title}</div>
    {/* {orderCatalogues.filter(g => g.gameCode === code).length > 0 && <span style={{color:'#c1481f', background:'none', padding:'3px 8px',  position:'absolute', top:-5, left:0, fontWeight:'normal', fontSize:13}}>{orderCatalogues.filter(g => g.gameCode === code).length}&nbsp;Orders</span>}
     <img  src={defaultIcon} style={{height:100,  margin:10, top:0, position:'absolute',fontFamily:'Dancing Script'}} alt="logo" /> 
    */}</div>
                )}
                </div>
                
                <div style={{height:80}}></div>
                
                </div>
   </div>
           
                }
    
   
    </>)

}   

export default CustomerViewLayoutWrapper(CustomerPage, {showNavigationBar:true, removePadding:true, showSideMenu:true,/* backgroundImage */});