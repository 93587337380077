import React, { useEffect, useState } from 'react';
import {useNavigate} from 'react-router-dom';
import Logo1  from '../assets/images/brain-trainer-logo-large.svg';
import backgroundImage from '../assets/images/background_01.svg';
import backgroundImage02 from '../assets/images/background_02.svg';
//import DropIns from '../games/components/DropIns/DropIns';
import {animated, config, useSpring} from 'react-spring';
import { useSelector, useDispatch} from 'react-redux';
import introImage from '../assets/images/intro-image.JPG'
import { CREATE_NEW_USER_URL } from '../constants/URLs';
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import NotificationManager from '../notification/NotificationManager';

const UserRegistrationView = (props) => {

    const navigate = useNavigate();

    const user = useSelector( state => state.user);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [newUser, setNewUser] = useState(
        {
            "email": "",
            "password": "",
            "confirmPassword": "",
            "firstName": "",
            "lastName": "",
            "city": "",
            "isRootUser": true,
            "accountDomainName": ""
        }          
    );

    const createNewUser = () => {
        console.log(newUser)
        //navigate('/order-catalogues')

        //let companyDomain = companyidentifier != ""? "/"+companyidentifier:""
                        
        const requestOptions = {
            url: CREATE_NEW_USER_URL,
            method: 'post',
            headers: { 'Content-Type': 'application/json', 'access-control-allow-origin':'*' },
            data: {...newUser}
        };
    
        setIsSubmitting(true);
        trackPromise(axios({...requestOptions})
            .then((response) => {
                console.log(response)
               
                NotificationManager.success({
                    title:'Registration Success',
                    'message':'Your account has been created'
                })

                setTimeout(function(){ props.history.push("/#login") }, 1500);
            }).then(user => {
              // store user details and jwt token in local storage to keep user logged in between page refreshes
              /*localStorage.setItem('currentUser', JSON.stringify(user));
              currentUserSubject.next(user);                  
            return user;*/
            console.log(user)
           }).catch((e)=>
            {
                setIsSubmitting(false);                
                console.error("Error",e)                
                NotificationManager.error({
                    title:'Registration Failed',
                    message: e.message || "Invalid Entry"//e.response?.data
                })
            })
        );
    }

    const handleNewUserChange = (e)  => 
    {
        const {name, value} = e.target;

        setNewUser(nu => ({...nu, [name]:value}));
    }

    console.log(user)

    const [{x, opacity}, api] = useSpring(()=>({from:{x:-60, opacity:0}, config:config.wobbly}))

    const navigateToNext = () => {

        if(user && user.name && user.gender)
            navigate('order-catalogues')
        else
            navigate('setup')
    }

    useEffect(()=>
    {
        api.start(()=>({to:{x:0, opacity:1}}))
    },[])

    return(
        <div style={{display:'flex',
         justifyContent:'space-around',
         padding:20,
         textAlign:'center',
         alignItems:'center',
         position:'absolute', 
         flexDirection:'column',
         //background:'linear-gradient(135deg, rgb(150, 251, 196) 0%, rgb(249, 245, 134) 100%)',
         background:`url('${backgroundImage}')`,
         //backgroundImage:'url(images/background-photo-01.jpg)',
         background:`white`,
         backgroundPosition:'center',
         backdropFilter:'greyscale(80%)',
         backgroundRepeat:'no-repeat',
         backgroundSize:'cover',
         height:'100vh', width:'100vw'}}>
             
                          
            <div style={{display:'flex',}}>
                <div style={{position:'relative', width:50, height:50}}>                
                    <animated.img width={50} style={{position:'absolute',left:x, opacity}} src={Logo1}/>                
                </div>            
                <div className={"app-title"} style={{fontWeight:'normal'}}>Oda<span style={{opacity:1.0, color:"#f7941e"}}>Now</span></div>
            </div>  
            <span style={{fontSize:16, padding:'10px 25px', background:'none', color:'#333333'}}>Merchant Registration</span>
            <div className='account-prompt-view' style={{padding:0}} >
                <div style={{maxHeight:'45vh', overflowY:'auto',padding:20}}>
                    <div style={{alignItems:'flex-start'}} className='input-field'>
                        <label className='form-label-2'>Account Domain Name</label>
                        <input name='accountDomainName' onChange={handleNewUserChange} value={newUser.accountDomainName} style={{width:'100%'}} className='form-text-input'/>
                    </div>
                    <div style={{alignItems:'flex-start'}} className='input-field'>
                        <label className='form-label-2'>Email</label>
                        <input name='email' onChange={handleNewUserChange} value={newUser.email} style={{width:'100%'}} className='form-text-input'/>
                    </div>
                    <div style={{alignItems:'flex-start'}} className='input-field'>
                        <label className='form-label-2'>First Name</label>
                        <input name='firstName' onChange={handleNewUserChange} value={newUser.firstName} style={{width:'100%'}} className='form-text-input'/>
                    </div>
                    <div style={{alignItems:'flex-start'}} className='input-field'>
                        <label className='form-label-2'>Last Name</label>
                        <input name='lastName' onChange={handleNewUserChange} value={newUser.lastName} style={{width:'100%'}} className='form-text-input'/>
                    </div>
                    <div style={{alignItems:'flex-start'}} className='input-field'>
                        <label className='form-label-2'>Password</label>
                        <input name='password' type='password' onChange={handleNewUserChange} value={newUser.password} style={{width:'100%'}} className='form-text-input'/>
                    
                    </div>
                    <div style={{alignItems:'flex-start'}} className='input-field'>
                        <label className='form-label-2'>Password-Retype</label>
                        <input name='confirmPassword'  type='password' onChange={handleNewUserChange} value={newUser.confirmPassword} style={{width:'100%'}} className='form-text-input'/>
                    
                    </div>
                </div>


                <div style={{width:'95%', padding:20}}>
                    <button style={{width:'100%',padding:'15px 25px'}} onClick={()=>createNewUser()} className='app-button-primary' >Register</button>
                </div>

                <div style={{display:'flex', padding:20, flexDirection:'row', marginTop:20, justifyContent:'space-around', alignItems:'flex-start'}} className='input-field'>                    
                    <span style={{fontSize:11, color:'#464646', textDecoration:'none', padding:'10px 0px'}}>Already have an account?</span>
                    <button onClick={()=>navigate('/merchant-login')} className='app-button-primary'>Login</button>
                </div>
                
            </div>
            <div style={{display:'flex', width:'100%', justifyContent:'space-between'}}>
                
                <button onClick={()=>navigate('/')} className='app-button-primary'>Back</button>
               {/*  <button onClick={()=>createNewUser()} className='app-button-primary' >Register</button> */}
            </div>
           
            <div>
                
                <div style={{color:'#0f5a4d',bottom:-15, position:'absolute', left:0, bottom:0, fontSize:10, width:'100%', background:'none', color:'white'}} >
                    <p>
                    OdaNow 2023
                    </p>
                </div>
            </div>
           
        {/* <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</div>
         */}</div>
        )
}

export default UserRegistrationView;//LayoutWrapper(SplashScreen, {showNavigationBar:false, showSideMenu:false});