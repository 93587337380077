import React, {useEffect, useState} from 'react';
import {
    Link,
    useNavigate
} from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';
import LayoutWrapper from '../layout/LayoutWrapper';
//import Logo1  from '../assets/images/logo.svg';
//import ViewWrapper from './ViewWrapper';
//import Prompt from '../components/Prompt/Prompt';
import GameHeader from '../components/GameHeader/GameHeader';

//import GameSelector from '../games/GameSelector/GameSelector';
import {
    useParams,
    
} from 'react-router-dom';
import PopUp from '../components/PopUp/PopUp';
import Moment from 'moment';
import {v4 as uuidv4} from 'uuid';

//import useAnalyticsEventTrack from '../games/hooks/analyticsTracker';
import OrderFormEditor from '../components/Form/FormEditor';
import { moveByIndexField } from '../utilities/arrayUtilities';
import CatalogueDetailsForm from '../components/Catalogue/CatalogueDetailsForm';
import CatalogueStageFormDesigner from '../components/Catalogue/CatalogueStageFormDesigner';
import CatalogueStagesDesigner from '../components/Catalogue/CatalogueStagesDesigner';
import { NavigationCounter } from '../components/ViewNavigation/NavigationCounter';
import StageEditor from '../components/Stage/StageEditor';
import { getEntities, postEntities, putEntities, deleteEntities } from '../api/restUtilities';

import { BaseURL } from '../constants/URLs';

const CatalogueDesignerStageSelector = (props) => {

    let currentIndex = props.currentIndex;

    switch(currentIndex)
    {
        case 0 : return <CatalogueDetailsForm {...props} />
        case 1 : return <CatalogueStagesDesigner {...props}/>
        case 2 : return <CatalogueStageFormDesigner {...props}/>
    }
}

const CreateOrderCatalogue = (props)  => {

    const {orderCatalogueId} = useParams();

    const dispatch = useDispatch();    

    const orderCatalogues = useSelector(state => state.orderCatalogues);

    //const currentOrderCatalogue = orderCatalogues.find(g => g.id === orderCatalogueId);

    console.log("currentOrderCatalogue",orderCatalogues)

    const gamePlayData = useSelector(state => state.gamePlayData);

    const dummyStage = {  
        id:uuidv4(),      
        position:2,
        title: "New Stage",
        duration: 6,
        description: "This stage hanldes Customer inquiries and can be entered by the Customer or the Merchant.",
        type: "OrderPlacement", 
        formFields:[],
        reviewers:[]
    }

    /*const gaEventTracker = useAnalyticsEventTrack(orderCatalogueId)
    useEffect(()=>{
        gaEventTracker("Launched Catalogue@", orderCatalogueId)
    })*/

    const dummyCatalogue = 
    {
        title:'New Catalogue',
        description: 'This is a long text description',
        lastEdited: "2022-08-30T19:52:06.056Z"
    }


   

    const [currentNavigationIndex, setCurrentNavigationIndex] = useState(0);
    const [showStageEditor, setShowStageEditor] = useState(false);
    const [showStageDeletePrompt, setShowStageDeletePrompt] = useState(false);
    const [isEditingStage, setIsEditingStage] = useState(true);
    const [orderCatalogue, setOrderCatalogue] = useState({...dummyCatalogue})//currentOrderCatalogue ? {...currentOrderCatalogue} : {...dummyCatalogue})
    const [orderStages, setOrderStages] = useState([])//useState(orderCatalogue.stages ? [...orderCatalogue.stages] : [] )
    const [selectedStage, setSelectedStage] = useState({
        title: "Inquiry",
        description: "This stage hanldes Customer inquiries and can be entered by the Customer or the Merchant.",
        type: "OrderPlacement", // Inquiry type will have compulsory fields
        formFields: [ // Custom/optional fields
            {
                caption:'Phone Number',
                type:'text',
                id:'12233-112233'
            },
            {
                caption:'Phone Number',
                type:'dropdown',
                id:'12233-112233',

            },
            {
                caption:'Quantity',
                type:'number',
                id:'12233-112233',
                minValue:0,
                maxValue:20
            },

        ],
        acknowledgement: {

        },
        reviewers:[
            {
                name:"Karl",
                email:"karl@yahoo.com"
            },
            {
                name:"Chinenye",
                email:"Chinenye@yahoo.com"
            },
            {
                name:"Sarah",
                email:"sarah@yahoo.com"
            }
        ]
    })
    const [navigationSteps, setNavigationSteps] = useState([
        {caption:"Details"}, {caption:"Stages"}, {caption:"Form Details"}
    ])

    useEffect(()=>{
        orderCatalogue?.stages && setOrderStages([...orderCatalogue.stages])
    },[orderCatalogue])



    useEffect(()=>
    {
        console.log("loading data...")
        getEntities({
            url:BaseURL + 'api/v1/ordercatalogue/getordercatalogue?id=' + orderCatalogueId, 
            successCallback:setOrderCatalogue});

        /*getEntities({
            url:BaseURL + 'api/v1/ordercatalogue/getorderstages?ordercatalogueid=' + orderCatalogueId, 
            successCallback:setOrderStages});*/

    },[])

    const getLastEdtedDuration = (lastPlayed) => {
        
        
        if(lastPlayed && lastPlayed !== -Infinity)
        {
            console.log("lastPlayed",lastPlayed);
            return Moment(new Date(lastPlayed)).startOf('second').fromNow()
        }
        return "";//new Date(Math.max(...gamePlayData.filter(g => g.gameCode === code).map(gp => new Date(gp.startTime))));
        
    }

    const stageCallbackHandler = (action, data) => {

        console.log("callback data", {action, data})
        switch(action)
        {
            case "updateOrderDetails" : updateCatalogueDetails(data);
                break;
            case "openStageEditor" : openStageEditor(data) 
                break;
            case "moveStage" : moveStage(data)
                break;
            case "showDeletePrompt" : showDeletePrompt(data)
                break;
            case "addStage" : addStage(data)
                break;
            case "setSelectedStageField" : setSelectedStageField(data) 
                break;
            case "updateStageReviewers" : updateStageReviewers(data)
                break;
            case "updateForm" : updateForm(data)
                break;
        }
    }

    const updateCatalogueDetails = (detailsFieldData) => {
        console.log(detailsFieldData)
        setOrderCatalogue(oc => ({...oc, [detailsFieldData.name]: detailsFieldData.value}))

       
    }

    const updateForm = (updatedFormFields) => { 
        console.log(updatedFormFields)     
        //setSelectedStage(s => ({...s,formFields:updatedFormFields}))
        setSelectedStage(s => ({...s,dataForm:{name:"stage 1", description: "stage", items:updatedFormFields}}))
    }

    const updateStageReviewers = (updatedReviewers) => {
        console.log(updatedReviewers)
        setSelectedStage(s => ({...s,reviewers:[...updatedReviewers]}))
    }

    const setSelectedStageField = ({field, value}) => {
        setSelectedStage(s => ({...s,[field]:value}))
    }

    const addStage = (data) => {
        console.log("Addstade", data)
        setSelectedStage({
            ...dummyStage,
            position:data.position
        });
        setShowStageEditor(true);
        setIsEditingStage(false);
    }

    const addNewStage = () => {

        //
        let newId = uuidv4();

        let newStageData = {...selectedStage, posiition: parseInt(selectedStage.position),catalogueId: orderCatalogueId,id:newId};

        setOrderStages( os => [...os, {...newStageData}]);
                    setShowStageEditor(false);
                    setIsEditingStage(true);

        /*postEntities(
            {
                url:BaseURL + 'api/v1/ordercatalogue/saveorderstage', 
                data:{...selectedStage, posiition: parseInt(selectedStage.position),catalogueId: orderCatalogueId,id:newId},
                successCallback: ()=>{
                    setOrderStages( os => [...os, {...selectedStage}]);
                    setShowStageEditor(false);
                    setIsEditingStage(true);
                
                },
                successNotification:{title:"Success", message:'Order Stage created'},
                errorNotification:{title:"Failed", message:'Order Stage creation failed'},
            }        
        )*/
        
    }

    const updateStage = () => {
        setOrderStages( os => os.map(o =>
            o.id === selectedStage.id ? {...selectedStage} : o));
          
        setShowStageEditor(false);
    }

    const showDeletePrompt = (stage) => {
        setSelectedStage(stage);
        setShowStageDeletePrompt(true);

    }

    const deleteSelectedStage = () => {
        setOrderStages(os => os.filter(o => o.id !== selectedStage?.id))
        setShowStageDeletePrompt(false);
    }

    const moveStage = ({newPosition, stage}) => {
        console.log({newPosition, stage})
        setOrderStages(od => moveByIndexField(stage.position, newPosition, od, "position"))
    }

    const saveCatalogue = () => {
        //dispatch({type:"UPDATE_CATALOGUE", data:{...orderCatalogue, stages:[...orderStages]}}); 
        
        putEntities(
            {
                url:BaseURL + 'api/v1/OrderCatalogue/UpdateCatalogue', 
                data:{...orderCatalogue, stages:[...orderStages]},
                successCallback: ()=>{
                    //setShowCreateCustomerPopup(false);
                    //getEntities({url:BaseURL + `api/v1/customer/getcustomers?maxPerPage=${maxPerPage}&pageNumber=${currentPageIndex}&searchString=${searchString}`, successCallback: setCustomerRecordsData});
      
                    //setCustomer({...dummyCustomerData})
                },
                successNotification:{title:"Success", message:'Catalogue updated'},
                errorNotification:{title:"Failed", message:'Catalogue update failed'},
            }        
        )
    }
    

    //console.log(moveByIndexField(2,4,[{name:'A',position:1},{name:'B',position:2},{name:'C',position:3},{name:'D',position:4}],'position'))

    const openStageEditor = (stage) => {       
        setSelectedStage(stage);
        setShowStageEditor(true);
        setIsEditingStage(true)
    }

    const goToNextNavigationStep = () => {

        setCurrentNavigationIndex ( c => {
            if(c < navigationSteps.length -1)
            {
                return c + 1;
            }

            return c;
        })
       
    }

    const goToPreviousNavigationStep = () => {

        setCurrentNavigationIndex ( c => {
            if(c > 0)
            {
                return c - 1;
            }

            return c;
        })
       
    }

    

    return( <>
    <GameHeader titleSubText={"Order Workflow Builder"} moduleActions={[
        {
            actionCaption: "Save",
            actionFunc: ()=>saveCatalogue()
        }
    ]} activityTitle={orderCatalogue?.title}/>
   
    <div style={{
        position:'relative',
        height:'calc(100vh - 50px)',
        width:'100%'
    }}>
        
        
        <div style={{
            //backgroundColor:'green',
            //background: 'linear-gradient(0deg, rgba(9,73,121,1) 19%, rgba(0,145,200,1) 47%, rgba(52,154,230,1) 69%, rgba(0,212,255,1) 100%)',
            //background: 'linear-gradient(45deg, rgb(9, 73, 121) 19%, rgb(0, 145, 200) 47%, rgb(63 230 52) 69%, rgb(0, 212, 255) 100%)',
            
            width:'100%',
            height:'100%',
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            position:'relative',
            alignItems:'center'
        }}>
             <div style={{width:'100%', textAlign:'right', padding:5, fontSize:12, color:'grey'}}>
                {`Last edited: ${getLastEdtedDuration(orderCatalogue?.lastModificationDate)}`}
            </div>
            <NavigationCounter  onClick={(c)=>setCurrentNavigationIndex(c)} currentNavigationStep={currentNavigationIndex} navigationSteps={navigationSteps}/>
                   
            <div style={{backgroundColor:'none', height:'95%', margin:'10px', display:'flex',
            flexDirection:'column',
            overflow:'auto',
            borderRadius:10,
            //background:'linear-gradient(0deg, rgb(255 133 95 / 15%) 0%, rgb(253 253 253) 100%)',
            //justifyContent:'center',
            position:'relative',
            minWidth:300,

            //maxWidth:700,
            alignItems:'center', 
            //border:'1px solid #e9e9e9',
            width:'100%', 
            //boxShadow:'rgb(157 154 154 / 60%) 2px 3px 7px',
            }}>

                   {/* <GameSelector gameCode={gameCode}/> */}
                   <CatalogueDesignerStageSelector orderStages={orderStages} orderCatalogue={orderCatalogue} stageCallbackHandler={stageCallbackHandler} currentIndex={currentNavigationIndex}/>
            </div>

            <div style={{display:'flex', width:'100%', borderTop:'1px solid grey', padding:5, justifyContent:'space-between'}}>
                <button onClick={goToPreviousNavigationStep} className='app-button-primary'>Back</button>
                <button onClick={goToNextNavigationStep} className='app-button-primary'>Next</button>
            </div>

        </div>
       
    </div>
    { selectedStage && <PopUp open={showStageEditor} >
        <div style={{width:'100%', height:'100%', display:'flex', flexDirection:'column',
        padding:0,
        borderRadius:10, 
        overflow:'hidden',
        boxShadow:'2px 2px 5px rgba(10,10,10,0.7)', minHeight:50, maxHeight:'70vh', background:'white'}}>
            <div style={{padding:10, display:'flex', justifyContent:'left', overflowY:'auto', /* alignItems:'center', */ fontSize:14}}>
           {/*  <img  src={defaultIcon} style={{height:50, background:'#ecf5ef', borderRadius:5,   margin:2, marginRight:20}} alt="logo" />  */}
            <StageEditor key={selectedStage.id} stageCallbackHandler={stageCallbackHandler} stageInfo={selectedStage}/>
            
            </div>
           
                 
            <div style={{width:'100%', 
        padding:10,
         
        background:'#EEEEEE', display:'flex', justifyContent:'space-between'}}>
                <button className='app-button-primary' onClick={isEditingStage? ()=> updateStage() : ()=>addNewStage()} >{isEditingStage ? "Update" : "Add"}</button>
                <button className='app-button-primary' onClick={() => setShowStageEditor(false)}>Close</button>
            </div>            
        </div>
    </PopUp>}

    { selectedStage && <PopUp open={showStageDeletePrompt} >
        <div style={{width:'100%', height:'100%', display:'flex', flexDirection:'column',
        padding:0,
        borderRadius:10, 
        overflow:'hidden',
        boxShadow:'2px 2px 5px rgba(10,10,10,0.7)', minHeight:50, background:'white'}}>
            <div style={{padding:15, display:'flex', justifyContent:'center', alignItems:'center', fontWeight:'normal', fontSize:16}}>
           {/*  <img  src={defaultIcon} style={{height:50, background:'#ecf5ef', borderRadius:5,   margin:2, marginRight:20}} alt="logo" />  */}
            <div style={{display:'flex', color:'#444444', padding:20, flexDirection:'column'}}>

          {`Delete stage: ${selectedStage.title}`}
            
            </div>
            
            </div>
           
                 
            <div style={{width:'100%', 
        padding:10,
         
        background:'#EEEEEE', display:'flex', justifyContent:'space-between'}}>
                <button className='app-button-primary' onClick={() => deleteSelectedStage()} >Delete</button>
                <button className='app-button-primary' onClick={() => setShowStageDeletePrompt(false)}>Close</button>
            </div>            
        </div>
    </PopUp>}
    </>)

}   

export default LayoutWrapper(CreateOrderCatalogue, {showNavigationBar:false, showSideMenu:false});