import React, {useEffect, useState, useRef, memo, useCallback} from 'react';
import {
    Link,
    useNavigate,
    useParams
} from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';
import { useSpring, animated as a, config } from 'react-spring';
import LayoutWrapper from '../../layout/LayoutWrapper';
import ViewWrapper from '../ViewWrapper';
//import { deleteAsset } from '../redux/actions/assetActions';
import Prompt from '../../components/Prompt/Prompt';
import searchIcon from '../../assets/images/search.svg'
import PopUp from '../../components/PopUp/PopUp';
import backgroundImage from '../../assets/images/background_01.svg';
import {v4 as uuidv4} from 'uuid';
import axios from 'axios';
import { BaseURL } from '../../constants/URLs';
import defaultIcon  from '../../assets/images/default-inventory-icon.svg';
import { getEntities, postEntities, putEntities, deleteEntities } from '../../api/restUtilities';

import PaginationView from '../../components/PaginationView/PaginationView';
//import CustomerViewLayoutWrapper from './customer/layout/CustomerViewLayoutWrapper';
import CustomerViewLayoutWrapper from './layout/CustomerViewLayoutWrapper';
import NumberField from '../../components/NumberField/NumberField';
import ImageBox from '../../components/ImageBox/ImageBox';
import SearchListView from '../../components/SearchListView/SearchListView';
import OrderForm from '../../components/Form/OrderForm';
import TabbedView from '../../components/TabbedView/TabbedView';
import okayIcon from '../../assets/images/ok-button.svg'
import {Buffer} from 'buffer';


const OrderItemsListView = memo((props) => {
    const currentUser  = useSelector(state => state.user);
    //const inventory = useSelector(state => state.inventory) 
    const [showAddItemPopup, setShowAddItemPopup] = useState(false)
    const [selectedItems, setSelectedItems] = useState([])
    const [items, setItems] = useState(props.items ? [...props.items] : [])
    const [tax, setTax] = useState(props.tax ? props.tax : 0)
    const [discount, setDiscount] = useState(props.discount ? props.discount : 0)
    const [images, setImages] = useState({});

    useEffect(()=>{
        setItems(props.items)
    },[props.items])

    const handleItemSelectionChange = (item) =>{
        setSelectedItems(s => s.some(si => si.inventoryItemId === item.id) ? [...s.filter(si => si.inventoryItemId != item.id)] : [...s,{...item,id:uuidv4(),  inventoryItemId:item.id, name:item.name, unitCost: item.sellingPrice, quantity:0, availableQuantity: item.quantity}])
    }

    const addItem = () => {
        setItems(i => [...i, ...selectedItems]);
        setSelectedItems([])
    }

    const removeItem = (id) => {
        setItems(itemList =>  [
            ...itemList.filter(i => i.id !== id)] )
    }

    const updateItemQuantity = (e) => {
        let {name, value} = e;

        // name is the 'id' of the item

        setItems(itemList =>  [
            ...itemList.map(
                (m, i) => m.id === name ? {...m, quantity:parseFloat(value) || 0}
                                        : m
            )                  
             ] )


    }

    const showItemPopup = () =>{
        setSelectedItems([])
        setShowAddItemPopup(true)
    }

    const getImage = useCallback((key, type) => {
       
        console.log("Loading Image pre", {key, k:images[key]})
        var stringifiedData = '';
        if(images[key] === undefined)// || images[key] == null)//!images[key])
        {
            console.log("is calling Loading Image", key)
            setImages( i => ({...i, [key]:null}))// or default
            //responseType:"arraybuffer"}
            axios.get(key, {responseType:"arraybuffer"}).then(d => {
                let bdata = Buffer.from(d.data, "binary").toString('base64')//Buffer.from(d.data,'base64');
                //let bdata = btoa(data)
                console.log(bdata);
                stringifiedData = `data:${type};charset=utf-8;base64, ${bdata}`
                setImages( i => ({...i, [key]:stringifiedData}))
            })
        }
        else
        {
            stringifiedData = images[key]
        }
       
        //let d = Buffer.from(r.data).toString('base64');
        //console.log(d)
        //setImages( i = ({...i, [key]:d}))
        return stringifiedData;//`data:image/png;base64, ${d}`;
    }, [items])

    useEffect(()=>{


        
        props.onItemChange && props.onItemChange(items)

        console.log("Loading Image pre items", {items})

        //items?.images?.map(a =>a.fileClass == 'IMAGE' && getImage(BaseURL + `api/v1/Inventory/GetFileByKey?key=${a.link}`, a.fileType));
        items?.map(a =>a.defaultImageFile && a.defaultImageFile.fileClass == 'IMAGE' && getImage(BaseURL + `api/v1/Inventory/GetFileByKey?key=${a.defaultImageFile.link}`, a.defaultImageFile.fileType));
    

    }, [items])

    const renderComponent = ({data}) => {
       
        if(data?.defaultImageFile)
        {
            //getImage(BaseURL + `api/v1/Inventory/GetFileByKey?key=${data.defaultImageFile?.link}`, data.defaultImageFile?.fileType )
            console.log("calling get Image")
        } 
        return (<div className='item-card' onClick={e => handleItemSelectionChange(data)} style={{margin:0, height:120, height:'auto', boxShadow:'2px 2px 11px -2px rgb(20 20 20 / 20%)', border:selectedItems.some(s =>s.inventoryItemId === data?.id) ? '2px solid orange' :0,  marginBottom:10, alignItems:'center', width:'100%'}}>
        <div style={{padding:0, display:'flex', width:'100%', justifyContent:'left', alignItems:'center', fontSize:20}}>
        <div style={{padding:0, display:'flex', flexDirection:'column', width:'110px', justifyContent:'left', alignItems:'center', fontSize:20}}>
        <ImageBox  src={data.defaultImageFile ? images[BaseURL + `api/v1/Inventory/GetFileByKey?key=${data.defaultImageFile.link}`]: defaultIcon}  style={{height:'auto', width:100, background:'none', borderRadius:5,   margin:5, marginRight:10}} alt="logo" /> 
                       
            <button  className='app-button-secondary-small'  type="checkbox" >Select</button>
          {/*  <div style={{background:'aqua', padding:15, width:20, height:20, fontSize:13, margin:10}} className="user-info-avartar">
               {data.firstName?.toUpperCase()?.charAt(0)}                    
           </div> */}</div>
           {/*  <img  src={defaultIcon} style={{height:50, background:'none', borderRadius:5,   margin:5, marginRight:20}} alt="logo" />  */}
           <div style={{padding:15, display:'flex', width:'100%', justifyContent:'left', alignItems:'center', fontSize:20}}>
                       
                       <div style={{display:'flex', flexGrow:1, flexDirection:'column'}}>

                           <div style={{display:'flex'}}>{data.title}</div>
                           <div style={{display:'flex', color:'#939090', fontSize:11}}>{data.description}</div>
                           <div style={{display:'flex', color:'#888888', paddingTop:5, fontSize:16}}>{`${data.availableQuantity} Available`}</div>
                           <div style={{display:'flex', color:'green', paddingTop:5, fontSize:16}}>{/* `\u20A6` */}{`NGN ${data.unitPrice} `}</div>
                           
                       
                       </div>
                  
                   </div>
      
       </div>
   </div>)
      }

    return(<>
        <table className='invoice-table' style={{width:'100%', minWidth:400}} cellSpacing={0} cellPadding={5}>
            <thead>
            <tr style={{padding:5, borderBottom:'1px solid #565656'}}>
                        <th>SN</th>
                        <th></th>
                        <th>Item</th>
                        <th>Unit Cost</th>
                        <th>Quantity</th>
                        <th>Total</th>
                        <th></th>
                    </tr>
            </thead>
            <tbody>
                    
                {items.map((ff, index) => 
                
                                                          
                    <tr >
                        <td>{index+1}</td>
                        <td style={{width:100}}>
                        {/* <ImageBox  src={ff.defaultImageFile ? getImage(BaseURL + `api/v1/Inventory/GetFileByKey?key=${ff.defaultImageFile.link}`, ff.defaultImageFile.fileType): defaultIcon} style={{height:'auto', width:80, background:ff.type === 'Service'? '#d6f7ef' :'white', borderRadius:5, padding:5,  margin:5, marginRight:20}} alt={ff.title} /> 
                         */}   
                        <ImageBox  src={ff.defaultImageFile ? images[BaseURL + `api/v1/Inventory/GetFileByKey?key=${ff.defaultImageFile.link}`]: defaultIcon} style={{height:'auto', width:80, background:ff.type === 'Service'? '#d6f7ef' :'white', borderRadius:5, padding:5,  margin:5, marginRight:20}} alt={ff.title} /> 
                        
                        </td>
                        <td>{ff.title + ff.availableQuantity}</td>
                        <td>{ff.unitPrice}</td>
                        {/* <td>{ff.quantity}</td>  */} 
                        <td>
                        {props.isEditable ?  
                        <NumberField name={ff.id} minValue={0}  onChange={updateItemQuantity} key={ff.quantity} value={ff.quantity} />
                         : ff.quantity}
                        </td>                     
                        <td>{ff.quantity * ff.unitPrice}</td>
                        <td>
                            <div className='row-delete-button' onClick={()=>removeItem(ff.id)}>
                            <svg height="14" width="14" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-19bqh2r"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
                            </div>
                        </td>
                    </tr>
                  
                  

                )}
                </tbody>
                <tfoot>
                    
                {props.isEditable && <tr style={{backgroundColor:'white'}}>                        
                        <td colSpan={7} align='center'>
                            <div style={{display:'flex', justifyContent:'center'}}>
                                <button onClick={()=>showItemPopup()} style={{padding:'10px 20px', fontSize:14}} className='app-button-secondary-small'>+ Add Item</button>                     
                            </div>
                        </td> 
                    </tr>}
                   
                    
                    <tr>                        
                        <td style={{textAlign:'right'}}  colSpan={5}>Total</td>                      
                        <td style={{fontSize:20}}>{items.reduce((p,c)=> p + (c.quantity*c.unitPrice), 0) * (100.0-discount+tax)/100.0}</td>
                        <td></td>
                    </tr>
                    <tr style={{background:"none"}} >
                        <td style={{height:55, background:'none', padding:0}} colSpan={7}>
                        <div className="zigzag"></div>
                        </td>

                    </tr>
                    </tfoot>
                </table>
                

                {<PopUp isEmbedded={true} open={showAddItemPopup} >
        <div style={{width:'100%', height:'100%', display:'flex', flexDirection:'column',
        padding:0,
        borderRadius:10, 
        overflow:'hidden',
        boxShadow:'2px 2px 5px rgba(10,10,10,0.7)', minHeight:50, background:'white'}}>
            <div style={{padding:15, display:'flex', justifyContent:'center', alignItems:'center', fontWeight:'normal', fontSize:16}}>
           {/*  <img  src={defaultIcon} style={{height:50, background:'#ecf5ef', borderRadius:5,   margin:2, marginRight:20}} alt="logo" />  */}
            <div style={{display:'flex', color:'#444444', padding:0, flexDirection:'column'}}>
            {/** An async call should be made to fetch inventory */}
            
              

            <SearchListView renderComponent={renderComponent} dataUrl={BaseURL + `api/v1/Inventory/GetInventories?accountId=${currentUser?.accountId}`}/>
            
            </div>
            
            </div>
           
                 
            <div style={{width:'100%', 
        padding:10,
         
        background:'#EEEEEE', display:'flex', justifyContent:'space-between'}}>
                <button className='app-button-primary' onClick={()=>{addItem();setShowAddItemPopup(false)}} >Add</button>
                <button className='app-button-primary' onClick={()=>setShowAddItemPopup(false)} >Close</button>
            </div>            
        </div>
    </PopUp>}

    </>)
})

const CustomerInventoryView = (props)  => {
    
    const {accountDomainName} = useParams()
    const customerAccount  = useSelector(state => state.customerAccount);// JSON.parse(localStorage.getItem('currentUser'));

    
    const [openPromptDialog, setOpenPromptDialog] = useState(false);
    const [promptOkAction, setPromptOkAction] = useState(null);
    const [searchString, setSearchString] = useState("");
    const [showCreateNewOrderPopup, setShowCreateNewOrderPopup] = useState(false);
    const [showAddCataloguesPopup, setShowAddCataloguesPopup] = useState(false);
    const [showOrderPlacementPopup, setShowOrderPlacementPopup] = useState(false)
    const [selectedOrderCatalogue, setSelectedOrderCatalogue] = useState(null)
    const [selectedQuantityTracker, setSelectedQuantityTracker] = useState({})
    const [cartItems, setCartItems] = useState([]);
   
    const [inventories, setInventories] = useState(null);    
    const [isEditing, setIsEditing] = useState(false);
    const [inventory, setInventory] = useState({});

    const [currentPageIndex, setCurrentPageIndex] = useState(0) 
    const [totalRecords, setTotalRecords] = useState(0) 
    const [totalPageCount, setTotalPageCount] = useState(0)
    const [orderCatalogues, setOrderCatalogues] = useState([])
    const [selectedCustomer, setSelectedCustomer] = useState(customerAccount ? {...customerAccount} : null)
    const [images, setImages] = useState({});
    const [order, setOrder] = useState({id:uuidv4()});
    const [isPlacingOrder, setIsPlacingOrder] = useState(false);
    const [hasPlacedAnOrder, setHasPlacedAnOrder] = useState(false);


    const [catalogueFilter, setCatalogueFilter] = useState('')
   
    const maxPerPage = 20

    const getImage = useCallback((key, type) => {
       
        var stringifiedData = '';
        console.log("iamges var type",images[key] )
        if(images[key] === undefined)
        {
            //responseType:"arraybuffer"}
            setImages( i => ({...i, [key]:null}))
            console.log("iamges var type 333",images[key] )
            axios.get(key, {responseType:"arraybuffer"}).then(d => {
                let bdata = Buffer.from(d.data, "binary").toString('base64')//Buffer.from(d.data,'base64');
                //let bdata = btoa(data)
                console.log("loaded data 333", bdata);
                 stringifiedData = `data:${type};charset=utf-8;base64, ${bdata}`
                setImages( i => ({...i, [key]:stringifiedData}))
            })
        }
        else
        {
            stringifiedData = images[key]
        }
       
        //let d = Buffer.from(r.data).toString('base64');
        //console.log(d)
        //setImages( i = ({...i, [key]:d}))
        return stringifiedData;//`data:image/png;base64, ${d}`;
    },[inventories])

    useEffect(()=>{
        getEntities({
            url:BaseURL + `api/v1/ordercatalogue/getPublicOrderCatalogues?accountDomainName=${accountDomainName}`, 
            successCallback:(d)=>setOrderCatalogues(d.listData)});
    },[])

    useEffect(()=>{
       
             
        getEntities({url:BaseURL + `api/v1/inventory/GetInventoriesByAccountName?accountDomainName=${accountDomainName}&maxPerPage=${maxPerPage}&pageNumber=${currentPageIndex}&searchString=${searchString}${catalogueFilter}`, successCallback: setInventoryRecordsData});
      
      
    },[currentPageIndex])


    useEffect(()=>{
        setCatalogueFilter(selectedOrderCatalogue ? `&catalogueId=${selectedOrderCatalogue.id}` : '')
    },[selectedOrderCatalogue])

    useEffect(()=>{

        clearTimeout(delayTimer?.current);

        //let catalogueFilter = selectedOrderCatalogue ? `&catalogueId=${selectedOrderCatalogue.id}` : ''
        delayTimer.current = setTimeout(function() {
            getEntities({url:BaseURL + `api/v1/inventory/GetInventoriesByAccountName?accountDomainName=${accountDomainName}&maxPerPage=${maxPerPage}&pageNumber=${currentPageIndex}&searchString=${searchString}${catalogueFilter}`, successCallback: setInventoryRecordsData});
      
        }, 1000);
        
    },[searchString, selectedOrderCatalogue, catalogueFilter])

    const delayTimer = useRef();
   
    const setInventoryRecordsData = (data) => {
        const {listData, pageNumber,totalRecords,totalPageCount} = data;
        setCurrentPageIndex(pageNumber);
        setTotalRecords(totalRecords);
        setTotalPageCount(totalPageCount);
        setInventories(listData);
    }
   
    useEffect(()=>{
        console.log(inventory)
    }, [inventory])
   

    const createNewOrder = () => {
        let newOrderId = uuidv4();

        let templateData = selectedOrderCatalogue ? {...selectedOrderCatalogue} : {}

        // :TODO
        //Check the customer entry
        //dispatch({type:'CREATE_ORDER', data: {...newOrder, catalogue:{...templateData}, id:newOrderId}});

        //history(`/edit-order-catalogue/${newOrderCatalogueId}`);

        postEntities(
            {
                url:BaseURL + 'api/v1/order/placeorderascustomer', 
                data:{...order, CatalogueId:templateData.id, catalogue:{...templateData}, invoice:{invoiceItems:[...cartItems]}, customerInfo:{...selectedCustomer}, id:newOrderId},
                successCallback: (d)=>{
                    console.log("new order", d)
                //setShowCreateNewOrderPopup(false);
                setOrder({...d});
                setHasPlacedAnOrder(true);
                setIsPlacingOrder(false);
                /* getEntities({
                    url:BaseURL + 'api/v1/ordercatalogue/getordercatalogues', 
                    successCallback:setOrders}); */
                    
                    //refreshData();
                    
                    //history(`/order/${d.id}`)
                },
                successNotification:{title:"Success", message:'Order created'},
                errorNotification:{title:"Failed", message:'Order creation failed'},
            }        
        )

        //history(`/edit-order/${newOrderId}`)
    }
  

    const stageCallbackHandler = (action, data) => {

        console.log("Key entered", {action,data})
        switch(action)
        {
           
            case "updateFormData" : updateFormData(data)
                break;
            case "updateInvoiceItems" : setCartItems(data?.invoiceItems)
                break;
                         
        }
    }
    
    

    

    const updateFormData = (formData) => {
                
        //let currentStage = orderStages[currentNavigationIndex];
        //dispatch('UPDATE_ORDER', 
        //setOrder(o => ({...o, orderData:{...formData, stageId:currentStage.id}}))
        //setOrderStages( os => os.map(o =>
        //    o.id === currentStage.id ? {...o, formData:[...formData]} : o));

        //setOrderDataSet([...formData])

        console.log("Entered data 333", formData)
            
    }

  

   

    const showEditingModal = (c) => {
        setInventory(c);
        setShowCreateNewOrderPopup(true);
        setIsEditing(true);
    }

    const addToCart = (item) =>{

        //cartItems.find(ci => ci.id === g.id) 
        console.log("cart", cartItems.find(ci => ci.id === item.id) )

        let cartItem = {...item, inventoryItemId:item.id, quantity:selectedQuantityTracker[item?.id] || 0}
        setCartItems(c => c.find(ci => ci.id == item.id) ? [...c] : [...c,cartItem])

    }

    useEffect(()=>{
        //cartItems.find(ci => ci.id === item.id) 

        //let cartItem = {...item, quantity:selectedQuantityTracker[item?.id] || 0}
        //setCartItems(c => c.find(ci => ci.id == item.id) ? [...c] : [...c,cartItem])
        setCartItems(c => c.map(ci => ({...ci,quantity:selectedQuantityTracker[ci?.id] || 0})))

    },[selectedQuantityTracker])

    const removeFromCart = (item) =>{

        setCartItems(c => c.filter(ci => ci.id != item.id))
        
    }

   


 
   
   /*  useEffect(()=>{
        console.log(inventory)
    }, [inventory]) */

    useEffect(()=>{
        console.log("Image loading 2", inventories)
        inventories?.map( i => i?.images?.map(a =>a.fileClass == 'IMAGE' && getImage(BaseURL + `api/v1/Inventory/GetFileByKey?key=${a.link}`, a.fileType)));
    
    }, [inventories])

    return(<><ViewWrapper title={'Inventory'}>
   
            <Prompt promptOptions={{...promptOkAction}} OkLabel={"Yes"}  isOpen={openPromptDialog} modalClosed={()=> setOpenPromptDialog(false)}/>
                 <div style={{margin:5, position:'relative', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                    {selectedOrderCatalogue?.title}

                    <span className='notifications-count'>{cartItems?.length || 0}</span>

                    {selectedOrderCatalogue && cartItems?.length > 0 &&
                    <buttom value={searchString} onClick={() => setShowOrderPlacementPopup(true)} className={'app-button-primary-01'}>
                        Place Order
                    </buttom>}
                </div>

                <div style={{margin:5, position:'relative'}}>
                    <div style={{display:'flex', position:'sticky', flexDirection:'row', width:'100%', margin:'10px 0px', alignItems:'center', justifyContent:'center'}}>
                    {searchString && <div style={{color:'#ab571e', marginRight:10}}  onClick={e=>setSearchString("")} >Clear</div>}    
                    <select className='search-input-wide'  value={selectedOrderCatalogue?.id} onChange={e=>setSelectedOrderCatalogue(orderCatalogues ? orderCatalogues.find(o => o.id===e.target.value) : null)} placeholder="Select Catalogue" >
                        {orderCatalogues.map(oc => <option value={oc.id}>{oc.title}</option>)}
                    </select>
                    
                    </div>      
                </div>

                <div style={{margin:5, position:'relative'}}>
                    <div style={{display:'flex', position:'sticky', flexDirection:'row', width:'100%', margin:'10px 0px', alignItems:'center', justifyContent:'center'}}>
                    {searchString && <div style={{color:'#ab571e', marginRight:10}}  onClick={e=>setSearchString("")} >Clear</div>}    
                    <input className='search-input-wide'  value={searchString} onChange={e=>setSearchString(e.target.value)} placeholder="Search" style={{backgroundImage:`url('${searchIcon}')`, backgroundRepeat:'no-repeat', backgroundPositionX:'98%',  backgroundPositionY:'50%',}}/>
                    
                    </div>      
                </div>

                <div style={{margin:5, display:'flex', justifyContent:'space-between', position:'relative'}}>
                    <span style={{color:'#888888'}}>{`Total Records: ${totalRecords}`}</span>
                <PaginationView numberOfPages={totalPageCount} partLength={3} currentPageIndex={currentPageIndex+1} onClick={(i)=>setCurrentPageIndex(i-1)}/>  
                </div>

                <div style={{margin:5}}>
                <Prompt promptOptions={{...promptOkAction}} OkLabel={"Yes"}  isOpen={openPromptDialog} modalClosed={()=> setOpenPromptDialog(false)}/>
                
                {inventories && inventories.length > 0 ? 
                <div style={{ width:'100%', display: 'inline-grid',
                flexDirection: 'column', columnGap:20, rowGap:20, gridTemplateColumns:'repeat(auto-fill, minmax(260px, 1fr))', minHeight:'calc(100vh-50px)'}}>
              
                {inventories.map((g,i) => 
               <div className='item-card' style={{margin:0, height:'auto', background:'white', minHeight:120, boxShadow:'2px 2px 11px -2px rgb(20 20 20 / 20%)',  marginBottom:10, alignItems:'center', width:'100%'}}>
               <div style={{display:'flex', width:'100%', justifyContent:'left', flexDirection:'column', alignItems:'center', }}>
                   <div style={{padding:5, display:'flex', width:'100%', justifyContent:'left', flexDirection:'column',alignItems:'center', fontSize:20}}>
                       {/* <div style={{background:'#ffe8be', padding:15, width:50, fontSize:16, height:50, margin:15}} className="user-info-avartar">
                       {i+1}                 
                       </div> */}
                       <div style={{fontSize:11, color:'grey', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                       {/* <img  src={defaultIcon} style={{height:180, background:g.type === 'Service'? '#d6f7ef' :'white', borderRadius:5, padding:5,  margin:5, marginRight:20}} alt="logo" /> 
                        */}
                         <ImageBox background={'none'} src={g.defaultImageFile ? images[BaseURL + `api/v1/Inventory/GetFileByKey?key=${g.defaultImageFile.link}`]: defaultIcon} style={{width:'100%', minWidth:180, background:g.type === 'Service'? '#d6f7ef' :'none', borderRadius:5, padding:5,  margin:5, marginRight:10}} alt={g.title} /> 
                      
                        {/* {g.type} */}
                       </div>
                       {/*  <img  src={defaultIcon} style={{height:50, background:'none', borderRadius:5,   margin:5, marginRight:20}} alt="logo" />  */}
                       <div style={{display:'flex', flexGrow:1, flexDirection:'column'}}>

                           <div style={{display:'flex', fontSize:12}}>{g.title}</div>
                           <div style={{display:'flex', color:'#888888', paddingTop:5, fontSize:12}}>{`${g.availableQuantity} Available`}</div>
                           <div style={{display:'flex', color:'green', paddingTop:5, fontSize:12}}>{/* `\u20A6` */}{`NGN ${g.unitPrice} `}</div>
                           
                       
                       </div>
                  
                   </div>
                   <div style={{display:'flex', width:'100%', padding:10, justifyContent:'flex-end'}}>
                            <NumberField key={`quantity-value-${g.id}`} onChange={(d)=>setSelectedQuantityTracker(s => ({...s,[g.id]:d.value}))} value={selectedQuantityTracker[g.id] || 0}/>
                           <button className='app-button-secondary-small' onClick={()=>showEditingModal(g)}>View</button>
                           {cartItems && [...cartItems].find(ci => ci.id === g.id) ? <button className='app-button-secondary-small' onClick={()=>removeFromCart(g)}>Remove</button> : <button className='app-button-secondary-small' onClick={()=>addToCart(g)}>Add</button>}
                          
                   </div>

               </div>
           </div>
                )}</div>
                :
                <div style={{width:'100%', height:100, fontSize:20, color:'#CECECE', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    No Record found  
                </div>}
                
                
                <div style={{minHeight:100}}>

                {/* <OrderItemsListView items={cartItems} /> */}
                <PopUp isEmbedded={true} fillWidth={true} open={showOrderPlacementPopup} style={{maxWidth:700}} >
                    {hasPlacedAnOrder ? 
                    <div style={{width:'100%', background:'white',padding:10, display:'flex', height:'70vh', 
                    justifyContent:'center', alignItems:'center', flexDirection:'column', borderRadius:5}}>
                        <img src={okayIcon} width={60} style={{background:'#b1e7d3', margin:20, padding:10, borderRadius:'50%'}}/>
                        <span>Your order has been placed #{order?.orderNumber}</span>
                        <div style={{padding:20}}>
                            <button onClick={()=>{setShowOrderPlacementPopup(false); setHasPlacedAnOrder(false)}} className={'app-button-primary-01'}>Close</button>
                        </div>
                    </div>:
                    <div style={{width:'100%', background:'white',padding:10, borderRadius:5}}>
                    <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                        <button value={searchString} onClick={() => setShowOrderPlacementPopup(false)} className={'app-button-primary-01'}>
                                Back
                        </button>
                        <span className='notifications-count'>{cartItems?.length || 0}</span>

                        {selectedOrderCatalogue && cartItems?.length > 0 &&
                        <buttom value={searchString} onClick={() => createNewOrder()} className={'app-button-primary-01'}>
                            Place Order
                        </buttom>}
                    </div>
                    <TabbedView tabbedViewCaptions={["Cart", "Your Details"]} style={{width:'100%', height:'65vh'}}>
                    <div style={{padding:10, background:'white', width:'100%'}}>

                    
                        
                    
                    <OrderForm key={`${cartItems?.map(c => c.id).concat()}-${selectedOrderCatalogue ? selectedOrderCatalogue?.id : ""}`} isEditable={false} stageCallbackHandler={stageCallbackHandler}  items={cartItems} formFields={(selectedOrderCatalogue?.stages && selectedOrderCatalogue?.stages[0]?.dataForm?.items) || []}/>
                    </div>
                    <div style={{padding:10, background:'white', width:'100%'}}>
                        <div style={{padding:15, display:'flex', flexDirection:'column', justifyContent:'left', alignItems:'center', fontSize:20}}>
                        {/* <div style={{width:'100%', padding:10}}>
                            <button className='app-button-primary'  onClick={()=>setShowSelectCustomerPopup(true)} >Select existing Customer</button>
                        </div> */}
                        <div className='input-field' style={{width:'100%'}}>
                            <label className='form-label'>Customer FirstName</label>
                            <input value={selectedCustomer?.firstName} onChange={e => setSelectedCustomer(sot => sot ? {...sot, firstName:e.target.value} : null)} className='form-text-input'/>
                        </div> 
                        <div className='input-field' style={{width:'100%'}}>
                            <label className='form-label'>Customer Last Name *</label>
                            <input value={selectedCustomer?.lastName} onChange={e => setSelectedCustomer(sot => sot ? {...sot, lastName:e.target.value} : null)} className='form-text-input'/>
                        </div> 
                        <div className='input-field' style={{width:'100%'}}>
                            <label className='form-label'>Customer Email *</label>
                            <input value={selectedCustomer?.email} onChange={e => setSelectedCustomer(sot => sot ? {...sot, email:e.target.value} : null)} className='form-text-input'/>
                        </div>  
                        <div className='input-field' style={{width:'100%'}}>
                            <label className='form-label'>Customer Phone *</label>
                            <input value={selectedCustomer?.phoneNumber} onChange={e => setSelectedCustomer(sot => sot ? {...sot, phoneNumber:e.target.value} : null)} className='form-text-input'/>
                        </div>   
                        <div className='input-field' style={{width:'100%'}}>
                            <label className='form-label'>Customer Address*</label>
                            <input value={selectedCustomer?.address} onChange={e => setSelectedCustomer(sot => sot ? {...sot, address:e.target.value} : null)} className='form-text-input'/>
                        </div>        
                        </div>
                    </div>
                    </TabbedView>
                    </div>}
                    
                </PopUp>
                </div>
                
                </div>
    </ViewWrapper>
    {<PopUp open={showCreateNewOrderPopup} >
    <div style={{width:'100%', height:'100%', display:'flex', flexDirection:'column',
        padding:0,
        borderRadius:10, 
        overflow:'hidden',
        boxShadow:'2px 2px 5px rgba(10,10,10,0.7)', minHeight:40, background:'white'}}>
            <div style={{padding:15, display:'flex', justifyContent:'left', alignItems:'center', fontSize:20}}>
           {/*  <img  src={defaultIcon} style={{height:50, background:'#ecf5ef', borderRadius:5,   margin:2, marginRight:20}} alt="logo" /> 
            */} <div style={{display:'flex', flexDirection:'column'}}>

                <div style={{display:'flex'}}>{isEditing ? "Edit Inventory Item" :'New Inventory Item'}</div>
               
            </div>
            
            </div>
            <div style={{padding:15, display:'flex', flexDirection:'column', justifyContent:'left', alignItems:'center', fontSize:20}}>
            <div className='input-field' style={{width:'100%'}}>
                <label className='form-label'>Title</label>
                <input value={inventory.title} onChange={e => setInventory(sot => sot ? {...sot, title:e.target.value} : null)} className='form-text-input'/>
            </div> 
            <div className='input-field' style={{width:'100%'}}>
                <label className='form-label'>Description</label>
                <input value={inventory.description} onChange={e => setInventory(sot => sot ? {...sot, description:e.target.value} : null)} className='form-text-input'/>
            </div> 
            <div className='input-field' style={{width:'100%'}}>
                <label className='form-label'>Unit</label>
                <input value={inventory.unit} onChange={e => setInventory(sot => sot ? {...sot, unit:e.target.value} : null)} className='form-text-input'/>
            </div>    
            <div className='input-field' style={{width:'100%'}}>
                <label className='form-label'>Unit Price</label>
                <input value={inventory.unitPrice} onChange={e => setInventory(sot => sot ? {...sot, unitPrice:parseFloat(e.target.value)} : null)} className='form-text-input'/>
            </div>    
            <div className='input-field' style={{width:'100%'}}>
                <label className='form-label'>Available Quantity</label>
                <input value={inventory.availableQuantity} onChange={e => setInventory(sot => sot ? {...sot, availableQuantity: parseFloat(e.target.value)} : null)} className='form-text-input'/>
            </div>   
            <div className='input-field' style={{width:'100%'}}>
                <label className='form-label'>Item Type</label>
                <select value={inventory.type} onChange={e => setInventory(sot => sot ? {...sot, type: e.target.value} : null)} className='form-text-input'>
                    <option value='Product'>Product</option>
                    <option value='Service'>Service</option>
                </select>
            </div>   
            <div className='input-field' style={{width:'100%'}}>
                <div style={{display:'flex', alignItems:'center'}}>
                
                <input type={'checkbox'} checked={inventory.isMappedToCatalogues} onChange={e => setInventory(sot => sot ? {...sot, isMappedToCatalogues: e.target.checked} : null)}  />
                <label className='form-label'>Is mapped only to catalogues</label>
                </div>
                
                {inventory.isMappedToCatalogues && 
                <div>
                    <div> {inventory.catalogues.length}
                    {inventory.catalogues && inventory.catalogues.map(invCat => <div key={invCat.catalogueId}> {invCat.catalogueName} </div>)}
                    </div>
                    <button onClick={()=>setShowAddCataloguesPopup(true)}>Add Catalogue</button>
                </div>
                }
            </div>          
            </div>          
                
            <div style={{width:'100%', 
                padding:10,                
                background:'#EEEEEE', display:'flex', justifyContent:'space-between'}}>
               {/*  <button className='app-button-primary' onClick={() => isEditing? updateInventory() : createInventory()} >{isEditing ? "Update" : "Create"}</button> */}
                <button className='app-button-primary' onClick={() => {setIsEditing(false);setShowCreateNewOrderPopup(false)}}>Cancel</button>
            </div>            
        </div>

        </PopUp>}

        
    </>)

}   

export default CustomerViewLayoutWrapper(CustomerInventoryView, {showNavigationBar:true,removePadding:false, showSideMenu:true});