export const BaseURL =  "https://api.odanow.net/";
//export const BaseURL =   "https://localhost:44369/";
export const CREATE_NEW_USER_URL = BaseURL+"api/v1/user/register";
export const USERS_URL = BaseURL+"/users";
export const AUTHENTICATE_USER_URL = BaseURL+"api/v1/user/login";
export const REQUEST_PASSWORD_RESET_URL = BaseURL+"api/v1/user/requestPasswordReset";
export const PASSWORD_RESET_URL = BaseURL+"api/v1/user/ResetAccountPassword";
export const PIN_RESET_URL = BaseURL+"api/v1/user/ResetSubAccountPIN";



export const AUTHENTICATE_SUBACCOUNT_USER_URL = BaseURL+"api/v1/user/subaccountlogin";
export const AUTHENTICATE_CUSTOMER_URL = BaseURL+"api/v1/Customer/LoginCustomer";
export const REGISTER_CUSTOMER_URL = BaseURL+"api/v1/Customer/RegisterCustomerAccount";