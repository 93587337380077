import React, {useEffect, useState} from 'react';
import {
    Link,
    useNavigate,
    useParams
} from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';
import { useSpring, animated as a, config } from 'react-spring';
import LayoutWrapper from '../../layout/LayoutWrapper';
import ViewWrapper from '../ViewWrapper';
//import { deleteAsset } from '../redux/actions/assetActions';
import Prompt from '../../components/Prompt/Prompt';
import searchIcon from '../../assets/images/search.svg';
import defaultIcon  from '../../assets/images/default-game-icon.svg';
import PopUp from '../../components/PopUp/PopUp';
import backgroundImage from '../../assets/images/background_01.svg';
import {v4 as uuidv4} from 'uuid';
import axios from 'axios';
import Moment from 'moment';
import { BaseURL } from '../../constants/URLs';

import { getEntities, postEntities, putEntities, deleteEntities } from '../../api/restUtilities';
import { useRef } from 'react';
import PaginationView from '../../components/PaginationView/PaginationView';
import QRCode from 'react-qr-code';
import SearchListView from '../../components/SearchListView/SearchListView';
import CustomerViewLayoutWrapper from './CustomerViewLayoutWrapper';

const NewOrderSetupView = (props)  => {
    
    
    const history = useNavigate();
    //const {accountDomainName} = useParams();
    const dummyOrderData ={
        "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        "catalogueId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        "accountId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        "createdBy": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        "lastModifiedBy": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        "orderNumber": "string",
        "title": "string",
        "description": "string",
        "invoice": null,
        "customerInfo": null,
        "currentStage": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        "creationDate": "2023-01-06T03:23:58.783Z",
        "lastModificationDate": "2023-01-06T03:23:58.783Z"
      }
      const customerAccount = useSelector(state => state.customerAccount); 

    const [openPromptDialog, setOpenPromptDialog] = useState(false);
    const [promptOkAction, setPromptOkAction] = useState(null);
    const [searchString, setSearchString] = useState("");
    //const [showCreateNewOrderPopup, setShowCreateNewOrderPopup] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(customerAccount ? {...customerAccount} : null)
    const [showSelectCustomerPopup, setShowSelectCustomerPopup] = useState(false)
    const [showCreateNewOrderPopup, setShowCreateNewOrderPopup] = useState(false);   
    const [selectedOrderCatalogue, setSelectedOrderCatalogue] = useState(null);
    const [newOrder, setNewOrder] = useState({...dummyOrderData})
    //const [orderList, setOrderList] = useState([...orders])
   
    const [orders, setOrders] = useState([]);    
    const [orderCatalogues, setOrderCatalogues] = useState([])
    
    const [currentPageIndex, setCurrentPageIndex] = useState(0) 
    const [totalRecords, setTotalRecords] = useState(0) 
    const [totalPageCount, setTotalPageCount] = useState(0) 
    const [isDataLoaded, setIsDataLoaded] = useState(false)

    const maxPerPage = 10

    const refreshData = (newPageIndex) =>
    {
        getEntities({url:BaseURL + `api/v1/order/getorders?maxPerPage=${maxPerPage}&pageNumber=${newPageIndex != undefined ? newPageIndex :currentPageIndex}&searchString=${searchString}`, successCallback: setOrderRecordsData});
      
        getEntities({
            url:BaseURL + 'api/v1/ordercatalogue/getordercatalogues', 
            successCallback:setOrderCatalogues});
    }

    /*useEffect(()=>{


       
    },[currentPageIndex])*/

    useEffect(()=>{

        clearTimeout(delayTimer?.current);
        delayTimer.current = setTimeout(function() {
            //getEntities({url:BaseURL + `api/v1/order/getcustomers?maxPerPage=${maxPerPage}&pageNumber=${currentPageIndex}&searchString=${searchString}`, successCallback: setOrderRecordsData});
            refreshData()
            setIsDataLoaded(true)
        }, 1000);
        
    },[searchString])

    const delayTimer = useRef();
 

    const setOrderRecordsData = (data) => {
        const {listData, pageNumber,totalRecords,totalPageCount} = data;

        //console.log(data)
        setCurrentPageIndex(pageNumber);
        setTotalRecords(totalRecords);
        setTotalPageCount(totalPageCount);
        setOrders(listData);
    }
   
   
   

    const formatDate = (lastPlayed) => {
       
        if(lastPlayed && lastPlayed !== -Infinity)
        {
            console.log("lastPlayed",lastPlayed);
            return Moment(new Date(lastPlayed)).startOf('second').fromNow()
        }
        return "";
        
    }

    const deleteOrder = (id) => {
       let order = orders.find(o => o.id === id)

       if(order)
        deleteEntities(
            {
                url:BaseURL + 'api/v1/order/remove?id='+id, 
                data:{...order},
                successCallback: ()=>{
                    setShowCreateNewOrderPopup(false);
                    //getEntities({url:BaseURL + `api/v1/order/getcustomers?maxPerPage=${maxPerPage}&pageNumber=${currentPageIndex}&searchString=${searchString}`, successCallback: setOrderRecordsData});
                    refreshData();
                    setNewOrder({...dummyOrderData})
                },
                successNotification:{title:"Success", message:'Customer deleted'},
                errorNotification:{title:"Failed", message:'Customer delete failed'},
            }        
        )

    }

    const deleteModel = (id) =>
    {
        setOpenPromptDialog(true);
        setPromptOkAction({message: "Do you want to delete the Customer?",OkAction:()=>{deleteOrder(id);setOpenPromptDialog(false)}});
    }

    

    const createNewOrder = () => {
        let newOrderId = uuidv4();

        let templateData = selectedOrderCatalogue ? {...selectedOrderCatalogue} : {}

        // :TODO
        //Check the customer entry
        //dispatch({type:'CREATE_ORDER', data: {...newOrder, catalogue:{...templateData}, id:newOrderId}});

        //history(`/edit-order-catalogue/${newOrderCatalogueId}`);

        postEntities(
            {
                url:BaseURL + 'api/v1/order/save', 
                data:{...newOrder, CatalogueId:templateData.id, catalogue:{...templateData}, customerInfo:{...selectedCustomer}, id:newOrderId},
                successCallback: (d)=>{
                    console.log("new order", d)
                //setShowCreateNewOrderPopup(false);
                setNewOrder({...d})
                /* getEntities({
                    url:BaseURL + 'api/v1/ordercatalogue/getordercatalogues', 
                    successCallback:setOrders}); */
                    refreshData();
                    //setNewOrderCatalogue({...dummyOrderCataloge})
                    //history(`/edit-order-catalogue/${d.id}`)
                    history(`/${accountDomainName}/manage-order/${d.id}`)
                },
                successNotification:{title:"Success", message:'Catalogue created'},
                errorNotification:{title:"Failed", message:'Catalogue creation failed'},
            }        
        )

        //history(`/edit-order/${newOrderId}`)
    }

    const toggleSelectedCatalogue = (id) => {
        if(selectedOrderCatalogue && selectedOrderCatalogue.id === id)
        {
            setSelectedOrderCatalogue(null);
        }
        else
        {
            setSelectedOrderCatalogue(orderCatalogues.find(ot => ot.id === id));
        }
    }

    useEffect(()=>{

        if(orderCatalogues && orderCatalogues.length > 0)
        setSelectedOrderCatalogue({...orderCatalogues[0]})
    },[orderCatalogues])

   
    /* useEffect(()=>{
        setOrderList([...orders])
    },[orders]) */

    useEffect(()=>{
        getEntities({
            url:BaseURL + 'api/v1/ordercatalogue/getordercatalogues', 
            successCallback:setOrderCatalogues});
    },[])
   
    const handleItemSelectionChange = () => 
    {
        
        //console.log(selectedCustomer)
        /* onFieldChangeHandler({name:'customerName', value:selectedCustomer.name})
        onFieldChangeHandler({name:'customerEmail', value:selectedCustomer.email})
        onFieldChangeHandler({name:'customerPhoneNumber', value:selectedCustomer.phoneNumber}) */
    }

    const {accountDomainName} = useParams()
    const [accountInfo, setAccountInfo] = useState(null)

    useEffect(()=>{
        getEntities({
            url:BaseURL + `api/v1/AccountConfiguration/GetAccountInfo?accountDomainName=${accountDomainName}`, 
            successCallback:setAccountInfo});
    },[])

    const renderComponent = ({data}) => {
       
        return (<div className='item-card' style={{margin:0, height:120, boxShadow:'2px 2px 11px -2px rgb(20 20 20 / 20%)', border:selectedCustomer?.id === data?.id ? '2px solid orange' :0,  marginBottom:10, alignItems:'center', width:'100%'}}>
        <div style={{padding:15, display:'flex', width:'100%', justifyContent:'left', alignItems:'center', fontSize:20}}>
           
            <button  className='app-button-secondary-small' onClick={e => setSelectedCustomer(data)} type="checkbox" >Select</button>
           <div style={{background:'aqua', padding:15, width:20, height:20, fontSize:13, margin:10}} className="user-info-avartar">
               {data.firstName?.toUpperCase()?.charAt(0)}                    
           </div>
           {/*  <img  src={defaultIcon} style={{height:50, background:'none', borderRadius:5,   margin:5, marginRight:20}} alt="logo" />  */}
           <div style={{display:'flex', flexGrow:1, flexDirection:'column'}}>

           <div style={{display:'flex'}}>{`${data.firstName} ${data.lastName}`}</div>
           <div style={{display:'flex', color:'#888888', fontSize:14}}>{data.email}</div>
           <div style={{display:'flex', color:'#888888', fontSize:14}}>{data.phoneNumber}</div>
           <div style={{display:'flex', color:'#888888', fontSize:14}}>{data.address}</div>          
       
       </div>
      
       </div>
   </div>)
      }

    return(<>
   
            <Prompt promptOptions={{...promptOkAction}} OkLabel={"Yes"}  isOpen={openPromptDialog} modalClosed={()=> setOpenPromptDialog(false)}/>
            {/* <div style={{padding:15, position:'relative', width:'100%'}}>
                    <div style={{display:'flex', fontSize:20, position:'sticky', flexDirection:'row', width:'100%', margin:'0px', alignItems:'center', justifyContent:'space-between', color:'grey'}}>
                   <img height={80} src={accountInfo?.businessLogo} />
                    {`@${accountDomainName}`}
                    </div>      
                </div> */}

                 {/* <div style={{padding:15, position:'relative', width:'100%'}}>
                    <div style={{display:'flex', position:'sticky', flexDirection:'row', width:'100%', margin:'0px', alignItems:'center', justifyContent:'flex-start'}}>
                   
                    <buttom value={searchString} onClick={() => setShowCreateNewOrderPopup(true)} className={'app-button-primary'}>
                        + New Order
                    </buttom>
                    </div>      
                </div> */}

                {/* <div style={{padding:15, position:'relative', width:'100%'}}>
                    <div style={{display:'flex', position:'sticky', flexDirection:'row', width:'100%', margin:'10px 0px', alignItems:'center', justifyContent:'center'}}>
                    {searchString && <div style={{color:'#ab571e', marginRight:10}}  onClick={e=>setSearchString("")} >Clear</div>}    
                    <input className='search-input-wide'  value={searchString} onChange={e=>setSearchString(e.target.value)} placeholder="Search" style={{backgroundImage:`url('${searchIcon}')`, backgroundRepeat:'no-repeat', backgroundPositionX:'98%',  backgroundPositionY:'50%',}}/>
                    
                    </div>      
                </div> */}

                {/* <div style={{padding:15, position:'relative', width:'100%', display:'flex', justifyContent:'space-between', position:'relative'}}>
                    <span style={{color:'#888888'}}>{`Total Records: ${totalRecords}`}</span>
                <PaginationView numberOfPages={totalPageCount} partLength={3} currentPageIndex={currentPageIndex+1} onClick={(i)=>{setCurrentPageIndex(i-1);refreshData(i-1);}}/>  
                </div> */}

                <div style={{padding:15, position:'relative', width:'100%'}}>
                
                <div style={{width:'100%', height:'100%', display:'flex', flexDirection:'column',
        padding:0,
        borderRadius:10, 
        overflow:'hidden',
        //boxShadow:'2px 2px 5px rgba(10,10,10,0.7)', 
        minHeight:40, background:'white'}}>
            <div style={{padding:15, display:'flex', justifyContent:'left', alignItems:'center', fontSize:20}}>
           {/*  <img  src={defaultIcon} style={{height:50, background:'#ecf5ef', borderRadius:5,   margin:2, marginRight:20}} alt="logo" /> 
            */} <div style={{display:'flex', flexDirection:'column'}}>

                <div style={{display:'flex'}}>{'New Order'}</div>
                <div style={{display:'flex'}}>
                    <span style={{color:'#888888', fontWeight:'normal', fontFamily:'Righteous',padding:'3px 0px',  fontSize:12}}>
                    {"Select a Workflow"}           
                    </span>
            
                </div>
            </div>
            
            </div>
            <div style={{padding:15, display:'flex', flexDirection:'column', justifyContent:'left', alignItems:'center', fontSize:20}}>
            {/* <div style={{width:'100%', padding:10}}>
                <button className='app-button-primary'  onClick={()=>setShowSelectCustomerPopup(true)} >Select existing Customer</button>
            </div> */}
            <div className='input-field' style={{width:'100%'}}>
                <label className='form-label'>Customer FirstName</label>
                <input value={selectedCustomer?.firstName} onChange={e => setSelectedCustomer(sot => sot ? {...sot, firstName:e.target.value} : null)} className='form-text-input'/>
            </div> 
            <div className='input-field' style={{width:'100%'}}>
                <label className='form-label'>Customer Last Name *</label>
                <input value={selectedCustomer?.email} onChange={e => setSelectedCustomer(sot => sot ? {...sot, lastName:e.target.value} : null)} className='form-text-input'/>
            </div> 
            <div className='input-field' style={{width:'100%'}}>
                <label className='form-label'>Customer Email *</label>
                <input value={selectedCustomer?.email} onChange={e => setSelectedCustomer(sot => sot ? {...sot, email:e.target.value} : null)} className='form-text-input'/>
            </div>  
            <div className='input-field' style={{width:'100%'}}>
                <label className='form-label'>Customer Phone *</label>
                <input value={selectedCustomer?.phoneNumber} onChange={e => setSelectedCustomer(sot => sot ? {...sot, phoneNumber:e.target.value} : null)} className='form-text-input'/>
            </div>          
            </div>
            <div style={{flexGrow:1, fontWeight:'normal', color:'#555555', maxHeight:'40vh', background:'#f0fdfa', boxShadow:'inset 0px 2px 5px', padding:20, textAlign:'left', overflowY:'auto'}}>
           
                {orderCatalogues?.map( oct => <div onClick={()=>toggleSelectedCatalogue(oct.id)} style={{ minHeight:100, margin:0, marginBottom:10, height:'auto', background:'white', justifyContent:'flex-start', alignItems:'center', width:'100%', backgroundColor:selectedOrderCatalogue && selectedOrderCatalogue.id === oct.id ?  '#f3ebe5' : 'white', border:selectedOrderCatalogue && selectedOrderCatalogue.id === oct.id ? '2px solid orange' : 0}}  className={'item-card'}>
                    
                    <img  src={defaultIcon} style={{height:80, padding:10, background:'none', borderRadius:5,   margin:2, }} alt="logo" />
                    <div style={{padding:10, display:'flex', height:'100', justifyContent:'flex-start', flexDirection:'column'}}>
                    <span>{oct.title}</span>
                    <span style={{marginTop:10, fontSize:12, color:'grey'}}>{oct.description}</span>
                    </div>
                    </div>)}
            </div>
                
            <div style={{width:'100%', 
                padding:10,       
                position:'fixed',
                bottom:0,
                left:0,         
                background:'#EEEEEE', display:'flex', justifyContent:'space-between'}}>
                <button className='app-button-primary' onClick={() => createNewOrder()} >Create</button>
                <button className='app-button-primary' onClick={() => setShowCreateNewOrderPopup(false)}>Cancel</button>
            </div>            
        </div>
                
                
                <div style={{height:80}}></div>
                
                </div>
   
    

       {/*  {<PopUp styles={{borderRadius:9}} isEmbedded={true} open={showSelectCustomerPopup} >
        <div style={{width:'100%', height:'100%', display:'flex', flexDirection:'column',
        padding:0,
        borderRadius:10, 
        overflow:'hidden',
        boxShadow:'2px 2px 5px rgba(10,10,10,0.7)', minHeight:50, background:'white'}}>
            <div style={{padding:0, display:'flex', justifyContent:'center', alignItems:'center', fontWeight:'normal', fontSize:16}}>
          
            <div style={{display:'flex', color:'#444444', padding:20, flexDirection:'column'}}>
            

            <SearchListView renderComponent={renderComponent} dataUrl={BaseURL + `api/v1/customer/getcustomers`}/>
            
            </div>
            
            </div>
           
                 
            <div style={{width:'100%', 
        padding:10,
         
        background:'#EEEEEE', display:'flex', justifyContent:'space-between'}}>
                <button className='app-button-primary' onClick={()=>{handleItemSelectionChange();setShowSelectCustomerPopup(false)}} >Add</button>
                <button className='app-button-primary' onClick={()=>setShowSelectCustomerPopup(false)} >Close</button>
            </div>            
        </div>
    </PopUp>} */}

  
    </>)

}   

export default CustomerViewLayoutWrapper(NewOrderSetupView, {showNavigationBar:false,removePadding:true, showSideMenu:false});