import React, {useEffect, useState} from 'react';
import {
    Link,
    useNavigate
} from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';
import Moment from 'moment';
import {v4 as uuidv4} from 'uuid';

import StageCard from '../Stage/StageCard';

const CatalogueStagesDesigner = (props) => {

   
    //const [currentNavigationStep, setCurrentNavigationStep] = useState(1)

    console.log("stages", props.orderStages)

    return(
      <div style={{padding:10,height:'100%', minWidth:350, maxWidth:600, width:'80%'}}>
            <div style={{display:'flex', flexDirection:'column', 
            width:'90%', 
            marginTop:10, marginBottom:40, justifyContent:'center'}}>
                    {!props.orderStages || props.orderStages.length === 0 &&  <div style={{display:'flex', cursor:'pointer', color:'#ff7f10', alignItems:'center', padding:10, flexGrow:1, justifyContent:'space-around', fontSize:14}}>               
                            <span onClick={()=>props.stageCallbackHandler("addStage", {position:1})}>+ Add Stage</span>
                    </div>}
                    {props.orderStages.map((os, index, arr) =><>
                    <div style={{display:'flex'}}>
                        <div style={{display:'flex', flexDirection:'column'}}>
                            <div style={{display:'flex', width:40, height:30,justifyContent:'center',}}>
                            {index > 0 && 
                            <div style={{borderLeft:'1px dashed green', height:30,}}></div>}
                            </div>
                            <div style={{
                            //background:index === currentNavigationStep ? 'green' : 'grey', 
                            background:'grey',
                            color:'white',
                            width:40, height:40, 
                            borderRadius:'50%',
                            position:'relative',
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center',
                            fontSize:13}}>{index + 1} 
                            {/* <span style={{whiteSpace:'nowrap', 
                            fontSize:10, color:'grey', position:'absolute', top:50}}>{ns.caption}</span> */}
                            </div>
                            <div style={{display:'flex', width:40, flexGrow:1, justifyContent:'center',}}>
                            {index < arr.length-1 &&
                            <div style={{borderLeft:'1px dashed green', height:'100%'}}></div>}
                            </div>
                        </div>
                       
                            <StageCard stageInfo={os} index={index} stageCallbackHandler={props.stageCallbackHandler}/>
                       
                    </div>
                        
                       

                    </>)}      
                    
            </div>
            </div>
    )
}


export default CatalogueStagesDesigner;