import React from 'react';
import NavigationBar from '../../layout/navigation/NavigationBar';
import '../../layout/LayoutWrapper.css'
import SideMenu from '../../layout/sideMenu/SideMenu';
import {SideMenuContext} from '../../layout/sideMenu/SideMenuContext';
import NotificationContainer from '../../notification/NotificationContainer';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { getEntities } from '../../api/restUtilities';
import CustomerNavigationView from './components/CustomerNavigationView';
// Navigation Layout is a HOC for pages
// that will require navigation views to be present.
const CustomerViewLayoutWrapper = (PageComponent, options={}, accountInfo, accountDomainName) => (props) => {
        
   

    return (<SideMenuContext>
        <div style={{display:'flex'}}>
            <div style={{width:100, background:'black'}}>dsgg</div>
          {/*<NotificationsWrapper>*/}
          <div style={{minHeight:'100vh', flexGrow:1}}>
            {options.showNavigationBar && <NavigationBar showSideMenuButton={options.showSideMenu}/>}
             {/* Notification Container */}
             <NotificationContainer />

             {accountDomainName === null ? <div>Store account not found!</div> : <div background={options.backgroundImage} className={`centered-view ${options.showSideMenu? 'offset-centered-view':''}`} style={{
                backgroundColor:options.backgroundColor,
                backgroundImage:`url(${options.backgroundImage})`,
                backgroundRepeat:'no-repeat',
                backgroundSize:'cover',
                flexDirection:'column',
                display:'flex',
                paddingTop:options.removePadding ? 0 : null,
                alignItems:`${options.alignCenter? 'center':'flex-start'}`,
                justifyContent:`${options.alignCenter? 'center': 'start'}`
            }}>     

                {/* <div style={{padding:5, position:'relative', width:'100%'}}>
                    <div style={{display:'flex', fontSize:20, position:'sticky', flexDirection:'row', width:'100%', margin:'0px', alignItems:'center', justifyContent:'space-between', color:'grey'}}>
                   <img height={80} src={accountInfo?.businessLogo} />
                    {`@${accountDomainName}`}
                    </div>      
                </div>     */}     
                <CustomerNavigationView/>
                
                
                {/*Page Component*/}
                <PageComponent {...props}/>
               
                {/*Side Menu*/}
                {options.showSideMenu === true && <SideMenu/>}
            </div>}
            </div>
            {/*</NotificationsWrapper>*/}
        </div>
          
        </SideMenuContext>)
}

export default CustomerViewLayoutWrapper;


