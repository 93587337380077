import React from 'react'


export const NavigationCounter = ({navigationSteps, currentNavigationStep, onClick}) => {

    return(
        <div style={{display:'flex', width:'80%', marginTop:15, marginBottom:40, alignItems:'center'}}>
               
                {navigationSteps.map((ns, index, arr) =><><div onClick={()=>onClick(index)} style={{
                    background:index === currentNavigationStep ? '#99e799' :  index > currentNavigationStep ? '#CECECE' : 'grey',
                    transform:index === currentNavigationStep ? 'scale(1.4)' : 'scale(1)',
                    transition:'transform 300ms', 
                    color:index === currentNavigationStep ? 'green' : 'white',
                    width:24, height:24, 
                    borderRadius:'50%',
                    position:'relative',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    cursor:'pointer',
                    fontSize:13}}>{index + 1} <span style={{whiteSpace:'nowrap', 
                    fontSize:10, color:'grey', position:'absolute', top:35}}>{ns.caption}</span></div>
                    {arr.length > 1 && index < arr.length-1 &&
                    <div style={{borderTop:`dashed ${currentNavigationStep > index ? "2px green" : "1px grey" }`, flexGrow:1}}></div>}
                </>)}      
                
        </div>
    )
}
