import React, { useEffect, useState } from 'react';
import {useNavigate} from 'react-router-dom';
import Logo1  from '../assets/images/brain-trainer-logo-large.svg';
import backgroundImage from '../assets/images/background_01.svg';
//import DropIns from '../games/components/DropIns/DropIns';
import {animated, config, useSpring} from 'react-spring';
import searchIcon from '../assets/images/search.svg'
import { useSelector, useDispatch} from 'react-redux';
import LayoutWrapper from '../layout/LayoutWrapper';
import ViewWrapper from './ViewWrapper';
//import { deleteAsset } from '../redux/actions/assetActions';
import Prompt from '../components/Prompt/Prompt';

const HelpView = (props) => {

    const [{x, opacity}, api] = useSpring(()=>({from:{x:-60, opacity:0}, config:config.wobbly}))
    const [activeViewIndex, setActiveViewIndex] = useState(0)
    const [searchString, setSearchString] = useState("");

    const filterList = (searchStr, data, fieldName) => {
        if(data && fieldName)
        {
            return data.filter( d => d[`${fieldName}`]?.toLowerCase().match(searchStr.toLowerCase()))
        }
        else
        {
           return []; 
        }
    }
   
    useEffect(()=>
    {
        api.start(()=>({to:{x:0, opacity:1}}))
    },[])

    const gameInfo = useSelector(state => state.gameInfo)

    return(<ViewWrapper title={"Help"}>
        <div style={{padding:10, position:'relative'}}>
        <div style={{textAlign:'justify'}} >
       
       <div style={{paddingLeft:10, padding:20, color:'#787878'}}>Order now is an order managment solution.</div>
      {/*  <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</div>
       */} 
       <div style={{margin:5, position:'relative'}}>
            <div style={{display:'flex', position:'sticky', flexDirection:'row', width:'100%', margin:'10px 0px', alignItems:'center', justifyContent:'center'}}>
            {searchString && <div style={{color:'#ab571e', marginRight:10}}  onClick={e=>setSearchString("")} >Clear</div>}    
            <input className='search-input-wide'  value={searchString} onChange={e=>setSearchString(e.target.value)} placeholder="Search Help" style={{backgroundImage:`url('${searchIcon}')`, backgroundRepeat:'no-repeat', backgroundPositionX:'98%',  backgroundPositionY:'50%',}}/>
            
            </div>      
        </div>

       {filterList(searchString, gameInfo, "title").map((gi, index) => <div>
           <div onClick={()=>setActiveViewIndex(avi => avi === index + 1 ? 0 : index + 1)} style={{fontWeight:'normal', background:'none', borderBottom:'1px dashed #d0d3b2', marginTop:10, padding:'10px 2px'}}>{gi.title}</div>
           <div style={{display: (index + 1) === activeViewIndex ? 'block': 'none' ,flexGrow:1, fontWeight:'normal', color:'#555555', margin: 2, maxHeight:'80vh', background:'#f0fdfa', padding:20, textAlign:'left', overflowY:'auto'}}>
               <div style={{fontWeight:'bold'}}>Description</div>
               <p>{gi?.description}</p>
               <div style={{fontWeight:'bold'}}>How To Play</div>
               <ol>{gi.howToPlay.map((h) => <li>{`${h}`}</li>)}</ol>
               <div style={{fontWeight:'bold'}}>Benefits</div>
               <ol>{gi.benefits.map((b,i) => <li>{`${b}`}</li>)}</ol>
           </div>
       </div>)}

       </div>
        </div>
       
        </ViewWrapper>)
}

export default LayoutWrapper(HelpView, {showNavigationBar:true, showSideMenu:true,backgroundImage});